/**
 * Created by Kaku.Guo on 2019/8/8.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ReviewDialogActions, ReviewActions, UploadActions} from '../../actions';
import {REVIEW_TYPES} from '../../constants';
import {getReviewValidations} from '../../data';
import {hasValue} from '../../utils';
import {getMessage} from '../index';

const mapDispatchToProps = dispatch => {
    return {
        addReview: model => dispatch(ReviewActions.addReview(model)),
        editReview: model => dispatch(ReviewActions.editReview(model)),
        getReviewDetailByAccount: model => dispatch(ReviewActions.getReviewDetailByAccount(model)),
        setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model)),
        mergeDialogOptions: model => dispatch(ReviewDialogActions.mergeOptions(model)),
        hideDialog: () => dispatch(ReviewDialogActions.hide(true)),
        uploadImages: model => dispatch(UploadActions.uploadQueue(model))
    };
};

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    return {messages};
};

function AddReviewBtn(props) {
    const {
        messages,
        data = {},
        addReview,
        editReview,
        getReviewDetailByAccount,
        isPostingAllowed,
        setDialogOptions,
        mergeDialogOptions,
        hideDialog,
        uploadImages
    } = props;
    const {id, sellerId} = data;
    const type = REVIEW_TYPES.REVIEW;
    const handleAdd = () => {
        const options = isPostingAllowed
            ? {
                  headerText: `messages.com.epicgames.plugin.store.asset.${type}.btn.add`,
                  saveText: `messages.com.epicgames.plugin.store.asset.${type}.dialog.submit.add`,
                  summaryPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.summary`,
                  descPlaceholder: `messages.com.epicgames.plugin.store.asset.${type}.desc`,
                  validations: getReviewValidations(messages, false, 8000, true),
                  isVisible: true,
                  isSummaryEnable: true,
                  isRatingEnable: true,
                  isDescEnable: true,
                  isImageEnable: true,
                  contentMaxLength: 8000,
                  isLoading: true,
                  onPreload: () => {
                      getReviewDetailByAccount({
                          offerId: id,
                          onSuccess: reviewData => {
                              if (hasValue(reviewData)) {
                                  mergeDialogOptions({
                                      isLoading: false,
                                      headerText: `messages.com.epicgames.plugin.store.asset.${type}.btn.edit`,
                                      saveText: `messages.com.epicgames.plugin.store.asset.${type}.btn.edit`,
                                      defaultValue: reviewData,
                                      onSave: value => {
                                          const nextData = {
                                              ...value,
                                              offerId: id,
                                              targetOwner: sellerId,
                                              type,
                                              topicId: reviewData.id,
                                              isListPage: true
                                          };
                                          if (nextData.images) {
                                              uploadImages({
                                                  type,
                                                  files: nextData.images,
                                                  onSuccess: nextImages => {
                                                      editReview({...nextData, images: nextImages});
                                                  }
                                              });
                                          } else {
                                              editReview(nextData);
                                          }
                                      }
                                  });
                              } else {
                                  mergeDialogOptions({isLoading: false});
                              }
                          },
                          onFailed: () => {
                              mergeDialogOptions({
                                  isLoading: false,
                                  error: {
                                      errorCode:
                                          'errors.com.epicgames.review.get_review_detail_failed'
                                  },
                                  preventSubmit: true
                              });
                          }
                      });
                  },
                  onSave: value => {
                      const nextData = {
                          ...value,
                          offerId: id,
                          targetOwner: sellerId,
                          type,
                          isListPage: true
                      };
                      if (nextData.images) {
                          uploadImages({
                              type,
                              files: nextData.images,
                              onSuccess: images => {
                                  addReview({...nextData, images});
                              }
                          });
                      } else {
                          addReview(nextData);
                      }
                  }
              }
            : {
                  isVisible: true,
                  headerText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.header',
                  contentText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.content',
                  saveText: 'messages.com.epicgames.plugin.store.asset.dialog.disabled.confirm',
                  onSave: () => hideDialog()
              };
        setDialogOptions(options);
    };
    return (
        <span className="btn add-btn add-review-btn" onClick={handleAdd}>
            {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.reviews.btn.add')}
        </span>
    );
}

AddReviewBtn.propTypes = {
    messages: PropTypes.object,
    data: PropTypes.object,
    addReview: PropTypes.func,
    editReview: PropTypes.func,
    getReviewDetailByAccount: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    mergeDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    uploadImages: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReviewBtn);
