import root from 'window-or-global';
import {takeLatest} from 'redux-saga/effects';
import {LauncherIntegrationUtils} from 'epic-launcher-integration/app/scripts';
import {LauncherIntegrationActions} from '../actions';
import { hasValue } from '../utils';

let assetItem = null;
const updateCommands = (commandList) => {
    LauncherIntegrationUtils.processLauncherObject(commandList);
    root.reduxStore.dispatch(LauncherIntegrationActions.updateCommands(commandList));
};

function registerCommands(externalCommandList) {
    if (externalCommandList) {
        updateCommands(externalCommandList);
    }
    assetItem.getcommands().then(updateCommands);
}

function updateDownloadProgress(progressEvent) {
    LauncherIntegrationUtils.processLauncherObject(progressEvent);
    root.reduxStore.dispatch(LauncherIntegrationActions.updateDownloadProgress(progressEvent));
}

function* initLauncherDownload(action) {
    try {
        const assetDetail = action.payload;
        assetItem = null;
        yield LauncherIntegrationUtils.configureItemFromVault(assetDetail.catalogItemId, (assetItemResponse) => {
            //Refresh the entitlements if item is not valid
            assetItem = assetItemResponse;
            if (assetDetail.owned && assetItem) {
                assetItem.getiteminfo().then((itemInfo) => {
                    if (!itemInfo || !itemInfo.bisvalid) {
                        LauncherIntegrationUtils.refreshEntitlements();
                    }
                }).catch((reason) => {
                    console.log('Failed to get item info, cannot refresh entitlements');
                });
            }

            assetItem.subscribe('CommandsUpdated', (commandList) => {
                registerCommands(commandList);
            }).then((id) => { assetItem.commandsEventId = id; });
            assetItem.subscribe('Progress', (progressEvent) => {
                updateDownloadProgress(progressEvent);
            }).then((id) => { assetItem.progressEventId = id; });
            registerCommands();
        });
    } catch (e) {
        //ignore
    }
}

function executeCommand(action) {
    try {
        const command = action.payload;
        assetItem.executecommand(command.id);
    } catch (e) {
        //ignore
    }
}

function executeDownloadCommand(action) {
    try {
        const command = action.payload;
        if (assetItem) {
            switch (command) {
                case ('cancel'):
                    assetItem.cancel();
                    break;
                case ('pause'):
                    assetItem.pause();
                    break;
                case ('resume'):
                    assetItem.resume();
                    break;
                default:
                    break;
            }
        }
    } catch (e) {
        //ignore
    }
}

function* reset() {
    if (hasValue(assetItem)) {
        if (hasValue(assetItem.commandsEventId)) {
            yield assetItem.unsubscribe(assetItem.commandsEventId).catch((e) => {});
        }
        if (hasValue(assetItem.progressEventId)) {
            yield assetItem.unsubscribe(assetItem.progressEventId).catch((e) => {});
        }
        assetItem = null;
    }
}

function* AssetSaga() {
    yield takeLatest(LauncherIntegrationActions.TYPES.INIT_LAUNCHER_DOWNLOAD, initLauncherDownload);
    yield takeLatest(LauncherIntegrationActions.TYPES.EXECUTE_COMMAND, executeCommand);
    yield takeLatest(LauncherIntegrationActions.TYPES.EXECUTE_DOWNLOAD_COMMAND, executeDownloadCommand);
    yield takeLatest(LauncherIntegrationActions.TYPES.RESET_LAUNCHER_DOWNLOAD, reset);
}

export default AssetSaga;