import React from 'react';
import {useIsLoggedIn} from 'epic-ue-shared';
import {getPath} from '@epic-core/common';
import history from 'history';
import root from 'window-or-global';

interface Properties {
    isLoggedIn: boolean;
    location: history.Location;
    globalBase: string;
}

export const LoginValidator = ({isLoggedIn, location, globalBase}: Properties) => {
    const graphQlIsLoggedIn = useIsLoggedIn();
    const search = location.search || '';

    if (!root.__server_side_render && !isLoggedIn && graphQlIsLoggedIn) {
        const to = `${getPath()}login?state=${globalBase}${getPath()}${search}`;
        setTimeout(() => {
            root.location.href = to;
        }, 500);
    }
    return <div />;
};
