import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

const mapStateToProps = state => {
    const {isLauncher} = state.get('config').toJS();
    return {isLauncher};
};

const SHAPE =
    'M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z';

const DialogCloseButton = props => (
    <path d={SHAPE} fill={props?.fillColor ? props.fillColor : '#f5f5f5'} />
);

DialogCloseButton.propTypes = {
    isLauncher: PropTypes.bool,
    fillColor: PropTypes.string
};

export default connect(mapStateToProps)(DialogCloseButton);
