/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Tab(props) {
    const {className, activeTabId, children, id, panelId, ...otherProps} = props;

    const isActive = activeTabId === id;

    const tabClasses = classNames('tab', {'tab--active': isActive}, className);

    return (
        <li className={tabClasses} id={id} role="presentation" {...otherProps}>
            <h2
                aria-controls={panelId}
                aria-selected={isActive ? 'true' : 'false'}
                className="tab__btn"
                role="tab">
                {children}
            </h2>
        </li>
    );
}

Tab.propTypes = {
    className: PropTypes.string,
    activeTabId: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    panelId: PropTypes.string
};
