/**
 * Created by Kaku.Guo on 2019/6/3.
 */
import axios from 'axios';
import Url from '../constants/Url';

class UploadApi {
    upload(params) {
        return axios.post(Url().UPLOAD, params, {
            headers: {
                contentType: 'application/x-www-form-urlencoded'
            }
        })
            .then(response => response.data.data)
            .catch((ex) => { throw ex; });
    }
}
export default new UploadApi();