import {useAccountStore} from 'epic-ue-shared';
import {useEffect} from 'react';
import root from 'window-or-global';

export const CabinedCheck = () => {
    const {account} = useAccountStore();
    const cabinedMode = account?.cabinedMode;
    const clientEnv = root.clientEnvConfig;
    const cabinedRedirect = clientEnv.EPIC_ACCOUNT_CABINED_REDIRECT;

    useEffect(() => {
        if (cabinedRedirect && cabinedMode) {
            root.location.href = cabinedRedirect;
        }
    }, [cabinedRedirect, cabinedMode]);

    return null;
};
