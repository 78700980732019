/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {AssetTile, getMessage, Message} from '../index';
import {UnrealLoading} from 'epic-ue-loading';

const AssetList = props => {
    const {
        isLoading,
        data = {},
        messages,
        supportForumUrl,
        handleAddToCart,
        isAddingOffer,
        currentOffer,
        offersInCart,
        isLauncher,
        isVaultPage
    } = props;
    const {elements} = data;

    if (isLoading) {
        return (
            <div className="asset-list-group asset-list-group-loading">
                <UnrealLoading fillViewHeight={false} />
            </div>
        );
    }

    const getNoResultContent = () => {
        if (isVaultPage) {
            return <Message code="messages.com.epicgames.plugin.store.vault.no_result" />;
        }
        return (
            <Fragment>
                <div className="sub-header">
                    <h2>
                        {getMessage(
                            messages,
                            'messages.com.epicgames.plugin.store.search.no_result'
                        )}
                    </h2>
                    <div className="shared">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.search.no_result_content',
                                    [supportForumUrl]
                                )
                            }}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        <div className="asset-list-group">
            {elements && elements.length
                ? elements.map((asset, idx) => (
                      <AssetTile
                          key={`asset-${idx}`}
                          data={asset}
                          index={idx}
                          isNewTagEnabled={true}
                          handleAddToCart={handleAddToCart}
                          isAddingOffer={isAddingOffer}
                          currentOffer={currentOffer}
                          offersInCart={offersInCart}
                          messages={messages}
                          isLauncher={isLauncher}
                          isVaultPage={isVaultPage}
                      />
                  ))
                : getNoResultContent()}
        </div>
    );
};
AssetList.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    messages: PropTypes.object,
    supportForumUrl: PropTypes.string,
    handleAddToCart: PropTypes.func,
    isAddingOffer: PropTypes.bool,
    currentOffer: PropTypes.string,
    offersInCart: PropTypes.array,
    isLauncher: PropTypes.bool,
    isVaultPage: PropTypes.bool
};
export default AssetList;
