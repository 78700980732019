import root from 'window-or-global';
import {generateRoutePath, getLocale} from '@epic-core/common';
import moment from 'moment';
import {
    PRICE_RANGE_REGX,
    IMAGE_NAME_REGX,
    PARAM_ARRAY_KEYS,
    NO_REVIEWS_CATEGORIES,
    NO_QUESTIONS_CATEGORIES
} from '../constants';
import {PageArgsSchema as generateParams} from '../data';

// const urlParamRegex = new RegExp('[?&]([^=&#]+)=([^&#]*)', 'g');

export function getAssetPath(asset) {
    return `${root.__webpack_asset_path || `${root.appContext}/static/`}${asset}`;
}

export function trackProgress() {
    if (
        typeof root.com.epicgames !== 'undefined' &&
        typeof root.com.epicgames.web !== 'undefined' &&
        !!root.com.epicgames.web.track
    ) {
        root.com.epicgames.web.track.loadPixel();
    }
}

export function loadScript(url) {
    if (typeof root.document === 'undefined') {
        console.log('FortniteUtils loadScript skipped, document not available');
        return;
    }
    const scriptTag = root.document.createElement('script');
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('src', url);
    root.document.head.appendChild(scriptTag);
}

export function loadTalon(origin = '') {
    const sdkOrigin = origin || 'talon-website-prod.ecosec.on.epicgames.com';
    return new Promise((resolve, reject) => {
        const script = root.document.createElement('script');
        script.src = `https://${sdkOrigin}/talon_sdk.js`;
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = e => reject(e);
        root.document.head.appendChild(script);
    }).catch(e => {
        throw e;
    });
}

export function processCoupons(coupons, totalPriceDisplay) {
    if (!coupons.length) {
        return '';
    }
    const codes = coupons
        .filter(
            ({
                codeStatus,
                consumptionMetadata: {minSalesPriceDisplay = {}} = {},
                useCount = 0,
                maxNumberOfUses = 0
            }) => {
                const isActive = codeStatus === 'ACTIVE';
                const available = useCount < maxNumberOfUses;
                const appliedMinPrice =
                    parseFloat(minSalesPriceDisplay.amount) <=
                    parseFloat(totalPriceDisplay.amount.replace(/,*/g, ''));
                return isActive && appliedMinPrice && available;
            }
        )
        .sort(
            (
                {consumptionMetadata: {amountDisplay: c1rice = {}} = {}},
                {consumptionMetadata: {amountDisplay: c2Price = {}} = {}}
            ) => {
                return parseFloat(c2Price.amount) - parseFloat(c1rice.amount);
            }
        );
    return codes.length ? codes[0].code : '';
}

export function canPlayVideo() {
    if (typeof root.document === 'undefined') {
        console.log('FortniteUtils canPlayVideo: false, document not available');
        return false;
    }
    let canPlay;
    const video = root.document.createElement('video');
    if (video.canPlayType && video.canPlayType('video/webm') !== '') {
        canPlay = 'webm';
    } else if (video.canPlayType && video.canPlayType('video/mp4') !== '') {
        canPlay = 'mp4';
    } else {
        canPlay = 'none';
    }
    return canPlay;
}

export function getDateParams(input) {
    const date = new Date(input);
    const time = date.getTime();
    const year = date.getFullYear();
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const month = date.getMonth();
    const enMonth = months[month + 1];
    const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    return {date, time, year, month, enMonth, day, hour, min, sec};
}

export function parseHTML(string) {
    const html = root.document.createElement('div');
    html.innerHTML = string;
    return html;
}

/**
 * If the delta for the x or y coordinates of the mouse have changed from mouse down to mouse up
 *  it is dragging motion and not intended to be a click.
 * @param  {Object} e Event object
 * @return {Boolean}   true/false
 */
export function draggingMotion(e, mouseDown) {
    const allowed = 40;
    const x = e.clientX;
    const y = e.clientY;

    if (mouseDown) {
        if (Math.abs(x - mouseDown.x) > allowed) {
            return true;
        }

        if (Math.abs(y - mouseDown.y) > allowed) {
            return true;
        }
    }

    return false;
}

export function getSurvivorNavLink(isLoggedIn, commander) {
    if (isLoggedIn && commander) {
        return generateRoutePath('/search-for-survivors/rewards', true);
    }
    return generateRoutePath('/search-for-survivors', true);
}

/**
 * Fisher-Yates shuffle function.  This shuffle is done in-place and is destructive
 * @param array
 */
export function shuffle(array) {
    let unShuffled = array.length;
    let element = null;
    let currentElement;

    // While there remain elements to shuffle…
    while (unShuffled) {
        // Pick a remaining element…
        currentElement = Math.floor(Math.random() * unShuffled--);

        // And swap it with the current element.
        element = array[unShuffled];
        array[unShuffled] = array[currentElement];
        array[currentElement] = element;
    }

    return array;
}

/**
 * Get locale from path
 * @param location
 */
const localeRegex = /(.*)\/(\w{2}(-{1}\w{2})?)\//;
export function getCurrentLocale(location) {
    const args = localeRegex.exec(location.pathname);
    if (args && args.length > 2 && args[1] === root.appContext) {
        return args[2];
    }
    return '';
}

export function isExpired(endDate) {
    return new Date(endDate).getTime() < new Date().getTime();
}

export function getError(error) {
    if (error) {
        return error.data || (error.response && error.response.data) || {};
    }
    return {};
}
export function debounce(func, wait, immediate) {
    let timeout;
    return function execute(...args) {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
export function isOneOf(value, array) {
    return !!(array.indexOf(value) + 1);
}

export function isSameObjectsWithNormalFields(obj, other, ignoreFields = [], includeFunc = []) {
    if (!obj || !other) return obj === other;
    const keysA = Object.keys(obj);
    const keysB = Object.keys(other);
    if (keysA.length !== keysB.length) return false;
    return keysA.every(key => {
        if (ignoreFields.indexOf(key) !== -1) {
            return true;
        }
        if (isOneOf(typeof obj[key], ['object', 'string', 'boolean', 'number'])) {
            return JSON.stringify(obj[key]) === JSON.stringify(other[key]);
        } else if (typeof obj[key] === 'function' && includeFunc.includes(key)) {
            return JSON.stringify(obj[key]()) === JSON.stringify(other[key]());
        }
        return true;
    });
}

export function generateAsyncActions(...args) {
    return args.reduce((prev, next) => {
        prev[next] = next;
        prev[`${next}_PENDING`] = `${next}_PENDING`;
        prev[`${next}_SUCCESS`] = `${next}_SUCCESS`;
        prev[`${next}_FAILED`] = `${next}_FAILED`;
        return prev;
    }, {});
}

export function formatDate(date = null) {
    let result = date;
    if (!result) {
        result = moment();
    }
    return `${result.format('YYYY-MM-DD,HH:mm:ss.SSS').replace(',', 'T')}Z`;
}

export function getReportHref(paramName, paramValue, contentType) {
    let finalHref;
    const locale = getLocale();
    const clientEnvConfig = root.clientEnvConfig || {};
    const reportBaseUrl = clientEnvConfig.EPIC_SAFETY_REPORT_URL || '';
    const reportPath = 'policies/reporting-misconduct/submit-report';
    const productIdVal = 'uem_content_report';
    if (root.location.href) {
        try {
            const contentUrlVal = new URL(root.location.href);
            if (contentUrlVal && paramName && paramValue) {
                contentUrlVal.searchParams.append(paramName, paramValue);
            }

            const reportHref = new URL(`${locale ? `${locale}/` : ''}${reportPath}`, reportBaseUrl);
            if (reportHref) {
                reportHref.searchParams.append('product_id', productIdVal);
                if (contentType) reportHref.searchParams.append('content_type', contentType);
                if (contentUrlVal && contentUrlVal.href) {
                    reportHref.searchParams.append(
                        'content_url',
                        encodeURI(contentUrlVal.toString())
                    );
                }
                finalHref = reportHref;
            }
        } catch (e) {
            console.log('getReportHref failed to construct href', e);
        }
    }
    return finalHref ? finalHref : `${reportBaseUrl}${locale ? `${locale}/` : ''}${reportPath}`;
}

export function slateToHtml(text) {
    if (!text) {
        return '';
    }
    let execText = text;
    const tagStartRegx = /<(\w+)(?:\s+\w+=".*?")*>/;
    let result = '';
    while (tagStartRegx.test(execText)) {
        const match = tagStartRegx.exec(execText);
        const tag = match[1];
        if (tag) {
            const tagEndIdx = execText.indexOf('</>');
            if (tagEndIdx) {
                result += execText.substring(0, tagEndIdx + 3).replace('</>', `</${tag}>`);
                execText = execText.substring(tagEndIdx + 3);
            }
        }
    }
    result += execText;
    if (result.includes('\r\n')) {
        result = result.replace(/\r\n/g, '<br />');
    }
    if (result.includes('\r')) {
        result = result.replace(/\r/g, '<br />');
    }
    if (result.includes('\n')) {
        result = result.replace(/\n/g, '<br />');
    }
    return result;
}

export function isJsonString(str) {
    try {
        if (typeof JSON.parse(str) === 'object') {
            return true;
        }
    } catch (e) {
        return false;
    }
    return false;
}

export function compareVersion(version1, version2) {
    const versionArray1 = version1.split('.');
    const versionArray2 = version2.split('.');
    let idx = 0;
    const minLength = Math.min(versionArray1.length, versionArray2.length);
    let diff = 0;
    while (
        idx < minLength &&
        (diff = versionArray1[idx].length - versionArray2[idx].length) === 0 &&
        (diff = versionArray1[idx].localeCompare(versionArray2[idx])) === 0
    ) {
        ++idx;
    }
    diff = diff !== 0 ? diff : versionArray1.length - versionArray2.length;
    return diff;
}

export function buildVersionSlice(compatibleApps = []) {
    const result = {};
    compatibleApps.forEach(ca => {
        const tempArr = ca.split('.');
        const valueArr = result[tempArr[0]] || [];
        valueArr.push(tempArr[1]);
        result[tempArr[0]] = valueArr;
    });
    return result;
}

export function buildVersionCombine(versionSlice = {}) {
    const keys = Object.keys(versionSlice);
    return keys.reduce((prev, next) => {
        const arr = versionSlice[next];
        let tempArr = [];
        let index = parseInt(arr[0], 10);
        const last = parseInt(arr[arr.length - 1], 10);
        while (index <= last + 1) {
            if (arr.includes(index.toString())) {
                tempArr.push(`${next}.${index}`);
            } else if (
                arr.includes(`${index.toString()}ea`) ||
                arr.includes(`${index.toString()}EA`)
            ) {
                tempArr.push(`${next}.${index} EA`);
            } else if (tempArr.length > 0) {
                const val =
                    tempArr.length === 1
                        ? tempArr[0]
                        : `${tempArr[0]} - ${tempArr[tempArr.length - 1]}`;
                prev.push(val);
                tempArr = [];
            }
            index++;
        }
        return prev;
    }, []);
}

export function formatCompatibleApps(compatibleApps = []) {
    if (!compatibleApps) {
        return [];
    }
    if (compatibleApps.length <= 1) {
        if (compatibleApps[0]) {
            const formattedEarlyAccessArr = [];
            if (compatibleApps[0].includes('ea')) {
                formattedEarlyAccessArr.push(compatibleApps[0].replace('ea', ' EA'));
                return formattedEarlyAccessArr;
            } else if (compatibleApps[0].includes('EA')) {
                formattedEarlyAccessArr.push(compatibleApps[0].replace('EA', ' EA'));
                return formattedEarlyAccessArr;
            }
        }
        return compatibleApps;
    }
    const sortedApps = compatibleApps.sort((a, b) => compareVersion(a, b));
    return buildVersionCombine(buildVersionSlice(sortedApps));
}

export function getImageParameters(url, field) {
    if (!url) {
        return '';
    }
    if (!field) {
        return url;
    }

    const parameters = IMAGE_NAME_REGX.exec(url);
    let result = '';
    if (parameters) {
        switch (field) {
            case 'path':
                result = parameters[1];
                break;
            case 'name':
                result = parameters[2];
                break;
            case 'extension':
                result = parameters[3];
                break;
            default:
                result = url;
        }
    }
    return result || '';
}

export function generateDisplayImageUrl(url, displayValues) {
    const imageName = getImageParameters(url, 'name');
    if (!imageName || !displayValues) {
        return url;
    }
    const displayItem = displayValues.find(item => {
        return item.type === 'Snapshot' && item.url.indexOf(`${imageName}_Snapshot`) > -1;
    });
    return displayItem ? displayItem.url : url;
}

export function escapeRegExp(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function highlightWords(line, word) {
    if (!word) {
        return line;
    }
    const regex = new RegExp(`(${escapeRegExp(word)})`, 'gi');
    return line.replace(regex, '<strong>$1</strong>');
}

export function hasValue(obj) {
    if (obj === undefined || obj === null) {
        return false;
    }
    if (obj instanceof Array || typeof obj === 'string') {
        return obj.length > 0;
    } else if (['number', 'boolean'].includes(typeof obj)) {
        return true;
    }
    return Object.keys(obj).length > 0;
}

export function getPriceOptions(priceRanges = {}, messages, getMessage) {
    const {priceRangeLabels = [], priceRanges: pricePeriods = []} = priceRanges;
    let result = [];
    if (priceRangeLabels.length) {
        result = priceRangeLabels.map((v, i) => ({
            label: v,
            value: pricePeriods[i]
        }));
    }
    result.unshift({
        label: getMessage(messages, 'messages.com.epicgames.plugin.store.asset.free'),
        value: '[0,0]'
    });
    return result;
}

export function isCategoryReviewable(categories = []) {
    let isCategoryReviewable = true;
    if (categories) {
        categories.forEach(cate => {
            if (cate.path && NO_REVIEWS_CATEGORIES.includes(cate.path)) {
                isCategoryReviewable = false;
            }
        });
    }
    return isCategoryReviewable;
}

export function isCategoryQuestionable(categories = []) {
    let isCategoryQuestionable = true;
    if (categories) {
        categories.forEach(cate => {
            if (cate.path && NO_QUESTIONS_CATEGORIES.includes(cate.path)) {
                isCategoryQuestionable = false;
            }
        });
    }
    return isCategoryQuestionable;
}

export function getCategoryFromPath({params}) {
    const {parentCategory, subCategory, category} = params;
    if (parentCategory && subCategory) {
        return `${parentCategory}/${subCategory}`;
    }
    return category;
}

export function generateCustomPrice(priceRange, decimals = 2) {
    const result = {
        low: '',
        high: ''
    };
    const priceArr = priceRange
        .replace('[', '')
        .replace(']', '')
        .split(',');
    if (priceArr.length) {
        if (priceArr[0]) {
            result.low = priceArr[0] / 10 ** decimals;
        }
        if (priceArr[1]) {
            result.high = priceArr[1] / 10 ** decimals;
        }
    }
    return result;
}

export function generatePriceRange(customPrice, decimals = 2) {
    const {low, high} = customPrice;
    const priceLow = low ? Math.floor((low * 10 ** decimals).toFixed(2)) : '';
    const priceHigh = high ? Math.ceil((high * 10 ** decimals).toFixed(2)) : '';
    return `[${priceLow},${priceHigh}]`;
}

export function generateFilterParams(params, {priceRanges, decimals}, engineVersions) {
    if (!hasValue(params)) {
        return {};
    }
    return generateParams(
        Object.keys(params).reduce((prev, next) => {
            let value = params[next];
            if (next === 'priceRange') {
                if (PRICE_RANGE_REGX.test(value)) {
                    prev.priceRange = value;
                    if (value !== '[0,0]' && !priceRanges.includes(value)) {
                        prev.customPriceRange = generateCustomPrice(value, decimals);
                        prev.isCustomPrice = true;
                        return prev;
                    }
                }
            } else if (PARAM_ARRAY_KEYS.includes(next) && !(value instanceof Array)) {
                value = [value];
            }
            if (next === 'compatibleWith' && value.some(pt => engineVersions.indexOf(pt) > 6)) {
                prev.isAllEngineVisible = true;
            }
            prev[next] = value;
            return prev;
        }, {})
    );
}

export function redirectLogin(loginBase) {
    const {config} = (root.reduxStore && root.reduxStore.getState().toJS()) || {config: {}};
    const currentUrl = root.location.href;
    if (config.isLauncher && hasValue(root.ue)) {
        root.ue.launcher.requestsignin(currentUrl);
        return;
    }
    root.location.href = `${root.appContext}/login?state=${currentUrl}`;
}

export function loginInterceptor(isLoggedIn, loginBase, callback) {
    return () => {
        if (!isLoggedIn) {
            redirectLogin(loginBase);
            return;
        }
        if (callback) {
            callback();
        }
    };
}

export function formatLink(link) {
    if (!link) {
        return '';
    }
    return link.indexOf('http') === 0 ? link : `http://${link}`;
}

export function combinePageTitle(messages, getMessage, code, args) {
    const suffix = getMessage(messages, 'messages.com.epicgames.plugin.store.title.suffix') || '';
    return `${getMessage(messages, code, args)} ${suffix}`;
}

export function runRule(value, validations) {
    if (!value) {
        return {};
    }
    return Object.keys(value).reduce((prev, next) => {
        if (validations[next] && validations[next].rules) {
            for (const rule in validations[next].rules) {
                if (validations[next].rules.hasOwnProperty(rule)) {
                    const validate = validations[next].rules[rule](value[next]);
                    if (!validate) {
                        prev[next] = validations[next].messages[rule];
                        break;
                    }
                }
            }
        }
        return prev;
    }, {});
}

export function trimLR(str) {
    if (!str) {
        return '';
    }
    return str.replace(/^\s+|\s+$/g, '');
}

export function formatWhiteSpaces(str = '', isRichTxt = false) {
    return isRichTxt
        ? str.replace(/\n\n\n+/g, '\n\n\n')
        : str.replace(/\t\t+/g, '\t\t').replace(/\s\s\s+/g, '   ');
}

export function formatTextInput(str = '', isRichTxt) {
    let _value = formatWhiteSpaces(str, isRichTxt);
    _value = trimLR(_value);
    return _value;
}
