/**
 * Created by Kaku.Guo on 2019/7/18.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {ModalDialog, ReviewItem, getMessage} from '../index';
import {REVIEW_TYPES} from '../../constants';
import {ReviewActions, ReviewDialogActions, UploadActions} from '../../actions';
import {hasValue} from '../../utils';

export function ReviewSummaryReducer(state, action = {}) {
    if (!action || !action.payload) {
        return {};
    }
    return Object.assign({}, state, action.payload);
}

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const {isLoadingReview, activeData, detailError} = state.get('review').toJS();
    return {
        messages,
        isLoading: isLoadingReview,
        data: activeData,
        error: detailError
    };
};

const mapDispatchToProps = dispatch => ({
    editReview: model => dispatch(ReviewActions.editReview(model)),
    deleteReview: model => dispatch(ReviewActions.deleteReview(model)),
    addReply: model => dispatch(ReviewActions.addReply(model)),
    editReply: model => dispatch(ReviewActions.editReply(model)),
    deleteReply: model => dispatch(ReviewActions.deleteReply(model)),
    uploadImages: model => dispatch(UploadActions.uploadQueue(model)),
    setDialogOptions: model => dispatch(ReviewDialogActions.setOptions(model))
});

function ReviewSummaryDialog(props) {
    const {messages, options = {}, stateDispatch, isLoading, data = {}, error} = props;
    const {className, isVisible = false, type} = options;
    const prefixCls = 'review-summary-modal';

    const getTargetEle = () => {
        if (!type) {
            return null;
        }
        if (isLoading) {
            return <div className="loading" />;
        }
        if (!hasValue(data)) {
            return null;
        }
        if (data.hasError) {
            return <div>{getMessage(messages, data.errorMsg)}</div>;
        }
        let reviewType;
        let reviewData;
        if (type === REVIEW_TYPES.REPLY) {
            const {topic, topicType, ...replyObj} = data;
            reviewType = topicType;
            reviewData = Object.assign({}, topic, {defaultReply: replyObj});
        } else {
            reviewType = type;
            reviewData = Object.assign({}, data, {bestReply: null, publisherReply: null});
            reviewData.isReplyEnabled =
                type === REVIEW_TYPES.REVIEW ||
                (type === REVIEW_TYPES.QUESTION && !!reviewData.identityOwned);
        }

        return (
            <ReviewItem type={reviewType} className={`${prefixCls}__target`} data={reviewData} />
        );
    };

    const classNames = cn(prefixCls, className);
    let errorMessage = null;
    if (error && error.errorCode) {
        errorMessage = getMessage(messages, error.errorCode);
    }
    const _titleMessge = data.hasError
        ? 'errors.com.epicgames.common.error'
        : 'messages.com.epicgames.plugin.store.asset.review.dialog.post_detail';
    return (
        <ModalDialog className={classNames} isVisible={isVisible} onClose={() => stateDispatch()}>
            <div className={`${prefixCls}__header`}>{getMessage(messages, _titleMessge)}</div>
            <div className={`${prefixCls}__content`}>{getTargetEle()}</div>
            <div className={cn('footer', `${prefixCls}__footer`)}>
                <div className="error">{errorMessage}</div>
            </div>
        </ModalDialog>
    );
}

ReviewSummaryDialog.propTypes = {
    messages: PropTypes.object,
    options: PropTypes.object,
    stateDispatch: PropTypes.func,
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSummaryDialog);
