/**
 * Created by Kaku.Guo on 2018/11/14.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {RatingUtil} from 'epic-comment-rating/app';

const Star = props => {
    const {offset, size} = props;
    const fillColor = '#dca100';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={size || 20}
            height={size || 20}
            viewBox="0 0 64 64"
            preserveAspectRatio="xMaxYMax slice">
            <defs>
                <linearGradient id={`half_grad_${offset}`} gradientUnits="objectBoundingBox">
                    <stop offset={offset / 100} stopColor={fillColor} stopOpacity="1" />
                    <stop offset={offset / 100} stopColor="#ffffff" stopOpacity="0" />
                </linearGradient>
            </defs>
            <path
                d="M 32.16821,51.958929 49.835803,61.572665 46.461779,41.210619 60.755351,26.789771
                41.002007,23.818686 32.16821,5.2926661 23.334413,23.818686 3.5810693,26.789771
                17.87464,41.210619 14.500617,61.572665 32.16821,51.958929"
                fill={`url(#half_grad_${offset})`}
                stroke={fillColor}
                strokeWidth="5px"
            />
        </svg>
    );
};
Star.propTypes = {
    offset: PropTypes.number,
    isLauncher: PropTypes.bool,
    size: PropTypes.number
};
export default Star;
export const getRatingStars = average => {
    const result = [];
    for (let i = 0; i < 5; i++) {
        const star = RatingUtil.calculateStarFillLeft(i, average);
        result.push(<Star offset={star} key={`rating-star-${i}`} />);
    }
    return result;
};
