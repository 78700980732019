/**
 * Created by Kaku.Guo on 2018/11/20.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { getSortOptions } from '../../constants';
import { getMessage, SortSelect } from '../index';

const SortDropdown = (props) => {
    const {
        messages,
        sortBy,
        sortDir,
        total,
        handleChange,
        withRelevancy,
        isFreePage
    } = props;

    const sortOptions = getSortOptions(withRelevancy, isFreePage);
    const selectOptions = sortOptions.map(it => ({
        text: getMessage(messages, it.label),
        value: `${it.value}_${it.dir}`
    }));

    return (
        <SortSelect
            className={`asset-list-sort ${!total ? 'disabled' : ''}`}
            title={getMessage(messages, 'messages.com.epicgames.plugin.store.filter.sort_by')}
            value={`${sortBy}_${sortDir}`}
            options={selectOptions}
            onChange={handleChange}
            disabled={!total} />
    );
};
SortDropdown.propTypes = {
    messages: PropTypes.object,
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
    total: PropTypes.number,
    handleChange: PropTypes.func,
    withRelevancy: PropTypes.bool,
    isFreePage: PropTypes.bool
};
export default SortDropdown;