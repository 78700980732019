import {SharedValueEffect} from '@epic-core/hooks';
import {PurchaseSuccessEvent, Receipt} from './purchase.service';
import {wishlistItemsKey, wishlistRefreshKey} from './wishlist.hooks';

export const WishlistEffects: SharedValueEffect = values => {
    values.on<Receipt>(PurchaseSuccessEvent, receipt => {
        console.log('Wishlist effect', receipt);
        const offers = receipt.Offers || [];
        const offerIds = offers.map(offer => offer.OfferId);

        const wishlistItems = values.get(wishlistItemsKey);
        let wishlistItemPurchased = false;
        wishlistItems.forEach(item => {
            if (offerIds.includes(item.offerId)) {
                wishlistItemPurchased = true;
            }
        });

        if (wishlistItemPurchased) {
            values.set(wishlistRefreshKey, true);
        }
    });
};
