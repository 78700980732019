/**
 * Created by Kaku.Guo on 2019/7/19.
 */
/* eslint-disable react/prop-types*/
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import cn from 'classnames';
import Select from '../select/Select';
import { runRule } from '../../../utils';

function ValidateSelect(props, ref) {
    const prefixCls = 'validate-select';
    const { className, isDisabled, placeholder, defaultValue, options, validateRule } = props;

    const [value, setValue] = useState(defaultValue || null);
    const [validateError, setValidateError] = useState(null);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            const validate = runRule({ value }, { value: validateRule });
            if (validate.value) {
                setValidateError(validate.value);
                return false;
            }
            return { value };
        }
    }));

    const handleChange = val => {
        setValue(val);
        setValidateError(null);
    };


    const classNames = cn(prefixCls, className);
    return (
        <div className={classNames}>
            <Select
                className={validateError && `${prefixCls}--error`}
                placeholder={placeholder}
                disabled={isDisabled}
                options={options}
                value={value}
                onChange={handleChange}
            />
            {validateError && <span className="error">{validateError}</span>}
        </div>
    );
}

export default forwardRef(ValidateSelect);