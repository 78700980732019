import {isPasswordReuseError, isValidationError} from './ValidationUtils';

import ValidationError from './errors/ValidationError';
import GeneralServiceError from './errors/GeneralServiceError';

/**
 *
 * Handles password resuse error
 *
 * @param {AxiosResponse} https://github.com/axios/axios/blob/master/index.d.ts#L70-L77
 * @return void
 *
 */
export function handlePasswordReuseError(ex) {
    const response = ex.response || {};
    const responseData = response.data || {};
    const errorCode = responseData.errorCode;
    const errorMessage = responseData.errorMessage;
    if (isPasswordReuseError(errorCode)) {
        throw new ValidationError(errorMessage, 'password', errorCode);
    }
}

/**
 *
 * Handles validation related errors
 *
 * @param {AxiosResponse} https://github.com/axios/axios/blob/master/index.d.ts#L70-L77
 * @return void
 *
 */
export function handleValidationError(ex) {
    const response = ex.response || {};
    const responseData = response.data || {};
    const errorCode = responseData.errorCode;
    const validationFailures = responseData.validationFailures || {};
    // Account service only returns the first validated field
    const targetFieldName = Object.keys(validationFailures)[0] || '';
    const targetValidationObj = validationFailures[targetFieldName];

    if (targetValidationObj && isValidationError(errorCode)) {
        throw new ValidationError(
            targetValidationObj.errorMessage,
            targetValidationObj.fieldName,
            targetValidationObj.errorCode
        );
    }
}

/**
 *
 * Handles any general service errors
 *
 */
export function handleGeneralServiceError(ex) {
    const response = ex.response || {};
    const responseData = response.data || {};
    const errorCode = responseData.errorCode;
    const errorMessage = responseData.errorMessage;
    if (errorCode && errorMessage) {
        throw new GeneralServiceError(errorMessage, errorCode);
    }
}

/**
 *
 * Handles general validation errors
 *
 * @param {AxiosResponse} https://github.com/axios/axios/blob/master/index.d.ts#L70-L77
 * @return void
 *
 */
export function handleServiceValidationError(ex) {
    // Password related validation
    handlePasswordReuseError(ex);

    // Handle general validation
    handleValidationError(ex);
}

/**
 *
 * General Service error handler
 *
 * @param {AxiosResponse} https://github.com/axios/axios/blob/master/index.d.ts#L70-L77
 * @param {String}        Custom log message
 * @return void
 *
 */
export function handleErrors(ex, customMessage): never {
    handleServiceValidationError(ex);
    handleGeneralServiceError(ex);

    console.error(`${customMessage}`, ex.message);
    throw ex;
}
