import {fromJS} from 'immutable';
import typeToReducer from 'type-to-reducer';
import {CmsActions} from '../actions';
import {getError} from '../utils';

const {GET_CMS_PAGE_DATA} = CmsActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isLoading: false,
    cmsPageData: {},
    error: ''
});

export default typeToReducer(
    {
        [GET_CMS_PAGE_DATA]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    cmsPageData: {},
                    error: ''
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    cmsPageData: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    isLoading: false,
                    error: getError(action.payload)
                })
        }
    },
    DEFAULT_STATE
);
