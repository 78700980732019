import React from 'react';
import Tooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { getMessage } from '../../../index';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    const messages = state.get('localization').get('messageProperties');
    return { messages };
};

const CustomToolBar = ({ messages }) => (
    <div id="custom-toolbar">
        <span className="ql-formats">
            <button data-tip data-for="editor-bold" className="ql-bold" />
            <Tooltip id="editor-bold">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.bold')}
            </Tooltip>

            <button data-tip data-for="editor-italic" className="ql-italic" />
            <Tooltip id="editor-italic">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.italic')}
            </Tooltip>

            <button data-tip data-for="editor-underline" className="ql-underline" />
            <Tooltip id="editor-underline">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.underline')}
            </Tooltip>

            <button data-tip data-for="editor-strike" className="ql-strike" />
            <Tooltip id="editor-strike">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.strikethrough')}
            </Tooltip>

            <button data-tip data-for="editor-blockquote" className="ql-blockquote" />
            <Tooltip id="editor-blockquote">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.quotes')}
            </Tooltip>

            <button data-tip data-for="editor-link" className="ql-link" />
            <Tooltip id="editor-link">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.hyperlink')}
            </Tooltip>
        </span>
        <span className="ql-formats">
            <button data-tip data-for="editor-ordered" className="ql-list" value="ordered" />
            <Tooltip id="editor-ordered">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.ordered')}
            </Tooltip>

            <button data-tip data-for="editor-bullet" className="ql-list" value="bullet" />
            <Tooltip id="editor-bullet">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.unordered')}
            </Tooltip>
        </span>
        <span className="ql-formats">
            <button data-tip data-for="editor-clean" className="ql-clean" />
            <Tooltip id="editor-clean">
                {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.review.editor.tooltip.clean')}
            </Tooltip>
        </span>
    </div>
);

CustomToolBar.propTypes = {
    messages: PropTypes.object
};

export default connect(mapStateToProps)(CustomToolBar);
