import root from 'window-or-global';
import {getPageNoLeadingSlash} from 'epic-node-cms/src/cmsUtils/utils';

export function getOrigin() {
    let appContext = '';
    if (root.appContext && root.appContext !== '/') {
        appContext = root.appContext;
    }

    if (!root.location.origin) {
        const port = root.location.port ? `:${root.location.port}` : '';
        root.location.origin = `${root.location.protocol}//${root.location.hostname}${port}`;
    }
    return root.location.origin + appContext;
}

export default function url() {
    const origin = getOrigin();

    const URL = {
        SAMPLE: {
            GET: `${origin}/api/sample`,
            SAVE: `${origin}/api/sample`
        },
        ASSET: {
            LIST: `${origin}/api/assets`,
            SUGGEST: `${origin}/api/assets/suggest`,
            VAULT: `${origin}/api/assets/vault`,
            SELLER: sellerName => `${origin}/api/assets/seller/${sellerName}`,
            GET: slug => `${origin}/api/assets/asset/${slug}`,
            ITEM: item => `${origin}/api/assets/item/${item}`
        },
        CMS_PAGE_DATA: {
            GET: (locale, urlPattern) =>
                `${origin}/api/${locale}/cms/${getPageNoLeadingSlash(urlPattern)}`
        },
        HOME_DATA: {
            GET: `${origin}/api/homeData`
        },
        TAG: {
            LIST: `${origin}/api/tags`
        },
        SHOPPING_CART: {
            LIST: `${origin}/api/shoppingCart`,
            ADD: `${origin}/api/shoppingCart`,
            DELETE: `${origin}/api/shoppingCart/delete`,
            CLEAR: `${origin}/api/shoppingCart/clear`,
            PREVIEW: `${origin}/api/shoppingCart/preview`
        },
        PURCHASE: {
            GET: `${origin}/purchase`,
            TRACK: `${origin}/api/purchase/track`
        },
        REVIEW: {
            ADD: (offerId, type) => `${origin}/api/review/${offerId}/${type}/add`,
            SET_BEST_REPLY: (topicId, replyId) =>
                `${origin}/api/review/questions/${topicId}/reply/${replyId}/best`,
            LIST: (offerId, type) => `${origin}/api/review/${offerId}/${type}/list`,
            DETAIL: (offerId, type, topicId) =>
                `${origin}/api/review/${offerId}/${type}/${topicId}/detail`,
            DETAIL_BY_ACCOUNT: offerId => `${origin}/api/review/${offerId}/reviews/detail`,
            EDIT: (offerId, type) => `${origin}/api/review/${offerId}/${type}/edit`,
            DELETE: (offerId, topicId) => `${origin}/api/review/${offerId}/reviews/${topicId}`,
            REPLY: (topicId, type) => `${origin}/api/review/${type}/${topicId}/reply`,
            GET_REPLY: (topicId, type) => `${origin}/api/review/${type}/${topicId}/reply`,
            VOTE: (id, type) => `${origin}/api/review/${type}/${id}/vote`, // vote type: reviews,questions,replies
            EDIT_REPLY: (replyId, type) => `${origin}/api/review/${type}/replies/${replyId}`,
            DELETE_REPLY: (replyId, type) => `${origin}/api/review/${type}/replies/${replyId}`,
            RATING: offerId => `${origin}/api/review/${offerId}/ratings`
        },
        UPLOAD: `${origin}/api/upload`,
        SSO: `${origin}/api/sso`,
        COUPON: {
            GET_DETAIL: slug => `${origin}/api/coupon/${slug}`
        },
        SELLER: {
            GET_TRADER_INFO: `${origin}/api/traderInfo`
        }
    };

    return URL;
}
