/**
 * Created by Kaku.Guo on 2018/11/19.
 */
import axios from 'axios';
import Url from '../constants/Url';

class ConfigApi {
    getSuggestTags(params) {
        return axios.get(Url().TAG.LIST, { params })
            .then(response => response.data.data)
            .catch((ex) => { throw ex; });
    }
}
export default new ConfigApi();