/**
 * Created by Kaku.Guo on 2018/11/30.
 */
const TYPES = {
    ERROR_OCCURRED: 'ERROR_OCCURRED',
    RESET_ERROR: 'RESET_ERROR'
};
export default {
    TYPES,
    errorOccurred: payload => ({
        type: TYPES.ERROR_OCCURRED,
        payload
    }),
    resetError: payload => ({
        type: TYPES.RESET_ERROR,
        payload
    })
};