import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TabPanel = props => {
    const {
        activeTabId,
        children,
        id,
        tabId,
        ...otherProps
    } = props;

    const isActive = activeTabId === tabId;

    const panelClasses = classNames(
        'tabPanel',
        {'tabPanel--active': isActive}
    );

    return (
        <section
            aria-hidden={isActive ? 'true' : null}
            aria-labelledby={tabId}
            className={panelClasses}
            id={id}
            role="tabpanel"
            {...otherProps}
        >
            {isActive && children}
        </section>
    );
};

TabPanel.propTypes = {
    activeTabId: PropTypes.string,
    children: PropTypes.node,
    id: PropTypes.string,
    tabId: PropTypes.string
};

export default TabPanel;
