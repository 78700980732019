/**
 * Created by Kaku.Guo on 2019/6/25.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function ReviewImages({ className, images = [], fullScreenDispatch }) {
    const prefixCls = 'review-images';

    const handleFullScreen = idx => e => {
        fullScreenDispatch(idx);
    };

    const classNames = cn(prefixCls, className);
    return (
        <ul className={classNames}>
            {images.map((it, idx) => (
                <li key={`${prefixCls}-${idx}`} onClick={handleFullScreen(idx)}>
                    <img src={it.thumbUrl || it.imageUrl} alt={idx} />
                </li>
            ))}
        </ul>
    );
}

ReviewImages.propTypes = {
    className: PropTypes.string,
    images: PropTypes.array,
    fullScreenDispatch: PropTypes.func
};

export default ReviewImages;