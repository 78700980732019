/**
 * Created by Kaku.Guo on 2019/5/16.
 */
/* eslint-disable react/prop-types*/
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { getMessage, Rate } from '../../index';

function SummaryInput(props, ref) {
    const prefixCls = 'summary-input';
    const { placeholder = '', value: defaultValue = '', rating: defaultRating = 0, locale, username, className, required,
        messages, isDisabled, validateError, ratingError, isRatingEnable, isLauncher, clearValidator } = props;
    const localeNow = moment().locale(locale).format('LLL');
    const [value, setValue] = useState(defaultValue);
    const [rating, setRating] = useState(defaultRating);
    const [errorMessage, setErrorMessage] = useState(validateError);
    const [ratingErrorMessage, setRatingErrorMessage] = useState(ratingError);
    const classNames = cn(prefixCls, className, errorMessage && `${prefixCls}--error`);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        setRating(defaultRating);
    }, [defaultRating]);

    useEffect(() => {
        setErrorMessage(validateError);
    }, [validateError]);

    useEffect(() => {
        setRatingErrorMessage(ratingError);
    }, [ratingError]);

    useImperativeHandle(ref, () => ({
        getValue: () => value,
        getRating: () => rating
    }));

    const handleChange = e => {
        setValue(e.target.value);
        // hooks can't be triggered with triggered with same validation.
        clearValidator();
    };

    const handleRatingChange = v => {
        setRating(v);
        clearValidator();
    };

    const placeHolder = required && placeholder ? `* ${placeholder}` : placeholder;

    return (
        <div className={classNames}>
            <div className={`${prefixCls}__fields`}>
                {isRatingEnable &&
                (<div className={cn(`${prefixCls}__rating-container`, ratingErrorMessage && `${prefixCls}__rating-container--error`)}>
                    <Rate
                        className={`${prefixCls}__rating`}
                        isLauncher={isLauncher}
                        initialRating={rating}
                        onChange={handleRatingChange}
                        readonly={isDisabled} />
                    {ratingErrorMessage && <span className={`${prefixCls}__error-message`}>{ratingErrorMessage}</span>}
                </div>
                )}
                <div className={cn(`${prefixCls}__input-container`, errorMessage && `${prefixCls}__input-container--error`)}>
                    <input
                        className={`${prefixCls}__input`}
                        type="text"
                        value={value}
                        maxLength={100}
                        placeholder={placeHolder}
                        disabled={isDisabled}
                        onChange={handleChange} />
                    {errorMessage && <span className={`${prefixCls}__error-message`}>{errorMessage}</span>}
                </div>
            </div>
            <span className={`${prefixCls}__message`}>{getMessage(messages, 'messages.com.epicgames.plugin.store.asset.question.summary_tip', [username, localeNow])}</span>
        </div>
    );
}

export default forwardRef(SummaryInput);
