/**
 * Created by Kaku.Guo on 2018/10/29.
 */
import typeToReducer from 'type-to-reducer';
import {fromJS} from 'immutable';
import {AssetActions} from '../actions';
import {getError} from '../utils';
import {emitEvent} from 'epic-ue-shared';
import root from 'window-or-global';

const {
    LIST_ASSETS,
    SUGGEST_ASSETS,
    VAULT_ASSETS,
    GET_HOME_DATA,
    GET_ASSET,
    GET_HELMET,
    SET_ASSETS_OWNED,
    SELLER_ASSETS,
    UPDATE_ASSET_RATING
} = AssetActions.TYPES;

export const DEFAULT_STATE = fromJS({
    data: {},
    homeData: {},
    suggestData: [],
    isLoading: false,
    isLoadingHomeData: false,
    isLoadingSuggest: false,
    error: {}
});

const updateData = (state, updateFunc) => {
    const {data, homeData} = state.toJS();
    if (data && data.elements && data.elements.length) {
        data.elements.forEach(updateFunc);
    } else if (data && data.data && data.data.id) {
        updateFunc(data.data);
    }
    if (data && data.relativeItems && data.relativeItems.length) {
        data.relativeItems.forEach(updateFunc);
    }
    if (data && data.recommendOffers && data.recommendOffers.length) {
        data.recommendOffers.forEach(updateFunc);
    }
    if (homeData && Object.keys(homeData).length) {
        for (const key in homeData) {
            if (homeData.hasOwnProperty(key) && homeData[key].length) {
                homeData[key].forEach(updateFunc);
            }
        }
    }
    return state.merge({
        data,
        homeData
    });
};

export default typeToReducer(
    {
        [LIST_ASSETS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) => {
                const data = action.payload || {};
                const paging = data.paging;
                if (paging) {
                    if (!root.__server_side_render) {
                        emitEvent({
                            eventAction: 'client.asset.load.total',
                            interactionType: 'route',
                            eventLabel: paging.total,
                            eventValue: root.window.location.search
                        });
                    }
                }
                return state.merge({
                    isLoading: false,
                    data: action.payload
                });
            },
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [SUGGEST_ASSETS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingSuggest: true,
                    suggestData: [],
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoadingSuggest: false,
                    suggestData: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoadingSuggest: false
                })
        },
        [VAULT_ASSETS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    data: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [GET_HOME_DATA]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingHomeData: true,
                    homeData: {},
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoadingHomeData: false,
                    homeData: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoadingHomeData: false
                })
        },
        [GET_ASSET]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    data: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [GET_HELMET]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    helmet: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    helmet: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [SET_ASSETS_OWNED]: (state, action) => {
            const offers = action.payload;
            const setOwned = item => {
                if (offers.includes(item.id)) {
                    item.owned = true;
                    item.ownedCount = 1;
                }
            };
            return updateData(state, setOwned);
        },
        [SELLER_ASSETS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    data: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [UPDATE_ASSET_RATING]: (state, action) => {
            const {offerId, data: rating} = action.payload;
            const setRating = item => {
                if (item.id === offerId) {
                    item.rating = rating;
                }
            };
            return updateData(state, setRating);
        }
    },
    DEFAULT_STATE
);
