/**
 * Created by Kaku.Guo on 2019/6/5.
 */
import {getMessage} from '../../components';

export const getReviewValidations = (
    messages,
    descriptionRequired = true,
    contentMaxLength = 3000,
    enableRating
) => ({
    title: {
        rules: {
            required: value => !!value.trim().length,
            length: value => value.trim().length >= 5 && value.trim().length <= 100
        },
        messages: {
            required: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.required'
            ),
            length: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.text.length',
                [5, 100]
            )
        }
    },
    content: {
        rules: {
            required: value => {
                return descriptionRequired ? !!value.replace(/<[^>]+>/g, '').trim().length : true;
            },
            length: value =>
                descriptionRequired
                    ? value.length >= 1 && value.replace(/<[^>]+>/g, '').length <= contentMaxLength
                    : true
        },
        messages: {
            required: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.required'
            ),
            length: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.text.length',
                [0, contentMaxLength]
            )
        }
    },
    images: {
        rules: {
            length: value => !value || value.length <= 5
        },
        messages: {
            length: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.image.length',
                [0, 5]
            )
        }
    },
    rating: {
        rules: {
            required: value => (enableRating ? !!value : true)
        },
        messages: {
            required: getMessage(
                messages,
                'messages.com.epicgames.plugin.store.validations.required'
            )
        }
    }
});
