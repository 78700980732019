import root from 'window-or-global';

// const protocol = '^(https?:\\/\\/)?';
// const domain = '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})';
// const port = '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*';
// const query = '(\\?[;&a-z\\d%_.~+=-]*)?';
// const fragment = '(\\#[-a-z\\d_]*)?$';
// urlRegex = new RegExp(`${protocol}${domain}${port}${query}${fragment}`);
const urlRegex = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,13})(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i; //eslint-disable-line

const protocolRegex = /^(https:\/\/|http:\/\/)(.*)/;

const escapeDomain = userInput => {
    if (!userInput || !protocolRegex.test(userInput)) return userInput;
    const match = userInput.match(protocolRegex);
    return match.length >= 3 ? match[2] : userInput;
};
const escapeProtocol = userInput => {
    if (!userInput || !protocolRegex.test(userInput)) return null;
    const match = userInput.match(protocolRegex);
    return match.length >= 3 ? match[1] : userInput;
};

function CustomTheme() {
    const Quill = require('quill'); //eslint-disable-line
    const snow = Quill.import('themes/snow');
    const extendFunc = snow.prototype.extendToolbar;
    snow.prototype.extendToolbar = function extendToolbar(toolbar) {
        extendFunc.call(this, toolbar);
        if (!this.tooltip.protocolSelect) {
            this.tooltip.textbox.setAttribute('data-link', 'url.com');
            const span = root.document.createElement('span');
            span.innerHTML =
                '<select><option value="https://">https://</option>' +
                '<option value="http://">http://</option></select>';
            span.className = 'protocolSelect';
            const protocolSelect = span.children[0];
            this.tooltip.root.insertBefore(span, this.tooltip.textbox);
            this.tooltip.protocolSelect = protocolSelect;
            const getSelectProtocol = () => {
                return protocolSelect.options[protocolSelect.selectedIndex].innerText;
            };
            const setSelectProtocol = protocol => {
                if (protocol) {
                    protocolSelect.value = protocol;
                }
            };
            this.tooltip.save = function save() {
                const tooltip = this.tooltip;
                const input = tooltip.textbox;
                const value = input.value;
                const userInput = `${escapeProtocol(value) || getSelectProtocol()}${escapeDomain(
                    value
                )}`;
                if (tooltip.root.getAttribute('data-mode') === 'link') {
                    if (!userInput || !userInput.match(urlRegex)) {
                        tooltip.textbox.classList.add('error');
                        tooltip.textbox.setAttribute('title', 'Invalid url format.');
                        return;
                    }
                    const scrollTop = tooltip.quill.root.scrollTop;
                    if (tooltip.linkRange) {
                        tooltip.quill.formatText(tooltip.linkRange, 'link', userInput, 'user');
                        delete tooltip.linkRange;
                    } else {
                        tooltip.restoreFocus();
                        tooltip.quill.format('link', userInput, 'user');
                    }
                    tooltip.quill.root.scrollTop = scrollTop;
                }
                tooltip.textbox.value = '';
                tooltip.textbox.classList.remove('error');
                tooltip.textbox.setAttribute('title', '');
                tooltip.hide();
            }.bind(this);

            const editCall = this.tooltip.edit;
            this.tooltip.edit = (mode = 'link', preview = null) => {
                setSelectProtocol(escapeProtocol(preview));
                editCall.bind(this.tooltip, mode, escapeDomain(preview)).call(this.tooltip);
            };

            const positionCall = this.tooltip.position;
            this.tooltip.position = function position(reference) {
                positionCall.call(this, reference);
                if (this.root.style.left.startsWith('-')) {
                    this.root.style.left = 0;
                }
            }.bind(this.tooltip);
        }
    };
    return snow;
}

export default CustomTheme;
