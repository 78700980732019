/**
 * Created by Kaku.Guo on 2018/11/27.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RatingBoard, getNumericMessage} from '../../index';
import {withRouter} from 'react-router-dom';
import {scrollToReviewTab} from '../../review/reviewUtils';
import {isCategoryReviewable} from '../../../utils';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const {isLoadingRating, rating} = state.get('review').toJS();
    const isLauncher = state.get('config').get('isLauncher');
    const {epicSellerOwner = ''} = state.get('config').toJS();
    return {
        messages,
        isLoading: isLoadingRating,
        data: rating,
        isLauncher,
        epicSellerOwner
    };
};

function AssetDetailRating(props) {
    const {
        className,
        isLoading,
        data = {},
        categories = [],
        isLauncher,
        messages,
        match,
        external,
        seller = {},
        epicSellerOwner = '',
        external: hasBuyLink
    } = props;
    const {total = 0, answeredQuestion = 0, totalQuestion = 0, legacyRatingNum = 0} = data;
    const prefixCls = 'asset-detail-rating';
    const classNames = cn(prefixCls, className);
    const isEpicOwner = epicSellerOwner === seller.owner;
    const noReviews = isEpicOwner || hasBuyLink || !isCategoryReviewable(categories);

    if (noReviews) {
        return null;
    }

    const renderContent = () => {
        if (isLoading) {
            return <div className="loading" />;
        }
        if (external) {
            return totalQuestion ? (
                <div className={`${prefixCls}__summary`} key="rating-summary">
                    <Link to={`${match.url}/questions`} onClick={scrollToReviewTab} replace>
                        {getNumericMessage(
                            messages,
                            'messages.com.epicgames.plugin.store.asset.answered_questions',
                            totalQuestion,
                            [answeredQuestion, totalQuestion]
                        )}
                    </Link>
                </div>
            ) : null;
        }
        const reviewCount = total - legacyRatingNum;
        return [
            <RatingBoard
                key="rating-board"
                className={`${prefixCls}__board`}
                messages={messages}
                isLauncher={isLauncher}
                data={data}
                renderReviewBtn={children => (
                    <Link to={`${match.url}/reviews`} onClick={scrollToReviewTab} replace>
                        {children}
                    </Link>
                )}
            />,
            <div className={`${prefixCls}__summary`} key="rating-summary">
                {!!reviewCount && (
                    <Link to={`${match.url}/reviews`} onClick={scrollToReviewTab} replace>
                        {getNumericMessage(
                            messages,
                            'messages.com.epicgames.plugin.store.asset.customer_reviews',
                            reviewCount,
                            [reviewCount]
                        )}
                    </Link>
                )}
                {!!reviewCount && !!totalQuestion && (
                    <div className={`${prefixCls}__summary__sep`} />
                )}
                {!!totalQuestion && (
                    <Link to={`${match.url}/questions`} onClick={scrollToReviewTab} replace>
                        {getNumericMessage(
                            messages,
                            'messages.com.epicgames.plugin.store.asset.answered_questions',
                            totalQuestion,
                            [answeredQuestion, totalQuestion]
                        )}
                    </Link>
                )}
            </div>
        ];
    };
    return <div className={classNames}>{renderContent()}</div>;
}

AssetDetailRating.propTypes = {
    match: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    categories: PropTypes.array,
    messages: PropTypes.object,
    isLoading: PropTypes.bool,
    isLauncher: PropTypes.bool,
    external: PropTypes.bool,
    seller: PropTypes.object,
    epicSellerOwner: PropTypes.string
};
export default connect(mapStateToProps)(withRouter(AssetDetailRating));
