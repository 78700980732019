import React from 'react';
import {useWishlistItemCount} from '../../shared/wishlist.hooks';
import {Heart} from '@epic-ui/icons';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {useLocalization} from '@epic-core/localization';

const HeartWrap = styled.div`
    display: inline-block;
    margin: 0 0.25em 0 1em;
    padding: 0;
    position: relative;
    top: -2px;
    a {
        .MuiSvgIcon-root {
            transition: color 0.2s ease-out;
            font-size: 28px;
            color: #ff6d6d;
        }
        &:hover .MuiSvgIcon-root {
            color: #f98888;
        }
    }
`;

const NumberWrap = styled.div`
    position: absolute;
    bottom: -5px;
    right: -8px;
    font-size: 10px;
    background: #ff6d6d;
    border-radius: 8px;
    color: white;
    height: 16px;
    min-width: 16px;
    padding: 1px 2px 0;
    text-align: center;
    font-weight: bolder;
    font-size: 11px;

    &.two-digits {
        padding: 1px 2px 0 0px;
    }
`;

export const WishlistIcon = () => {
    const numberOfWishlistItems = useWishlistItemCount();
    const getMessage = useLocalization();

    return (
        <HeartWrap>
            <Link
                to={generateRoutePath('/wishlist')}
                title={getMessage('epic.marketplace.wishlist.view_wishlist')}>
                <Heart />
            </Link>
            <NumberWrap className={`${numberOfWishlistItems > 9 ? 'two-digits' : ''}`}>
                {numberOfWishlistItems}
            </NumberWrap>
        </HeartWrap>
    );
};
