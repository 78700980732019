import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/*
 * Defaults to a `<span>` component, so only horizontal margins are applied.
 * Set `props.component` to a block-level element for vertical margins
 */
export default function Spacer(props) {
    const {
        bottom,
        className,
        as: Component,
        left,
        margin,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        right,
        top,
        ...otherProps
    } = props;

    const classes = classNames(
        margin && `spacer--${margin}`,
        top && `spacer__top--${top}`,
        bottom && `spacer__bottom--${bottom}`,
        left && `spacer__left--${left}`,
        right && `spacer__right--${right}`,
        padding && `spacer__pad--${padding}`,
        paddingTop && `spacer__padTop--${paddingTop}`,
        paddingBottom && `spacer__padBottom--${paddingBottom}`,
        paddingLeft && `spacer__padLeft--${paddingLeft}`,
        paddingRight && `spacer__padRight--${paddingRight}`,
        className
    );

    return <Component className={classes} {...otherProps} />;
}

const validSpacing = PropTypes.oneOf([0, 4, 8, 12, 16, 20, 24, 28, 32]);

Spacer.propTypes = {
    bottom: validSpacing,
    className: PropTypes.string,
    as: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    left: validSpacing,
    margin: validSpacing,
    padding: validSpacing,
    paddingBottom: validSpacing,
    paddingLeft: validSpacing,
    paddingRight: validSpacing,
    paddingTop: validSpacing,
    right: validSpacing,
    top: validSpacing
};

Spacer.defaultProps = {
    as: 'span'
};
