/**
 * Created by Kaku.Guo on 2019/2/20.
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import ReactGallery from 'react-image-gallery';
import AliceCarousel from 'react-alice-carousel';
import throttle from 'lodash.throttle';
import root from 'window-or-global';
import {getAssetPath} from '../../utils';
import {LockContext} from '../index';
import {getOptImage} from 'epic-ue-shared';

class ImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            startIndex: 0,
            fullScreen: false,
            maxThumbs: 4,
            stagePadding: {}
        };
        this.imageGallery = null;
        this.carousel = null;
    }

    getThumbnailItems = () => {
        const {data = []} = this.props;
        const {currentIndex} = this.state;
        return data.map(({thumbnail}, idx) => (
            <a
                className={`image-gallery-thumbnail ${currentIndex === idx ? 'active' : ''}`}
                onClick={this.handleThumbnailChange(idx)}
                key={`image-gallery-thumbnail-${idx}`}>
                <img src={getOptImage({url: thumbnail, width: 200})} alt="" />
            </a>
        ));
    };

    handleThumbnailChange = idx => () => {
        this.imageGallery.slideToIndex(idx);
        this.setState({currentIndex: idx});
    };

    handleScreenChange = ele => {
        //exit full screen
        if (!ele) {
            const currentIndex = this.imageGallery.getCurrentIndex();
            this.carousel._slideToItem(currentIndex);
            this.setState({currentIndex, fullScreen: false});
        } else {
            this.setState({fullScreen: true});
        }
    };

    slideNext = () => {
        const {startIndex, maxThumbs} = this.state;
        const {data = []} = this.props;
        let index = startIndex + maxThumbs;
        if (index >= data.length) {
            return;
        }
        if (index + maxThumbs >= data.length) {
            index = data.length - maxThumbs;
        }
        this.setState({startIndex: index});
    };

    slidePrev = () => {
        const {startIndex, maxThumbs} = this.state;
        if (startIndex === 0) {
            return;
        }
        let index = startIndex - maxThumbs;
        if (index < 0) {
            index = 0;
        }
        this.setState({startIndex: index});
    };

    handleSlideChange = ({item}) => {
        this.setState({startIndex: item});
    };

    toggleFullScreen = () => {
        const {fullScreen} = this.state;
        if (fullScreen) {
            this.imageGallery.exitFullScreen();
        } else {
            this.imageGallery.fullScreen();
        }
    };

    calcVisibleThumbnails = throttle(() => {
        const THUMB_WIDTH = 150;
        // subtract 100px to account for padding
        const stageContainer = root.document.querySelector('.image-gallery-thumbnails');
        if (stageContainer) {
            const stageWidth = stageContainer.clientWidth - 100;
            // add 8px to account for spacing between thumbnails
            const totalThumbs = stageWidth / (THUMB_WIDTH + 8);
            const maxThumbs = Math.floor(totalThumbs);
            const remainingWidth = (totalThumbs % 1) * THUMB_WIDTH;

            // add stage padding if there's space to fit 21%+ of a thumbnail
            const stagePadding =
                remainingWidth / THUMB_WIDTH > 0.2
                    ? {paddingLeft: 0, paddingRight: Math.floor(remainingWidth)}
                    : {};

            this.setState({
                maxThumbs,
                stagePadding
            });
        }
    }, 150);

    componentDidMount() {
        this.calcVisibleThumbnails();
        const {width, height} = root.screen;
        const responsive = width * 9 > height * 16;
        this.setState({responsive}); //eslint-disable-line
    }

    onSlide = (currentIndex = 0) => {
        const {data = []} = this.props;
        const {maxThumbs} = this.state;
        const params =
            currentIndex + maxThumbs < data.length - 1
                ? {currentIndex, startIndex: currentIndex - 1}
                : {currentIndex};
        this.setState(params);
    };

    render() {
        const {data = [], className} = this.props;
        const {startIndex, maxThumbs, stagePadding, responsive} = this.state;
        const defaultImage = getAssetPath('logo-epic.svg');
        return (
            <div className={`image-gallery ${className}`}>
                <LockContext.Consumer>
                    {({isLocked}) => {
                        return (
                            <Fragment>
                                <ReactGallery
                                    additionalClass={`image-gallery-showcase ${
                                        responsive ? 'responsive' : ''
                                    }`}
                                    ref={ref => {
                                        this.imageGallery = ref;
                                    }}
                                    items={data}
                                    showPlayButton={false}
                                    disableArrowKeys={isLocked}
                                    showFullscreenButton={false}
                                    showThumbnails={false}
                                    infinite={false}
                                    onSlide={this.onSlide}
                                    defaultImage={defaultImage}
                                    slideDuration={0}
                                    onClick={this.toggleFullScreen}
                                    onScreenChange={this.handleScreenChange}
                                />
                                <div className="image-gallery-thumbnails">
                                    <AliceCarousel
                                        ref={ref => {
                                            this.carousel = ref;
                                        }}
                                        responsive={{
                                            0: {items: maxThumbs}
                                        }}
                                        stagePadding={stagePadding}
                                        items={this.getThumbnailItems()}
                                        startIndex={startIndex}
                                        dotsDisabled
                                        mouseDragEnabled
                                        buttonsDisabled
                                        keysControlDisabled={isLocked}
                                        onSlideChanged={this.handleSlideChange}
                                        infinite={false}
                                        onResized={this.calcVisibleThumbnails}
                                    />
                                    <div className="thumbnails-controls">
                                        <a
                                            className={`control-prev ${
                                                startIndex === 0 ? 'disabled' : ''
                                            }`}
                                            onClick={this.slidePrev}>
                                            <i className="fa fa-angle-left" />
                                        </a>
                                        <a
                                            className={`control-next ${
                                                startIndex + maxThumbs >= data.length
                                                    ? 'disabled'
                                                    : ''
                                            }`}
                                            onClick={this.slideNext}>
                                            <i className="fa fa-angle-right" />
                                        </a>
                                    </div>
                                </div>
                            </Fragment>
                        );
                    }}
                </LockContext.Consumer>
            </div>
        );
    }
}
ImageGallery.propTypes = {
    data: PropTypes.array,
    className: PropTypes.string
};
export default ImageGallery;
