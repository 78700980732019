import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import ReplyList from './ReplyList';
import markdownUtils from 'epic-comment-rating/app/scripts/commentrating/utils/markdown';
import DateUtil from 'epic-comment-rating/app/scripts/commentrating/utils/dateUtils';
import CommentActions from 'epic-comment-rating/app/scripts/actions/CommentActions';
import RatingArea from 'epic-comment-rating/app/scripts/component/common/RatingArea';
import { connect } from 'react-redux';
import { Link, Message, getMessage } from '../index';

const mapStateToProps = (state) => {
    return {
        messages: state.get('localization').get('messageProperties').toJS()
    };
};
@connect(mapStateToProps)
export default class CommentItem extends React.Component {
    static propTypes = {
        seller: PropTypes.object,
        messages: PropTypes.object,
        comment: PropTypes.object,
        commentObjectId: PropTypes.string,
        commentNamespace: PropTypes.string,
        replyInitCount: PropTypes.string,
        replyAppendCount: PropTypes.string,
        enableMarkdown: PropTypes.bool,
        enableAvatar: PropTypes.bool,
        enableTimeAgo: PropTypes.bool,
        loadingReplyId: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            showReply: true
        };

        this.toggleReplyList = this._toggleReplyList.bind(this);
        this.getMoreReply = this._getMoreReply.bind(this);
    }


    _toggleReplyList() {
        this.setState({ showReply: !this.state.showReply });
    }

    rawMarkup(markdownContent) {
        return markdownUtils.rawMarkup(markdownContent);
    }

    _getMoreReply() {
        const offset = this.props.comment.replyObject.startPage;
        CommentActions.getReplyList.defer({
            offset,
            flag: 'next',
            commentId: this.props.comment.id,
            commentObjectId: this.props.commentObjectId,
            commentNamespace: this.props.commentNamespace,
            replyAppendCount: this.props.replyAppendCount
        });
    }

    render() {
        const messages = this.props.messages;
        const seller = this.props.seller;
        const comment = this.props.comment.asMutable({ deep: true }) || {};
        const replyListObject = this.props.comment.replyObject || {};
        comment.formattedDisplayTime = DateUtil.jsDateFormat(comment.displayTime, '', messages);
        /**
         * set displayName to [Deleted User] if account is deleted
         */
        let displayName = comment.accountDisplayName;
        if (comment.deletedAccount) {
            displayName = getMessage(messages, 'messages.com.epicgames.plugin.commentrating.comments.deleted_user');
        }
        let displayNameContent = (
            <span className="username accent-color">{displayName}</span>
        );
        if (seller && seller.owner === comment.accountId) {
            //TODO: profile page is not complete yet
            displayNameContent = (
                <h4>
                    <Message code="messages.com.epicgames.plugin.commentrating.comments.publisher.post.label" className="" />
                    <Link to={`profile/${seller.name}`} className="username accent-color">{seller.name}</Link>
                </h4>
            );
        }

        /**
         * enable makedown content
         */
        let commentContent;
        if (this.props.enableMarkdown) {
            commentContent = (
                <div className="textile-container-view">
                    <div className="textile-preview" dangerouslySetInnerHTML={this.rawMarkup(comment.markdownContent)} />
                </div>
            );
        } else {
            commentContent = (
                <span className="textile-preview">{comment.content}</span>
            );
        }

        /**
         * Enable avatar
         *
         */
        let avatar;
        if (this.props.enableAvatar && comment.avatarImgUrl) {
            avatar = (
                <div className="user-avatar">
                    <img alt="avatar" src={comment.avatarImgUrl} />
                </div>
            );
        }

        /**
         * Hide reply/show reply button
         *
         */
        let replyLink;
        if (this.props.comment.replyObject && this.props.comment.replyObject.elements) {
            if (this.props.comment.replyObject.elements.length > 0) {
                replyLink = (
                    <a role="listitem" className="view-reply-btn" onClick={this.toggleReplyList}>
                        {this.state.showReply
                            ? <Message code="messages.com.epicgames.plugin.commentrating.comment.reply.hide" />
                            : <Message code="messages.com.epicgames.plugin.commentrating.comment.reply.show" />}
                    </a>
                );
            }
        }
        /**
         * Check if reply is loading
         *
         */
        let isReplyLoading = false;
        if (this.props.loadingReplyId && (this.props.loadingReplyId === this.props.comment.id)) {
            isReplyLoading = true;
        }

        return (
            <div className="comment-box" >
                <div className="comment" >
                    {avatar}
                    <RatingArea
                        voteTotal={comment.voteTotal}
                        voteInfo={comment.voteInfo}
                        id={comment.id} />
                    <div className="comment-info-box">
                        <div className="comment-body">

                            <span className="comment-content">
                                {displayNameContent}
                            </span>

                            {commentContent}

                            <p className="comment-links">
                                <span className="comment-date">
                                    {this.props.enableTimeAgo ? <TimeAgo date={comment.displayTime} /> : comment.formattedDisplayTime}
                                </span>
                                {replyLink}
                            </p>

                            <ReplyList
                                seller={seller}
                                replyListObject={replyListObject}
                                commentObjectId={this.props.commentObjectId}
                                commentNamespace={this.props.commentNamespace}
                                replyInitCount={this.props.replyInitCount}
                                replyAppendCount={this.props.replyAppendCount}
                                showMoreReply={this.getMoreReply}
                                showReply={this.state.showReply}
                                enableMarkdown={this.props.enableMarkdown}
                                enableAvatar={this.props.enableAvatar}
                                enableTimeAgo={this.props.enableTimeAgo}
                                isLoadingReply={isReplyLoading} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
