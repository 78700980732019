import React from 'react';
import AssetDetailWrap from './AssetDetailWrap.connected';
import {AssetActions, ConfigActions} from '../../actions';

export default class AssetDetailView extends React.Component {
    static async getInitialProps(match, {dataPreload, parsedUrl, store, config}, token) {
        const params = match.params || {};
        let slug = params.slug;
        let isItem = false;
        if (!slug) {
            slug = params.id;
            isItem = true;
        }

        store.dispatch(
            ConfigActions.setConfig({
                contentHeader: {
                    code: 'messages.com.epicgames.plugin.store.header.content_detail'
                }
            })
        );
        store.dispatch(AssetActions.getAsset({slug, isItem, token}));
        if (config.xEnvStr !== 'review') {
            store.dispatch(AssetActions.getHelmet({slug, isItem, token}));
        }
    }

    render() {
        return <AssetDetailWrap {...this.props} />;
    }
}
