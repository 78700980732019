import React from 'react';
import PropTypes from 'prop-types';
import ReplyItem from './ReplyItem';
import { Message } from '../index';

export default class ReplyList extends React.Component {
    static propTypes = {
        showMoreReply: PropTypes.func,
        seller: PropTypes.object,
        replyListObject: PropTypes.object,
        showReply: PropTypes.bool,
        enableMarkdown: PropTypes.bool,
        enableAvatar: PropTypes.bool,
        isLoadingReply: PropTypes.bool
    };

    showMoreReply = () => {
        this.props.showMoreReply();
    };

    render() {
        const {replyListObject, isLoadingReply, seller} = this.props;
        const replyList = replyListObject.elements || [];
        const ifShowMore = replyListObject.haveMore;
        const show = this.props.showReply;
        let viewMoreButton = null;
        let loadingStyle = {
            display: 'block'
        };

        let replyListStyle = {
            display: 'block'
        };

        if (!show) {
            replyListStyle = {
                display: 'none'
            };
        }

        if (!isLoadingReply) {
            loadingStyle = {
                display: 'none'
            };
        }

        if (ifShowMore && !isLoadingReply) {
            viewMoreButton = (
                <div className="view-more">
                    <span role="listitem" onClick={this.showMoreReply}>
                        <Message code="messages.com.epicgames.plugin.commentrating.comment.reply.more" />
                    </span>
                </div>
            );
        }

        const repliesNew = replyList.map((reply) => {
            return (
                <ReplyItem
                    seller={seller}
                    key={reply.id}
                    reply={reply}
                    enableMarkdown={this.props.enableMarkdown}
                    enableAvatar={this.props.enableAvatar}
                />
            );
        });

        return (
            <div className="reply-box" style={replyListStyle}>
                <div className="comment-reply">
                    {repliesNew}
                </div>
                <div className="loading-svg" style={loadingStyle} />
                {viewMoreButton}
            </div>
        );
    }
}
