import {reduxFullStateKey} from './redux.service';
import {useSharedValue} from '@epic-core/hooks';

export const useReduxStore = () => {
    const [state] = useSharedValue(reduxFullStateKey);
    return state;
};

export const useShoppingCart = () => {
    const state = useReduxStore();
    const shoppingCart = state.shoppingCart || {};
    return shoppingCart;
};
