/**
 * Created by Kaku.Guo on 2018/11/8.
 */
import { generateAsyncActions } from '../utils';

const TYPES = {
    SET_CONFIG: 'SET_PAGE_CONFIG',
    ...generateAsyncActions('GET_SUGGEST_TAGS', 'GET_LAUNCHER_OPTIONS')
};
export default {
    TYPES,
    setConfig: payload => ({
        type: TYPES.SET_CONFIG,
        payload
    }),
    getSuggestTags: payload => ({
        type: TYPES.GET_SUGGEST_TAGS_PENDING,
        payload
    }),
    getSuggestTagsSuccess: payload => ({
        type: TYPES.GET_SUGGEST_TAGS_SUCCESS,
        payload
    }),
    getSuggestTagsFailed: payload => ({
        type: TYPES.GET_SUGGEST_TAGS_FAILED,
        payload
    }),
    getLauncherOptions: payload => ({
        type: TYPES.GET_LAUNCHER_OPTIONS_PENDING,
        payload
    }),
    getLauncherOptionsSuccess: payload => ({
        type: TYPES.GET_LAUNCHER_OPTIONS_SUCCESS,
        payload
    }),
    getLauncherOptionsFailed: payload => ({
        type: TYPES.GET_LAUNCHER_OPTIONS_FAILED,
        payload
    })
};