import axios from 'axios';
import Url from '../constants/Url';

class UserApi {
    getSso(params) {
        return axios
            .get(Url().SSO, {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
}
export default new UserApi();
