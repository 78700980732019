class GeneralServiceError extends Error {
    public errorCode;
    public constructor(message, errorCode) {
        super(message);
        this.errorCode = errorCode;
        this.name = 'GeneralServiceError';
    }
}

export default GeneralServiceError;
