import React from 'react';
import {useLocalization} from '@epic-core/localization';
import {usePageTitle} from '../../shared/pageTitle.hooks';

const getContentHeaderText = (header, getMessage, pageTitleCode: string) => {
    if (pageTitleCode) {
        return getMessage(pageTitleCode);
    }
    if (!header) {
        return getMessage('messages.com.epicgames.plugin.store.title.marketplace');
    }
    if (typeof header === 'object') {
        return getMessage(header.code, Array.from(header.args || []));
    }
    return header;
};

interface Properties {
    header: string | {code: string; args: any[]};
}

export const PageTitle = ({header}: Properties) => {
    const {pageTitleCode} = usePageTitle();
    const getMessage = useLocalization();

    return <span className="h1">{getContentHeaderText(header, getMessage, pageTitleCode)}</span>;
};
