import GraphQlService from 'epic-node-services/src/graphql/GraphQlService';
import {Maybe} from "epic-graphql-types";
import {Eula} from "./type";

export const getLatestAndConvertQuery = `
    query getLatestAndConvert($eulaId:String!, $locale:String!) {
        Eula {
            getLatestAndConvert(
                id: $eulaId
                locale: $locale
            ) {
                title
                body
                version
                locale
            }
        }
    }
`;

export const hasAccountAcceptedQuery = `
    query hasAccountAccepted($eulaId:String!, $accountId:String!, $locale:String!) {
        Eula {
            hasAccountAccepted(
                id: $eulaId
                locale: $locale
                accountId: $accountId
            ) {
                accepted
            }
        }
    }
`;

const acceptEulaQuery = `
    mutation AcceptEula($id: String!, $locale: String!, $version: Int!, $accountId: String!) {
        Eula {
            acceptEula(id: $id, locale: $locale, version: $version, accountId: $accountId) {
                accepted
          }
    }
}`;

class EulaApi {
    public async hasAccountAccepted(accountId, eulaId, locale = 'en'): Promise<Maybe<Eula>> {
        try {
            const response = await GraphQlService.query(hasAccountAcceptedQuery, {
                eulaId,
                accountId,
                locale
            });
            return response?.data?.Eula;
        } catch (e) {
            console.error('Failed to load hasAccountAccepted()', e?.message);
            throw e;
        }
    }

    public async getLatest(eulaId, locale = 'en'): Promise<Maybe<Eula>> {
        try {
            const response = await GraphQlService.query(getLatestAndConvertQuery, {
                eulaId,
                locale
            });
            return response?.data?.Eula;
        } catch (e) {
            console.error('Failed to load getLatest()', e?.message);
        }
    }

    public async acceptEula(accountId, eulaId, version = 1, locale = 'en'): Promise<Eula> {
        try {
            const response = await GraphQlService.query(acceptEulaQuery, {
                id: eulaId,
                accountId,
                locale,
                version
            });
            return response?.data?.Eula;
        } catch (e) {
            console.error('Failed to load acceptEula()', e?.message);
            throw e;
        }
    }
}

export default new EulaApi();


