import {fromJS} from 'immutable';
import typeToReducer from 'type-to-reducer';
import {TraderDialogActions} from '../actions';
import {getError} from '../utils';

const {
    SET_TRADER_DIALOG_OPTIONS,
    MERGE_TRADER_DIALOG_OPTIONS,
    GET_TRADER_INFO
} = TraderDialogActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isVisible: false
});

export default typeToReducer(
    {
        [SET_TRADER_DIALOG_OPTIONS]: (state, action) => DEFAULT_STATE.merge(action.payload),
        [MERGE_TRADER_DIALOG_OPTIONS]: (state, action) => state.merge(action.payload),
        [GET_TRADER_INFO]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    data: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        }
    },
    DEFAULT_STATE
);
