/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import Helmet from 'react-helmet';
import {AssetList, FilterPanel, SortDropdown, getMessage, Paginator} from '../../components';
import {
    isSameObjectsWithNormalFields,
    hasValue,
    generateFilterParams,
    combinePageTitle
} from '../../utils';
import {getDefaultSearchParams} from '../../constants';
import {
    CmsActions,
    ConfigActions,
    AssetActions,
    PageActions,
    ShoppingCartActions
} from '../../actions';
import root from 'window-or-global';
import {getHelmetMetaTags} from '../../utils/metaTagUtils';
import {emitEvent, getHelmetForMetaTags} from 'epic-ue-shared';

const getFilterFields = (extraFilter, staticParams) => {
    let fields = [
        'start',
        'count',
        'sortBy',
        'sortDir',
        'tag',
        'compatibleWith',
        'platform',
        'priceRange',
        'discountPercentage',
        'discountPercentageRange'
    ];
    if (extraFilter && extraFilter.length) {
        fields = fields.concat(extraFilter);
    }
    if (staticParams && Object.keys(staticParams).length) {
        fields = fields.concat(Object.keys(staticParams));
    }
    return fields;
};

export const generateListParams = (params = {}, extraFilter, staticParams) => {
    const fields = getFilterFields(extraFilter, staticParams);
    return Object.keys(params).reduce((prev, next) => {
        if (fields.includes(next) && hasValue(params[next])) {
            prev[next] = params[next];
        }
        return prev;
    }, {});
};

class BasePageView extends Component {
    constructor(props, staticParams, extraState = {}) {
        super(props);
        const {location, handleChange, priceRanges, engineVersions, messages} = props;
        const searchParams =
            generateFilterParams(qs.parse(location.search), priceRanges, engineVersions) || {};
        if (searchParams.keywords) {
            searchParams.sortBy = 'relevancy';
            searchParams.sortDir = 'DESC';
        } else if (searchParams.sortBy === 'relevancy') {
            searchParams.sortBy = 'effectiveDate';
        }
        handleChange('redirectChange', Object.assign({}, searchParams, staticParams));
        const {pageTitleCode, pageTileArgs, helmetData} = extraState;
        let helmet = helmetData;
        if (!helmet && pageTitleCode) {
            helmet = {
                title: combinePageTitle(messages, getMessage, pageTitleCode, pageTileArgs)
            };
        }
        this.state = {searchParams, staticParams, ...extraState, helmet};
    }

    componentDidMount() {
        const {extraFilter, searchParams, staticParams} = this.state;
        const generatedParams = generateListParams(
            Object.assign({}, getDefaultSearchParams(), searchParams, staticParams),
            extraFilter,
            staticParams
        );
        this.setState({
            listParams: generatedParams
        });

        const {contentHeader} = this.state;
        const {setConfig} = this.props;
        if (contentHeader) {
            setConfig({contentHeader});
        }

        const data = this.props.data || {};
        const elements = data.elements || [];
        const total = data && data.paging ? data.paging.total : 0;

        if (!elements.length) {
            this.handleListAssets(generatedParams);
        }

        emitEvent({
            eventAction: 'client.asset.load.total',
            interactionType: 'route',
            eventLabel: total,
            eventValue: root.window.location.search
        });
    }

    UNSAFE_componentWillReceiveProps({listParams, location: {pathname, search}, match: {params}}) {
        const {listParams: stateParams, extraFilter, staticParams} = this.state;
        const {
            location: {pathname: oldPathname, search: oldSearch},
            handleChange,
            priceRanges,
            engineVersions,
            getCmsPageData
        } = this.props;
        const generatedParams = generateListParams(listParams, extraFilter, staticParams);
        const queryChangedOnly =
            pathname === oldPathname &&
            !isSameObjectsWithNormalFields(qs.parse(search), qs.parse(oldSearch));
        const sellerChanged = params.sellerName !== this.props.match.params.sellerName;
        if (!isSameObjectsWithNormalFields(generatedParams, stateParams, ['effectiveDate'])) {
            this.setState({
                listParams: generatedParams
            });
            this.changePageUrl(generatedParams);
            const isServerSide = root.__server_side_render;
            if (!isServerSide) {
                getCmsPageData(pathname);
            }
        } else if (queryChangedOnly) {
            const newParams =
                generateFilterParams(qs.parse(search), priceRanges, engineVersions) || {};
            const keywords = newParams.keywords || '';
            const oldKeywords = stateParams.keywords || '';
            if (keywords && keywords !== oldKeywords) {
                newParams.sortBy = 'relevancy';
                newParams.sortDir = 'DESC';
            }
            handleChange('redirectChange', Object.assign({}, newParams, staticParams));
            const newStateParams = generateListParams(
                Object.assign({}, getDefaultSearchParams(), newParams, staticParams),
                extraFilter,
                staticParams
            );
            this.setState(
                {
                    listParams: newStateParams
                },
                this.handleListAssets(newStateParams)
            );
        } else if (sellerChanged) {
            const searchParams = generateFilterParams(
                qs.parse(search),
                priceRanges,
                engineVersions
            );
            const sellerParams = {sellerName: params.sellerName};
            this.setState({staticParams: sellerParams});
            handleChange('redirectChange', Object.assign({}, searchParams, sellerParams));
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {listParams} = this.state;
        return isSameObjectsWithNormalFields(listParams, nextState.listParams, ['effectiveDate']);
    }

    componentWillUnmount() {
        root.document.body.classList.toggle('noscroll', false);
    }

    changePageUrl = params => {
        //set url params
        const {location, history} = this.props;
        const {staticParams} = this.state;
        let urlParams = params;
        if (staticParams && Object.keys(staticParams).length) {
            urlParams = Object.keys(params).reduce((prev, next) => {
                if (!Object.keys(staticParams).includes(next)) {
                    prev[next] = params[next];
                }
                return prev;
            }, {});
        }
        const pathname = location.pathname;
        history.push({pathname, search: `?${qs.stringify(urlParams)}`});
    };

    handleListAssets = (params, staticParams) => {
        this.props.listAssets(Object.assign({}, params, staticParams || this.state.staticParams));
    };

    getSortDropdown = () => {
        const {messages, data, handleChange} = this.props;
        const {isPriceHidden, isAssetsPage} = this.state;
        const listParams = this.state.listParams || {};
        const {sortBy, sortDir, keywords} = listParams;
        const total = data && data.paging ? data.paging.total : 0;
        let sortByVal = sortBy;

        if (!isAssetsPage && sortBy === 'relevancy') {
            sortByVal = 'effectiveDate';
        }
        return (
            <SortDropdown
                sortBy={sortByVal}
                sortDir={sortDir}
                total={total}
                handleChange={model => handleChange('sortChange', model)}
                messages={messages}
                withRelevancy={!!keywords}
                isFreePage={isPriceHidden}
            />
        );
    };

    getPaginator = () => {
        const {data, handleChange} = this.props;
        const {paging = {}} = data;
        const {start, count} = paging;
        const total = data && data.paging ? data.paging.total : 0;
        const pageCount = Math.ceil(total / count);

        return (
            <Paginator
                defaultPageSize={20}
                pageSizeOptions={['8', '20', '60', '100']}
                pageCount={pageCount}
                current={start === 0 ? 1 : Math.floor(start / count) + 1}
                handleChange={model => handleChange('paginationChange', model)}
                total={total}
                pageSize={count || 20}
            />
        );
    };

    getAssetList = () => {
        const {
            isLoading,
            data,
            messages,
            supportForumUrl,
            handleChange,
            handleAddToCart,
            isAddingOffer,
            currentOffer,
            offersInCart,
            isLauncher
        } = this.props;
        const {isAssetsPage} = this.state;
        return (
            <AssetList
                isLoading={isLoading}
                data={data}
                handleChange={model => handleChange('paginationChange', model)}
                messages={messages}
                isAssetsPage={isAssetsPage}
                supportForumUrl={supportForumUrl}
                handleAddToCart={handleAddToCart}
                isAddingOffer={isAddingOffer}
                currentOffer={currentOffer}
                offersInCart={offersInCart}
                isLauncher={isLauncher}
            />
        );
    };

    getFilterPanel = () => {
        const {messages, handleChange} = this.props;
        const {isPriceHidden, isFilterVisible, listParams} = this.state;
        const className = isFilterVisible ? 'visible-small-screen' : '';
        return (
            <FilterPanel
                className={className}
                handleChange={model => handleChange('filterChange', model)}
                messages={messages}
                isPriceHidden={isPriceHidden}
                listParams={listParams}
                onClose={this.closeFilter}
            />
        );
    };

    getHelmetElement = () => {
        const {cmsPageData, isLoading, messages, locale} = this.props;
        const {helmet, descriptionCode, descriptionCodeArgs} = this.state;
        if (cmsPageData && Object.keys(cmsPageData).length) {
            return getHelmetForMetaTags(cmsPageData, 'UE Marketplace');
        } else if (helmet && !cmsPageData && !isLoading) {
            // if no cmsPageData, fallback to in-app helmet data
            const pageTitle = helmet.title;
            const helmetTags = getHelmetMetaTags({
                title: pageTitle,
                description: getMessage(
                    messages,
                    descriptionCode || 'epic.ue.marketplace.meta.showcase',
                    descriptionCodeArgs
                ),
                locale
            });

            return <Helmet {...helmetTags} />;
        }
        return null;
    };

    openFilter = () => {
        this.setState({isFilterVisible: true});
        root.document.body.classList.toggle('noscroll', true);
    };

    closeFilter = () => {
        this.setState({isFilterVisible: false});
        root.document.body.classList.toggle('noscroll', false);
    };

    getFilterLink = () => {
        const {messages} = this.props;
        return (
            <div className="filter-link">
                <a onClick={this.openFilter}>
                    {getMessage(messages, 'messages.com.epicgames.plugin.store.filter.header')}
                </a>
            </div>
        );
    };

    render() {
        return (
            <main className="category-container">
                {this.getHelmetElement()}
                {this.getSortDropdown()}
                {this.getFilterLink()}
                {this.getAssetList()}
                {this.getFilterPanel()}
                {this.getPaginator()}
            </main>
        );
    }
}
BasePageView.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    match: PropTypes.object,
    listParams: PropTypes.object,
    listAssets: PropTypes.func,
    setConfig: PropTypes.func,
    getCmsPageData: PropTypes.func,
    cmsPageData: PropTypes.object,
    messages: PropTypes.object,
    supportForumUrl: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    handleChange: PropTypes.func,
    priceRanges: PropTypes.object,
    engineVersions: PropTypes.array,
    handleAddToCart: PropTypes.func,
    isAddingOffer: PropTypes.bool,
    currentOffer: PropTypes.string,
    offersInCart: PropTypes.array,
    isLauncher: PropTypes.bool,
    descriptionCode: PropTypes.string,
    descriptionCodeArgs: PropTypes.string,
    locale: PropTypes.string
};
export default BasePageView;

export const mapStateToProps = state => {
    const {isLoading: isLoadingCms, cmsPageData} = state.get('cms').toJS();
    const {isLoading: isLoadingAsset, data} = state.get('asset').toJS();
    const listParams = state.get('page').toJS();
    const {
        priceRanges,
        engineVersions,
        eventSaleLabel,
        isLauncher,
        supportForumUrl,
        categories
    } = state.get('config').toJS();
    const {isAddingOffer, currentOffer, offerIds} = state.get('shoppingCart').toJS();
    const {isLoggedIn} = state.get('user').toJS();
    const {locale} = state.get('localization').toJS();
    return {
        cmsPageData,
        messages: state.get('localization').get('messageProperties'),
        isLoading: isLoadingCms || isLoadingAsset,
        data,
        listParams,
        priceRanges,
        engineVersions,
        categories,
        isAddingOffer,
        currentOffer: currentOffer ? currentOffer.offerId : '',
        offersInCart: offerIds,
        eventSaleLabel,
        isLauncher,
        supportForumUrl,
        isLoggedIn,
        locale
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        },
        listAssets: model => {
            dispatch(AssetActions.listAssets(model));
        },
        handleChange: (func, model) => {
            dispatch(PageActions[func](model));
        },
        handleAddToCart: model => {
            dispatch(ShoppingCartActions.addOffer(model));
        },
        getCmsPageData: slug => {
            dispatch(CmsActions.getCmsPageData(slug));
        }
    };
};
