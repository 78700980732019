/**
 * Created by Kaku.Guo on 2018/10/29.
 */

const TYPES = {
    INIT_LAUNCHER_DOWNLOAD: 'INIT_LAUNCHER_DOWNLOAD',
    UPDATE_COMMAND: 'UPDATE_COMMAND',
    UPDATE_DOWNLOAD_PROGRESS: 'UPDATE_DOWNLOAD_PROGRESS',
    EXECUTE_COMMAND: 'EXECUTE_COMMAND',
    EXECUTE_DOWNLOAD_COMMAND: 'EXECUTE_DOWNLOAD_COMMAND',
    RESET_LAUNCHER_DOWNLOAD: 'RESET_LAUNCHER_DOWNLOAD'
};

export default {
    TYPES,
    initLauncherDownload: payload => ({
        type: TYPES.INIT_LAUNCHER_DOWNLOAD,
        payload
    }),
    updateCommands: payload => ({
        type: TYPES.UPDATE_COMMAND,
        payload
    }),
    executeCommand: payload => ({
        type: TYPES.EXECUTE_COMMAND,
        payload
    }),
    updateDownloadProgress: payload => ({
        type: TYPES.UPDATE_DOWNLOAD_PROGRESS,
        payload
    }),
    executeDownloadCommand: payload => ({
        type: TYPES.EXECUTE_DOWNLOAD_COMMAND,
        payload
    }),
    resetLauncherDownload: payload => ({
        type: TYPES.RESET_LAUNCHER_DOWNLOAD,
        payload
    })
};