import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import cn from 'classnames';
import DialogCloseButton from '../button/DialogCloseButton';

const toggleScroll = noscroll => {
    // root.document.body.classList.toggle('noscroll', noscroll);
};

export default function ModalDialog(props) {
    const {isVisible, onClose, className, children, overlayClassName} = props;
    const classNames = cn('enterprise-modal_dialog', className);

    return (
        <Modal
            onEntered={() => toggleScroll(true)}
            onExited={() => toggleScroll(false)}
            onClose={onClose}
            open={isVisible}
            closeOnOverlayClick={false}
            classNames={{
                overlay: overlayClassName,
                modal: classNames,
                closeButton: 'dialog__close'
            }}
            styles={{
                closeButton: {
                    cursor: 'pointer',
                    outline: 'none'
                }
            }}
            closeIconSvgPath={<DialogCloseButton />}
            blockScroll={false}
            center
            showCloseIcon>
            {children}
        </Modal>
    );
}

ModalDialog.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    className: PropTypes.string,
    overlayClassName: PropTypes.string,
    children: PropTypes.any
};
