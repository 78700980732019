/**
 * Created by Kaku.Guo on 2018/11/1.
 */
export default {
    OFFER_ITEM_TYPE_CONSUMABLE: 'CONSUMABLE',
    OFFER_ITEM_TYPE_WALLETCASH: 'WALLETCASH',
    SUPPORT_PLATFORM: 'platform',
    SELLER_DISCONTINUED: 'DISCONTINUED',
    DELETED: 'DELETED'
};

export const ID_PATTERN = /[0-9a-zA-Z]{32}/;

export const EnvHeader = {PUBLIC: 'public', REVIEW: 'review'};
export const REVIEW_PERMISSION = 'epicenv:review';
export const epicEnvName = 'X-Epic-Env';

export const ADPS_EIGHTEEN_OR_OLDER_POLICY = 'g-t79q16m0g9a7';

export const CATEGORY_PATH = {
    COMPLETE_PROJECT: 'projects/completeprojects',
    ASSETS: 'assets',
    CODE_PLUGINS: 'assets/codeplugins'
};
export const DISTRIBUTION_METHOD = {
    ASSET_PACK: 'ASSET_PACK',
    COMPLETE_PROJECT: 'COMPLETE_PROJECT',
    ENGINE_PLUGIN: 'ENGINE_PLUGIN'
};
export const REGEXPS = {
    INT: /^(?:[+]?(?:0|[1-9]\d*))$/,
    INTEGER: /^(?:[+-]?(?:0|[1-9]\d*))$/,
    FLOAT: /^(?:[+]?(?:\d+))?(?:\.\d*)?(?:[eE][+-]?(?:\d+))?$/,
    WORDS: /^[A-Z\s]+$/i,
    SPECIAL_WORDS: /^[A-Z\s\u00C0-\u017F]+$/i,
    ALPHA: /^[A-Z]+$/i,
    NUMERIC: /^[+]?(?:\d*[.])?\d+$/,
    ALPHANUMERIC: /^[0-9A-Z]+$/i,
    PRICE_RANGE: /^\[(\d{0,9}),(\d{0,9})]$/,
    URL: /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/i,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
};
export const SORT_BY_COL = [
    'effectiveDate',
    'title',
    'relevancy',
    'currentPrice',
    'discountPercentage',
    'discountPercentageRange',
    'averageRating'
];
export const SORT_DIR_COL = ['ASC', 'DESC'];

export const IMAGE_REGEX = new RegExp('((.jpg)|(.png)|(.jpeg))\\b', 'i');

export const URL_PROTOCOL = ['http', 'https', 'mailto'];
