/**
 * Created by Kaku.Guo on 2019/5/28.
 */
import { fromJS } from 'immutable';
import typeToReducer from 'type-to-reducer';
import { ReviewDialogActions } from '../actions';

const {
    SET_OPTIONS,
    MERGE_OPTIONS
} = ReviewDialogActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isVisible: false
});

export default typeToReducer({
    [SET_OPTIONS]: (state, action) => (DEFAULT_STATE.merge(action.payload)),
    [MERGE_OPTIONS]: (state, action) => (state.merge(action.payload))
}, DEFAULT_STATE);