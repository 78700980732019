/**
 * Created by Kaku.Guo on 2018/9/20.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Immutable from 'seamless-immutable';
import {debounce} from '../../utils';
import root from 'window-or-global';

class MultipleAutoSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            listData: props.listData || [],
            isListVisible: false,
            currentIndex: -1,
            isActive: false,
            handleSuggest: debounce(this.handleSuggest, 600)
        };
        this.inputRef = null;
    }

    componentDidMount() {
        root.document.body.addEventListener('click', this.handelClickOutside);
    }

    componentWillUnmount() {
        root.document.body.removeEventListener('click', this.handelClickOutside);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            listData,
            equalFilter,
            textField,
            valueField,
            isCreatable,
            isLoading,
            value
        } = nextProps;
        const {listData: oldData, isLoading: preLoading} = this.props;
        const {inputValue} = this.state;
        const input = inputValue.trim();
        if (!listData.length || listData !== oldData) {
            let newData = listData.filter(it => value.every(v => v[valueField] !== it[valueField]));
            if (input && isCreatable) {
                const canCreate = !listData.some(item => equalFilter(input, item));
                if (canCreate) {
                    if (Immutable.isImmutable(newData)) {
                        newData = Immutable.asMutable(newData);
                    }
                    const obj = {canCreate};
                    obj[textField] = input;
                    newData.push(obj);
                }
            }
            this.setState({
                listData: newData,
                currentIndex: newData.length ? 0 : -1
            });
        }
        if (!isLoading && isLoading !== preLoading) {
            this.setState({
                isListVisible: true
            });
        }
    }

    handelClickOutside = e => {
        if (e) {
            const {classNames} = this.props;
            const path = e.path || (e.composedPath && e.composedPath());
            const isClickOutside = path.every(
                p => p.className !== `multiple-auto-suggest ${classNames || ''}`
            );
            if (isClickOutside) {
                this.setState({
                    inputValue: '',
                    isListVisible: false,
                    currentIndex: -1,
                    isActive: false
                });
            }
        }
    };

    handleSuggest = value => {
        const {onSearch} = this.props;
        if (onSearch && value && value.trim()) {
            onSearch(value);
        } else {
            this.setState({
                listData: []
            });
        }
    };

    handleKeyPress = e => {
        if (e) {
            const {listData, currentIndex, isListVisible} = this.state;
            const {textField} = this.props;
            switch (e.charCode) {
                case 13:
                    //enter
                    e.preventDefault();
                    e.stopPropagation();
                    if (isListVisible && currentIndex >= 0) {
                        const item = listData[currentIndex];
                        if (item.canCreate) {
                            this.handleCreate(e, item[textField]);
                        } else {
                            this.handleSelect(e, item);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };

    handleKeyDown = e => {
        if (e) {
            const {currentIndex} = this.state;
            const {listData} = this.state;
            const dataLength = listData.length;
            let nextIndex = -1;
            switch (e.keyCode) {
                case 38:
                    //up
                    e.preventDefault();
                    e.stopPropagation();
                    if (currentIndex <= 0) {
                        nextIndex = dataLength - 1;
                    } else {
                        nextIndex = currentIndex - 1;
                    }
                    break;
                case 40:
                    //down
                    e.preventDefault();
                    e.stopPropagation();
                    nextIndex = (currentIndex + 1) % dataLength;
                    break;
                default:
                    break;
            }
            if (nextIndex >= 0) {
                this.setState({
                    currentIndex: nextIndex
                });
            }
        }
    };

    handleInputChange = e => {
        if (e) {
            const {handleSuggest} = this.state;
            const inputValue = e.target.value;
            this.setState({
                inputValue
            });
            handleSuggest(inputValue);
        }
    };

    handleFocus = e => {
        if (e) {
            e.stopPropagation();
        }
        this.inputRef.focus();
        const {onFocus} = this.props;
        if (onFocus) {
            onFocus();
        }
    };

    handleInputBlock = e => {
        if (e) {
            e.stopPropagation();
        }
        const {isDisabled} = this.props;
        if (isDisabled) {
            return;
        }
        this.handleFocus();
        this.setState({
            isActive: true
        });
    };

    handleRemove = (item, e) => {
        if (e) {
            e.stopPropagation();
        }
        const {value, onChange, valueField, isClearable, isDisabled} = this.props;
        if (isDisabled && !isClearable) {
            return;
        }
        const newValue = value.filter(it => it !== item || it[valueField] !== item[valueField]);
        if (onChange) {
            onChange(newValue);
        }
    };

    handleSelect = (e, item) => {
        if (e) {
            e.stopPropagation();
        }
        const {value, onChange} = this.props;
        let newValue = value;
        if (Immutable.isImmutable(value)) {
            newValue = Immutable.asMutable(value);
        }
        newValue.push(item);
        if (onChange) {
            onChange(newValue);
        }
        this.setState({
            inputValue: '',
            listData: []
        });
        this.handleFocus();
    };

    getItemComponent = item => {
        const {textField, getItemComponent} = this.props;
        if (getItemComponent) {
            return getItemComponent(item);
        }
        return item[textField];
    };

    getCreateOption = (value, isSelected) => {
        const {getCreateOption} = this.props;
        let label = `Create option ${value}`;
        if (getCreateOption) {
            label = getCreateOption(value);
        }
        return (
            <li
                key={`multi-auto-create-${value}`}
                className={`multi-auto-create ${isSelected ? 'selected' : ''}`}
                onClick={e => this.handleCreate(e, value)}>
                {label}
            </li>
        );
    };

    handleCreate = (e, value) => {
        if (e) {
            e.stopPropagation();
        }
        const {onCreate} = this.props;
        this.setState({
            inputValue: ''
        });
        onCreate(value);
        this.handleFocus();
    };

    render() {
        const {
            classNames,
            value,
            textField,
            isLoading,
            isDisabled,
            isDropUp,
            placeholder,
            maxLength
        } = this.props;
        const {listData, inputValue, currentIndex, isListVisible, isActive} = this.state;
        const placeHolder = value.length ? '' : placeholder;
        const inputSize = inputValue ? inputValue.length + 1 : placeHolder.length + 1;
        const showList = isListVisible && !isLoading && listData.length;
        return (
            <div className={`multiple-auto-suggest ${classNames}`}>
                <div
                    className={`multiple-input-block ${isDisabled ? 'disabled' : ''} ${
                        isActive ? 'active' : ''
                    }`}
                    onClick={this.handleInputBlock}>
                    <span>
                        <i className="search-icon fa fa-search" />
                    </span>
                    {value.map((item, idx) => (
                        <div
                            key={`selected-item-${idx}`}
                            className="selected-item"
                            onClick={() => this.handleRemove(item)}>
                            {item[textField]}
                            <span>x</span>
                        </div>
                    ))}
                    <input
                        className="multiple-auto-input"
                        type="text"
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        onKeyPress={this.handleKeyPress}
                        disabled={isDisabled}
                        placeholder={placeHolder}
                        readOnly={isLoading}
                        maxLength={maxLength}
                        size={inputSize}
                        ref={ref => {
                            this.inputRef = ref;
                        }}
                    />
                    <i className={`loading-block ${isLoading ? '' : 'hidden'}`} />
                </div>
                <ul
                    className={`multiple-auto-list ${showList ? '' : 'hidden'} ${
                        isDropUp ? 'dropup' : ''
                    }`}>
                    {listData.map((item, idx) => {
                        if (!item.canCreate) {
                            return (
                                <li
                                    key={`multiple-auto-list-item-${idx}`}
                                    className={currentIndex === idx ? 'selected' : ''}
                                    onClick={e => this.handleSelect(e, item)}>
                                    {this.getItemComponent(item)}
                                </li>
                            );
                        }
                        return this.getCreateOption(item[textField], currentIndex === idx);
                    })}
                </ul>
            </div>
        );
    }
}
MultipleAutoSuggest.defaultProps = {
    textField: 'name',
    valueField: 'id',
    maxLength: 50,
    value: [],
    listData: [],
    placeholder: ''
};
MultipleAutoSuggest.propTypes = {
    classNames: PropTypes.string,
    textField: PropTypes.string,
    valueField: PropTypes.string,
    value: PropTypes.array,
    listData: PropTypes.array,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isCreatable: PropTypes.bool,
    isDropUp: PropTypes.bool,
    isClearable: PropTypes.bool,
    onSearch: PropTypes.func,
    onCreate: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    getCreateOption: PropTypes.func,
    getItemComponent: PropTypes.func,
    maxLength: PropTypes.number,
    equalFilter: PropTypes.func
};
export default MultipleAutoSuggest;
