/**
 * Created by Kaku.Guo on 2019/6/14.
 */
import React, { Fragment } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Rate, Icon, Message } from '../index';

function RatingBoard({ data = {}, className, isLauncher, size = 20, renderReviewBtn }) {
    const { total = 0, averageRating = 0, legacyRatingNum = 0 } = data;
    const prefixCls = 'rating-board';
    const classNames = cn(prefixCls, className);
    const ratings = [];
    for (let i = 5; i >= 1; i--) {
        ratings.push({
            value: i,
            percent: Math.round(data[`rating${i}Percent`] || 0)
        });
    }
    const renderContent = () => {
        if (total && total > 0) {
            return (
                <Fragment>
                    <div className={`${prefixCls}__bar`}>
                        <Rate
                            className={`${prefixCls}__average`}
                            initialRating={averageRating || 0}
                            size={size}
                            isLauncher={isLauncher}
                            readonly />
                        <Icon name="angle-down" />
                        <span className={`${prefixCls}__count`}>{total}</span>
                    </div>
                    <div className={`${prefixCls}__pop`}>
                        <div className={`${prefixCls}__pop__container`}>
                            <div className={`${prefixCls}__pop__corner`} />
                            <div className={`${prefixCls}__pop__title`}>
                                <Message code="messages.com.epicgames.plugin.store.asset.review.rating.average" args={[averageRating]} />
                                <Message
                                    numeric
                                    number={total}
                                    code="messages.com.epicgames.plugin.store.asset.review.rating.total"
                                    args={[total]} />
                            </div>
                            <ul className={`${prefixCls}__pop__content`}>
                                {ratings.map((it, idx) => (
                                    <li key={`rating-${idx}`}>
                                        <Rate
                                            initialRating={it.value}
                                            size={13}
                                            isLauncher={isLauncher}
                                            readonly
                                        />
                                        <div className={`${prefixCls}__pop__content__percent`}>
                                            <div style={{ width: `${it.percent}%` }} />
                                        </div>
                                        <span>{it.percent}%</span>
                                    </li>
                                ))}
                            </ul>
                            {total - legacyRatingNum > 0 && (
                                <div className={`${prefixCls}__pop__btn`}>
                                    {renderReviewBtn(
                                        <Message
                                            numeric
                                            number={total - legacyRatingNum}
                                            code="messages.com.epicgames.plugin.store.asset.see_all_reviews"
                                            args={[total - legacyRatingNum]} />)}
                                </div>
                            )}
                        </div>
                    </div>
                </Fragment>
            );
        }
        return <Message className={`${prefixCls}__empty`} code="messages.com.epicgames.plugin.store.asset.review.rating.empty" />;
    };
    return (
        <div className={classNames}>
            {renderContent()}
        </div>
    );
}

RatingBoard.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    isLauncher: PropTypes.bool,
    size: PropTypes.number,
    renderReviewBtn: PropTypes.func
};

export default RatingBoard;
