import {SharedValueEffect} from '@epic-core/hooks';
import {PurchaseService, PurchaseSuccessEvent, Receipt} from './purchase.service';

export const PurchaseEffects: SharedValueEffect = values => {
    const service = values.service(PurchaseService);

    service.addPurchaseListener(receipt => {
        values.dispatch<Receipt>(PurchaseSuccessEvent, receipt);
    });
};
