import {sharedService, AppEventType} from '@epic-core/hooks';

interface Item {
    EntitlementId: string;
    EntitlementName: string;
    ItemId: string;
    itemNamespace: string;
}

interface ReceiptOffer {
    Items: Item[];
    OfferId: string;
    OfferNamespace: string;
}

export interface Receipt {
    Offers: ReceiptOffer[];
    Transactionid: string;
    TransactionState: string;
    grantedVouncher: string | null;
}

class PurchaseServiceProvider {
    callbacks = [];

    public addPurchaseListener = cb => {
        if (cb && typeof cb === 'function') {
            this.callbacks.push(cb);
        }
    };

    public onPurchaseSuccess(receipt: Receipt) {
        this.callbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb(receipt);
            }
        });
    }
}

export const PurchaseSuccessEvent: AppEventType = 'purchase-success';

export const purchaseService = new PurchaseServiceProvider();

export const PurchaseService = sharedService(values => {
    return {
        onPurchaseSuccess: purchaseService.onPurchaseSuccess,
        addPurchaseListener: purchaseService.addPurchaseListener
    };
});
