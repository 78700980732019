import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getOptImage} from 'epic-ue-shared';

const mapStateToProps = state => {
    const {banner} = state.get('config').toJS();

    return banner || {};
};

@connect(mapStateToProps)
export default class Banner extends Component {
    render() {
        const {
            bgColor,
            bgSize,
            height,
            image,
            openInNewTab,
            textColor,
            title,
            titlePosition,
            url
        } = this.props;

        const titleStyle = {
            background: bgColor,
            color: textColor,
            alignSelf: titlePosition
        };

        const bannerHeight = height !== 0 ? height : null;

        const bgStyle = {
            backgroundImage: `url(${getOptImage({url: image, width: 1400})})`,
            backgroundSize: bgSize,
            height: bannerHeight
        };

        // don't render banner until image is available
        // otherwise, a large blank area will appear over the header
        if (!image) {
            return null;
        }

        // render as link if URL is available
        const Element = url ? 'a' : 'div';

        return (
            <Element
                className="topBanner"
                href={url}
                style={{height: bannerHeight}}
                target={url ? (openInNewTab ? '_blank' : '') : ''}>
                <div className="topBanner__img" style={bgStyle} />
                <h3 className="topBanner__title" style={titleStyle}>
                    {title}
                </h3>
            </Element>
        );
    }
}

Banner.displayName = 'Banner';

Banner.propTypes = {
    bgColor: PropTypes.string,
    bgSize: PropTypes.string,
    height: PropTypes.string,
    image: PropTypes.string,
    openInNewTab: PropTypes.bool,
    textColor: PropTypes.string,
    title: PropTypes.string,
    titlePosition: PropTypes.string,
    url: PropTypes.string
};
