import * as React from "react";
import styled from 'styled-components';
import root from 'window-or-global';
import {useLocalization} from "@epic-core/localization";

interface EulaContentProps {
    content: string;
    title: string;
    version: number;
}

const EulaModalContent = styled.div`
    overflow-y: auto;
    flex: 1;
    min-height: 200px;
    padding: 20px 30px 20px 20px;
    text-align: left;
    background-color: #eee;
    border: solid 1px #333;
    border-radius: 5px;
    box-shadow: inset 0 -10px 10px 0px rgba(33, 33, 33, .5);
    line-height: 1.5em;
    white-space: pre-wrap;
    .header {
        font-size: 18px;
    }
    .highlight-italic {
        font-weight: 600;
        font-style: italic;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, .9) !important;
    }
    &::-webkit-scrollbar-button {
        display: contents !important;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #a9a9a9 !important;
    }
`;

const EulaModalContentHeader = styled.div`
    color: #333;
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
`;

export const EulaContent: React.FC<EulaContentProps> = ({content, title, version}) => {
    const getMessage = useLocalization();
    React.useEffect(() => {
        if (version) {
            const el = root.document.querySelector('#eula-dialog');
            if (el) {
                const aTags = root.document.querySelectorAll('#eula-dialog a');
                for (let i=0; i<aTags.length; i++){
                    const anchor = aTags[i];
                    if (anchor.getAttribute("href")) {
                        anchor.target = "_blank";
                        anchor.rel = "noopener noreferrer";
                    }
                }
            }
        }
    }, []);
    return (
        <EulaModalContent id="eula-dialog">
            {
                version ?
                    (
                        <>
                            <EulaModalContentHeader>{title}</EulaModalContentHeader>
                            <p dangerouslySetInnerHTML={{__html: content}} />
                        </>
                    ) :
                    <p>{getMessage("errors.com.epicgames.common.unhandled_error")}</p>
            }
        </EulaModalContent>
    );
}

