import {call, put, takeLatest} from 'redux-saga/effects';
import {getLocale} from '@epic-core/common';
import {extractSlugLocale} from '@epic-mw/localization';
import {CmsActions} from '../actions';
import {CmsApi} from '../apis';

function* getCmsPageData(action) {
    try {
        const locale = getLocale();
        const pathname = action.payload;
        const {pageSlug} = extractSlugLocale(pathname, locale);
        const cmsPageSlug = `/marketplace${pageSlug.replace('/content-cat', '')}`;
        const data = yield call(CmsApi.getCmsPageData, cmsPageSlug);
        yield put(CmsActions.getCmsPageDataSuccess(data));
    } catch (e) {
        yield put(CmsActions.getCmsPageDataFailed(e));
    }
}

function* CmsSaga() {
    yield takeLatest(CmsActions.TYPES.GET_CMS_PAGE_DATA_PENDING, getCmsPageData);
}

export default CmsSaga;
