import GraphQlService from 'epic-node-services/src/graphql/GraphQlService';
import {WishlistItems, WishlistItem} from 'epic-graphql-types';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';

const wishListItemQuery = countryCode => `
{
    created
    id
    namespace
    offer {
        effectiveDate
        id
        isCodeRedemptionOnly
        keyImages {
            type
            url
            height
            width
        }
        offerType
        price(country: "${countryCode}") {
            totalPrice {
                currencyCode
                currencyInfo {
                    decimals
                    symbol
                }
                discount
                discountPrice
                fmtPrice(locale: "${getLocale()}") {
                    originalPrice
                    discountPrice
                    intermediatePrice
                }
                originalPrice
            }
        }
        title
        status
    }
    offerId
    updated
}`;

const WISHLIST_QUERY = countryCode => `
query {
    Wishlist {
        wishlistItems(store: "ues") {
            elements ${wishListItemQuery(countryCode)}
        }
    }
}`;

interface WishlistQueryRes {
    data: {
        Wishlist: WishlistItems;
    };
}

const WISHLIST_ADD_MUTATION = (offerId, countryCode) => `
mutation {
    Wishlist {
        addToWishlist(offerId: "${offerId}", namespace: "ue", store: "ues") {
            success
            wishlistItem ${wishListItemQuery(countryCode)}
        }
    }
}`;

interface WishlistAddRes {
    data: {
        Wishlist: {
            addToWishlist: {
                success;
                wishlistItem: WishlistItem;
            };
        };
    };
}

const WISHLIST_REMOVE_MUTATION = offerId => `
mutation {
    Wishlist {
        removeFromWishlist(
			offerId: "${offerId}",
			namespace: "ue",
			operation: REMOVE,
			store: "ues") {
            success
        }
    }
}`;

class WishlistApi {
    public async getWishlistItems(countryCode: string): Promise<any> {
        if (root.__server_side_render) {
            return [];
        }

        if (!countryCode) {
            throw new Error('getWishlistItems() requires a countryCode');
        }

        try {
            const response: WishlistQueryRes = await GraphQlService.query(
                WISHLIST_QUERY(countryCode)
            );
            const data = response.data || {};
            const wishlist = data.Wishlist || {};
            const wishlistItems: WishlistItems = wishlist.wishlistItems || {};
            const elements = wishlistItems.elements || [];
            return elements;
        } catch (e) {
            console.error('getWishlistItems() failed to load');
            throw e;
        }
    }

    public async addToWishlist(offerId: string, countryCode: string): Promise<any> {
        if (!offerId) {
            throw new Error('addToWishlist() requires an offerId');
        }

        try {
            const response: WishlistAddRes = await GraphQlService.query(
                WISHLIST_ADD_MUTATION(offerId, countryCode)
            );
            const data = response.data || {};
            const wishlist = data.Wishlist || {};
            const addToWishlist = wishlist.addToWishlist || {};
            return addToWishlist;
        } catch (e) {
            console.error('addToWishlist() failed to load');
            throw e;
        }
    }

    public async removeFromWishlist(offerId: string): Promise<any> {
        if (!offerId) {
            throw new Error('removeFromWishlist() requires an offerId');
        }

        try {
            const response: WishlistAddRes = await GraphQlService.query(
                WISHLIST_REMOVE_MUTATION(offerId)
            );
            const data = response.data || {};
            const wishlist = data.Wishlist || {};
            const removeFromWishlist = wishlist.removeFromWishlist || {};
            return removeFromWishlist;
        } catch (e) {
            console.error('removeFromWishlist() failed to load');
            throw e;
        }
    }
}

export const wishlistApi = new WishlistApi();
