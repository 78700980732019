import root from 'window-or-global';
import React from 'react';
import {Redirect} from 'react-router-dom';
import {FabRedirect} from './FabRedirect';
import {getPath} from '@epic-core/common';
import qs from 'query-string';
import {ErrorView, ErrorPage, AssetDetailView, FabLandingView, SellerAssetsView} from '../pages';

const redirectFabLocale = (destination, route) => {
    let destPath = destination;
    // handle tag urls manually since match doesn't pick up query params
    if (route.location.pathname.endsWith('assets')) {
        switch (route.location.search) {
            case '?tag=75&tag=1265':
                destPath =
                    '/channels/unreal-engine?categories=buildings-architecture&listing_types=3d-model';
                break;
            case '?tag=75&tag=2170':
                destPath = '/channels/unreal-engine?q=automotive';
                break;
            case '?tag=75&tag=6269':
                destPath = '/channels/unreal-engine?q=fashion';
                break;
            case '?tag=75&tag=9934':
                destPath = '/channels/unreal-engine?q=television';
                break;
            case '?tag=75&tag=453':
                destPath = '/channels/unreal-engine?q=simulation';
                break;
            case '?tag=4906':
                destPath = '/channels/unreal-engine?is_free=true';
                break;
            default:
                return <Redirect to={`${getPath()}${route.match.params.locale}/`} />;
        }
    }
    return <FabRedirect to={destPath} />;
};

const redirectUemLocale = (destination, route) => {
    const path = getPath();
    let destPath = null;
    if (typeof destination === 'function') {
        destPath = destination(route.match.params);
    } else {
        destPath = destination;
    }
    let epicEnv;
    if (route && route.location && route.location.search) {
        epicEnv = qs.parse(route.location.search)['X-Epic-Env'];
    }
    let query = '';
    if (epicEnv) {
        query = `?X-Epic-Env=${epicEnv}`;
    }
    const redirectUrl = `${path}${route.match.params.locale}${destPath}${query}`;
    return <Redirect to={redirectUrl} />;
};

const goSlug = redirectUemLocale.bind(null, ({slug}) => `/product/${slug}`);
const goItemDetail = redirectUemLocale.bind(null, ({id}) => `/item-detail/${id}`);

const getRoutes = function getRoutes() {
    const clientEnvConfig = root.clientEnvConfig || {};
    const epicSeller = clientEnvConfig.EPIC_FAB_EPIC_SELLER_ID || '';
    const quixelSeller = clientEnvConfig.EPIC_FAB_QUIXEL_SELLER_ID || '';

    return [
        {
            path: 'store',
            exact: true,
            render: redirectUemLocale.bind(null, '/')
        },
        {
            path: 'assets',
            exact: true,
            render: redirectFabLocale.bind(null, '/')
        },
        {
            path: 'featured',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine')
        }, //old Showcase link
        {
            path: 'showcase',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine')
        },
        {
            path: 'new-content',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?sort_by=-createdAt')
        },
        {
            path: 'content-cat/assets/2d',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=2d-asset')
        },
        {
            path: 'content-cat/assets/animations',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=animation')
        },
        {
            path: 'content-cat/assets/archvis',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?categories=buildings-architecture&listing_types=3d-model'
            )
        },
        {
            path: 'content-cat/assets/blueprints',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?listing_types=smart-asset'
            )
        },
        {
            path: 'content-cat/assets/characters',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?categories=characters-creatures&listing_types=smart-asset'
            )
        },
        {
            path: 'content-cat/assets/codeplugins',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?listing_types=tool-and-plugin'
            )
        },
        {
            path: 'content-cat/assets/environments',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?listing_types=environment'
            )
        },
        {
            path: 'content-cat/assets/showcasedemos',
            exact: true,
            render: redirectFabLocale.bind(null, `/seller-id/${epicSeller}`)
        },
        {
            path: 'content-cat/assets/materials',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=material')
        },
        {
            path: 'content-cat/assets/megascans',
            exact: true,
            render: redirectFabLocale.bind(null, `/seller-id/${quixelSeller}`)
        },
        {
            path: 'content-cat/assets/music',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?categories=music&listing_types=audio'
            )
        },
        {
            path: 'content-cat/assets/props',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=3d-model')
        },
        {
            path: 'content-cat/assets/soundfx',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?categories=sound-effects&listing_types=audio'
            )
        },
        {
            path: 'content-cat/assets/textures',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=material')
        },
        {
            path: 'content-cat/learn/features',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                `/seller-id/${epicSeller}?categories=engine-tools&listing_types=tool-and-plugin`
            )
        },
        {
            path: 'content-cat/learn/games',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                `/seller-id/${epicSeller}?listing_types=education-tutorial`
            )
        },
        {
            path: 'content-cat/learn/legacysamples',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                `/seller-id/${epicSeller}?listing_types=education-tutorial`
            )
        },
        {
            path: 'content-cat/assets/onlinelearning',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                `/seller-id/${epicSeller}?categories=unreal-engine&listing_types=education-tutorial`
            )
        },
        {
            path: 'content-cat/assets/fx',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?listing_types=vfx')
        },
        {
            path: 'content-cat/assets/weapons',
            exact: true,
            render: redirectFabLocale.bind(
                null,
                '/channels/unreal-engine?categories=weapons-combat&listing_types=3d-model'
            )
        },
        {
            path: 'on-sale',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?onsale=1')
        },
        {
            path: 'free',
            exact: true,
            render: redirectFabLocale.bind(null, '/channels/unreal-engine?is_free=true')
        },
        {path: 'vault', exact: true, render: redirectFabLocale.bind(null, '/library')},
        {
            path: 'profile/Epic+Games',
            exact: true,
            render: redirectFabLocale.bind(null, `/seller-id/${epicSeller}?is_free=true`)
        },
        {
            path: 'profile/Quixel',
            exact: true,
            render: redirectFabLocale.bind(null, `/seller-id/${quixelSeller}?is_free=true`)
        },
        {path: 'profile/:sellerName', exact: true, component: SellerAssetsView},
        {path: 'error', exact: true, component: ErrorView},
        {path: 'error-404', exact: true, component: ErrorPage},
        {path: 'error-500', exact: true, component: ErrorPage},
        {path: 'coupons/:coupons', exact: true, component: ErrorPage},
        {path: 'item-detail/:id', exact: false, component: AssetDetailView},
        {path: 'item/:id', exact: true, render: goItemDetail},
        {path: 'product/:slug', exact: false, component: AssetDetailView},
        {path: 'content/:slug', exact: true, render: goSlug},
        {path: 'learn/:slug', exact: true, render: goSlug},
        {path: 'slug/:slug', exact: true, render: goSlug},
        {path: ':slug', exact: true, render: goSlug},
        {path: '', component: FabLandingView}
    ];
};

export default getRoutes;
