import {generateAsyncActions} from '../utils';

const TYPES = {
    SET_TRADER_DIALOG_OPTIONS: 'SET_TRADER_DIALOG_OPTIONS',
    MERGE_TRADER_DIALOG_OPTIONS: 'MERGE_TRADER_DIALOG_OPTIONS',
    HIDE_TRADER_DIALOG: 'HIDE_TRADER_DIALOG',
    ...generateAsyncActions('GET_TRADER_INFO')
};
export default {
    TYPES,
    setOptions: payload => ({
        type: TYPES.SET_TRADER_DIALOG_OPTIONS,
        payload
    }),
    mergeOptions: payload => ({
        type: TYPES.MERGE_TRADER_DIALOG_OPTIONS,
        payload
    }),
    hide: payload => ({
        type: TYPES.HIDE_TRADER_DIALOG,
        payload
    }),
    getTraderInfo: payload => ({
        type: TYPES.GET_TRADER_INFO_PENDING,
        payload
    }),
    getTraderInfoSuccess: payload => ({
        type: TYPES.GET_TRADER_INFO_SUCCESS,
        payload
    }),
    getTraderInfoFailed: payload => ({
        type: TYPES.GET_TRADER_INFO_FAILED,
        payload
    })
};
