/**
 * Created by Kaku.Guo on 2019/5/22.
 */
import {delay} from 'redux-saga';
import {call, put, takeLatest, select} from 'redux-saga/effects';
import {ReviewActions, ReviewDialogActions, UploadActions, AssetActions} from '../actions';
import {ReviewApi} from '../apis';
import {getError} from '../utils';
import {REVIEW_TYPES} from '../constants';

const getReviewListParams = state =>
    state
        .get('review')
        .get('listParams')
        .toJS();
const getOfferId = state =>
    state
        .get('asset')
        .get('data')
        .get('data')
        .get('id');
const getAssetList = state =>
    state
        .get('asset')
        .get('data')
        .toJS();

function* hideReviewDialog(action) {
    const hasImage = action.payload || false;
    yield put(ReviewDialogActions.mergeOptions({isVisible: false, isReportVisible: false}));
    yield call(delay, 300);
    yield put(ReviewDialogActions.setOptions());
    if (hasImage) {
        yield put(UploadActions.updateQueueStatus());
    }
}

function* addReview(action) {
    try {
        yield put(
            ReviewDialogActions.mergeOptions({
                isLoading: true,
                error: {}
            })
        );
        const {offerId, type, isListPage, ...params} = action.payload;
        const data = yield call(ReviewApi.addReview, offerId, type, params);
        yield put(ReviewDialogActions.hide(true));
        if (!isListPage) {
            const listParams = yield select(getReviewListParams);
            if (listParams && listParams.type === type && listParams.offerId) {
                yield put(ReviewActions.getReviews(listParams));
            }
            if (type === REVIEW_TYPES.REVIEW) {
                yield put(ReviewActions.getReviewDetailByAccountSuccess(data));
            }
        } else {
            const assetList = yield select(getAssetList);
            yield put(
                AssetActions.listAssetsSuccess({
                    ...assetList,
                    elements: assetList.elements.map(asset => {
                        return {
                            ...asset,
                            reviewed: asset.reviewed || asset.id === offerId
                        };
                    })
                })
            );
        }
        yield put(ReviewActions.getRating({offerId, isListPage}));
    } catch (e) {
        yield put(
            ReviewDialogActions.mergeOptions({
                isLoading: false,
                error: getError(e)
            })
        );
    }
}

function* editReview(action) {
    try {
        const {offerId, isListPage, ...reviewBody} = action.payload;
        const {type, topicId} = reviewBody;
        yield put(ReviewDialogActions.mergeOptions({isLoading: true, error: {}}));
        const data = yield call(ReviewApi.editReview, offerId, type, reviewBody);
        yield put(ReviewDialogActions.hide(true));
        if (!isListPage) {
            yield put(ReviewActions.getReviewDetail({offerId, type, topicId}));
            if (type === REVIEW_TYPES.REVIEW) {
                yield put(ReviewActions.getReviewDetailByAccountSuccess(data));
            }
        }
        yield put(ReviewActions.getRating({offerId, isListPage}));
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({isLoading: false, error: getError(e)}));
    }
}

function* deleteReview(action) {
    const {offerId, topicId, type, onSuccess, onFailed} = action.payload;
    try {
        yield put(ReviewDialogActions.mergeOptions({isLoading: true, error: {}}));
        yield call(ReviewApi.deleteReview, offerId, topicId, {type});
        yield put(ReviewDialogActions.hide());
        if (onSuccess) {
            onSuccess();
        }
        const listParams = yield select(getReviewListParams);
        if (listParams && listParams.type === type && listParams.offerId) {
            yield put(ReviewActions.getReviews(listParams));
        }
        if (type === REVIEW_TYPES.REVIEW) {
            yield put(ReviewActions.getReviewDetailByAccountSuccess({}));
            yield put(ReviewActions.getRating({offerId}));
        }
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({isLoading: false, error: getError(e)}));
        if (onFailed) {
            onFailed({payload: getError(e)});
        }
    }
}

function* getReviews(action) {
    try {
        const {offerId, type, ...params} = action.payload;
        const data = yield call(ReviewApi.getReviews, offerId, type, params);
        yield put(ReviewActions.getReviewsSuccess(data));
    } catch (e) {
        yield put(ReviewActions.getReviewsFailed(e));
    }
}

function* getReviewDetailByAccount(action) {
    const {offerId, onSuccess, onFailed} = action.payload;
    try {
        const data = yield call(ReviewApi.getReviewDetailByAccount, offerId);
        if (onSuccess) {
            onSuccess(data);
        }
        yield put(ReviewActions.getReviewDetailByAccountSuccess(data));
    } catch (e) {
        if (onFailed) {
            onFailed();
        }
        yield put(ReviewActions.getReviewDetailByAccountFailed(e));
    }
}

function* getReviewDetail(action) {
    const {offerId, type, topicId, withReply, onSuccess, onFailed} = action.payload;
    try {
        const data = yield call(ReviewApi.getReviewDetail, offerId, type, topicId, {withReply});
        if (onSuccess) {
            onSuccess({offerId, type, topicId, data});
        }
        yield put(ReviewActions.getReviewDetailSuccess({topicId, data}));
    } catch (e) {
        if (onFailed) {
            onFailed(getError(e));
        }
        yield put(ReviewActions.getReviewDetailFailed(e));
    }
}

function* voteReview(action) {
    const {id, isHelpful, type, topicId} = action.payload;
    try {
        yield call(ReviewApi.voteReview, id, type, {isHelpful});
        yield put(ReviewActions.voteReviewSuccess({id, type, topicId, isHelpful}));
    } catch (e) {
        yield put(ReviewActions.voteReviewFailed({error: e, id, isHelpful, type, topicId}));
    }
}

function* addReply(action) {
    const {topicId, content, type, onSuccess, onFailed} = action.payload;
    try {
        yield put(ReviewDialogActions.mergeOptions({isLoading: true, error: {}}));
        yield call(ReviewApi.replyReview, topicId, type, {content});
        yield put(ReviewDialogActions.hide());
        if (onSuccess) {
            onSuccess();
        }
        const offerId = yield select(getOfferId);
        yield put(ReviewActions.getReviewDetail({offerId, type, topicId}));
        yield put(ReviewActions.getRating({offerId}));
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({isLoading: false, error: getError(e)}));
        if (onFailed) {
            onFailed({payload: getError(e)});
        }
    }
}

function* deleteReply(action) {
    const {replyId, type, topicId, onSuccess, onFailed} = action.payload;
    try {
        yield put(ReviewDialogActions.mergeOptions({isLoading: true, error: {}}));
        yield call(ReviewApi.deleteReply, replyId, type, {topicId});
        yield put(ReviewDialogActions.hide(true));
        if (onSuccess) {
            onSuccess();
        }
        const offerId = yield select(getOfferId);
        yield put(ReviewActions.getReviewDetail({offerId, type, topicId}));
        yield put(ReviewActions.getRating({offerId}));
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({isLoading: false, error: getError(e)}));
        if (onFailed) {
            onFailed({payload: getError(e)});
        }
    }
}

function* editReply(action) {
    try {
        yield put(ReviewDialogActions.mergeOptions({isLoading: true, error: {}}));
        const {replyId, type, ...params} = action.payload;
        yield call(ReviewApi.editReply, replyId, type, params);
        yield put(ReviewDialogActions.hide());
        const offerId = yield select(getOfferId);
        yield put(ReviewActions.getReviewDetail({offerId, type, topicId: params.topicId}));
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({isLoading: false, error: getError(e)}));
    }
}

function* getReviewReplies(action) {
    try {
        const {topicId, type, ...params} = action.payload;
        const data = yield call(ReviewApi.getReviewReplies, topicId, type, params);
        yield put(ReviewActions.getReviewRepliesSuccess({topicId, data}));
    } catch (e) {
        yield put(ReviewActions.getReviewRepliesFailed(e));
    }
}

function* showMoreReplies(action) {
    try {
        const {topicId, type, ...params} = action.payload;
        const data = yield call(ReviewApi.getReviewReplies, topicId, type, params);
        yield put(ReviewActions.showMoreRepliesSuccess({topicId, data}));
    } catch (e) {
        yield put(ReviewActions.showMoreRepliesFailed(e));
    }
}

function* setBestReply(action) {
    try {
        yield put(
            ReviewDialogActions.mergeOptions({
                isLoading: true,
                error: {}
            })
        );
        const {replyId, topicId, isBest} = action.payload;
        yield call(ReviewApi.setBestReply, topicId, replyId, {isBest});
        yield put(ReviewDialogActions.hide());
        const offerId = yield select(getOfferId);
        yield put(ReviewActions.getReviewDetail({offerId, type: REVIEW_TYPES.QUESTION, topicId}));
        yield put(ReviewActions.getRating({offerId}));
    } catch (e) {
        yield put(
            ReviewDialogActions.mergeOptions({
                isLoading: false,
                error: getError(e)
            })
        );
    }
}

function* getRating(action) {
    const {offerId, isListPage} = action.payload;
    try {
        const data = yield call(ReviewApi.getRating, offerId);
        if (isListPage) {
            yield put(AssetActions.updateRating({offerId, data}));
        } else {
            yield put(ReviewActions.getRatingSuccess(data));
        }
    } catch (e) {
        if (!isListPage) {
            yield put(ReviewActions.getRatingFailed(e));
        }
    }
}

function* ReviewSaga() {
    const {
        ADD_REVIEW_PENDING,
        SET_BEST_REPLY_PENDING,
        GET_REVIEWS_PENDING,
        GET_REVIEW_DETAIL_PENDING,
        GET_REVIEW_DETAIL_BY_ACCOUNT_PENDING,
        EDIT_REVIEW_PENDING,
        DELETE_REVIEW_PENDING,
        REPLY_REVIEW_PENDING,
        GET_REVIEW_REPLIES_PENDING,
        SHOW_MORE_REPLIES_PENDING,
        VOTE_REVIEW_PENDING,
        EDIT_REPLY_PENDING,
        DELETE_REPLY_PENDING,
        GET_RATING_PENDING
    } = ReviewActions.TYPES;
    const {HIDE_REVIEW_DIALOG} = ReviewDialogActions.TYPES;

    yield takeLatest(HIDE_REVIEW_DIALOG, hideReviewDialog);
    // review
    yield takeLatest(ADD_REVIEW_PENDING, addReview);
    yield takeLatest(DELETE_REVIEW_PENDING, deleteReview);
    yield takeLatest(EDIT_REVIEW_PENDING, editReview);
    yield takeLatest(GET_REVIEWS_PENDING, getReviews);
    yield takeLatest(GET_REVIEW_DETAIL_PENDING, getReviewDetail);
    yield takeLatest(GET_REVIEW_DETAIL_BY_ACCOUNT_PENDING, getReviewDetailByAccount);
    yield takeLatest(VOTE_REVIEW_PENDING, voteReview);
    // reply
    yield takeLatest(REPLY_REVIEW_PENDING, addReply);
    yield takeLatest(DELETE_REPLY_PENDING, deleteReply);
    yield takeLatest(EDIT_REPLY_PENDING, editReply);
    yield takeLatest(GET_REVIEW_REPLIES_PENDING, getReviewReplies);
    yield takeLatest(SHOW_MORE_REPLIES_PENDING, showMoreReplies);
    yield takeLatest(SET_BEST_REPLY_PENDING, setBestReply);
    // rating
    yield takeLatest(GET_RATING_PENDING, getRating);
}

export default ReviewSaga;
