/**
 * Created by Kaku.Guo on 2019/1/30.
 */
import React from 'react';
import PropTypes from 'prop-types';

class Select extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: false
        };
    }

    handleSelectChange = value => (e) => {
        const {
            onChange,
            value: oldValue
        } = this.props;
        if (value !== oldValue) {
            onChange(value);
        }
    };

    toggleDropDown = () => {
        if (this.props.disabled) {
            return;
        }
        this.setState({
            showOptions: !this.state.showOptions
        });
    };

    handleBlur = () => {
        this.setState({
            showOptions: false
        });
    };

    render() {
        const {
            value,
            disabled,
            options,
            className,
            placeholder
        } = this.props;

        const { showOptions } = this.state;

        let displayValue = placeholder || '';
        if (value) {
            const displayOption = options.find(option => option.value === value);
            if (displayOption) {
                displayValue = displayOption.text;
            }
        }
        const selectOptions = options.reduce((prev, next) => {
            if (next) {
                prev.push(<li
                    className={next.value === value ? 'selected' : ''}
                    key={next.text}
                    onClick={this.handleSelectChange(next.value)}>
                    {next.text}
                </li>);
            }
            return prev;
        }, []);

        let minLength = options.reduce((prev, next) => {
            if (next && next.text && next.text.length > prev) {
                return next.text.length;
            }
            return prev;
        }, 0);
        minLength = minLength / 2 + 2;
        //set minimum length to 10
        if (minLength < 10) {
            minLength = 10;
        }

        return (
            <div
                className={`select-div ${className || ''} ${disabled ? 'disabled' : ''}`}
                style={{
                    width: `${minLength}em`
                }}
                tabIndex={-1}
                onBlur={this.handleBlur}
                onClick={this.toggleDropDown}>
                <div className="current-value">
                    <span>{displayValue}</span>
                    <i className="fa fa-sort-down" />
                </div>
                <ul className={`select-options ${showOptions ? '' : 'hidden'}`}>
                    {selectOptions}
                </ul>
            </div>
        );
    }
}
Select.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string
};

export default Select;
