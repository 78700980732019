import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import root from 'window-or-global';

import LockContext from './LockContext';
/* eslint-disable react/no-find-dom-node */
const ContextWrapper = ({children, styleClass}) => {
    const sectionRef = useRef(null);
    const [isLocked, setLock] = useState(false);

    const handleClick = ({target}) => {
        const _current = findDOMNode(sectionRef.current);
        if (!_current) return;
        setLock(!_current.contains(target));
    };

    useEffect(() => {
        root.document.addEventListener('click', handleClick);
        return () => {
            root.document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <LockContext.Provider value={{isLocked}}>
            <section ref={sectionRef} onClick={handleClick} className={styleClass}>
                {children}
            </section>
        </LockContext.Provider>
    );
};

ContextWrapper.propTypes = {
    children: PropTypes.any,
    styleClass: PropTypes.string
};

export default ContextWrapper;
