/**
 * Created by Kaku.Guo on 2018/11/23.
 */
import React, {useState, useEffect} from 'react';
import root from 'window-or-global';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getMessage, Link} from '../../index';
import AssetDetailInfo from './AssetDetailInfo';
import AssetDetailRating from './AssetDetailRating';
import {LauncherIntegrationUtils} from 'epic-launcher-integration/app/scripts';
import {loadTalon} from '../../../utils';

const AssetDetailContent = props => {
    const {data = {}, messages, isLinkSeller, locale, isLoggedIn, setTraderDialogOptions} = props;
    const {
        title,
        seller,
        effectiveDate,
        description,
        customAttributes,
        compatibleApps,
        categories = []
    } = data;
    useEffect(() => {
        if (seller?.isTrader && !root.talon) loadTalon();
    }, []);
    const hasBuyLink = customAttributes && 'BuyLink' in customAttributes;
    const [compatibility, setCompatibility] = useState('');
    LauncherIntegrationUtils.checkItemCompatibility(compatibleApps, compatibilityResult => {
        if (compatibilityResult && compatibility !== compatibilityResult) {
            setCompatibility(compatibilityResult);
        }
    });

    const viewTraderDetails = () => {
        const options = {
            isVisible: true,
            isLoggedIn,
            sellerId: seller.id,
            sellerName: seller.name
        };
        setTraderDialogOptions(options);
    };

    const compatMsgProp = compatibility.replace(/-/g, '_');
    let iconClass = '';
    if (compatibility === 'no-engine') {
        iconClass = 'fa-minus-circle';
    } else if (compatibility === 'incompatible') {
        iconClass = 'fa-exclamation-circle';
    } else if (compatibility === 'partial') {
        iconClass = 'fa-info-circle';
    } else if (compatibility === 'compatible') {
        iconClass = 'fa-check-circle';
    }

    return (
        <div className="asset-details__content">
            <div className="asset-detail-full">
                <div className="pull-left">
                    <h1 className="post-title">{title}</h1>
                </div>
                <div className={`pull-right compatibility ${!compatibility ? 'hidden' : ''}`}>
                    <i
                        className={`fa fa-2x ${iconClass} ${compatibility}`}
                        title={getMessage(
                            messages,
                            `messages.com.epicgames.plugin.store.launcher.${compatMsgProp}`
                        )}
                    />
                </div>
                <div className="clearfix" />
                {seller && (
                    <div className="asset-desc-nvs">
                        <span className="seller-name">
                            {isLinkSeller ? (
                                <Link to={`profile/${seller.name.replace(/ /g, '+')}`}>
                                    {seller.name}
                                </Link>
                            ) : (
                                seller.name
                            )}
                            {seller.isTrader && (
                                <a
                                    className="verified-trader-icon"
                                    onClick={viewTraderDetails}
                                    title={getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.trader.info.verified_trader'
                                    )}>
                                    <span />
                                </a>
                            )}
                        </span>
                        {categories &&
                            categories.map(cate => {
                                if (cate.path !== 'assets') {
                                    return (
                                        <span
                                            key={`item-cat-${cate.name}-key`}
                                            className={`item-cat-${cate.name}`}>
                                            &nbsp;-{' '}
                                            <Link
                                                className="item-cat"
                                                to={`content-cat/${cate.path}`}>
                                                {cate.name}
                                            </Link>
                                        </span>
                                    );
                                }
                                return null;
                            })}
                        {effectiveDate && (
                            <span className="">
                                &nbsp;-{' '}
                                {moment(effectiveDate).format(
                                    locale === 'en-US' ? 'MMM D, YYYY' : 'YYYY/MM/DD'
                                )}
                            </span>
                        )}
                    </div>
                )}
                <AssetDetailRating
                    seller={seller}
                    external={hasBuyLink}
                    categories={data && data.categories}
                />
                <p className="asset-detail-text">{description}</p>
            </div>
            <AssetDetailInfo {...props} />
        </div>
    );
};
AssetDetailContent.propTypes = {
    data: PropTypes.object,
    messages: PropTypes.object,
    isLinkSeller: PropTypes.bool,
    locale: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    setTraderDialogOptions: PropTypes.func
};
export default AssetDetailContent;
