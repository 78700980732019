/**
 * Created by Kaku.Guo on 2018/12/14.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {getMessage} from '../index';
import $ from 'jquery';

const AddToCartBtn = props => {
    const {
        bindId,
        isAddingOffer,
        currentOffer,
        offersInCart = [],
        handleAddToCart,
        messages
    } = props;
    const isAddedToCart = offersInCart.includes(bindId);
    const isAddingToCart = isAddingOffer && currentOffer === bindId;
    const handleAdd = e => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (isAddedToCart || isAddingToCart) {
            return;
        }
        let ele = $(e.target)
            .closest('.featured-tile')
            .first();
        if (!ele || !ele.length) {
            ele = $(e.target)
                .closest('.asset-container')
                .find('.image-box')
                .first();
        }
        handleAddToCart({offerId: bindId, ele});
    };
    return (
        <span
            className={`btn add-btn add-cart-btn shift-left ${isAddedToCart ? 'added' : ''} ${
                isAddingToCart ? 'adding' : ''
            }`}
            onClick={handleAdd}>
            {isAddedToCart ? (
                <i className="in-cart">
                    {getMessage(
                        messages,
                        'messages.com.epicgames.plugin.store.message.cart.in_cart'
                    )}
                </i>
            ) : (
                <i className="add">
                    {getMessage(
                        messages,
                        'messages.com.epicgames.plugin.store.message.cart.add_to_cart'
                    )}
                </i>
            )}
        </span>
    );
};
AddToCartBtn.propTypes = {
    bindId: PropTypes.string,
    isAddingOffer: PropTypes.bool,
    currentOffer: PropTypes.string,
    offersInCart: PropTypes.array,
    handleAddToCart: PropTypes.func,
    messages: PropTypes.object
};
export default AddToCartBtn;
