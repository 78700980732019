/**
 * Created by Kaku.Guo on 2019/5/16.
 */
/* eslint-disable react/prop-types*/
import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react';
import cn from 'classnames';
import root from 'window-or-global';
import { FileUpload, Icon, getMessage } from '../index';
import { VALID_IMAGE_TYPES, UPLOAD_STATUS } from '../../constants';

const {
    PENDING,
    SUCCESS,
    FAILED
} = UPLOAD_STATUS;
function ImageList(props, ref) {
    const { className, value: defaultValue, maxFiles = 5, messages, isDisabled, uploadDisabled, uploadStatus = {}, validateError } = props;
    const [imageList, setImageList] = useState(defaultValue || []);
    const [errorMessage, setErrorMessage] = useState(validateError);
    const uploadRef = useRef(null);

    useEffect(() => {
        setImageList(defaultValue || []);
    }, [defaultValue]);

    useEffect(() => {
        setErrorMessage(validateError);
    }, [validateError]);

    useImperativeHandle(ref, () => ({
        getValue: () => (imageList)
    }));

    const handleUpload = (val) => {
        const original = imageList.filter(it => !(it instanceof root.Blob));
        setImageList(original.concat(val));
        setErrorMessage();
    };

    const handleRemove = (idx, file) => () => {
        if (isDisabled) {
            return;
        }
        if (file instanceof root.Blob) {
            uploadRef.current.onRemove(file);
        } else {
            setImageList((prev) => {
                prev.splice(idx, 1);
                return Array.from(prev);
            });
        }
        setErrorMessage();
    };

    const getUploadStatus = (idx) => {
        switch (uploadStatus[idx]) {
            case PENDING:
                return (<div className="loading" />);
            case SUCCESS:
                return (<Icon name="check-circle" size={20} className="success" />);
            case FAILED:
                return (<Icon name="times-circle" size={20} className="error" />);
            default:
                return null;
        }
    };

    const getImageUrl = (img) => {
        if (img instanceof root.Blob) {
            return img.preview.url;
        }
        if (img.thumbUrl) {
            return img.thumbUrl;
        }
        return img.imageUrl;
    };

    const classNames = cn('image-list', className, (isDisabled || uploadDisabled) && 'disabled', errorMessage && 'error');
    const uploadTooltip = uploadDisabled ? getMessage(messages, 'messages.com.epicgames.plugin.store.asset.questions.add_image.tooltip') : '';

    return (
        <div className={classNames}>
            <ul>
                {imageList.map((img, idx) => (
                    <li key={`image-${idx}`}>
                        <img
                            alt={`${idx}`}
                            onClick={handleRemove(idx, img)}
                            src={getImageUrl(img)} />
                        <div className="upload-status">
                            {getUploadStatus(idx)}
                        </div>
                    </li>
                ))}
                <li className={!uploadDisabled && imageList.length < maxFiles ? '' : 'hidden'} title={uploadTooltip}>
                    <FileUpload
                        ref={uploadRef}
                        isDisabled={isDisabled}
                        maxFiles={maxFiles}
                        accepts={VALID_IMAGE_TYPES}
                        onChange={handleUpload}>
                        {getMessage(messages, 'messages.com.epicgames.plugin.store.asset.questions.add_image')}
                    </FileUpload>
                </li>
            </ul>
            <div className="image-list--error">{errorMessage}</div>
        </div>
    );
}

export default forwardRef(ImageList);