/**
 * Created by Kaku.Guo on 2018/11/23.
 */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cn from 'classnames';
import Tooltip from 'react-tooltip';
import {LauncherIntegrationActions} from '../../../actions';
import {getMessage} from '../../index';
import {formatCompatibleApps} from '../../../utils';
import AssetDownloadProgress from './AssetDownloadProgress';
import {distributionMethodMap} from '../../../constants/Constants';

const mapDispatchToProps = dispatch => {
    return {
        initLauncherDownload: asset => {
            dispatch(LauncherIntegrationActions.initLauncherDownload(asset));
        },
        executeDownloadCommand: command => {
            dispatch(LauncherIntegrationActions.executeDownloadCommand(command));
        },
        executeCommand: command => {
            dispatch(LauncherIntegrationActions.executeCommand(command));
        },
        resetLauncherDownload: () => {
            dispatch(LauncherIntegrationActions.resetLauncherDownload());
        }
    };
};

const mapStateToProps = state => {
    const {isLauncher} = state.get('config').toJS();
    const {isPurchasingOffer} = state.get('shoppingCart').toJS();
    const {commandLists, downloadProgress} = state.get('launcherIntegration').toJS();
    return {
        commandLists,
        downloadProgress,
        isPurchasingOffer,
        isLauncher
    };
};

const isIcoMoonIcon = iconKey => {
    if (iconKey === 'hololens2') return true;
};

@connect(mapStateToProps, mapDispatchToProps)
class AssetDetailInfo extends PureComponent {
    render() {
        const {data = {}, downloadProgress, messages} = this.props;
        const {platforms = [], compatibleApps = [], distributionMethod, customAttributes} = data;
        const compatibleAppsFormat = formatCompatibleApps(compatibleApps);
        const showDownloadProgress = downloadProgress && downloadProgress.bisactive;
        const hasBuyLink = customAttributes && 'BuyLink' in customAttributes;
        const hasPromotionalContent = customAttributes?.promotionalContent?.value === 'true';
        return (
            <div className="asset-acquire-callout">
                <div className={cn('asset-acquire__container', showDownloadProgress && 'hidden')}>
                    <div className="asset-acquire-right">
                        <ul>
                            {platforms.length ? (
                                <li className="platforms-container row">
                                    <div className="platforms-text col-12 label">
                                        {getMessage(
                                            messages,
                                            'messages.com.epicgames.plugin.store.asset.platforms'
                                        )}
                                    </div>
                                    <div className="col-12">
                                        {platforms.map(plat => (
                                            <i
                                                className={`${
                                                    isIcoMoonIcon(plat.key)
                                                        ? `icon-${plat.key}`
                                                        : `fa fa-${plat.key}`
                                                }`}
                                                key={`platform-key-${plat.value}`}
                                                title={plat.value}
                                            />
                                        ))}
                                    </div>
                                </li>
                            ) : (
                                ''
                            )}
                            {!!compatibleAppsFormat.length && (
                                <li className="row">
                                    <div className="platforms-text col-12 label">
                                        {getMessage(
                                            messages,
                                            'messages.com.epicgames.plugin.store.asset.versions'
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <span className="ue-version">
                                            {compatibleAppsFormat.join(', ')}
                                        </span>
                                    </div>
                                </li>
                            )}
                            {distributionMethod && !hasBuyLink && (
                                <li className="row">
                                    <div className="platforms-text col-12 label">
                                        {getMessage(
                                            messages,
                                            'messages.com.epicgames.plugin.store.asset.distributionMethod'
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <span
                                            className="distribution-method-text"
                                            data-tip
                                            data-for="distributionMethodTooltip">
                                            {getMessage(
                                                messages,
                                                distributionMethodMap[distributionMethod].text
                                            )}
                                        </span>
                                    </div>
                                    <Tooltip
                                        place="top"
                                        className="distribution-method-tooltip"
                                        id="distributionMethodTooltip">
                                        {getMessage(
                                            messages,
                                            distributionMethodMap[distributionMethod].tooltip
                                        )}
                                    </Tooltip>
                                </li>
                            )}
                            {hasPromotionalContent && (
                                <li className="row">
                                    <div className="platforms-text col-12 label">
                                        {getMessage(
                                            messages,
                                            'messages.com.epicgames.plugin.store.trader.promotional_content'
                                        )}
                                    </div>
                                    <div className="col-12">
                                        <span
                                            className="promotional-content-text"
                                            data-tip
                                            data-for="promotionalContentTooltip">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.promotional_content_text'
                                            )}
                                        </span>
                                    </div>
                                    <Tooltip
                                        place="top"
                                        className="promotional-content-tooltip"
                                        id="promotionalContentTooltip">
                                        {getMessage(
                                            messages,
                                            'messages.com.epicgames.plugin.store.trader.promotional_content_tooltip'
                                        )}
                                    </Tooltip>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>

                {showDownloadProgress && <AssetDownloadProgress {...this.props} />}
            </div>
        );
    }
}
AssetDetailInfo.propTypes = {
    data: PropTypes.object,
    downloadProgress: PropTypes.object,
    isPurchasingOffer: PropTypes.bool,
    messages: PropTypes.object,
    isLearnAsset: PropTypes.bool
};
export default AssetDetailInfo;
