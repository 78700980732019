import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {generateRoutePath} from '@epic-core/common';
import {debounce, isSameObjectsWithNormalFields, highlightWords} from '../../utils';
import root from 'window-or-global';

class AutoSuggest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            isListVisible: false,
            currentIndex: -1,
            listData: [],
            handleSuggest: debounce(this.handleSuggest, 600)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.isLoadingSuggest && this.props.isLoadingSuggest) {
            const hasData = nextProps.listData && nextProps.listData.length;
            this.setState({
                value: nextProps.value || '',
                listData: nextProps.listData || [],
                isListVisible: hasData,
                currentIndex: hasData && nextProps.selectFirstByDefault ? 0 : -1
            });
        } else if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const isPropsChanged = !isSameObjectsWithNormalFields(this.props, nextProps, [
            'onSearch',
            'onSuggest',
            'onChange'
        ]);
        const isStateChanged = !isSameObjectsWithNormalFields(this.state, nextState, [
            'handleSuggest'
        ]);
        return isPropsChanged || isStateChanged;
    }

    componentDidUpdate() {
        const {currentIndex} = this.state;
        if (currentIndex >= 0) {
            const {classNames} = this.props;
            const selectedItem = root.document.querySelector(
                `.auto-complete-panel${
                    classNames ? `.${classNames}` : ''
                } .autocomplete li.selected`
            );
            if (selectedItem) {
                const parentNode = selectedItem.parentNode;
                let shouldScrollDown = selectedItem.offsetTop - parentNode.clientHeight;
                shouldScrollDown += selectedItem.clientHeight;
                const shouldScrollUp = selectedItem.offsetTop;
                if (shouldScrollDown > parentNode.scrollTop) {
                    parentNode.scrollTo({top: shouldScrollDown});
                } else if (shouldScrollUp < parentNode.scrollTop) {
                    parentNode.scrollTo({top: shouldScrollUp});
                }
            }
        }
    }

    handleKeyPress = e => {
        if (e) {
            const {currentIndex, isListVisible, value, listData, handleSuggest} = this.state;
            const {onSearch} = this.props;
            const mobile =
                root._epicGamesNav &&
                root._epicGamesNav.browser &&
                (root._epicGamesNav.browser.mobile || root._epicGamesNav.browser.tablet);

            switch (e.charCode) {
                case 13: {
                    //enter
                    e.preventDefault();
                    e.stopPropagation();
                    if (mobile) {
                        handleSuggest(value);
                    } else if (isListVisible && currentIndex >= 0) {
                        const val = listData[currentIndex];
                        this.handleSelect(val);
                    } else if (onSearch) {
                        //search
                        this.setState({
                            isListVisible: false
                        });
                        onSearch({query: value});
                    }
                    break;
                }
                default:
                    break;
            }
        }
    };

    handleKeyDown = e => {
        if (e) {
            const {currentIndex, listData} = this.state;
            const dataLength = listData.length;
            let nextIndex = -1;
            switch (e.keyCode) {
                case 38:
                    //up
                    e.preventDefault();
                    e.stopPropagation();
                    if (currentIndex <= 0) {
                        nextIndex = dataLength - 1;
                    } else {
                        nextIndex = currentIndex - 1;
                    }
                    break;
                case 40:
                case 9:
                    //down
                    e.preventDefault();
                    e.stopPropagation();
                    nextIndex = (currentIndex + 1) % dataLength;
                    break;
                default:
                    break;
            }
            if (nextIndex >= 0) {
                this.setState({
                    currentIndex: nextIndex
                });
            }
        }
    };

    handleInputChange = e => {
        if (e) {
            const mobile =
                root._epicGamesNav &&
                root._epicGamesNav.browser &&
                (root._epicGamesNav.browser.mobile || root._epicGamesNav.browser.tablet);

            const {handleSuggest} = this.state;
            const value = e.target.value;
            this.setState({
                value,
                isListVisible: false,
                currentIndex: -1
            });

            if (!mobile) {
                handleSuggest(value);
            }
        }
    };

    handleInputFocus = () => {};

    handleSelect = value => {
        const {history} = this.props;
        this.setState({
            value: value.name,
            currentIndex: -1,
            isListVisible: false
        });
        if (history && value.id) {
            const pathName = generateRoutePath(`product/${value.urlSlug}`);
            history.push(pathName);
        }
    };

    handleSuggest = value => {
        const {onSuggest} = this.props;
        if (onSuggest) {
            onSuggest(value);
        }
    };

    handleBlur = e => {
        if (e && !e.currentTarget.contains(e.relatedTarget)) {
            this.setState({
                isListVisible: false,
                currentIndex: -1,
                listData: []
            });
        }
    };

    handleClear = () => {
        this.setState({
            value: '',
            isListVisible: false,
            currentIndex: -1,
            listData: []
        });
    };

    handleSearch = () => {
        const {value} = this.state;
        const {onSearch} = this.props;
        if (value && onSearch) {
            onSearch({query: value});
        }
    };

    render() {
        const {
            classNames,
            textField,
            valueField,
            isLoadingSuggest,
            isDisabled,
            placeholder,
            excludeData = []
        } = this.props;
        const {value, currentIndex, listData, isListVisible} = this.state;
        const displayedData = listData.filter(
            li => !excludeData.find(ex => ex[valueField] === li[valueField])
        );
        return (
            <div
                className={`auto-complete-panel ${classNames}`}
                onBlur={this.handleBlur}
                tabIndex={-1}>
                <div className={`input-search ${isDisabled ? 'disabled' : ''}`}>
                    <input
                        className="text"
                        type="text"
                        value={value}
                        onChange={this.handleInputChange}
                        onFocus={this.handleInputFocus}
                        onKeyDown={this.handleKeyDown}
                        onKeyPress={this.handleKeyPress}
                        disabled={isDisabled}
                        placeholder={placeholder}
                        readOnly={isLoadingSuggest}
                    />
                    <i className={`search-loading ${isLoadingSuggest ? '' : 'hidden'}`} />
                    <i
                        className={`search-cancel ${isLoadingSuggest || !value ? 'hidden' : ''}`}
                        onClick={this.handleClear}
                    />
                    <span onClick={this.handleSearch}>
                        <i className="search-icon fa fa-search" />
                    </span>
                </div>
                <ul className={`autocomplete ${!isListVisible && 'hidden'}`} tabIndex={-1}>
                    {displayedData.map((item, idx) => (
                        <li
                            key={`auto-list-item-${idx}`}
                            className={currentIndex === idx ? 'selected' : ''}
                            onClick={e => this.handleSelect(item, e)}
                            dangerouslySetInnerHTML={{
                                __html: highlightWords(item[textField], value)
                            }}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}
AutoSuggest.defaultProps = {
    textField: 'name',
    valueField: 'id',
    listData: []
};
AutoSuggest.propTypes = {
    classNames: PropTypes.string,
    textField: PropTypes.string,
    valueField: PropTypes.string,
    value: PropTypes.string,
    listData: PropTypes.array,
    history: PropTypes.object,
    isLoadingSuggest: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onSuggest: PropTypes.func,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
    excludeData: PropTypes.array,
    selectFirstByDefault: PropTypes.bool
};
export default AutoSuggest;
