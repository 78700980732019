/**
 * Created by Kaku.Guo on 2019/3/28.
 */
import { REGEXPS, SORT_BY_COL, SORT_DIR_COL, PRICE_RANGE_REGX } from '../../constants';

export const getParamsValidations = () => ({
    start: {
        rule: (value) => (REGEXPS.INT.test(value))
    },
    count: {
        rule: (value) => (REGEXPS.INT.test(value))
    },
    sortBy: {
        rule: (value) => (SORT_BY_COL.includes(value))
    },
    discountPercentageRange: {
        rule: (value) => (REGEXPS.INT.test(value))
    },
    sortDir: {
        rule: (value) => (SORT_DIR_COL.includes(value))
    },
    tag: {
        generator: (value) => {
            if (value instanceof Array) {
                return value.reduce((prev, next) => {
                    if (REGEXPS.INT.test(next)) {
                        prev.push(next);
                    }
                    return prev;
                }, []);
            }
            return [];
        }
    },
    compatibleWith: {},
    platform: {},
    priceRange: {
        rule: (value) => (PRICE_RANGE_REGX.test(value))
    },
    customPriceRange: {},
    isAllEngineVisible: {},
    isCustomPrice: {},
    isPriceInvalid: {},
    tagKeyWord: {},
    keywords: {},
    category: {},
    effectiveDate: {},
    saleType: {},
    sellerName: {},
    featured: {}
});

export default (params, fields = []) => {
    const validations = getParamsValidations();
    const ruleKeys = Object.keys(validations);
    let obj = params;
    if (fields.length) {
        obj = fields.reduce((prv, nxt) => {
            prv[nxt] = params[nxt];
            return prv;
        }, {});
    }
    return Object.keys(obj).reduce((prev, next) => {
        if (obj.hasOwnProperty(next) && ruleKeys.includes(next) && validations[next]) {
            if (validations[next].rule) {
                if (validations[next].rule(obj[next])) {
                    prev[next] = obj[next];
                }
            } else if (validations[next].generator) {
                prev[next] = validations[next].generator(obj[next]);
            } else {
                prev[next] = obj[next];
            }
        }
        return prev;
    }, {});
};