import qs from 'query-string';
import {REVIEW_TYPES} from '../../constants';
import {hasValue} from '../../utils';
import root from 'window-or-global';

export const scrollToReviewTab = () => {
    const reviewTabEle = root.document.getElementById('review-content');
    if (reviewTabEle) {
        reviewTabEle.scrollIntoView({behavior: 'smooth'});
    }
};

export const getReviewFromQuery = query => {
    const params = qs.parse(query);
    if (hasValue(params)) {
        if (params.review) {
            return {
                topicId: params.review,
                type: REVIEW_TYPES.REVIEW
            };
        }
        if (params.question) {
            return {
                topicId: params.question,
                type: REVIEW_TYPES.QUESTION
            };
        }
        if (params.reply) {
            return {
                topicId: params.reply,
                type: REVIEW_TYPES.REPLY
            };
        }
    }
    return null;
};
