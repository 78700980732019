import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getMetaTags} from '../../utils/metaTagUtils';
import {getMessage} from '../../components';
import {ErrorActions} from '../../actions';

const mapStateToProps = state => {
    const {status, errorCode, args} = state.get('error').toJS();
    return {
        localizationState: state.get('localization'),
        status,
        errorCode,
        args
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetError: () => {
            dispatch(ErrorActions.resetError());
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class ErrorView extends React.Component {
    componentDidMount() {
        const {history, resetError} = this.props;
        //reset the error when route change
        this.unlisten = history.listen(() => {
            resetError();
        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        const {localizationState, errorCode, args} = this.props;
        const tags = getMetaTags(localizationState);
        const messages = localizationState.get('messageProperties');
        let errorMessage = getMessage(messages, errorCode, args);
        if (!errorMessage) {
            errorMessage = getMessage(messages, 'errors.epic.common.unknown');
        }
        return (
            <div>
                <Helmet
                    meta={tags}
                    title={getMessage(messages, 'epic.enterprise.social.meta.site_name')}
                />
                <div className="error-page">
                    <div className="error-box">{errorMessage}</div>
                </div>
            </div>
        );
    }
}
ErrorView.propTypes = {
    localizationState: PropTypes.object,
    errorCode: PropTypes.string,
    args: PropTypes.array,
    resetError: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(ErrorView);
