import React from 'react';
import {ConfigActions} from '../../actions';
import FabLanding from './FabLanding.connected';

export default class FabLandingView extends React.Component {
    static async getInitialProps(match, {dataPreload, parsedUrl, store}, token) {
        store.dispatch(
            ConfigActions.setConfig({
                contentHeader: {
                    code: 'messages.com.epicgames.plugin.store.header.fab_landing'
                }
            })
        );
    }

    render() {
        return <FabLanding {...this.props} />;
    }
}
