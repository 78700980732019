/**
 * Created by Kaku.Guo on 2018/10/29.
 */
import {call, put, takeLatest, select} from 'redux-saga/effects';
import {AssetActions, ReviewActions} from '../actions';
import {AssetApi, ReviewApi} from '../apis';

const getLoggedInState = state => state.get('user').get('isLoggedIn');

function* listAssets(action) {
    try {
        const {token, ...params} = action.payload;
        const data = yield call(AssetApi.listAssets, params, token);
        yield put(AssetActions.listAssetsSuccess(data));
    } catch (e) {
        yield put(AssetActions.listAssetsFailed(e));
    }
}

function* suggestAssets(action) {
    try {
        const params = action.payload;
        const data = yield call(AssetApi.suggestAssets, params);
        yield put(AssetActions.suggestAssetsSuccess(data));
    } catch (e) {
        yield put(AssetActions.suggestAssetsFailed(e));
    }
}

function* vaultAssets(action) {
    try {
        const params = action.payload;
        const data = yield call(AssetApi.vaultAssets, params);
        yield put(AssetActions.vaultAssetsSuccess(data));
    } catch (e) {
        yield put(AssetActions.vaultAssetsFailed(e));
    }
}

function* getHomeData(action) {
    try {
        const token = action?.payload?.token;
        const data = yield call(AssetApi.getHomeData, token);
        yield put(AssetActions.getHomeDataSuccess(data));
    } catch (e) {
        console.error('getHomeData() failed', e.message);
        yield put(AssetActions.getHomeDataFailed(e));
    }
}

function* getAsset(action) {
    try {
        const {slug, isItem, token} = action.payload;
        let associatedOfferId;
        if (isItem) {
            const itemData = yield call(AssetApi.getItem, slug, token);
            associatedOfferId = itemData.data.id;
        }
        const offerSlug = associatedOfferId ? associatedOfferId : slug;
        const data = yield call(AssetApi.getAsset, offerSlug, token);

        yield put(AssetActions.getAssetSuccess(data));
        const isLoggedIn = yield select(getLoggedInState);
        const {
            data: {id, customAttributes}
        } = data;
        const hasBuyLink = customAttributes && 'BuyLink' in customAttributes;
        if (id && !hasBuyLink) {
            if (isLoggedIn) {
                // getReviewDetailByAccount
                const reviewDetail = yield call(ReviewActions.getReviewDetailByAccount, {
                    offerId: id
                });
                const reviewPayload = reviewDetail.payload;
                yield put(ReviewActions.getReviewDetailByAccountSuccess(reviewPayload));
            } else {
                yield put(ReviewActions.getReviewDetailByAccountSuccess({}));
            }
        }
        if (id) {
            try {
                try {
                    const data = yield call(ReviewApi.getRating, id);
                    yield put(ReviewActions.getRatingSuccess(data));
                } catch (e) {
                    yield put(ReviewActions.getRatingFailed(e));
                }
            } catch (e) {
                yield put(ReviewActions.getRatingFailed(e));
            }
        }
    } catch (e) {
        yield put(AssetActions.getAssetFailed(e));
    }
}

function* sellerAssets(action) {
    try {
        const data = yield call(AssetApi.sellerAssets, action.payload);
        yield put(AssetActions.sellerAssetsSuccess(data));
    } catch (e) {
        yield put(AssetActions.sellerAssetsFailed(e));
    }
}

function* getHelmet(action) {
    try {
        const {slug, isItem, token} = action.payload;
        let data;
        if (isItem) {
            data = yield call(AssetApi.getItem, slug, token);
        } else {
            data = yield call(AssetApi.getAsset, slug, token);
        }
        yield put(AssetActions.getHelmetSuccess(data));
    } catch (e) {
        yield put(AssetActions.getHelmetFailed(e));
    }
}

function* AssetSaga() {
    yield takeLatest(AssetActions.TYPES.LIST_ASSETS_PENDING, listAssets);
    yield takeLatest(AssetActions.TYPES.SUGGEST_ASSETS_PENDING, suggestAssets);
    yield takeLatest(AssetActions.TYPES.VAULT_ASSETS_PENDING, vaultAssets);
    yield takeLatest(AssetActions.TYPES.GET_HOME_DATA_PENDING, getHomeData);
    yield takeLatest(AssetActions.TYPES.GET_ASSET_PENDING, getAsset);
    yield takeLatest(AssetActions.TYPES.GET_HELMET_PENDING, getHelmet);
    yield takeLatest(AssetActions.TYPES.SELLER_ASSETS_PENDING, sellerAssets);
}

export default AssetSaga;
