import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {Icon, ReadMoreText, getMessage, Message} from '../index';
import ReviewSubTitle from './ReviewSubTitle';
import {REVIEW_TYPES} from '../../constants';
import {getReportHref} from '../../utils';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    const globalBase = state.get('config').get('globalBase');
    return {isLoggedIn, isPostingAllowed, messages, accountInfo, globalBase};
};

const ReplyItem = props => {
    const prefixCls = 'review-item';
    const {messages, className, data = {}, accountInfo, type, topicIdentityId, isPublisher} = props;
    const {id, isCurrentUser, title, content, isBest, displayed, identityId} = data;

    const classNames = cn(prefixCls, className);

    const [hideReview, showReviewAnyway] = useState(!displayed);

    const renderTools = () => {
        return (
            <Fragment>
                {!isCurrentUser && (
                    <li title="Report">
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                            href={getReportHref(
                                'reply',
                                id,
                                type === REVIEW_TYPES.REVIEW ? 'uem_review' : 'uem_question'
                            )}
                            target="_blank">
                            <Icon name="flag" />
                        </a>
                    </li>
                )}
            </Fragment>
        );
    };

    const renderBestAnswerIcon = () => {
        if (type !== REVIEW_TYPES.QUESTION) {
            return null;
        }
        //if not the best answer, and the current user is not the question owner, return null
        if (!isBest && accountInfo.id !== topicIdentityId) {
            return null;
        }
        const bestAnswerClassNames = cn(
            `${prefixCls}__best`,
            isBest && `${prefixCls}__best--isBest`,
            'disabled'
        );
        return (
            <div className={bestAnswerClassNames}>
                <Icon
                    name="check-circle"
                    title={
                        !isBest
                            ? getMessage(
                                  messages,
                                  'messages.com.epicgames.plugin.store.asset.questions.mark.tooltip'
                              )
                            : ''
                    }
                />
            </div>
        );
    };

    if (hideReview) {
        const isSelf = accountInfo.id === identityId;
        const message = getMessage(
            messages,
            'messages.com.epicgames.plugin.store.asset.reivew_support_for_help'
        );
        return (
            <Fragment>
                <div className={classNames}>
                    <div className={`${prefixCls}__title`}>
                        <Message code="messages.com.epicgames.plugin.store.asset.review_on_hide" />
                    </div>
                    {isSelf && (
                        <div
                            className={`${prefixCls}__content self_warning`}
                            dangerouslySetInnerHTML={{__html: message}}
                        />
                    )}
                    <ul className={`${prefixCls}__tools`}>
                        <li className="button-show_anyway" onClick={() => showReviewAnyway(false)}>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.asset.review_show_anyway'
                            )}
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }

    return (
        <div className={classNames}>
            <div className={`${prefixCls}__header`}>
                {renderBestAnswerIcon()}
                <div className={`${prefixCls}__title`}>
                    {title && <h4>{title}</h4>}
                    <ReviewSubTitle isPublisher={isPublisher} data={data} />
                </div>
            </div>
            <ReadMoreText className={`${prefixCls}__content rich-text-content`} text={content} />
            <ul className={`${prefixCls}__tools`}>{renderTools()}</ul>
        </div>
    );
};

ReplyItem.propTypes = {
    topicIdentityId: PropTypes.string,
    accountInfo: PropTypes.object,
    messages: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    mergeDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    editReply: PropTypes.func,
    deleteReply: PropTypes.func,
    voteReview: PropTypes.func,
    setBestReply: PropTypes.func,
    isPublisher: PropTypes.bool,
    type: PropTypes.oneOf(['reviews', 'questions']).isRequired,
    globalBase: PropTypes.string
};

export default connect(mapStateToProps)(ReplyItem);
