/**
 * Created by Kaku.Guo on 2019/6/3.
 */
import root from 'window-or-global';
import { call, put, takeLatest } from 'redux-saga/effects';
import { UploadActions, ReviewDialogActions } from '../actions';
import { UploadApi } from '../apis';
import { UPLOAD_STATUS } from '../constants';
import { getError } from '../utils';

const {
    PENDING,
    SUCCESS,
    FAILED
} = UPLOAD_STATUS;
function* uploadQueue(action) {
    const { type, files, onSuccess } = action.payload;
    yield put(ReviewDialogActions.mergeOptions({
        isLoading: true,
        error: {}
    }));
    try {
        const images = [];
        const status = {};
        for (let i = 0; i < files.length; i++) {
            try {
                if (files[i] instanceof root.Blob) {
                    status[i] = PENDING;
                    yield put(UploadActions.updateQueueStatus(status));
                    const formData = new root.FormData();
                    formData.append('file', files[i]);
                    formData.append('type', type);
                    const data = yield call(UploadApi.upload, formData);
                    images.push(data);
                } else {
                    images.push(files[i]);
                }
                status[i] = SUCCESS;
                yield put(UploadActions.updateQueueStatus(status));
            } catch (er) {
                status[i] = FAILED;
                yield put(UploadActions.updateQueueStatus(status));
            }
        }
        if (onSuccess) {
            onSuccess(images);
        }
    } catch (e) {
        yield put(ReviewDialogActions.mergeOptions({
            isLoading: false,
            error: getError(e)
        }));
    }
}

function* UploadSaga() {
    const {
        UPLOAD_QUEUE_PENDING
    } = UploadActions.TYPES;
    yield takeLatest(UPLOAD_QUEUE_PENDING, uploadQueue);
}

export default UploadSaga;