/**
 * Created by Kaku.Guo on 2018/11/23.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {generateDisplayImageUrl} from '../../../utils';
import {ImageGallery} from '../../index';
import {getOptImage} from 'epic-ue-shared';

class AssetDetailGallery extends Component {
    constructor(props) {
        super(props);
        this.imageGallery = null;
    }

    render() {
        const {data = {}} = this.props;
        const {keyImages = []} = data;
        const galleryData = keyImages.reduce((prev, next) => {
            if (next.type === 'Screenshot') {
                const original = getOptImage({url: next.url, width: 1920});
                const thumbnail = getOptImage({
                    url: generateDisplayImageUrl(original, keyImages),
                    width: 200
                });
                const originalTitle = next.title;
                prev.push({original, thumbnail, originalTitle});
            }
            return prev;
        }, []);
        return <ImageGallery className="asset-details__gallery" data={galleryData} />;
    }
}

AssetDetailGallery.propTypes = {
    data: PropTypes.object
};
export default AssetDetailGallery;
