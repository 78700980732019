/**
 * Created by Kaku.Guo on 2019/5/22.
 */
import {generateAsyncActions} from '../utils';

const TYPES = {
    RESET_LIST_PARAMS: 'RESET_LIST_PARAMS',
    ...generateAsyncActions(
        'ADD_REVIEW',
        'SET_BEST_REPLY',
        'GET_REVIEWS',
        'GET_REVIEW_DETAIL_BY_ACCOUNT',
        'GET_REVIEW_DETAIL',
        'EDIT_REVIEW',
        'DELETE_REVIEW',
        'REPLY_REVIEW',
        'GET_REVIEW_REPLIES',
        'SHOW_MORE_REPLIES',
        'VOTE_REVIEW',
        'EDIT_REPLY',
        'DELETE_REPLY',
        'EDIT_REPLY',
        'GET_RATING'
    )
};
export default {
    TYPES,
    addReview: payload => ({
        type: TYPES.ADD_REVIEW_PENDING,
        payload
    }),
    setBestReply: payload => ({
        type: TYPES.SET_BEST_REPLY_PENDING,
        payload
    }),
    getReviews: payload => ({
        type: TYPES.GET_REVIEWS_PENDING,
        payload
    }),
    getReviewsSuccess: payload => ({
        type: TYPES.GET_REVIEWS_SUCCESS,
        payload
    }),
    getReviewsFailed: payload => ({
        type: TYPES.GET_REVIEWS_FAILED,
        payload
    }),
    getReviewDetailByAccount: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_BY_ACCOUNT_PENDING,
        payload
    }),
    getReviewDetailByAccountSuccess: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_BY_ACCOUNT_SUCCESS,
        payload
    }),
    getReviewDetailByAccountFailed: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_BY_ACCOUNT_FAILED,
        payload
    }),
    getReviewDetail: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_PENDING,
        payload
    }),
    getReviewDetailSuccess: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_SUCCESS,
        payload
    }),
    getReviewDetailFailed: payload => ({
        type: TYPES.GET_REVIEW_DETAIL_FAILED,
        payload
    }),
    editReview: payload => ({
        type: TYPES.EDIT_REVIEW_PENDING,
        payload
    }),
    editReviewSuccess: payload => ({
        type: TYPES.EDIT_REVIEW_SUCCESS,
        payload
    }),
    editReviewFailed: payload => ({
        type: TYPES.EDIT_REVIEW_FAILED,
        payload
    }),
    deleteReview: payload => ({
        type: TYPES.DELETE_REVIEW_PENDING,
        payload
    }),
    deleteReviewSuccess: payload => ({
        type: TYPES.DELETE_REVIEW_SUCCESS,
        payload
    }),
    deleteReviewFailed: payload => ({
        type: TYPES.DELETE_REVIEW_FAILED,
        payload
    }),
    addReply: payload => ({
        type: TYPES.REPLY_REVIEW_PENDING,
        payload
    }),
    addReplySuccess: payload => ({
        type: TYPES.REPLY_REVIEW_SUCCESS,
        payload
    }),
    addReplyFailed: payload => ({
        type: TYPES.REPLY_REVIEW_FAILED,
        payload
    }),
    getReviewReplies: payload => ({
        type: TYPES.GET_REVIEW_REPLIES_PENDING,
        payload
    }),
    showMoreReplies: payload => ({
        type: TYPES.SHOW_MORE_REPLIES_PENDING,
        payload
    }),
    showMoreRepliesSuccess: payload => ({
        type: TYPES.SHOW_MORE_REPLIES_SUCCESS,
        payload
    }),
    showMoreRepliesFailed: payload => ({
        type: TYPES.SHOW_MORE_REPLIES_FAILED,
        payload
    }),
    getReviewRepliesSuccess: payload => ({
        type: TYPES.GET_REVIEW_REPLIES_SUCCESS,
        payload
    }),
    getReviewRepliesFailed: payload => ({
        type: TYPES.GET_REVIEW_REPLIES_FAILED,
        payload
    }),
    voteReview: payload => ({
        type: TYPES.VOTE_REVIEW_PENDING,
        payload
    }),
    voteReviewSuccess: payload => ({
        type: TYPES.VOTE_REVIEW_SUCCESS,
        payload
    }),
    voteReviewFailed: payload => ({
        type: TYPES.VOTE_REVIEW_FAILED,
        payload
    }),
    editReply: payload => ({
        type: TYPES.EDIT_REPLY_PENDING,
        payload
    }),
    editReplySuccess: payload => ({
        type: TYPES.EDIT_REPLY_SUCCESS,
        payload
    }),
    editReplyFailed: payload => ({
        type: TYPES.EDIT_REPLY_FAILED,
        payload
    }),
    deleteReply: payload => ({
        type: TYPES.DELETE_REPLY_PENDING,
        payload
    }),
    deleteReplySuccess: payload => ({
        type: TYPES.DELETE_REPLY_SUCCESS,
        payload
    }),
    deleteReplyFailed: payload => ({
        type: TYPES.DELETE_REPLY_FAILED,
        payload
    }),
    getRating: payload => ({
        type: TYPES.GET_RATING_PENDING,
        payload
    }),
    getRatingSuccess: payload => ({
        type: TYPES.GET_RATING_SUCCESS,
        payload
    }),
    getRatingFailed: payload => ({
        type: TYPES.GET_RATING_FAILED,
        payload
    }),
    resetListParams: () => ({
        type: TYPES.RESET_LIST_PARAMS
    })
};
