import axios from 'axios';
import {getLocale} from '@epic-core/common';
import Url from '../constants/Url';

class CmsApi {
    getCmsPageData(urlPattern) {
        return axios
            .get(Url().CMS_PAGE_DATA.GET(getLocale(), urlPattern))
            .then(response => response.data)
            .catch(ex => {
                throw ex;
            });
    }
}
export default new CmsApi();
