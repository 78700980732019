import React from 'react';
import ReactDOM from 'react-dom';
import root from 'window-or-global';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {fromJS} from 'immutable';
import {setLocale} from '@epic-core/common';
import {ScrollToTopOnUpdate} from '../client/components';
import {alt} from 'epic-react-common';
import {configureStore} from './configureStore';
import pako from 'pako';
import App from './App';
import {configureTracking} from './utils/TrackingUtils';
import {bootstrapSharedValues, sharedStore, configureServices} from './app.initializers';
import {LocalizationContext, mergeLocalizationDataAltStore} from '@epic-mw/localization';
import {LocalizationContext as CoreLocalizationContext} from '@epic-core/localization';
import {SharedValueContext} from '@epic-core/hooks';
import {clientInterceptors} from '@epic-core/axios-client';

const init = () => {
    let initialState = fromJS({});
    if (root.__epic_client_state) {
        setLocale(root.__locale);
        const preload = JSON.parse(
            pako.inflate(root.__epic_client_state.split(','), {to: 'string'})
        );
        root.__epic_client_state = preload;
        initialState = fromJS(preload);
        configureTracking(initialState);
        clientInterceptors();
    }

    const renderClient = function renderClient() {
        let LocalizationStore = {};
        const localization = initialState.get('localization').toJS();
        setLocale(localization.locale);
        localization.messages = localization.messageProperties;
        LocalizationStore = {
            messages: localization.messageProperties,
            i18nMessages: localization.messageProperties,
            loaded: true,
            supportedLocales: localization.supportedLocales,
            locale: localization.locale
        };

        if (root.__epic_react_common_localization) {
            root.__epic_react_common_localization = null;
            alt.bootstrap({
                LocalizationStore
            });
        }

        const store = configureStore(initialState);
        root.reduxStore = store;

        const i18nContext = mergeLocalizationDataAltStore(LocalizationStore);
        ReactDOM.hydrate(
            <BrowserRouter>
                <SharedValueContext.Provider value={sharedStore}>
                    <LocalizationContext.Provider value={i18nContext}>
                        <CoreLocalizationContext.Provider value={i18nContext}>
                            <Provider store={store}>
                                <ScrollToTopOnUpdate>
                                    <App />
                                </ScrollToTopOnUpdate>
                            </Provider>
                        </CoreLocalizationContext.Provider>
                    </LocalizationContext.Provider>
                </SharedValueContext.Provider>
            </BrowserRouter>,
            root.document.getElementById('bodyWrapper')
        );
    };

    bootstrapSharedValues(root.__epic_client_state).then(() => {
        configureServices();
        renderClient();
    });
};

init();
