import {useEffect, useCallback, useState} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {useLocalizationLocale} from "@epic-core/localization";
import root from 'window-or-global';
import moment from 'moment';

import {eulaServiceProvider} from './eula.service';

import EulaApi from './eula.api';
import {EulaContent, EulaLoadingStore} from './type';

const eulaContentsKey = createSharedValueKey('EulaContentKey', {
    body: '',
    title: '',
    version: null,
    locale: 'en'
} as EulaContent);

const acceptedEulaKey = createSharedValueKey('AcceptedEulaKey', false);
const openEulaKey = createSharedValueKey('OpenEulaKey', false);
const hasReachedEulaKey = createSharedValueKey('HasReachedEula', false);
const fetchingEula = createSharedValueKey('FetchingEulaKey', {
    loading: false,
    loaded: false,
    error: ''
} as EulaLoadingStore);

export const acceptEulaCallbackKey = createSharedValueKey('AcceptEulaCallbackKey', function() {});

export const isEulaStarted = () => {
    const {config: { eulaStartDate }} = root.reduxStore && root.reduxStore.getState().toJS() || { config: {} };
    const diff = moment(eulaStartDate).diff(moment(), 'seconds');
    return diff > 0 ? false : true;
}

export const useEula = (accountId?: string) => {
    const [loadingState, setLoadingState] = useSharedValue(fetchingEula);
    const [accepted, setAccepted] = useSharedValue(acceptedEulaKey);
    const [eulaContent, setEulaContent] = useSharedValue(eulaContentsKey);
    const lc = useLocalizationLocale();
    const {config: { eulaContentKey: EPIC_EULA_ID, eulaStartDate }} = root.reduxStore && root.reduxStore.getState().toJS() || { config: {} };
    const diff = moment(eulaStartDate).diff(moment(), 'seconds');

    useEffect(() => {
            if (!accountId || eulaContent.version || accepted || loadingState.loading) {
                return;
            }
            async function fetchEula() {
                setLoadingState({loading: true, loaded: false, error: ''});
                try {
                    const { hasAccountAccepted } = await EulaApi.hasAccountAccepted(accountId, EPIC_EULA_ID, lc);
                    if (hasAccountAccepted?.accepted) {
                        setAccepted(hasAccountAccepted?.accepted);
                        setLoadingState({loading: false, loaded: true, error: ''});
                        return;
                    }
                    const { getLatestAndConvert } = await EulaApi.getLatest(EPIC_EULA_ID, lc);
                    const { title, body, version, locale } = getLatestAndConvert;
                    setEulaContent({ title, body, version, locale});
                    setAccepted(false);
                    setLoadingState({loading: false, loaded: true, error: ''});
                } catch (err) {
                    console.error('Failed to fetch eula content');
                    setLoadingState({loading: false, loaded: true, error: err?.message});
                }
            }
            fetchEula();
        },
        [accountId]);
    return {
        accepted,
        eulaContent,
        ...loadingState
    }
}

export const useEulaAccept = (accountId) => {
    const { eulaContent: { version, locale } } = useEula();
    const [loading, setLoading] = useState(false);
    const [loadingAcceptance, setLoadingAcceptance] = useState(false);
    const [callback] = useSharedValue(acceptEulaCallbackKey);
    const [_accepted, setAccepted] = useSharedValue(acceptedEulaKey);
    const {config: { eulaContentKey: EPIC_EULA_ID }} = root.reduxStore && root.reduxStore.getState().toJS() || { config: {} };
    const acceptEula = useCallback(
        async () => {
            if (_accepted) {
                return;
            }
            setLoading(true);
            try {
                const response = await EulaApi.acceptEula(accountId, EPIC_EULA_ID, version, locale);
                setAccepted(response?.acceptEula?.accepted);
                eulaServiceProvider.onAcceptEulaSuccess(callback);
                setLoading(false);
                return response?.acceptEula;
            } catch (err) {
                console.error('Failed to accept latest eula');
                setLoading(false);
            }
        },
        []
    );
    const checkHasAcceptedEula = useCallback(async() => {
        try {
            setLoadingAcceptance(true);
            const { hasAccountAccepted } = await EulaApi.hasAccountAccepted(accountId, EPIC_EULA_ID, locale);
            setLoadingAcceptance(false);
            return hasAccountAccepted?.accepted;
        } catch (err) {
            console.error('Failed to check has eula accepted', err?.message);
            setLoadingAcceptance(false);
        }
    }, []);
    return {
        loading,
        loadingAcceptance,
        checkHasAcceptedEula,
        acceptEula
    };
}

export const useHasReachedEula = () => {
    const [hasReached, setHasReached] = useSharedValue(hasReachedEulaKey);
    const enableAcceptEula = useCallback(() => {
        setHasReached(true);
    }, []);
    const disableAcceptEula = useCallback(() => {
        setHasReached(false);
    }, []);
    return {
        hasReached,
        enableAcceptEula,
        disableAcceptEula
    }
}

export const useEulaDialog = () => {
    const [visible, setVisible] = useSharedValue(openEulaKey);
    const [callback, setCallback] = useSharedValue(acceptEulaCallbackKey);
    const { disableAcceptEula } = useHasReachedEula();
    const openEulaDialog = useCallback((cb?: Function) => {
        const cloned = (...args) => cb.bind(null, ...args);
        const htmlBody = root.document.querySelector("body");
        if (htmlBody) {
            htmlBody.style.overflowY = "hidden";
        }
        setVisible(true);
        if (cb) {
            setCallback(cloned);
        }
    }, []);
    const closeEulaDialog = useCallback((useCb?: boolean) => {
        const htmlBody = root.document.querySelector("body");
        if (htmlBody) {
            htmlBody.style.overflowY = "scroll";
        }
        setVisible(false);
        disableAcceptEula();
    }, []);
    return {
        visible,
        openEulaDialog,
        closeEulaDialog,
        callback
    };
}
