import {sharedService, createSharedValueKey} from '@epic-core/hooks';

export const reduxFullStateKey = createSharedValueKey('ReduxFullState', {});

class ReduxServiceProvider {
    callbacks = [];

    public addListener = cb => {
        if (cb && typeof cb === 'function') {
            this.callbacks.push(cb);
        }
    };

    public updateReduxStore(state) {
        this.callbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb(state);
            }
        });
    }
}

export const reduxService = new ReduxServiceProvider();

export const ReduxService = sharedService(values => {
    return {
        updateReduxStore: reduxService.updateReduxStore,
        addListener: reduxService.addListener
    };
});
