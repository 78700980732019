/**
 * Created by Kaku.Guo on 2019/5/8.
 */
import root from 'window-or-global';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

let resizeObserver = null;
/*
*  An observer for elements resizing
*  to observe an element, add function handleResize to the target element and call resizeObserver.observe(ele)
*  and call resizeObserver.unobserve(ele) for unobserving
* */
export default () => {
    if (resizeObserver) {
        return resizeObserver;
    }
    const ResizeObserver = root.ResizeObserver || ResizeObserverPolyfill;
    if (ResizeObserver) {
        resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.target.handleResize) {
                    entry.target.handleResize(entry);
                }
            });
        });
    }
    return resizeObserver;
};