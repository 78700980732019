import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Message, TagAutoSuggest, getMessage} from '../index';
import {getPriceOptions} from '../../utils';
import {DISCOUNT_OPTIONS} from '../../constants';
import root from 'window-or-global';

class FilterPanelView extends Component {
    getSelectedClass = (data, val, neverSelected) => {
        if (neverSelected) {
            return '';
        }
        let isSelected = false;
        if (data instanceof Array) {
            isSelected = data.includes(val);
        } else {
            isSelected = data === val;
        }
        return isSelected ? 'selected' : '';
    };

    state = {
        listParams: null
    };

    UNSAFE_componentWillReceiveProps({className, listParams}) {
        const headerEle = root.document.querySelector('#__external__header');
        if (headerEle && !!className) {
            const headerHeight = headerEle.clientHeight;
            const scrollTop = root.document.scrollingElement.scrollTop;
            let top = 0;
            if (scrollTop < headerHeight) {
                top = headerHeight - scrollTop;
            }
            this.setState({top});
        } else {
            this.setState({top: -1});
        }
        if (listParams) {
            this.setState({listParams});
        }
    }

    getEngineVersionsElements = () => {
        const {
            pageData: {isLauncher, launcherVersions = [], engineVersions = []},
            filterOptions: {isAllEngineVisible, compatibleWith}
        } = this.props;
        if (isLauncher) {
            const otherVersions = engineVersions.filter(it => !launcherVersions.includes(it));
            return (
                <Fragment>
                    <ul className="filter-options">
                        {launcherVersions.map((eng, idx) => (
                            <li
                                key={`engine-version-launcher-${idx}`}
                                className={this.getSelectedClass(compatibleWith, eng)}
                                onClick={this.handleFieldChange('compatibleWith', eng)}>
                                {eng
                                    .replace('UE_', '')
                                    .replace('EA', ' EA')
                                    .replace('ea', ' EA')}
                            </li>
                        ))}
                        {otherVersions.map((eng, idx) => (
                            <li
                                key={`engine-version-${idx}`}
                                className={`${
                                    isAllEngineVisible ? '' : 'hidden'
                                } ${this.getSelectedClass(compatibleWith, eng)}`}
                                onClick={this.handleFieldChange('compatibleWith', eng)}>
                                {eng
                                    .replace('UE_', '')
                                    .replace('EA', ' EA')
                                    .replace('ea', ' EA')}
                            </li>
                        ))}
                    </ul>
                    <a
                        className={`show-more ${isAllEngineVisible ? 'hidden' : ''}`}
                        onClick={this.showMoreEngine}>
                        <Message code="messages.com.epicgames.plugin.store.filter.show_uninstalled_engine" />
                    </a>
                </Fragment>
            );
        }
        return (
            <Fragment>
                <ul className="filter-options">
                    {engineVersions.map((eng, idx) => (
                        <li
                            key={`engine-version-${idx}`}
                            className={`${
                                idx > 6 && !isAllEngineVisible ? 'hidden' : ''
                            } ${this.getSelectedClass(compatibleWith, eng)}`}
                            onClick={this.handleFieldChange('compatibleWith', eng)}>
                            {eng
                                .replace('UE_', '')
                                .replace('EA', ' EA')
                                .replace('ea', ' EA')}
                        </li>
                    ))}
                </ul>
                <a
                    id="showMoreEngineVersion"
                    className={`show-more ${
                        isAllEngineVisible || engineVersions.length < 8 ? 'hidden' : ''
                    }`}
                    onClick={this.showMoreEngine}>
                    <Message code="messages.com.epicgames.plugin.store.filter.more_engine_version" />
                </a>
            </Fragment>
        );
    };

    getDiscountOptions = () => {
        const {messages, filterOptions} = this.props;
        const {discountPercentageRange} = filterOptions;
        return (
            <ul className="filter-options">
                {DISCOUNT_OPTIONS.map(opt => (
                    <li
                        className={`${this.getSelectedClass(opt.value, discountPercentageRange)}`}
                        onClick={this.handleDiscountSelect(opt)}
                        key={`filter-discount-${opt.value}`}>
                        {getMessage(messages, opt.name, opt.args)}
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        const {listParams} = this.state;
        // EGS-23072 [Marketplace] Search condition in url is not working
        if (!listParams) {
            return null;
        }
        const {className, messages, isPriceHidden, pageData, filterOptions} = this.props;

        const {platforms = [], priceRanges = {}, isLoadingTags, defaultTags, tagData} = pageData;

        const {symbol} = priceRanges;

        const {
            tag,
            platform,
            priceRange,
            customPriceRange: {low, high},
            isCustomPrice,
            isPriceInvalid,
            tagKeyWord
        } = filterOptions;

        const priceOptions = getPriceOptions(priceRanges, messages, getMessage);
        const tags = this.generateSelectedTags(tag);
        const classNames = cn('filter-section', className);
        return (
            <div className={classNames} onClick={this.handleClose} style={this.getExtraStyle()}>
                <article onClick={this.stopPropagation}>
                    <div className="filter-header">
                        <Message code="messages.com.epicgames.plugin.store.filter.header" />
                        <a onClick={this.resetFilter}>
                            <Message code="messages.com.epicgames.plugin.store.filter.reset_filter" />
                        </a>
                    </div>
                    <div className="filter-dropdown">
                        <div className="asset-tags filter-block">
                            <p className="title">
                                <Message code="messages.com.epicgames.plugin.store.asset.tags" />
                            </p>
                            <ul className="filter-options selected-tags">
                                {tags.map(t => (
                                    <li
                                        className="selected"
                                        key={`selected-tag-${t.name}`}
                                        onClick={this.handleRemoveTag(t)}>
                                        {t.name}
                                    </li>
                                ))}
                            </ul>

                            <TagAutoSuggest
                                classNames="search-panel"
                                placeholder={getMessage(
                                    messages,
                                    'messages.com.epicgames.plugin.store.asset.tags.search'
                                )}
                                onChange={this.handleTagSelect}
                                onSuggest={this.handleTagSuggest}
                                onSearch={({query}) => this.handleTagSuggest(query)}
                                listData={tagData}
                                defaultTags={defaultTags}
                                excludeData={tags}
                                value={tagKeyWord}
                                isDisabled={tags.length >= 10}
                                isLoadingSuggest={isLoadingTags}
                                selectFirstByDefault
                            />
                        </div>

                        <div className="filter-block">
                            <p className="title">
                                <Message code="messages.com.epicgames.plugin.store.asset.discount" />
                            </p>
                            {this.getDiscountOptions()}
                        </div>

                        {!isPriceHidden && (
                            <div className="price-range filter-block">
                                <p className="title">
                                    <Message code="messages.com.epicgames.plugin.store.filter.max_price" />
                                </p>
                                <ul className="filter-options">
                                    {priceOptions.map((p, idx) => (
                                        <li
                                            key={`filter-price-${idx}`}
                                            className={this.getSelectedClass(
                                                priceRange,
                                                p.value,
                                                isCustomPrice
                                            )}
                                            onClick={this.handleFieldChange('priceRange', p.value)}>
                                            {p.label}
                                        </li>
                                    ))}
                                </ul>
                                <div className="custom-range">
                                    <Message
                                        className={`custom-range-btn ${
                                            isCustomPrice ? 'selected' : ''
                                        }`}
                                        code="messages.com.epicgames.plugin.store.filter.price_range"
                                    />
                                    {symbol || ''}
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={low}
                                        onChange={this.handleCustomPrice('low')}
                                        onKeyPress={this.handlePriceKeyPress}
                                        maxLength="7"
                                    />
                                    <span> to </span>
                                    {symbol || ''}
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={high}
                                        onChange={this.handleCustomPrice('high')}
                                        onKeyPress={this.handlePriceKeyPress}
                                        maxLength="7"
                                    />
                                    <div className={`warning ${isPriceInvalid ? '' : 'hidden'}`}>
                                        <Message code="errors.com.epicgames.plugin.store.filter.invalid_price_range" />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="engine-version filter-block">
                            <p className="title">
                                <Message code="messages.com.epicgames.plugin.store.asset.versions" />
                            </p>
                            {this.getEngineVersionsElements()}
                        </div>

                        <div className="support-platform filter-block">
                            <p className="title">
                                <Message code="messages.com.epicgames.plugin.store.asset.platforms" />
                            </p>
                            <ul className="filter-options">
                                {platforms.map((p, idx) => (
                                    <li
                                        key={`filter-platform-${idx}`}
                                        className={this.getSelectedClass(platform, p.value)}
                                        onClick={this.handleFieldChange('platform', p.value)}>
                                        {p.displayName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}
FilterPanelView.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.object,
    pageData: PropTypes.object,
    filterOptions: PropTypes.object,
    listParams: PropTypes.object,
    isPriceHidden: PropTypes.bool
};
export default FilterPanelView;
