import React, {useCallback, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';

import {
    useAddToWishlist,
    useRemoveFromWishlist,
    useIsInWishlist,
    useWishlistItems,
    WISHLIST_ITEM_LIMIT
} from '../../shared/wishlist.hooks';
import {Heart, HeartOutline, LoadingDots} from '@epic-ui/icons';
import styled from 'styled-components';
import {useLocalization} from '@epic-core/localization';
import Tooltip from '@material-ui/core/Tooltip';

const HeartWrap = styled.button`
    background-color: #dca100;
    transition: 0.3s all ease-in;
    border: 0;
    outline: 0;
    padding: 0;
    &:hover {
        background-color: #ffcd43;
    }
`;

const WishlistLink = styled(Link)`
    color: #dca100;
    text-decoration: underline;
    margin-left: 4px;

    &:hover {
        color: #ffbf10;
    }
`;

enum WishlistTitleType {
    Add,
    Remove,
    AddSuccess,
    RemoveSuccess,
    MaxWishlistSizeMet
}

const DEFAULT_CONFIRMATION_DISPLAY = 2000;
const DEFAULT_LEAVE_DELAY = 150;

interface Properties {
    offerId: string;
    disableTooltip?: boolean;
}

export const WishlistToggle = (props: Properties) => {
    const offerId = props.offerId || '';
    const {disableTooltip} = props;
    const getMessage = useLocalization();
    const {wishlistItemsStore, loading} = useWishlistItems();
    const wishlistLength = wishlistItemsStore.length || 0;
    const isInWishlist = useIsInWishlist(offerId);
    const {
        addToWishlist,
        loading: adding,
        loaded: addComplete,
        success: addSuccess
    } = useAddToWishlist();
    const {
        removeFromWishlist,
        loading: removing,
        loaded: removeComplete,
        success: removeSuccess
    } = useRemoveFromWishlist();

    const [interactive, setInteractive] = useState(false);
    const [open, setOpen] = useState(false);
    const [leaveDelay, setLeaveDelay] = useState(DEFAULT_LEAVE_DELAY);

    const generateTitle = (titleType: WishlistTitleType) => {
        let title: React.ReactNode;

        switch (titleType) {
            case WishlistTitleType.Add:
                title = (
                    <React.Fragment>
                        {getMessage('epic.marketplace.wishlist.add_tooltip')}
                    </React.Fragment>
                );
                break;
            case WishlistTitleType.Remove:
                title = (
                    <React.Fragment>
                        {getMessage('epic.marketplace.wishlist.remove_tooltip')}
                    </React.Fragment>
                );
                break;
            case WishlistTitleType.AddSuccess:
                title = (
                    <React.Fragment>
                        {getMessage('epic.marketplace.wishlist.saved')}
                        <WishlistLink to={generateRoutePath('/wishlist')} data-shoppingcart={true}>
                            {getMessage('epic.marketplace.wishlist.view_all_wishlist')}
                        </WishlistLink>
                    </React.Fragment>
                );
                break;
            case WishlistTitleType.RemoveSuccess:
                title = (
                    <React.Fragment>
                        {getMessage('epic.marketplace.wishlist.remove_success')}
                    </React.Fragment>
                );
                break;
            case WishlistTitleType.MaxWishlistSizeMet:
                title = (
                    <React.Fragment>
                        {getMessage('epic.marketplace.wishlist.exceed_max_size_title')}
                        <WishlistLink to={generateRoutePath('/wishlist')} data-shoppingcart={true}>
                            {getMessage('epic.marketplace.wishlist.exceed_max_size_description')}
                        </WishlistLink>
                    </React.Fragment>
                );
                break;
            default:
                break;
        }
        return title;
    };

    const [title, setTitle] = useState(
        isInWishlist
            ? generateTitle(WishlistTitleType.Remove)
            : generateTitle(WishlistTitleType.Add)
    );

    useEffect(() => {
        if (!addComplete && !addSuccess) {
            return;
        }
        setInteractive(true);
        setLeaveDelay(DEFAULT_CONFIRMATION_DISPLAY);
        setTitle(generateTitle(WishlistTitleType.AddSuccess));
    }, [addComplete, addSuccess]);

    useEffect(() => {
        if (!removeComplete && !removeSuccess) {
            return;
        }
        setLeaveDelay(DEFAULT_CONFIRMATION_DISPLAY);
        setTitle(generateTitle(WishlistTitleType.RemoveSuccess));
    }, [removeComplete, removeSuccess]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = useCallback(() => {
        setInteractive(false);
        setOpen(false);
        setLeaveDelay(DEFAULT_LEAVE_DELAY);

        const timer = setTimeout(() => {
            setTitle(
                isInWishlist
                    ? generateTitle(WishlistTitleType.Remove)
                    : generateTitle(WishlistTitleType.Add)
            );
        }, DEFAULT_LEAVE_DELAY);

        return () => clearTimeout(timer);
    }, [isInWishlist]);

    const onClick = useCallback(
        e => {
            //stop the spotlight card from navigating on click
            e.preventDefault();
            e.stopPropagation();

            if (!offerId) return;

            if (isInWishlist) {
                removeFromWishlist(offerId);
                return;
            }

            if (wishlistLength >= WISHLIST_ITEM_LIMIT) {
                setInteractive(true);
                setLeaveDelay(DEFAULT_CONFIRMATION_DISPLAY);
                setTitle(generateTitle(WishlistTitleType.MaxWishlistSizeMet));
            } else {
                addToWishlist(offerId);
            }
        },
        [isInWishlist, addToWishlist, removeFromWishlist, wishlistItemsStore, offerId]
    );

    const showLoading = loading || adding || removing;

    const toggleEl = (
        <HeartWrap
            className={`wishlist-toggle ${isInWishlist ? 'in-wishlist' : 'not-in-wishlist'}`}
            onClick={onClick}>
            {showLoading ? <LoadingDots /> : isInWishlist ? <Heart /> : <HeartOutline />}
        </HeartWrap>
    );

    if (disableTooltip) return toggleEl;

    return (
        <Tooltip
            open={open}
            onClose={handleClose}
            leaveDelay={leaveDelay}
            onOpen={handleOpen}
            interactive={interactive}
            title={title}
            arrow
            placement="top">
            {toggleEl}
        </Tooltip>
    );
};
