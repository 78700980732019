import * as React from 'react';
import {
    Box,
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControlLabel
} from "@material-ui/core";
import styled from 'styled-components';
import {useLocalization} from "@epic-core/localization";
import {connect} from 'react-redux';
import {LoadingDots} from '@epic-ui/icons';

import {Button} from '..';
import {useEulaAccept, useEulaDialog} from "../../shared/eula/eula.hooks";

interface CheckboxProps extends Omit<MuiCheckboxProps, "color"> {
    color: string;
}

const MuiCheckboxWithoutColor: React.FC<CheckboxProps> = ({ color: _color, ...props }) => (
    <MuiCheckbox {...props} />
);

const Checkbox = styled(MuiCheckboxWithoutColor)`
  .MuiSvgIcon-root {
    fill: #dca100  !important;
    stroke: #111 !important;
  }
  .MuiTypography-root {
    margin-left: 5px !important;
  }
`;

const LayoutWrapper = styled(Box)`
    padding: 0 5px;
    .MuiCheckbox-root {
      padding: 5px !important;
    }
    .MuiFormControlLabel-label {
        margin-left: 5px !important;
    }
`
const LabelWrapper = styled(Box)`
    margin-bottom: 10px
`;

const CloaseButtonWrapper = styled(Box)`
    margin-top: 10px;
    text-align: center;
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #dca100;
    margin-top: 5px;
    outline: none;
`;

const AcceptButton = styled(Button)`
    height: 38px !important;
    with: 126px !important;
    border-radius: 5px !important;
`

const EulaAcceptFormConnect: React.FC<{identityId: string, isLoggedIn: boolean, loaded: boolean}> = ({identityId, isLoggedIn, loaded}) => {
    const [checked, setChecked] = React.useState(false);
    const getMessage = useLocalization();
    const {acceptEula, loading} = useEulaAccept(identityId);
    const {closeEulaDialog} = useEulaDialog();

    const handlerClickAccept = React.useCallback(async() => {
        const resp = await acceptEula();
        if (resp?.accepted) {
            closeEulaDialog(true);
        }
    }, []);

    if (!isLoggedIn) return;

    const handleToggle = () => {
        setChecked(!checked);
    }
    return (
        <LayoutWrapper>
            {
                loaded && <>
                    <LabelWrapper>
                        <FormControlLabel
                            label={getMessage('epic.marketplace.eula.accept.label')}
                            control={
                                <Checkbox
                                    size="small"
                                    color={'#ccc'}
                                    onChange={handleToggle}
                                    name='accept-label'
                                    checked={checked}
                                />
                            }
                        />
                    </LabelWrapper>
                    <AcceptButton onClick={handlerClickAccept} disabled={!checked || loading}>
                        {
                            loading ? <LoadingDots /> : <>{getMessage('epic.marketplace.eula.accept.button')}</>
                        }
                    </AcceptButton>
                </>
            }
            <CloaseButtonWrapper>
                <p>{getMessage('epic.marketplace.eula.dismiss.desc')}</p>
                <CloseButton onClick={closeEulaDialog}>{getMessage('epic.marketplace.eula.dismiss.button')}</CloseButton>
            </CloaseButtonWrapper>
        </LayoutWrapper>
    )
}

const mapStateToProps = state => {
    const {accountInfo, isLoggedIn} = state.get('user').toJS();
    return {
        identityId: accountInfo && accountInfo.id,
        isLoggedIn
    };
}

export const EulaAcceptForm =  connect(mapStateToProps)(EulaAcceptFormConnect);
