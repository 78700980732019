/**
 * Created by Kaku.Guo on 2018/11/9.
 */
const DESC = 'DESC';
const ASC = 'ASC';
export const getSortOptions = (withRelevancy = false, isFreePage = false) => {
    let options = [
        {
            label: 'messages.com.epicgames.plugin.store.filter.newest_first',
            value: 'effectiveDate',
            dir: DESC
        },
        {
            label: 'messages.com.epicgames.plugin.store.filter.oldest_first',
            value: 'effectiveDate',
            dir: ASC
        },
        {label: 'messages.com.epicgames.plugin.store.filter.title_asc', value: 'title', dir: ASC},
        {label: 'messages.com.epicgames.plugin.store.filter.title_desc', value: 'title', dir: DESC}
    ];
    if (withRelevancy) {
        options.unshift({
            label: 'messages.com.epicgames.plugin.store.filter.relevancy',
            value: 'relevancy',
            dir: DESC
        });
    }
    if (!isFreePage) {
        options = options.concat([
            {
                label: 'messages.com.epicgames.plugin.store.filter.price_asc',
                value: 'currentPrice',
                dir: ASC
            },
            {
                label: 'messages.com.epicgames.plugin.store.filter.price_desc',
                value: 'currentPrice',
                dir: DESC
            },
            {
                label: 'messages.com.epicgames.plugin.store.filter.discount_percent_desc',
                value: 'discountPercentage',
                dir: DESC
            }
        ]);
    }
    //    options = options.concat([
    //        { label: 'messages.com.epicgames.plugin.store.filter.rating_desc', value: 'averageRating', dir: DESC }
    //    ]);
    return options;
};

export const PRICE_RANGE_REGX = /^\[(\d{0,9}),(\d{0,9})]$/;
export const IMAGE_NAME_REGX = /(.*\/)*(.+).(png|jpg|jpeg|PNG|JPG|JPEG)/;
export const PARAM_ARRAY_KEYS = ['tag', 'compatibleWith', 'platform'];

export const getDefaultSearchParams = () => ({
    start: 0,
    count: 20,
    sortBy: 'effectiveDate',
    sortDir: 'DESC'
});

export const distributionMethodMap = {
    ASSET_PACK: {
        text: 'messages.com.epicgames.plugin.store.asset.distributionMethod.assetpack',
        tooltip: 'messages.com.epicgames.plugin.store.asset.distributionMethod.assetpack.tooltip'
    },
    COMPLETE_PROJECT: {
        text: 'messages.com.epicgames.plugin.store.asset.distributionMethod.completeProject',
        tooltip:
            'messages.com.epicgames.plugin.store.asset.distributionMethod.completeProject.tooltip'
    },
    ENGINE_PLUGIN: {
        text: 'messages.com.epicgames.plugin.store.asset.distributionMethod.enginePlugin',
        tooltip: 'messages.com.epicgames.plugin.store.asset.distributionMethod.enginePlugin.tooltip'
    }
};

export const REGEXPS = {
    INT: /^(?:[+]?(?:0|[1-9]\d*))$/,
    INTEGER: /^(?:[+-]?(?:0|[1-9]\d*))$/,
    FLOAT: /^(?:[+]?(?:\d+))?(?:\.\d*)?(?:[eE][+-]?(?:\d+))?$/,
    WORDS: /^[A-Z\s]+$/i,
    SPECIAL_WORDS: /^[A-Z\s\u00C0-\u017F]+$/i,
    ALPHA: /^[A-Z]+$/i,
    NUMERIC: /^[+]?(?:\d*[.])?\d+$/,
    ALPHANUMERIC: /^[0-9A-Z]+$/i,
    URL: /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/i,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
};
export const SORT_BY_COL = [
    'effectiveDate',
    'title',
    'relevancy',
    'currentPrice',
    'discountPercentage',
    'averageRating'
];
export const SORT_DIR_COL = ['ASC', 'DESC'];

export const VALID_IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];

export const getDefaultReviewParams = () => ({
    start: 0,
    count: 10,
    sortBy: 'CREATEDAT',
    sortDir: 'DESC'
});

export const UPLOAD_STATUS = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
};

export const replyDefaultCount = 10;

export const REVIEW_TYPES = {
    REVIEW: 'reviews',
    QUESTION: 'questions',
    REPLY: 'replies'
};

export const NO_REVIEWS_CATEGORIES = ['assets/onlinelearning'];
export const NO_QUESTIONS_CATEGORIES = ['assets/onlinelearning'];

export const KEY_BACKSPACE = 8;

export const DISCOUNT_OPTIONS = [
    {
        name: 'messages.com.epicgames.plugin.store.asset.discount.filter_discount',
        value: '10',
        args: ['10%']
    },
    {
        name: 'messages.com.epicgames.plugin.store.asset.discount.filter_discount',
        value: '30',
        args: ['30%']
    },
    {
        name: 'messages.com.epicgames.plugin.store.asset.discount.filter_discount',
        value: '50',
        args: ['50%']
    },
    {
        name: 'messages.com.epicgames.plugin.store.asset.discount.filter_discount',
        value: '70',
        args: ['70%']
    }
];
