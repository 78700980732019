import {useSharedValueEffects} from '@epic-core/hooks';
import {PurchaseEffects} from './shared/purchase.effect';
import {WishlistEffects} from './shared/wishlist.effect';
import {EulaEffects} from './shared/eula/eula.effect';
import {ReduxEffects} from './shared/redux.effect';

export const AppEffects = () => {
    useSharedValueEffects([PurchaseEffects, WishlistEffects, EulaEffects, ReduxEffects]);

    return null;
};
