import {useEffect, useCallback} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {useLocalizationLocale} from '@epic-core/localization';
import {useCountryCode} from '../misc.hooks';
import {couponApi} from './coupon.api';

export enum ImageType {
    SMALL = 'img_small',
    BANNER = 'img_banner'
}

const DEFAULT_LOADING_STATE = {loading: false, loaded: false, error: ''};
const couponsKey = createSharedValueKey('CouponsKey', []);
const couponCountsKey = createSharedValueKey('CouponCountsKey', 0);
const couponsLoadingKey = createSharedValueKey('CouponsLoadingKey', DEFAULT_LOADING_STATE);
const couponDetailKey = createSharedValueKey('couponDetailKey', {couponData: {}, seo: {}});
const couponDetailLoadingKey = createSharedValueKey(
    'couponDetailLoadingKey',
    DEFAULT_LOADING_STATE
);

const couponListRefreshKey = createSharedValueKey('CouponListNeedsRefresh', false);

const DEFAULT_MERCHANT_GROUP = 'UE_MKT';

let loadActive = false;

export const useCoupons = (identityId?: string) => {
    const [loadingState, setLoadingState] = useSharedValue(couponsLoadingKey);
    const [couponsStore, setCouponsStore] = useSharedValue(couponsKey);
    const [couponsCount, setCouponsCount] = useSharedValue(couponCountsKey);
    const [needRefresh, setNeedRefresh] = useSharedValue(couponListRefreshKey);
    const countryCode = useCountryCode();
    const locale = useLocalizationLocale();
    const merchantGroup = DEFAULT_MERCHANT_GROUP;
    useEffect(() => {
        if (
            (loadingState.loaded && !needRefresh) ||
            loadingState.loading ||
            loadActive ||
            !identityId
        ) {
            return;
        }
        async function fetchData() {
            try {
                loadActive = true;
                setLoadingState({loading: true, loaded: false, error: ''});
                const coupons = await couponApi.getCoupons({
                    countryCode,
                    identityId,
                    locale,
                    merchantGroup
                });
                if (coupons && coupons instanceof Array) {
                    setCouponsCount(coupons.length);
                }
                setCouponsStore(coupons);
                setNeedRefresh(false);
                setLoadingState({loading: false, loaded: true, error: ''});
            } catch (ex) {
                console.error('Failed to fetch coupons data', ex);
                setLoadingState({loading: false, loaded: true, error: ex});
            } finally {
                loadActive = false;
            }
        }
        fetchData();
    }, [needRefresh]);

    const refreshCouponList = useCallback(() => {
        setNeedRefresh(true);
    }, [needRefresh, setNeedRefresh]);

    return {
        couponsCount,
        couponsStore,
        refreshCouponList,
        ...loadingState
    };
};

export const useCouponsDetail = (slug: string) => {
    const [couponsDetailState, setCouponsDetailState] = useSharedValue(couponDetailKey);
    const [loadingState, setLoadingState] = useSharedValue(couponDetailLoadingKey);

    useEffect(() => {
        if (loadingState.loaded || loadingState.loading) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({...DEFAULT_LOADING_STATE, loading: true});
                const couponDetail = await couponApi.getCouponDetail(slug);
                setCouponsDetailState(couponDetail);
                setLoadingState({...DEFAULT_LOADING_STATE, loaded: true});
            } catch (err) {
                setLoadingState({...DEFAULT_LOADING_STATE, error: err, loaded: true});
            }
        }
        fetchData();
        return () => {
            setCouponsDetailState({couponData: {}, seo: {}});
        };
    }, []);
    return {
        couponsDetailState,
        ...loadingState
    };
};

export const useCouponsCounts = () => {
    const {couponsCount} = useCoupons();
    return couponsCount;
};
