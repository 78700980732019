import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getMessage as getLocalizedMessage} from '@epic-core/localization';

const getMessage = (messages, code, args) => {
    let message = getLocalizedMessage(messages, code, args);
    if (!message) {
        message =
            code && code.startsWith('errors.')
                ? getLocalizedMessage(messages, 'errors.epic.common.unknown')
                : '';
    }
    return message;
};

const getNumericMessage = (messages, code, count, args) => {
    const suffix = count === 1 ? '.single' : '';
    return getMessage(messages, `${code}${suffix}`, args);
};

const mapStateToProps = state => {
    return {
        messages: state.get('localization').get('messageProperties')
    };
};

@connect(mapStateToProps)
export default class MessageComponent extends React.PureComponent {
    static propTypes = {
        code: PropTypes.string,
        args: PropTypes.array,
        messages: PropTypes.object,
        className: PropTypes.string,
        title: PropTypes.string,
        numeric: PropTypes.bool,
        number: PropTypes.number
    };

    render() {
        const {code, args, messages, className, title, numeric, number} = this.props;
        if (!messages) {
            console.error('localization reducer does not have a message set');
            return <span />;
        }

        let message = numeric
            ? getNumericMessage(messages, code, number, args)
            : getMessage(messages, code, args);
        message = message || code;
        return (
            <span className={className} title={title} dangerouslySetInnerHTML={{__html: message}} />
        );
    }
}

export {getMessage, getNumericMessage, mapStateToProps};
