import React, {useState, useEffect} from 'react';
import root from 'window-or-global';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {Epic} from '@epic-ui/icons';
import {UnrealLoading} from 'epic-ue-loading';
import {Captcha, ModalDialog, getMessage} from '../index';
import {TraderDialogActions} from '../../actions';

const mapDispatchToProps = dispatch => {
    return {
        hideTraderDialog: () => dispatch(TraderDialogActions.hide(true)),
        updateTraderInfo: model => dispatch(TraderDialogActions.getTraderInfo(model)),
        mergeTraderDialogOptions: model => dispatch(TraderDialogActions.mergeOptions(model))
    };
};

const mapStateToProps = state => {
    const {messageProperties} = state.get('localization').toJS();
    const options = state.get('traderDialog').toJS();
    return {
        messages: messageProperties,
        options
    };
};

function TraderDialog(props) {
    const {
        options = {},
        messages,
        hideTraderDialog,
        updateTraderInfo,
        mergeTraderDialogOptions
    } = props;
    const {
        isVisible,
        className,
        onPreload,
        isLoading,
        sellerId,
        sellerName,
        data = {},
        error = {}
    } = options;
    const {
        legalName,
        email,
        addressLine1,
        addressLine2,
        city,
        stateProvince,
        postalCode,
        country,
        phoneNumber,
        businessRegistrationNumber
    } = data;
    const concatAddress = `${addressLine1 ? `${addressLine1}, ` : ''}${
        addressLine2 ? `${addressLine2}, ` : ''
    }${city ? `${city}, ` : ''}${stateProvince ? `${stateProvince}, ` : ''}${
        postalCode ? `${postalCode}, ` : ''
    }${country ? `${country}, ` : ''}`;
    const address = concatAddress ? concatAddress.substring(0, concatAddress.length - 2) : '';
    data.address = address;
    const isTraderInfoFetched = Object.values(data).some(val => Boolean(val));
    const isErrorGenerated = Object.values(error).some(val =>
        typeof val === 'function' ? false : Boolean(val)
    );
    const errorMessage = getMessage(messages, 'errors.com.epicgames.common.server_error');
    const [enableCaptcha, setEnableCaptcha] = useState(false);

    useEffect(() => {
        if (isVisible && onPreload) {
            onPreload();
        }
    }, [isVisible]);

    useEffect(() => {
        if (isErrorGenerated && enableCaptcha) {
            setEnableCaptcha(false);
            isLoading && mergeTraderDialogOptions({isLoading: false});
        }
    }, [enableCaptcha, isErrorGenerated]);

    const handleViewTraderClick = e => {
        setEnableCaptcha(true);
        mergeTraderDialogOptions({isLoading: true});
        isErrorGenerated && mergeTraderDialogOptions({error: {}});
    };

    const removeCaptchaBodyStyles = () => {
        // undo the breaking styles that talon script adds
        root.document.body.style.removeProperty('height');
        root.document.body.style.removeProperty('overflow');
    };

    const handleTraderDialogClose = () => {
        setEnableCaptcha(false);
        hideTraderDialog();
    };

    const handleCaptchaSolved = (solveToken, flowId) => {
        updateTraderInfo({sellerId, solveToken, flowId});
        removeCaptchaBodyStyles();
    };
    const handleCaptchaClosed = () => {
        mergeTraderDialogOptions({isLoading: false});
        setEnableCaptcha(false);
        removeCaptchaBodyStyles();
    };
    const handleCaptchaError = e => {
        mergeTraderDialogOptions({error: e, isLoading: false});
        setEnableCaptcha(false);
        removeCaptchaBodyStyles();
    };

    const classNames = cn('trader-info-modal', className);
    const learnTradersLink = 'https://epicgames.com/site/marketplace-disclosure-requirements';
    return (
        <ModalDialog onClose={handleTraderDialogClose} isVisible={isVisible} className={classNames}>
            <div className="left-col">
                <div className="icons">
                    <Epic />
                    <div className="verified-trader-icon" />
                </div>
                <div className="verified-trader-title">
                    {getMessage(
                        messages,
                        'messages.com.epicgames.plugin.store.trader.info.verified_trader'
                    )}
                </div>
                <div className="verified-trader-description">
                    {getMessage(
                        messages,
                        'messages.com.epicgames.plugin.store.trader.info.verified_trader_desc'
                    )}
                </div>
                <div className="verified-trader-learn-more">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.trader.info.learn_more',
                                [learnTradersLink, '']
                            )
                        }}
                    />
                </div>
            </div>
            <div className="right-col">
                <div className="verified-trader-seller">
                    <div className="verified-trader-seller-name">
                        {sellerName || 'Test seller name'}
                    </div>
                    <div className="verified-trader-icon" />
                </div>
                <div className="verified-trader-details">
                    {isLoading && (
                        <UnrealLoading key="trader-dialog-loading" fillViewHeight={false} />
                    )}
                    <div className={`trader-info-list ${isTraderInfoFetched ? '' : 'placeholder'}`}>
                        {isTraderInfoFetched ? (
                            <>
                                {legalName && (
                                    <div className="trader-info-item">
                                        <div className="info-title">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.name'
                                            )}
                                        </div>
                                        <div className="info-content">{legalName}</div>
                                    </div>
                                )}
                                {email && (
                                    <div className="trader-info-item">
                                        <div className="info-title">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.email_address'
                                            )}
                                        </div>
                                        <div className="info-content">{email}</div>
                                    </div>
                                )}
                                {address && (
                                    <div className="trader-info-item">
                                        <div className="info-title">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.address'
                                            )}
                                        </div>
                                        <div className="info-content">{address}</div>
                                    </div>
                                )}
                                {phoneNumber && (
                                    <div className="trader-info-item">
                                        <div className="info-title">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.phone_number'
                                            )}
                                        </div>
                                        <div className="info-content">{phoneNumber}</div>
                                    </div>
                                )}
                                {businessRegistrationNumber && (
                                    <div className="trader-info-item">
                                        <div className="info-title">
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.info.registration_number'
                                            )}
                                        </div>
                                        <div className="info-content">
                                            {businessRegistrationNumber}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className="trader-info-item">
                                    <div
                                        className="info-title placeholder"
                                        style={{width: '32px'}}
                                    />
                                    <div
                                        className="info-content placeholder"
                                        style={{width: '140px'}}
                                    />
                                </div>
                                <div className="trader-info-item">
                                    <div
                                        className="info-title placeholder"
                                        style={{width: '81px'}}
                                    />
                                    <div
                                        className="info-content placeholder"
                                        style={{width: '208px'}}
                                    />
                                </div>
                                <div className="trader-info-item">
                                    <div
                                        className="info-title placeholder"
                                        style={{width: '46px'}}
                                    />
                                    <div
                                        className="info-content placeholder"
                                        style={{width: '420px'}}
                                    />
                                </div>
                                <div className="trader-info-item">
                                    <div
                                        className="info-title placeholder"
                                        style={{width: '84px'}}
                                    />
                                    <div
                                        className="info-content placeholder"
                                        style={{width: '137px'}}
                                    />
                                </div>
                                <div className="trader-info-item">
                                    <div
                                        className="info-title placeholder"
                                        style={{width: '118px'}}
                                    />
                                    <div
                                        className="info-content placeholder"
                                        style={{width: '181px'}}
                                    />
                                </div>
                                <div className="gradient" />
                                <div className="btn-container">
                                    {enableCaptcha ? (
                                        <div className="captcha-container">
                                            <Captcha
                                                onSolved={handleCaptchaSolved}
                                                onClosed={handleCaptchaClosed}
                                                onError={handleCaptchaError}
                                            />
                                        </div>
                                    ) : (
                                        <button
                                            className={'btn view-trader-details-btn'}
                                            onClick={handleViewTraderClick}>
                                            {getMessage(
                                                messages,
                                                'messages.com.epicgames.plugin.store.trader.view_trader_details'
                                            )}
                                        </button>
                                    )}
                                    {isErrorGenerated && !isTraderInfoFetched && (
                                        <div className="error">{errorMessage}</div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ModalDialog>
    );
}

TraderDialog.propTypes = {
    options: PropTypes.object,
    messages: PropTypes.object,
    hideTraderDialog: PropTypes.func,
    updateTraderInfo: PropTypes.func,
    mergeTraderDialogOptions: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(TraderDialog);
