/**
 * Created by Kaku.Guo on 2018/11/30.
 */
import typeToReducer from 'type-to-reducer';
import { fromJS } from 'immutable';
import { PageActions } from '../actions';
import { getDefaultSearchParams } from '../constants';

const {
    REDIRECT_CHANGE,
    FILTER_CHANGE,
    SORT_CHANGE,
    PAGINATION_CHANGE
} = PageActions.TYPES;

const getInitFilterState = () => ({
    tag: [],
    compatibleWith: [],
    platform: [],
    priceRange: '',
    customPriceRange: { low: '', high: ''},
    isAllEngineVisible: false,
    isCustomPrice: false,
    isPriceInvalid: false,
    discountPercentageRange: '',
    tagKeyWord: ''
});

export const DEFAULT_STATE = fromJS({
    ...getDefaultSearchParams(),
    ...getInitFilterState()
});
export default typeToReducer({
    [REDIRECT_CHANGE]: (state, action) => (DEFAULT_STATE.merge(action.payload)),
    [FILTER_CHANGE]: (state, action) => {
        if (action.payload.reset) {
            return state.merge(getInitFilterState());
        }
        const stateJS = state.toJS();
        const newState = {};
        for (const key in action.payload) {
            if (action.payload.hasOwnProperty(key)) {
                const value = action.payload[key];
                let fieldValue = stateJS[key] || '';
                if (fieldValue instanceof Array) {
                    const idx = fieldValue.indexOf(value);
                    if (idx >= 0) {
                        fieldValue.splice(idx, 1);
                    } else {
                        fieldValue.push(value);
                    }
                } else {
                    fieldValue = value;
                }
                newState[key] = fieldValue;
            }
        }
        return state.merge(newState);
    },
    [SORT_CHANGE]: (state, action) => {
        return state.merge({
            ...action.payload,
            start: 0
        });
    },
    [PAGINATION_CHANGE]: (state, action) => {
        return state.merge(action.payload);
    }
}, DEFAULT_STATE);