import root from 'window-or-global';
import {call, put, takeLatest} from 'redux-saga/effects';
import {LauncherIntegrationUtils} from 'epic-launcher-integration/app/scripts';
import $ from 'jquery';
import {ShoppingCartActions, AssetActions} from '../actions';
import {ShoppingCartApi} from '../apis';
import {purchaseService} from '../shared/purchase.service';
import {processCoupons} from '../utils/Utils';

function* getOffers(action = {}) {
    const params = action.payload || {};
    try {
        let data = yield call(ShoppingCartApi.getShoppingCartPreview, params);
        const {calculatePrice, coupons} = params;
        if (calculatePrice && data.totalPriceDisplay) {
            const code = processCoupons(coupons, data.totalPriceDisplay);
            if (code) {
                params.code = code;
            }
            data = yield call(ShoppingCartApi.getOffers, params);
        }
        yield put(ShoppingCartActions.getOffersSuccess(data));
    } catch (e) {
        yield put(ShoppingCartActions.getOffersFailed(e));
    }
}

function* addOffer(action) {
    try {
        const {offerId, ele} = action.payload;
        const data = yield call(ShoppingCartApi.addOffer, {offerId});
        if (data.isReachMaxLimit) {
            yield put(ShoppingCartActions.addOfferFailed(data));
            setTimeout(() => {
                root.reduxStore.dispatch(ShoppingCartActions.resetMaxAmountAlert());
            }, 3000);
        } else {
            yield put(ShoppingCartActions.addOfferSuccess(data));
            //animation
            if (ele) {
                const cart = $('.shopping-cart .shopping-cart-icon');
                const imgClone = ele
                    .clone()
                    .offset({
                        top: ele.offset().top,
                        left: ele.offset().left
                    })
                    .css({
                        opacity: '0.5',
                        position: 'absolute',
                        height: ele.height(),
                        width: ele.width(),
                        'z-index': '100'
                    })
                    .appendTo($('body'))
                    .animate(
                        {
                            top: cart.offset().top + 10,
                            left: cart.offset().left + 10,
                            width: 75,
                            height: 75
                        },
                        500,
                        'swing'
                    );
                imgClone.animate(
                    {
                        width: 0,
                        height: 0
                    },
                    function detach() {
                        $(this).detach();
                    }
                );
            }
        }
    } catch (e) {
        yield put(ShoppingCartActions.addOfferFailed(e));
    }
}
function* removeOffer(action) {
    try {
        const params = action.payload;
        const data = yield call(ShoppingCartApi.removeOffer, params);
        yield put(ShoppingCartActions.removeOfferSuccess(data));
        const {callback} = action.payload;
        if (callback) {
            callback();
        }
    } catch (e) {
        yield put(ShoppingCartActions.removeOfferFailed(e));
    }
}
function* clearOffers() {
    try {
        const data = yield call(ShoppingCartApi.clearOffers);
        yield put(ShoppingCartActions.clearOffersSuccess(data));
    } catch (e) {
        yield put(ShoppingCartActions.clearOffersFailed(e));
    }
}

function* purchase(action) {
    try {
        const {
            namespace,
            offers,
            quantity,
            isShoppingCart,
            success,
            failed,
            isPurchasingOffer
        } = action.payload;
        if (isPurchasingOffer) {
            return;
        }
        const purchaseFlow = isShoppingCart ? 1 : 2;
        LauncherIntegrationUtils.requestPurchase(
            namespace,
            offers,
            quantity,
            receipt => {
                if (success) {
                    success(receipt);
                }
                // for free offers, the receipt has no offer info, so we will combine ourself
                if (!(receipt.offers && receipt.offers.length > 0)) {
                    receipt.offers = offers.map(offerid => ({offerid}));
                }
                root.reduxStore.dispatch(ShoppingCartActions.purchaseSuccess(receipt));
                try {
                    purchaseService.onPurchaseSuccess(receipt);
                } catch (e) {
                    console.log('purchase service failed', e.message);
                }
            },
            resp => {
                console.error('purchase fail:', resp);
                if (failed) {
                    failed(resp);
                }
                //TODO: purchase fail has no action yet
                root.reduxStore.dispatch(ShoppingCartActions.purchaseFailed());
            }
        );
        yield put(ShoppingCartActions.trackPurchaseType({offerId: offers, purchaseFlow}));
    } catch (e) {
        console.error('purchase error:', e);
        //TODO: purchase fail has no action yet
        yield put(ShoppingCartActions.purchaseFailed(e));
    }
}

function* purchaseSuccess(action) {
    const receipts = action.payload;
    if (receipts && receipts.offers) {
        const offerIds = receipts.offers.map(it => it.offerid);
        yield put(AssetActions.setAssetsOwned(offerIds));
    }
}

function* trackPurchaseType(action) {
    try {
        const params = action.payload;
        const data = yield call(ShoppingCartApi.trackPurchaseType, params);
        yield put(ShoppingCartActions.trackPurchaseTypeSuccess(data));
    } catch (e) {
        yield put(ShoppingCartActions.trackPurchaseTypeFailed(e));
    }
}

function* ShoppingCartSaga() {
    const {
        GET_OFFERS_PENDING,
        ADD_OFFER_PENDING,
        REMOVE_OFFER_PENDING,
        CLEAR_OFFERS_PENDING,
        PURCHASE_PENDING,
        PURCHASE_SUCCESS,
        TRACK_PURCHASE_TYPE_PENDING
    } = ShoppingCartActions.TYPES;
    yield takeLatest(GET_OFFERS_PENDING, getOffers);
    yield takeLatest(ADD_OFFER_PENDING, addOffer);
    yield takeLatest(REMOVE_OFFER_PENDING, removeOffer);
    yield takeLatest(CLEAR_OFFERS_PENDING, clearOffers);
    yield takeLatest(PURCHASE_PENDING, purchase);
    yield takeLatest(PURCHASE_SUCCESS, purchaseSuccess);
    yield takeLatest(TRACK_PURCHASE_TYPE_PENDING, trackPurchaseType);
}

export default ShoppingCartSaga;
