import {useCallback} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';

export const pageTitleCodeKey = createSharedValueKey('PageTitleCode', '');

export const usePageTitle = () => {
    const [pageTitleCode, setPageTitleCode] = useSharedValue(pageTitleCodeKey);

    const setPageTitle = useCallback(
        (code: string, force?: boolean) => {
            if (force || code !== pageTitleCode) {
                setPageTitleCode(code);
            }
        },
        [pageTitleCode, setPageTitleCode]
    );

    return {
        pageTitleCode,
        setPageTitle
    };
};
