/**
 * views/index.js
 */
export {default as ErrorView} from './error/ErrorView';
export {default as PageLayout} from './layout/PageLayout';
export {
    default as BasePageView,
    mapStateToProps,
    mapDispatchToProps
} from './basePage/BasePageView';

export {default as AssetDetailView} from './assetDetail/AssetDetailView';
export {default as SellerAssetsView} from './sellerAssets/SellerAssetsView';
export {default as FabLandingView} from './fabLanding/FabLandingView';
export {default as ErrorPage} from './error/ErrorPage';
