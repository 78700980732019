/**
 * Created by Kaku.Guo on 2019/6/3.
 */
import { generateAsyncActions } from '../utils';

const TYPES = {
    UPDATE_QUEUE_STATUS: 'UPDATE_QUEUE_STATUS',
    ...generateAsyncActions('UPLOAD_QUEUE')
};
export default {
    TYPES,
    uploadQueue: payload => ({
        type: TYPES.UPLOAD_QUEUE_PENDING,
        payload
    }),
    uploadQueueSuccess: payload => ({
        type: TYPES.UPLOAD_QUEUE_SUCCESS,
        payload
    }),
    uploadQueueFailed: payload => ({
        type: TYPES.UPLOAD_QUEUE_FAILED,
        payload
    }),
    updateQueueStatus: payload => ({
        type: TYPES.UPDATE_QUEUE_STATUS,
        payload
    })
};