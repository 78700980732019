import {SharedValueEffect} from '@epic-core/hooks';
import {ReduxService, reduxFullStateKey} from './redux.service';

export const ReduxEffects: SharedValueEffect = values => {
    const service = values.service(ReduxService);

    service.addListener(state => {
        values.set(reduxFullStateKey, state);
    });
};
