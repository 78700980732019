/**
 * Created by Kaku.Guo on 2019/6/4.
 */
import { fromJS } from 'immutable';
import typeToReducer from 'type-to-reducer';
import { UploadActions } from '../actions';

const {
    UPDATE_QUEUE_STATUS
} = UploadActions.TYPES;

export const DEFAULT_STATE = fromJS({
    queue: {}
});

export default typeToReducer({
    [UPDATE_QUEUE_STATUS]: (state, action) => {
        const val = action.payload;
        if (val) {
            const queue = state.get('queue').toJS();
            return state.merge({ queue: {...queue, ...val}});
        }
        return state.merge({ queue: {} });
    }
}, DEFAULT_STATE);