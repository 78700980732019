import axios from 'axios';
import Url from '../constants/Url';
import {getLocale} from '@epic-core/common';

class ShoppingCartApi {
    getOffers(params = {}) {
        const {code = '', calculatePrice = false} = params;
        const lang = getLocale();
        const queryParams = calculatePrice
            ? {
                  lang,
                  code,
                  calculatePrice
              }
            : {lang};
        return axios
            .get(Url().SHOPPING_CART.LIST, {params: queryParams})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
    getShoppingCartPreview() {
        return axios
            .get(Url().SHOPPING_CART.PREVIEW)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
    addOffer(params) {
        return axios
            .post(Url().SHOPPING_CART.ADD, params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
    removeOffer(params) {
        let requestParams = {};
        if (params.offerIds) {
            requestParams = {
                offerIds: params.offerIds
            };
        } else {
            requestParams = {
                offerId: params.offerId
            };
        }
        return axios
            .post(`${Url().SHOPPING_CART.DELETE}`, requestParams)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
    clearOffers() {
        return axios
            .post(Url().SHOPPING_CART.CLEAR)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
    purchase(params) {
        return axios
            .get(Url().PURCHASE.GET, {params})
            .then(response => response.data)
            .catch(ex => {
                throw ex;
            });
    }
    trackPurchaseType(params) {
        return axios
            .post(Url().PURCHASE.TRACK, params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
}
export default new ShoppingCartApi();
