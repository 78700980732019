/**
 * Created by Kaku.Guo on 2018/10/25.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Message} from '../index';
import root from 'window-or-global';

const BackArrow = props => {
    const goBack = e => {
        const {customizedHistory, history, backToLearn} = props;
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (backToLearn) {
            root.location.href = 'com.epicgames.launcher://ue/learn';
            return;
        }
        if (customizedHistory) {
            const previous = customizedHistory.pop();
            history.push(previous);
        } else {
            history.go(-1);
        }
    };

    return (
        <div className="back-button">
            <span onClick={goBack}>
                <i className="fa fa-angle-left fa-large" />
                <Message code="messages.com.epicgames.plugin.store.header.back" />
            </span>
        </div>
    );
};
BackArrow.propTypes = {
    customizedHistory: PropTypes.object,
    history: PropTypes.object,
    backToLearn: PropTypes.bool
};
export default BackArrow;
