/**
 * Created by Kaku.Guo on 2019/4/3.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import root from 'window-or-global';

class LinkDropDown extends Component {
    state = {
        isVisible: false
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.isVisible !== nextState.isVisible || false || this.props !== nextProps) {
            return true;
        }
        return false;
    }

    componentDidUpdate() {
        if (this.state.isVisible) this.dropdownRef.focus();
    }

    handleNavClick = e => {
        const {isVisible} = this.state;
        const isTouchDevice = !!root.document.querySelector('body.touch');
        if (isTouchDevice) {
            if (isVisible) {
                this.setState({isVisible: false});
            } else {
                if (e) {
                    e.preventDefault();
                }
                this.setState({isVisible: true}, () => this.dropdownRef.focus());
            }
        }
    };

    handleClose = e => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({isVisible: false});
    };

    handleBlur = e => {
        if (e && !e.currentTarget.contains(e.relatedTarget)) {
            this.setState({isVisible: false});
        }
    };

    render() {
        const {className, renderPlaceholder, renderDropDown} = this.props;
        const {isVisible} = this.state;
        const classNames = cn(className, 'link-dropdown', isVisible ? 'touch-visible' : '');
        const placeHolder = React.cloneElement(renderPlaceholder(), {
            onClick: this.handleNavClick,
            useDefaultTo: true,
            key: 'placeholder'
        });
        const dropdown = React.cloneElement(renderDropDown(), {
            onClick: this.handleClose,
            key: 'dropdown',
            ref: el => {
                this.dropdownRef = el;
            },
            tabIndex: '0'
        });
        return (
            <li className={classNames} key={`${Math.random()}`} onBlur={this.handleBlur}>
                {placeHolder}
                {dropdown}
            </li>
        );
    }
}

LinkDropDown.propTypes = {
    className: PropTypes.string,
    renderPlaceholder: PropTypes.func,
    renderDropDown: PropTypes.func
};

export default LinkDropDown;
