/**
 * Created by Kaku.Guo on 2018/12/6.
 */
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {UnrealLoading} from 'epic-ue-loading';
import {FabRedirect} from '../../routes/FabRedirect';
import {BasePageView, mapStateToProps, mapDispatchToProps} from '../index';
import {AssetActions, PageActions, TraderDialogActions} from '../../actions';

const sellerMapDispatchToProps = dispatch => {
    const actions = mapDispatchToProps(dispatch);
    actions.listAssets = model => {
        dispatch(AssetActions.sellerAssets(model));
    };
    actions.handleChange = (func, model) => {
        dispatch(PageActions[func](model));
    };
    actions.setTraderDialogOptions = model => {
        dispatch(TraderDialogActions.setOptions(model));
    };
    return actions;
};

/* Add meta tag descriptions for specific profiles */
const addMetaDescription = sellerName => {
    switch (sellerName) {
        case 'Epic+Games':
            return 'epic.ue.marketplace.meta.epic_content';
        default:
            return null;
    }
};

@connect(mapStateToProps, sellerMapDispatchToProps)
class SellerAssetsWrap extends BasePageView {
    constructor(props) {
        const {
            match: {
                params: {sellerName}
            }
        } = props;
        const staticParams = {
            sellerName
        };
        super(props, staticParams, {
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.profile'
            },
            pageTitleCode: 'messages.com.epicgames.plugin.store.title.profile',
            pageTileArgs: [sellerName],
            descriptionCode:
                addMetaDescription(sellerName) || 'epic.ue.marketplace.meta.seller_profile',
            descriptionCodeArgs: [sellerName]
        });
    }

    render() {
        const {data = {}} = this.props;
        const {sellerProfile = {}} = data;
        const {id} = sellerProfile;

        if (id) {
            return <FabRedirect to={`/seller-id/${id}`} />;
        }

        return (
            <div className="asset-details-container offset-top seller-assets overflow-y-visible">
                {this.getHelmetElement()}
                <UnrealLoading fillViewHeight={false} />
            </div>
        );
    }
}
export default withRouter(SellerAssetsWrap);
