import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RatingBoard, Link} from '../index';
import {isCategoryReviewable} from '../../utils';

const mapStateToProps = state => {
    const {epicSellerOwner = ''} = state.get('config').toJS();
    return {epicSellerOwner};
};

const AssetTileRating = props => {
    const {
        data: {categories, rating = {}, urlSlug, catalogItemId, customAttributes, seller = {}},
        isLauncher,
        epicSellerOwner
    } = props;

    const isEpicOwner = epicSellerOwner === seller.owner;

    const hasBuyLink = customAttributes && 'BuyLink' in customAttributes;

    const noReviews = isEpicOwner || hasBuyLink || !isCategoryReviewable(categories);
    if (noReviews) {
        return null;
    }

    const renderReviewBtn = children => {
        let link = '';
        if (urlSlug) {
            link = `product/${urlSlug}`;
        } else if (catalogItemId) {
            link = `item/${catalogItemId}`;
        }
        return <Link to={`${link}/reviews`}>{children}</Link>;
    };

    return (
        <RatingBoard
            data={rating}
            isLauncher={isLauncher}
            size={18}
            renderReviewBtn={renderReviewBtn}
        />
    );
};

AssetTileRating.propTypes = {
    data: PropTypes.object,
    isLauncher: PropTypes.bool,
    epicSellerOwner: PropTypes.string
};

export default connect(mapStateToProps)(AssetTileRating);
