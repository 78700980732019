/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from '../index';
import {getAssetPath} from '../../utils';
import {getOptImage} from 'epic-ue-shared';

const AssetTileImage = props => {
    const {
        isLearnAsset,
        isShowcase,
        data: {
            id,
            thumbnail,
            description,
            keyImages,
            isCatalogItem,
            learnThumbnail,
            catalogItemId,
            urlSlug
        }
    } = props;
    const screenshot = keyImages.filter(image => image.type === 'Screenshot');
    const DEFAULT_ASSET_IMAGE = getAssetPath('logo-epic.svg');
    const getLinkWithImg = (to, imgSrc, title) => {
        return (
            <Link to={to}>
                <img
                    src={getOptImage({url: imgSrc || DEFAULT_ASSET_IMAGE, width: 300})}
                    title={title}
                    alt={title}
                />
            </Link>
        );
    };

    let assetLink;
    if (isLearnAsset) {
        assetLink = getLinkWithImg(`samples/${id}`, learnThumbnail, description);
    } else if (isCatalogItem) {
        assetLink = getLinkWithImg(`item/${catalogItemId}`, thumbnail, description);
    } else {
        assetLink = getLinkWithImg(`product/${urlSlug}`, thumbnail, description);
    }
    return (
        <div className="image-box">
            <div>
                <div>
                    <div>
                        {isShowcase ? (
                            <div className="gallery">
                                <span>
                                    <img
                                        src={getOptImage({
                                            url: thumbnail || DEFAULT_ASSET_IMAGE,
                                            width: 600
                                        })}
                                        title={description}
                                        alt={description}
                                    />
                                </span>
                                {screenshot.map(it => (
                                    <span
                                        key={`screenshot-${screenshot.title}`}
                                        href={screenshot.url}
                                        className="hidden"
                                        width={screenshot.width}
                                        height={screenshot.height}
                                        data-title={screenshot.title}
                                    />
                                ))}
                                <i className="icon-search" />
                            </div>
                        ) : (
                            assetLink
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

AssetTileImage.propTypes = {
    isLearnAsset: PropTypes.bool,
    isShowcase: PropTypes.bool,
    data: PropTypes.object
};
export default AssetTileImage;
