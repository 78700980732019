import {generateAsyncActions} from '../utils';

const TYPES = {
    ...generateAsyncActions('GET_CMS_PAGE_DATA')
};
export default {
    TYPES,
    getCmsPageData: payload => ({
        type: TYPES.GET_CMS_PAGE_DATA_PENDING,
        payload
    }),
    getCmsPageDataSuccess: payload => ({
        type: TYPES.GET_CMS_PAGE_DATA_SUCCESS,
        payload
    }),
    getCmsPageDataFailed: payload => ({
        type: TYPES.GET_CMS_PAGE_DATA_FAILED,
        payload
    })
};
