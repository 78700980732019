import {sharedService, AppEventType} from '@epic-core/hooks';

class EulaServiceProvider {
    callbacks: Function[] = [];

    public addAcceptEulaListener = cb => {
        if (cb && typeof cb === 'function') {
            this.callbacks.push(cb);
        }
    }

    public onAcceptEulaSuccess = (...args) => {
        this.callbacks.forEach(cb => {
            if (cb && typeof cb === 'function') {
                cb(...args);
            }
        })
    }
}

export const AcceptEulaSuccessEvent: AppEventType = 'accept-success';

export const eulaServiceProvider = new EulaServiceProvider();

export const EulaService = sharedService(values => {
    return {
        addAcceptEulaListener: eulaServiceProvider.addAcceptEulaListener,
        onAcceptEulaSuccess: eulaServiceProvider.onAcceptEulaSuccess
    }
});
