/**
 * Created by Kaku.Guo on 2019/8/14.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const text = messages.get('messages.com.epicgames.plugin.store.beta_text');
    const tooltip = messages.get('messages.com.epicgames.plugin.store.beta_desc');
    return { text, tooltip };
};

function Beta({ text, tooltip, className}) {
    const prefixCls = 'beta';
    const classNames = cn(prefixCls, className);
    return (
        <span className={classNames} data-tooltip={tooltip}>
            {text}
        </span>
    );
}

Beta.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    tooltip: PropTypes.string
};

export default connect(mapStateToProps, null)(Beta);