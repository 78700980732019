/**
 * Created by Kaku.Guo on 2019/5/24.
 */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {ResizeObserver} from '../../../utils';
import {getMessage} from '../../index';

const mapStateToProps = state => {
    const messageProperties = state.get('localization').get('messageProperties');
    const expandText = getMessage(
        messageProperties,
        'messages.com.epicgames.plugin.store.message.read_more'
    );
    return {expandText};
};

function ReadMoreText(props) {
    const {text, className, lineHeight, linesToShow, expandText} = props;
    const prefixCls = 'read-more';
    const textRef = useRef(null);
    const [collapse, setCollapse] = useState(false);
    useEffect(() => {
        textRef.current.handleResize = ({contentRect: {height}}) => {
            if (height > (linesToShow + 3) * lineHeight) {
                setCollapse(true);
                ResizeObserver().unobserve(textRef.current);
            }
        };
        ResizeObserver().observe(textRef.current);
        return () => {
            ResizeObserver().unobserve(textRef.current);
        };
    }, []);

    const classNames = cn(prefixCls, className);
    return (
        <div className={classNames}>
            <div
                ref={textRef}
                className={`${prefixCls}__text`}
                style={{
                    lineHeight: `${lineHeight}px`,
                    maxHeight: collapse ? `${linesToShow * lineHeight}px` : 'unset'
                }}
                dangerouslySetInnerHTML={{__html: text}}
            />
            {collapse && (
                <div className={`${prefixCls}--collapsed`}>
                    <div
                        className={`${prefixCls}__mask`}
                        style={{
                            height: `${2 * lineHeight}px`,
                            top: `-${2 * lineHeight}px`
                        }}
                    />
                    <div className={`${prefixCls}__btn`} onClick={() => setCollapse(false)}>
                        <span>{expandText} </span>
                        <span className="fa fa-angle-double-down"></span>
                    </div>
                </div>
            )}
        </div>
    );
}

ReadMoreText.defaultProps = {
    lineHeight: 20,
    linesToShow: 4
};

ReadMoreText.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string,
    lineHeight: PropTypes.number,
    linesToShow: PropTypes.number,
    expandText: PropTypes.string
};

export default connect(mapStateToProps)(ReadMoreText);
