import React from 'react';
import root from 'window-or-global';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {Message} from '../index';
import {connect} from 'react-redux';

const mapStateToProps = state => {
    const locale = state.get('localization').get('locale');
    const ownTooltip = state
        .get('localization')
        .get('messageProperties')
        .get('messages.com.epicgames.plugin.store.asset.review.own.tooltip');
    const anonymousUserStr = state
        .get('localization')
        .get('messageProperties')
        .get('messages.com.epicgames.plugin.store.asset.reviews.sub.title.anonymous');
    return {locale, ownTooltip, anonymousUserStr};
};
const ReviewSubTitle = ({data = {}, locale, ownTooltip, isPublisher, anonymousUserStr}) => {
    const prefixCls = 'review-item__sub-title';
    const {createdAt, lastModifiedAt, identityOwned, identityName} = data;
    const createTime = moment(createdAt)
        .locale(locale)
        .format('LLL');
    const modifyTime =
        createdAt !== lastModifiedAt
            ? moment(lastModifiedAt)
                  .locale(locale)
                  .format('LLL')
            : null;
    const redactedNameStringFromRoot = root.clientEnvConfig.EPIC_STORE_NAME_REDACTED_STRING || '';
    const finalIdentityName =
        identityName === redactedNameStringFromRoot ? anonymousUserStr : identityName;

    return (
        <div className={prefixCls}>
            <Message
                code="messages.com.epicgames.plugin.store.asset.reviews.sub.title.create"
                args={[finalIdentityName, createTime]}
            />
            {modifyTime && (
                <Message
                    className={`${prefixCls}__edit`}
                    code="messages.com.epicgames.plugin.store.asset.reviews.sub.title.edit"
                    args={[modifyTime]}
                />
            )}
            {identityOwned && !isPublisher && (
                <span data-tooltip={ownTooltip}>
                    <span className={cn('icon-key', `${prefixCls}__own`)} />
                </span>
            )}
        </div>
    );
};

ReviewSubTitle.propTypes = {
    locale: PropTypes.string,
    data: PropTypes.object,
    isPublisher: PropTypes.bool,
    ownTooltip: PropTypes.string,
    anonymousUserStr: PropTypes.string
};

export default connect(mapStateToProps)(ReviewSubTitle);
