import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {LoadingDots} from '@epic-ui/icons';
import root from 'window-or-global';
import pt from 'prop-types';

import ShoppingCartView from './ShoppingCartView';
import {getMessage} from '../index';
import {getAssetPath} from '../../utils';
import {ShoppingCartActions} from '../../actions';
import {TOTAL_AMOUNT_LIMIT} from '../../constants';
import {useCoupons} from '../../shared/coupons/coupon.hooks';

const LoadingCoupongWrapper = styled.div`
    margin-left: 30px;
    display: inline-block;
`;
const WithCoupons = ({children, identityId}) => {
    const coupons = useCoupons(identityId);
    const {loading, loaded} = coupons || {};
    if (!loaded || loading) {
        return (
            <LoadingCoupongWrapper>
                <LoadingDots className="icon-loading-wrapper" />
            </LoadingCoupongWrapper>
        );
    }
    return children(coupons);
};

WithCoupons.propTypes = {
    children: pt.node,
    identityId: PropTypes.string
};

const mapStateToProps = state => {
    const {
        isLoadingOffer,
        isReachMaxLimit,
        offers,
        offerIds,
        totalPrice,
        errorMessage,
        appliedCoupon,
        totalCouponDiscount,
        totalDiscountPrice
    } = state.get('shoppingCart').toJS();
    const {isLauncher, shoppingCartMaxAmount} = state.get('config').toJS();
    return {
        isLoadingOffer,
        isReachMaxLimit,
        offers,
        offerIds,
        totalPrice,
        errorMessage,
        isLauncher,
        shoppingCartMaxAmount,
        appliedCoupon,
        totalCouponDiscount,
        totalDiscountPrice
    };
};

const mapDispatchToProps = dispatch => {
    return {
        listOffers: model => {
            dispatch(ShoppingCartActions.getOffers(model));
        },
        removeOffer: model => {
            dispatch(ShoppingCartActions.removeOffer(model));
        },
        clearOffers: () => {
            dispatch(ShoppingCartActions.clearOffers());
        },
        purchase: model => {
            dispatch(ShoppingCartActions.purchase(model));
        }
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class ShoppingCart extends ShoppingCartView {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    Event = {
        PURCHASE_CLOSE: 'epicPurchase.close'
    };

    receiveMessage = data => {
        if (
            data.data &&
            data.data.message &&
            data.data.message.name === this.Event.PURCHASE_CLOSE
        ) {
            const {receipt, reason} = data.data.message;
            if (reason === 'success') {
                const {offers = {}} = receipt;
                const offerIds = Object.values(offers).map(of => of && of.id);
                if (offerIds.length) {
                    this.props.removeOffer({offerIds});
                }
            }
        }
    };

    componentDidMount() {
        root.addEventListener('message', this.receiveMessage, false);
    }

    componentWillUnmount() {
        root.removeEventListener('message', this.receiveMessage);
    }

    getCouponCodes = () => {
        const {offerIds = []} = this.props;
        if (!offerIds.length) {
            return;
        }
        if (this.couponCodes && this.couponCodes.length) {
            return this.couponCodes;
        }
        return [];
    };
    toggleOpen = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const coupons = this.getCouponCodes();
        const {isOpen} = this.state;
        if (!isOpen) {
            this.props.listOffers({calculatePrice: true, coupons});
        }
        this.setState({
            isOpen: !isOpen
        });
    };
    isOpen = () => this.state.isOpen;
    getMessage = (code, args) => {
        const {messages} = this.props;
        return getMessage(messages, code, args);
    };
    getOffers = () => {
        const {offers = []} = this.props;
        return offers;
    };
    getDisplayOfferCount = () => {
        const {offerIds = []} = this.props;
        return offerIds.length;
    };
    getOfferCount = () => {
        const {offers = []} = this.props;
        return offers.length;
    };
    isReachMaxLimit = () => this.props.isReachMaxLimit;
    isLoadingCart = () => this.props.isLoadingOffer;
    getDisplayImage = ({keyImages}) => {
        let image = getAssetPath('logo-epic.svg');
        if (keyImages && keyImages.length) {
            const featuredImage = keyImages.find(ki => ki.type === 'Featured');
            if (featuredImage && featuredImage.url) {
                image = featuredImage.url;
            }
        }
        return image;
    };
    getDisplayPrice = ({discounted, price, discountPrice, voucherDiscount}) => {
        if (discounted || voucherDiscount) {
            return (
                <Fragment>
                    <span className="origin-price">{price}</span>
                    {discountPrice}
                </Fragment>
            );
        }
        return price;
    };

    getTotalPrice = () => this.props.totalPrice;

    getAppliednCoupon = () => this.props.appliedCoupon;

    getTotalDiscountPrice = () => this.props.totalDiscountPrice;

    getTotalCouponDiscount = () => this.props.totalCouponDiscount;

    isShowLimitTooltip = () => {
        if (this.props.totalAmount) {
            return this.props.totalAmount >= TOTAL_AMOUNT_LIMIT;
        }
    };

    handleRemoveOffer = ({id}) => e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const coupons = this.getCouponCodes();
        const {listOffers} = this.props;
        const callback = () => {
            listOffers({calculatePrice: true, coupons});
        };
        this.props.removeOffer({offerId: id, callback});
    };

    handlePurchase = e => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const {refreshCouponList} = this;
        const {offers, offerIds, purchase, clearOffers} = this.props;
        if (offers.length) {
            const {namespace} = offers[0];
            purchase({
                namespace,
                offers: offerIds,
                quantity: 1,
                isShoppingCart: true,
                success: () => {
                    clearOffers();
                    refreshCouponList();
                }
            });
            this.setState({
                isOpen: false
            });
        }
    };
    getLoadingImage = () => {
        const {isLauncher} = this.props;
        if (isLauncher) {
            return getAssetPath('loading-spin-launcher.svg');
        }
        return getAssetPath('loading-spin.svg');
    };
    getMaxLimitArgs = () => [this.props.shoppingCartMaxAmount];

    handleBlur = e => {
        if (e && !e.currentTarget.contains(e.relatedTarget)) {
            const dataset = e.relatedTarget ? e.relatedTarget.dataset : {};
            const shoppingcart = dataset.shoppingcart || false;
            if (!shoppingcart) {
                this.setState({isOpen: false});
            }
        }
    };

    render() {
        return (
            <WithCoupons identityId={this.props.identityId}>
                {props => <this.renderCartView coupons={props} />}
            </WithCoupons>
        );
    }
}
export default ShoppingCart;
