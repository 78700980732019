/**
 * Created by Kaku.Guo on 2019/5/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Select from './Select';

const SortSelect = (props) => {
    const { className, title, value, options, disabled, onChange } = props;

    const handleSortChange = (val) => {
        const sort = val.split('_');
        onChange({
            sortBy: sort[0],
            sortDir: sort[1]
        });
    };
    const classNames = cn('sort-select', className);

    return (
        <div className={classNames}>
            <div className="sort-title" dangerouslySetInnerHTML={{__html: title}} />
            <Select
                value={value}
                options={options}
                onChange={handleSortChange}
                disabled={disabled} />
        </div>
    );
};

SortSelect.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

export default SortSelect;