import {classicDarkThemeConfig} from 'epic-ue-theme';
import {createEpicTheme} from '@epic-ui/theme';

export const getTheme = () => {
    const theme = classicDarkThemeConfig;
    theme.overrides.MuiTooltip = {
        tooltip: {
            backgroundColor: '#4c4c4e'
        },
        arrow: {
            color: '#4c4c4e'
        }
    };

    return createEpicTheme(theme);
};
