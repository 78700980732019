import root from 'window-or-global';

export const euJurisdiction = [
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DE', // Germany
    'DK', // Denmark
    'EE', // Estonia
    'ES', // Spain
    'FI', // Finland
    'FR', // France
    'GB', // United Kingdom
    'GR', // Greece
    'HU', // Hungary
    'HR', // Croatia
    'IE', // Ireland, Republic of (EIRE)
    'IT', // Italy
    'LT', // Lithuania
    'LU', // Luxembourg
    'LV', // Latvia
    'MT', // Malta
    'NL', // Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SE', // Sweden
    'SI', // Slovenia
    'SK', // Slovakia
    'GF', // French Guiana
    'GP', // Guadeloupe
    'MQ', // Martinique
    'ME', // Montenegro
    'YT', // Mayotte
    'RE', // Réunion
    'MF' // Saint Martin
];

const isEuJurisdiction = countryCode => {
    return !!countryCode && euJurisdiction.includes(countryCode);
};

export const configureTracking = initialState => {
    const {country} = (initialState.toJS() && initialState.toJS().config) || {};
    const countryCode = root._epicTrackingCountryCode;
    const configurations = {
        onBeforeTrack: () => {
            return !isEuJurisdiction(countryCode) && !isEuJurisdiction(country);
        }
    };
    if (root._epicTracking) {
        root._epicTracking.configure(configurations);
    } else {
        root.addEventListener('trackingJsLoaded', () => {
            setTimeout(() => {
                root._epicTracking.configure(configurations);
            });
        });
    }
};
