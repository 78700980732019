import parse from 'url-parse';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';
import {sanitizeHtml} from '../../server/utils/Utils';

//eslint-disable-next-line
const defaultImage =
    'https://cdn2.unrealengine.com/Unreal+Engine%2F11_18_UE_Feed_Migration_Images%2FFEED_THUMB_UE_Logo_Generic-1400x788-c11642ffb55e268095321f5eb144f469beb0074f.jpg';

export function getMetaTags(localizationStore) {
    const locale = localizationStore.get('locale');
    const messages = localizationStore.get('messageProperties');
    // Social metadata
    const socialMeta = {
        siteName: messages.get('epic.enterprise.name'),
        description: messages.get('epic.enterprise.common.description'),
        twitterDesc: messages.get('epic.enterprise.common.description'),
        locale: locale ? locale.replace('-', '_') : locale
    };

    let baseUrl;
    if (typeof root.document === 'undefined') {
        baseUrl = root.location.origin;
    } else {
        baseUrl = root.document.getElementsByTagName('html')[0].dataset
            ? root.document.getElementsByTagName('html')[0].dataset.baseurl
            : root.document.getElementsByTagName('html')[0].getAttribute('data-baseurl');
    }

    //if ends with /enterprise add a slash
    if (!baseUrl.match(/.*\/$/)) {
        baseUrl += '/';
    }

    const ogUrl = root.location.href;

    const tags = [
        //Facebook
        {property: 'og:url', content: ogUrl},
        {property: 'og:title', content: socialMeta.siteName},
        {property: 'og:description', content: socialMeta.description},
        {property: 'og:site_name', content: socialMeta.siteName},
        {property: 'og:locale', content: socialMeta.locale},

        //Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: socialMeta.siteName},
        {name: 'twitter:description', content: socialMeta.twitterDesc}
    ];
    return tags;
}

interface HelmetTagProps {
    title: string;
    description: string;
    image: string;
    locale: string;
}

export const getHelmetMetaTags = (props: HelmetTagProps) => {
    const {title: rawTitle, description: rawDescription, image, locale} = props;
    const title = sanitizeHtml(rawTitle);
    const description = sanitizeHtml(rawDescription);
    const meta = [
        {
            property: 'og:title',
            content: title
        },
        {
            name: 'twitter:title',
            content: title
        },
        {
            name: 'description',
            content: description
        },
        {
            name: 'twitter:description',
            content: description
        },
        {
            property: 'og:description',
            content: description
        },
        {
            name: 'twitter:site',
            content: '@UnrealEngine'
        },
        {
            property: 'og:site_name',
            content: 'Unreal Engine'
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            property: 'og:type',
            content: 'article'
        }
    ];

    const currentUrl = parse(root.location.href);
    currentUrl.set('hash', '');
    currentUrl.set('query', '');

    meta.push({
        property: 'og:locale',
        content: locale
    });

    meta.push({
        property: 'og:image',
        content: image || defaultImage
    });
    meta.push({
        name: 'twitter:image',
        content: image || defaultImage
    });

    const link = [
        {
            rel: 'canonical',
            href: currentUrl.toString()
        }
    ];

    meta.push({
        property: 'og:url',
        content: currentUrl.toString()
    });

    return {link, meta, title};
};

interface StructuredArticleDataProps {
    name: string;
    description?: string;
    images?: string[];
    releaseDate?: string;
    category?: string;
    currencyCode?: string;
    price?: string;
    id: string;
    rating: {
        averageRating: number;
        total: number;
    };
}

export const getHelmetStructuredData = (props: StructuredArticleDataProps) => {
    const {
        name: rawName = '',
        description: rawDescription = '',
        releaseDate = '',
        category = '',
        rating,
        currencyCode = '',
        price = '',
        id = ''
    } = props;
    const images = props.images || [];
    const name = sanitizeHtml(rawName);
    const description = sanitizeHtml(rawDescription);

    const currentUrl = parse(root.location.href);
    currentUrl.set('hash', '');
    currentUrl.set('query', '');

    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        sku: id,
        productID: id,
        name,
        category,
        image: images,
        description,
        releaseDate,
        brand: {
            '@type': 'Brand',
            name: 'Unreal Engine',
            logo: {
                '@type': 'ImageObject',
                url:
                    'https://cdn2.unrealengine.com/Unreal+Engine%2Flogos%2FUnreal_Engine_Black-1125x1280-cfa228c80703d4ffbd1cc05eabd5ed380818da45.png'
            }
        }
    };

    if (price && currencyCode) {
        structuredData.offers = {
            '@type': 'Offer',
            price: price.replace(/\$/g, ''),
            availability: 'http://schema.org/InStock',
            priceCurrency: currencyCode,
            url: currentUrl.toString()
        };
    }

    if (rating && rating.averageRating && rating.total) {
        structuredData.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: rating.averageRating,
            reviewCount: rating.total
        };
    }

    return [
        {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(structuredData)
        }
    ];
};

export const getCanonicalUrl = () => {
    // removes querystring and locale to help crawlers aggregate
    const locale = getLocale();
    return root.hasOwnProperty('location')
        ? root.location.href.replace(root.location.search, '').replace(`${locale}/`, 'en-US/')
        : '';
};

export const getCmsSlugForTagQuery = (query: string) => {
    const tagMatchSlugMapping = [
        {
            match: /.*tag=75&tag=1265/,
            slug: '/industries/architecture'
        },
        {
            match: /.*tag=75&tag=2170/,
            slug: '/industries/automotive'
        },
        {
            match: /.*tag=75&tag=6269/,
            slug: '/industries/fashion'
        },
        {
            match: /.*tag=75&tag=9934/,
            slug: '/industries/film'
        },
        {
            match: /.*tag=75&tag=453/,
            slug: '/industries/training'
        },
        {
            match: /.*tag=75(?:(?!&tag=))/,
            slug: '/industries'
        },
        {
            match: /.*tag=4910(?:(?!&tag=))/,
            slug: '/free/free-for-the-month'
        },
        {
            match: /.*tag=4906(?:(?!&tag=))/,
            slug: '/free/permanently-free-collection'
        },
        {
            match: /.*tag=13960(?:(?!&tag=))/,
            slug: '/free/marketplace-collection'
        }
    ];
    for (let i = 0; i < tagMatchSlugMapping.length; i++) {
        const {match, slug} = tagMatchSlugMapping[i];
        if (match.test(query)) {
            return slug;
        }
    }
    return null;
};
