import {DefaultSharedStore, createSharedValueKey, SharedValueStoreContext} from '@epic-core/hooks';
import {AppSharedKeys, getSharedContext, setSharedContext} from 'epic-ue-shared';
import root from 'window-or-global';
import GraphQlService from 'epic-node-services/src/graphql/GraphQlService';

export const sharedStore = new DefaultSharedStore();

export async function bootstrapSharedValues(dataPreload) {
    let context = getSharedContext();
    const stores = Object.keys(AppSharedKeys);
    context = new SharedValueStoreContext(sharedStore);

    stores.forEach(store => {
        AppSharedKeys[store] = createSharedValueKey(store, dataPreload[store] || {});
        context.set(AppSharedKeys[store], dataPreload[store] || {});
    });

    setSharedContext(context);
}

export const useClientData = () => {};

export const configureServices = () => {
    const envConfig = root.clientEnvConfig;
    if (envConfig) {
        const services = [GraphQlService];

        for (const service in services) {
            if (!services.hasOwnProperty(service)) continue;
            services[service].configure(envConfig);
        }
    }
};
