import {fork} from 'redux-saga/effects';
import AssetSaga from './AssetSaga';
import LauncherIntegrationSaga from './LauncherIntegrationSaga';
import CmsSaga from './CmsSaga';
import ConfigSaga from './ConfigSaga';
import ShoppingCartSaga from './ShoppingCartSaga';
import ReviewSaga from './ReviewSaga';
import TraderSaga from './TraderSaga';
import UploadSaga from './UploadSaga';

export default function* sagas() {
    yield fork(AssetSaga);
    yield fork(LauncherIntegrationSaga);
    yield fork(CmsSaga);
    yield fork(ConfigSaga);
    yield fork(ShoppingCartSaga);
    yield fork(ReviewSaga);
    yield fork(TraderSaga);
    yield fork(UploadSaga);
}
