/**
 * Created by Kaku.Guo on 2018/10/29.
 */
import {generateAsyncActions} from '../utils';

const TYPES = {
    SET_ASSETS_OWNED: 'SET_ASSETS_OWNED',
    UPDATE_ASSET_RATING: 'UPDATE_ASSET_RATING',
    ...generateAsyncActions(
        'LIST_ASSETS',
        'SUGGEST_ASSETS',
        'GET_HOME_DATA',
        'GET_ASSET',
        'GET_HELMET',
        'VAULT_ASSETS',
        'SELLER_ASSETS'
    )
};

export default {
    TYPES,
    listAssets: payload => ({
        type: TYPES.LIST_ASSETS_PENDING,
        payload
    }),
    listAssetsSuccess: payload => ({
        type: TYPES.LIST_ASSETS_SUCCESS,
        payload
    }),
    listAssetsFailed: payload => ({
        type: TYPES.LIST_ASSETS_FAILED,
        payload
    }),
    suggestAssets: payload => ({
        type: TYPES.SUGGEST_ASSETS_PENDING,
        payload
    }),
    suggestAssetsSuccess: payload => ({
        type: TYPES.SUGGEST_ASSETS_SUCCESS,
        payload
    }),
    suggestAssetsFailed: payload => ({
        type: TYPES.SUGGEST_ASSETS_FAILED,
        payload
    }),
    vaultAssets: payload => ({
        type: TYPES.VAULT_ASSETS_PENDING,
        payload
    }),
    vaultAssetsSuccess: payload => ({
        type: TYPES.VAULT_ASSETS_SUCCESS,
        payload
    }),
    vaultAssetsFailed: payload => ({
        type: TYPES.VAULT_ASSETS_FAILED,
        payload
    }),
    getHomeData: payload => ({
        type: TYPES.GET_HOME_DATA_PENDING,
        payload
    }),
    getHomeDataSuccess: payload => ({
        type: TYPES.GET_HOME_DATA_SUCCESS,
        payload
    }),
    getHomeDataFailed: payload => ({
        type: TYPES.GET_HOME_DATA_FAILED,
        payload
    }),
    getHelmet: payload => ({
        type: TYPES.GET_HELMET_PENDING,
        payload
    }),
    getHelmetSuccess: payload => ({
        type: TYPES.GET_HELMET_SUCCESS,
        payload
    }),
    getHelmetFailed: payload => ({
        type: TYPES.GET_HELMET_FAILED,
        payload
    }),
    getAsset: payload => ({
        type: TYPES.GET_ASSET_PENDING,
        payload
    }),
    getAssetSuccess: payload => ({
        type: TYPES.GET_ASSET_SUCCESS,
        payload
    }),
    getAssetFailed: payload => ({
        type: TYPES.GET_ASSET_FAILED,
        payload
    }),
    setAssetsOwned: payload => ({
        type: TYPES.SET_ASSETS_OWNED,
        payload
    }),
    sellerAssets: payload => ({
        type: TYPES.SELLER_ASSETS_PENDING,
        payload
    }),
    sellerAssetsSuccess: payload => ({
        type: TYPES.SELLER_ASSETS_SUCCESS,
        payload
    }),
    sellerAssetsFailed: payload => ({
        type: TYPES.SELLER_ASSETS_FAILED,
        payload
    }),
    updateRating: payload => ({
        type: TYPES.UPDATE_ASSET_RATING,
        payload
    })
};
