import React from 'react';
import PropTypes from 'prop-types';
import {connectToStores} from 'epic-alt-utils/index';
import CommentItem from './CommentItem';
import { CommentStore } from 'epic-comment-rating/app/scripts/stores';
import { CommentActions, I18nActions } from 'epic-comment-rating/app/scripts/actions';
import root from 'window-or-global';
import { Message, SortSelect, getMessage } from '../index';

/* eslint-disable jsx-a11y/href-no-hash */

const SORT_OPTIONS = [
    { label: 'messages.com.epicgames.plugin.store.filter.newest_first', value: 'createdAt', dir: 'DESC' },
    { label: 'messages.com.epicgames.plugin.store.filter.oldest_first', value: 'createdAt', dir: 'ASC' },
    { label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_desc', value: 'upVoteCount', dir: 'DESC' },
    { label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_asc', value: 'upVoteCount', dir: 'ASC' }
];

@connectToStores
export default class CommentMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            sortBy: 'createdAt',
            sortDir: this.props.sortDir || 'DESC'
        };
    }

    static getStores() {
        return [CommentStore];
    }

    static getPropsFromStores() {
        return {
            commentStore: CommentStore.getState()
        };
    }

    static propTypes = {
        seller: PropTypes.object,
        commentObjectId: PropTypes.string,
        commentNamespace: PropTypes.string,
        replyInitCount: PropTypes.string,
        paginationCount: PropTypes.string,
        replyAppendCount: PropTypes.string,
        commentStore: PropTypes.object,
        enableMarkdown: PropTypes.bool.isRequired,
        enableAvatar: PropTypes.bool.isRequired,
        enableTimeAgo: PropTypes.bool,
        paginationType: PropTypes.string,
        showSort: PropTypes.bool,
        sortDir: PropTypes.string,
        messages: PropTypes.object
    };

    componentDidMount() {
        this.init();
        if (!root.__server_side_render) {
            I18nActions.getI18nMessage.defer();
            if (this.props.enableAvatar) {
                CommentActions.getUserAvatar.defer();
            }
        }
    }

    init(nextProps) {
        const props = nextProps || this.props;
        const {commentObjectId} = props;
        const currentComment = props.commentStore.commentMap[commentObjectId] || {};
        if (!currentComment.loaded) {
            const data = {
                commentObjectId,
                commentNamespace: props.commentNamespace,
                paginationCount: props.paginationCount,
                offset: 0,
                sortBy: this.state.sortBy,
                sortDir: this.state.sortDir,
                replyCount: props.replyInitCount
            };
            CommentActions.getCommentCount.defer({
                commentObjectId,
                commentNamespace: props.commentNamespace
            });
            CommentActions.getCommentList.defer(data);
        }
    }

    renderToNext = () => {
        const newOffset = parseInt(this.state.offset, 10) + parseInt(this.props.paginationCount, 10);
        this.setState({ offset: newOffset });
        const data = {
            commentObjectId: this.props.commentObjectId,
            commentNamespace: this.props.commentNamespace,
            paginationCount: this.props.paginationCount,
            offset: newOffset,
            sortBy: this.state.sortBy,
            sortDir: this.state.sortDir
        };
        CommentActions.getCommentList(data);
    };

    renderToPrev = () => {
        const newOffset = parseInt(this.state.offset, 10) - parseInt(this.props.paginationCount, 10);
        this.setState({ offset: newOffset });
        const data = {
            commentObjectId: this.props.commentObjectId,
            commentNamespace: this.props.commentNamespace,
            paginationCount: this.props.paginationCount,
            offset: newOffset,
            sortBy: this.state.sortBy,
            sortDir: this.state.sortDir
        };
        CommentActions.getCommentList(data);
    };

    loadMoreComment = () => {
        const newOffset = parseInt(this.state.offset, 10) + parseInt(this.props.paginationCount, 10);
        this.setState({ offset: newOffset });
        const data = {
            commentObjectId: this.props.commentObjectId,
            commentNamespace: this.props.commentNamespace,
            paginationCount: this.props.paginationCount,
            offset: newOffset,
            sortBy: this.state.sortBy,
            sortDir: this.state.sortDir,
            replyCount: this.props.replyInitCount,
            append: true
        };
        CommentActions.getCommentList(data);
    };

    getSortedComments = ({ sortBy, sortDir }) => {
        if (!(sortBy === this.state.sortBy && sortDir === this.state.sortDir)) {
            this.setState({
                sortBy,
                sortDir,
                offset: 0
            });
            const paramData = {
                commentObjectId: this.props.commentObjectId,
                commentNamespace: this.props.commentNamespace,
                paginationCount: this.props.paginationCount,
                offset: 0,
                sortBy,
                sortDir
            };
            CommentActions.getCommentList(paramData);
        }
    };

    render() {
        const { commentObjectId, seller, commentStore = {}, messages, commentNamespace,
            replyInitCount, replyAppendCount, enableMarkdown, enableAvatar, enableTimeAgo } = this.props;
        const { sortBy, sortDir, offset } = this.state;
        const { commentMap } = commentStore;

        const commentData = commentMap[commentObjectId] || {};
        // detect if reply is loading by listening changes to the addReplyCommentId, deleteRelyCommentId and updateReplyCommentId
        const loadingReplyId = parseInt(commentData.addReplyCommentId || commentData.deleteReplyCommentId
                                || commentData.updatedReplyCommentId || commentData.getReplyCommentId, 10);
        const { hasNext, loading, commentList = [] } = commentData;
        const hasPrev = offset > 0;
        let nextButtonStyle = {
            display: 'none'
        };
        let prevButtonStyle = {
            display: 'none'
        };
        if (hasNext && !loading) {
            nextButtonStyle = { display: 'inline-block' };
        }
        if (hasPrev && !loading) {
            prevButtonStyle = { display: 'inline-block' };
        }
        let loadingStyle = {};
        const comments = commentList.length ? commentList.map((comment) => {
            return (
                <CommentItem
                    seller={seller}
                    key={`${commentObjectId}-${comment.id}`}
                    comment={comment}
                    commentObjectId={commentObjectId}
                    commentNamespace={commentNamespace}
                    replyInitCount={replyInitCount}
                    replyAppendCount={replyAppendCount}
                    enableMarkdown={enableMarkdown}
                    enableAvatar={enableAvatar}
                    enableTimeAgo={enableTimeAgo}
                    loadingReplyId={loadingReplyId}
                />
            );
        }) : (
            <Message code="messages.com.epicgames.plugin.commentrating.comments.no_comment" />
        );
        if (!loading) {
            loadingStyle = {
                display: 'none'
            };
        }

        let sortBox;
        const selectOptions = SORT_OPTIONS.map(it => ({ text: getMessage(messages, it.label), value: `${it.value}_${it.dir}` }));
        if (this.props.showSort) {
            sortBox = (
                <SortSelect
                    title={getMessage(messages, 'messages.com.epicgames.plugin.store.asset.comment.sort.title')}
                    options={selectOptions}
                    value={`${sortBy}_${sortDir}`}
                    onChange={this.getSortedComments}
                />
            );
        }

        let pagination;
        if (this.props.paginationType === 'loadMore') {
            pagination = (
                <section className="pagination">
                    <button
                        className="btn btn-primary comment-load-more"
                        style={nextButtonStyle}
                        onClick={this.loadMoreComment}>
                        <Message code="messages.com.epicgames.plugin.commentrating.comment.reply.more" />
                    </button>
                </section>
            );
        } else {
            pagination = (
                <section className="pagination">
                    <span role="listitem" style={nextButtonStyle} onClick={this.renderToNext}>
                        <Message code="messages.com.epicgames.plugin.commentrating.paginate.next" />
                    </span>
                    <span role="listitem" style={prevButtonStyle} onClick={this.renderToPrev}>
                        <Message code="messages.com.epicgames.plugin.commentrating.paginate.prev" />
                    </span>
                </section>
            );
        }

        return (
            <div className="comment-rating-box">
                <section className="comments">
                    <div className="comments-container">
                        {sortBox}
                        <div className="comment-item">
                            {comments}
                        </div>
                    </div>
                    <div className="loading-svg" style={loadingStyle} />
                </section>
                {pagination}
            </div>
        );
    }
}
