import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Review, getMessage, ReviewItem} from '../../index';
import {ReviewActions} from '../../../actions';
import {REVIEW_TYPES} from '../../../constants';
import {hasValue} from '../../../utils';

const mapDispatchToProps = dispatch => ({
    getReviews: model => dispatch(ReviewActions.getReviews(model))
});

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const reviewData = state.get('review').toJS();
    const {data} = state
        .get('asset')
        .get('data')
        .toJS();
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    const globalBase = state.get('config').get('globalBase');
    return {
        messages,
        reviewData,
        assetData: data,
        isLoggedIn,
        isPostingAllowed,
        accountId: accountInfo.id,
        globalBase
    };
};

const SORT_OPTIONS = [
    {
        label: 'messages.com.epicgames.plugin.store.filter.newest_first',
        value: 'CREATEDAT',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.filter.oldest_first',
        value: 'CREATEDAT',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_desc',
        value: 'HELPFULNUMBER',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_asc',
        value: 'HELPFULNUMBER',
        dir: 'ASC'
    }
];

const AssetQuestions = props => {
    const type = REVIEW_TYPES.QUESTION;
    const {
        messages,
        assetData = {},
        reviewData = {},
        accountId,
        getReviews,
        showLegacyComments,
        preventScroll
    } = props;
    const {id, owned, seller = {}, customAttributes = {}} = assetData;
    let buyLink = customAttributes.BuyLink;
    buyLink = buyLink ? buyLink.value : null;
    const sellerId = seller.owner;
    const {data = {}} = reviewData;
    const selectOptions = SORT_OPTIONS.map(it => ({
        text: getMessage(messages, it.label),
        value: `${it.value}_${it.dir}`
    }));

    const onSearch = params => {
        if (id) {
            getReviews({...params, type, offerId: id});
        }
    };

    const renderList = () => {
        const {elements = []} = data;
        //for external product, only the seller can reply to a question
        const isReplyEnabled = (buyLink && sellerId === accountId) || (!buyLink && !!owned);
        return elements.map((it, idx) => (
            <ReviewItem type={type} key={`review-item-${idx}`} data={{isReplyEnabled, ...it}} />
        ));
    };

    const preventScrollVal = !hasValue(assetData) || preventScroll;

    return (
        <Review
            onSearch={onSearch}
            preventScroll={preventScrollVal}
            isLegacyCommentsEnabled={false}
            showLegacyComments={showLegacyComments}
            type={type}
            messages={messages}
            reviewData={reviewData}
            selectOptions={selectOptions}>
            {renderList()}
        </Review>
    );
};

AssetQuestions.propTypes = {
    accountId: PropTypes.string,
    messages: PropTypes.object,
    assetData: PropTypes.object,
    reviewData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    getReviews: PropTypes.func,
    addReview: PropTypes.func,
    uploadImages: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    showLegacyComments: PropTypes.func,
    globalBase: PropTypes.string,
    preventScroll: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetQuestions);
