import {fromJS} from 'immutable';
import {combineReducers} from 'redux-immutable';
import {reducer as form} from 'redux-form/immutable';
import {
    localization,
    bootstrap as bootstrapLocalization
} from '@epic-core/localization/dist/client/reducers/localization.reducer';
import user from './UserReducer';
import cms from './CmsReducer';
import config from './ConfigReducer';
import asset from './AssetReducer';
import launcherIntegration from './LauncherIntegrationReducer';
import page from './PageReducer';
import shoppingCart from './ShoppingCartReducer';
import error from './ErrorReducer';
import review from './ReviewReducer';
import reviewDialog from './ReviewDialogReducer';
import traderDialog from './TraderDialogReducer';
import upload from './UploadReducer';

const reducers = {
    form,
    localization,
    user,
    cms,
    config,
    asset,
    launcherIntegration,
    page,
    shoppingCart,
    error,
    review,
    reviewDialog,
    traderDialog,
    upload,
    HeaderStore: (state = {}) => state,
    FooterStore: (state = {}) => state
};
const bootstrapReducers = [bootstrapLocalization];

export function bootstrap(dataPreload) {
    let bootstrapState = fromJS({});
    for (const bootstrapReducer of bootstrapReducers) {
        bootstrapState = bootstrapState.merge(bootstrapReducer(dataPreload));
    }
    bootstrapState = bootstrapState.merge({
        config: dataPreload.config,
        cms: dataPreload.cms,
        user: dataPreload.user,
        shoppingCart: dataPreload.shoppingCart,
        HeaderStore: dataPreload.HeaderStore,
        FooterStore: dataPreload.FooterStore
    });
    return bootstrapState;
}

export function createReducer(extraReducers) {
    return combineReducers({
        ...reducers,
        ...extraReducers
    });
}
