import {UnrealEngineFooter} from '@wf-components/footer-react';
import React from 'react';

import {getLocale} from '@epic-core/common';

import root from 'window-or-global';

export const GlobalFooterWrapper = (): JSX.Element | null => {
    const locale = getLocale();

    const clientConfig = root.clientEnvConfig || {};
    const serverUrl = clientConfig.EPIC_SERVER_URL;

    return <UnrealEngineFooter domain={serverUrl} locale={locale} disableNewsletter />;
};
