/**
 * Created by Kaku.Guo on 2019/5/22.
 */
import {fromJS} from 'immutable';
import typeToReducer from 'type-to-reducer';
import {ReviewActions} from '../actions';
import {getError, hasValue} from '../utils';
import {getDefaultReviewParams} from '../constants';

const {
    GET_REVIEW_DETAIL,
    GET_REVIEW_DETAIL_BY_ACCOUNT,
    GET_REVIEWS,
    GET_REVIEW_REPLIES,
    SHOW_MORE_REPLIES,
    VOTE_REVIEW,
    GET_RATING,
    RESET_LIST_PARAMS
} = ReviewActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isLoading: false,
    isLoadingReply: false,
    isLoadingRating: false,
    isLoadingReview: false,
    isLoadingDetail: false,
    isVoting: false,
    activeId: null,
    activeData: {},
    data: {},
    detail: {},
    rating: {},
    error: {},
    detailError: {},
    listParams: getDefaultReviewParams()
});

const setItemUpvoted = (item, isHelpful) => {
    item.alreadyUpvote = isHelpful;
    if (isHelpful) {
        item.helpfulNum = item.helpfulNum ? item.helpfulNum + 1 : 1;
    } else {
        item.helpfulNum -= 1;
    }
};

const setItemVotingFailed = (item, type) => {
    item.votingError = {
        hasError: true,
        type
    };
};

const resetItemVotingFailed = item => {
    item.votingError = {};
};

export default typeToReducer(
    {
        [GET_REVIEWS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    data: {},
                    error: {},
                    listParams: action.payload
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    data: action.payload,
                    error: {}
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        },
        [GET_REVIEW_DETAIL]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingReview: true,
                    activeId: action.payload.topicId,
                    activeData: {},
                    error: {},
                    detailError: {}
                }),
            SUCCESS: (state, action) => {
                const {topicId, data} = action.payload;
                if (data.hasError) {
                    return state.merge({isLoadingReview: false, activeData: data});
                }
                const {elements} = state.get('data').toJS();
                let nextElements = elements || [];
                if (nextElements.length) {
                    nextElements = nextElements.map(it => {
                        return it.id === topicId ? data : it;
                    });
                }
                return state
                    .merge({isLoadingReview: false, activeId: null, activeData: data})
                    .setIn(['data', 'elements'], nextElements);
            },
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    detailError: getError(action.payload)
                })
        },
        [GET_REVIEW_DETAIL_BY_ACCOUNT]: {
            PENDING: (state, action) =>
                state.merge({
                    detail: {},
                    isLoadingDetail: true
                }),
            SUCCESS: (state, action) => {
                return state.merge({
                    detail: action.payload,
                    isLoadingDetail: false
                });
            },
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoadingDetail: false
                })
        },
        [GET_REVIEW_REPLIES]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingReply: true,
                    activeId: action.payload.topicId,
                    error: {},
                    detailError: {}
                }),
            SUCCESS: (state, action) => {
                const {topicId, data = {}} = action.payload;
                const {elements} = state.get('data').toJS();
                const activeData = state.get('activeData').toJS();
                const updateReplies = item => {
                    if (item && item.id === topicId) {
                        item.replyNum = data.paging.total;
                        item.replies = data.elements;
                        item.replyPaging = data.paging;
                    }
                };
                elements.forEach(updateReplies);
                updateReplies(activeData);
                return state
                    .merge({isLoadingReply: false, activeId: null, activeData})
                    .setIn(['data', 'elements'], elements);
            },
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    detailError: getError(action.payload),
                    isLoadingReply: false,
                    activeId: null
                })
        },
        [SHOW_MORE_REPLIES]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingReply: true,
                    activeId: action.payload.topicId,
                    error: {},
                    detailError: {}
                }),
            SUCCESS: (state, action) => {
                const {topicId, data = {}} = action.payload;
                const {elements} = state.get('data').toJS();
                const activeData = state.get('activeData').toJS();
                const updateReplies = item => {
                    if (item && item.id === topicId) {
                        item.replyNum = data.paging.total;
                        item.replies = [...item.replies, ...data.elements];
                        item.replyPaging = data.paging;
                    }
                };
                elements.forEach(updateReplies);
                updateReplies(activeData);
                return state
                    .merge({isLoadingReply: false, activeId: null, activeData})
                    .setIn(['data', 'elements'], elements);
            },
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    detailError: getError(action.payload),
                    isLoadingReply: false,
                    activeId: null
                })
        },
        [VOTE_REVIEW]: {
            PENDING: (state, action) =>
                state.merge({
                    isVoting: true,
                    voteError: false,
                    detailError: {}
                }),
            SUCCESS: (state, action) => {
                const {id, type, topicId, isHelpful} = action.payload;
                const {elements} = state.get('data').toJS();
                const activeData = state.get('activeData').toJS();
                const nextElements = elements.map(it => {
                    if (hasValue(it.votingError)) {
                        resetItemVotingFailed(it);
                    }
                    if (type === 'replies') {
                        //should consider best reply and publisher reply
                        if (it.id === topicId) {
                            if (it.bestReply && it.bestReply.id === id) {
                                setItemUpvoted(it.bestReply, isHelpful);
                            }
                            if (it.publisherReply && it.publisherReply.id === id) {
                                setItemUpvoted(it.publisherReply, isHelpful);
                            }
                            if (it.replies && it.replies.length) {
                                it.replies = it.replies.map(item => {
                                    if (item.id === id) {
                                        setItemUpvoted(item, isHelpful);
                                    }
                                    return item;
                                });
                            }
                        }
                        return it;
                    }
                    if (it.id === id) {
                        setItemUpvoted(it, isHelpful);
                    }
                    return it;
                });
                if (hasValue(activeData)) {
                    if (activeData.id === id) {
                        setItemUpvoted(activeData, isHelpful);
                    } else if (activeData.replies && activeData.replies.length) {
                        activeData.replies = activeData.replies.map(item => {
                            if (item.id === id) {
                                setItemUpvoted(item, isHelpful);
                            }
                            return item;
                        });
                    }
                }
                return state
                    .merge({activeData, isVoting: false})
                    .setIn(['data', 'elements'], nextElements);
            },
            FAILED: (state, action) => {
                const {id, type, topicId} = action.payload;
                const {elements} = state.get('data').toJS();
                const activeData = state.get('activeData').toJS();
                const nextElements = elements.map(it => {
                    if (type === 'replies') {
                        if (it.id === topicId) {
                            if (it.bestReply && it.bestReply.id === id) {
                                setItemVotingFailed(it, 'reply');
                            }
                            if (it.publisherReply && it.publisherReply.id === id) {
                                setItemVotingFailed(it, 'reply');
                            }
                            if (it.replies && it.replies.length) {
                                it.replies = it.replies.map(item => {
                                    if (item.id === id) {
                                        setItemVotingFailed(it, 'reply');
                                    }
                                    return item;
                                });
                            }
                        }
                        return it;
                    }
                    if (it.id === id) {
                        setItemVotingFailed(it, 'review');
                    }
                    return it;
                });
                if (hasValue(activeData)) {
                    if (activeData.id === id) {
                        setItemVotingFailed(activeData, 'review');
                    } else if (activeData.replies && activeData.replies.length) {
                        activeData.replies = activeData.replies.map(item => {
                            if (item.id === id) {
                                setItemVotingFailed(item, 'reply');
                            }
                            return item;
                        });
                    }
                }
                return state
                    .merge({activeData, isVoting: false})
                    .setIn(['data', 'elements'], nextElements);
            }
        },
        [GET_RATING]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingRating: true,
                    rating: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoadingRating: false,
                    rating: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    isLoadingRating: false
                })
        },
        [RESET_LIST_PARAMS]: state =>
            state.merge({
                listParams: getDefaultReviewParams()
            })
    },
    DEFAULT_STATE
);
