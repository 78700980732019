/**
 * Created by Kaku.Guo on 2019/2/2.
 */
class CustomizedHistory {
    stack = null;
    defaultLocation = null;
    constructor(location) {
        this.stack = [];
        this.defaultLocation = location;
    }

    push = (location) => {
        this.stack.push(location);
    };

    pop = () => {
        this.stack.pop();
        const last = this.stack.pop();
        if (last) {
            return last;
        }
        return this.defaultLocation;
    }
}
export default CustomizedHistory;