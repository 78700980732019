/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AssetTileImage from './AssetTileImage';
import AssetTileInfo from './AssetTileInfo';
import AssetTileRating from './AssetTileRating';
import AssetTilePrice from './AssetTilePrice';
import AssetTileDetail from './AssetTileDetail';

const AssetTile = props => {
    const {
        data: {klass, owned},
        isLearnAsset,
        isCompact,
        index = 0
    } = props;

    const {customAttributes} = props.data;
    const hasBuyLink = customAttributes && 'BuyLink' in customAttributes;

    const classNames = cn(
        'asset-container',
        isLearnAsset ? 'learn' : 'catalog',
        isCompact ? 'compact' : 'asset-full'
    );
    const childClass = cn('asset', klass, owned && 'asset--owned', index % 2 !== 0 && 'odd');
    return (
        <div className={classNames}>
            <article className={childClass}>
                <AssetTileImage {...props} />
                <AssetTileInfo {...props}>
                    {hasBuyLink ? (
                        <div className="buylink-spacer" />
                    ) : (
                        <>
                            <AssetTileRating {...props} />
                            <AssetTilePrice {...props} />
                        </>
                    )}
                    <AssetTileDetail {...props} />
                </AssetTileInfo>
            </article>
        </div>
    );
};
AssetTile.propTypes = {
    isLearnAsset: PropTypes.bool,
    isCompact: PropTypes.bool,
    data: PropTypes.object,
    index: PropTypes.number
};
export default AssetTile;
