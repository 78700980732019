import GraphQlService from 'epic-node-services/src/graphql/GraphQlService';
import axios from 'axios';
import {CouponDetails} from './types';
import Url from '../../constants/Url';

export const getCouponsQuery = `
    query getCoupons($currencyCountry: String!, $identityId: String!, $locale: String, $merchantGroup: String) {
        CodeRedemption {
            coupons(currencyCountry: $currencyCountry, identityId: $identityId, merchantGroup: $merchantGroup, includeSalesEventInfo: true) {
                code
                codeStatus
                codeType
                useCount
                maxNumberOfUses
                consumptionMetadata {
                    amountDisplay {
                      amount
                      currency
                      placement
                      symbol
                    }
                    minSalesPriceDisplay {
                      amount
                      currency
                      placement
                      symbol
                    }
                }
                endDate
                namespace
                salesEvent(locale: $locale) {
                    eventName
                    eventSlug
                    voucherImages {
                      type
                      url
                    }
                    voucherLink
                }
                startDate
            }
        }
    }
`;

interface CodeRedemption {
    coupons: CouponDetails[];
}

interface CouponsQueryRes {
    data: {
        CodeRedemption: CodeRedemption;
    };
}

class CouponApi {
    public async getCoupons({
        countryCode: currencyCountry = 'US',
        identityId,
        locale = 'en-US',
        merchantGroup = 'UE_MKT'
    }: {
        countryCode?: string;
        identityId: string;
        locale?: string;
        merchantGroup?: string;
    }): Promise<CouponDetails[]> {
        try {
            const response: CouponsQueryRes = await GraphQlService.query(getCouponsQuery, {
                currencyCountry,
                identityId,
                locale,
                merchantGroup
            });
            const coupons = response.data.CodeRedemption.coupons;
            return coupons;
        } catch (e) {
            console.error('getCoupins() failed to load');
            throw e;
        }
    }

    public async getCouponDetail(slug: string) {
        try {
            const resp = await axios.get(Url().COUPON.GET_DETAIL(slug));
            return resp.data.data;
        } catch (ex) {
            console.error('getCouponDetail() failed to load');
            throw ex;
        }
    }
}

export const couponApi = new CouponApi();
