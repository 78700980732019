/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Message} from '../index';

const AssetTilePrice = props => {
    const {
        data: {
            customAttributes,
            price = '',
            discounted,
            discountPercentage,
            priceValue,
            discountPrice
        },
        isLearnAsset
    } = props;

    let discountedAssetPrice = discounted ? discountPrice : '';
    if (discountedAssetPrice === '$0.00') {
        discountedAssetPrice = (
            <Message
                className="asset-price"
                code="messages.com.epicgames.plugin.store.asset.free"
            />
        );
    }

    return (
        <div className={`price-container ${discounted ? 'price-discount-container' : ''}`}>
            {customAttributes && customAttributes.BuyLink ? (
                <Message
                    className="asset-price"
                    code="messages.com.epicgames.plugin.store.asset.vendor_provide"
                />
            ) : (
                !isLearnAsset &&
                price !== '' && (
                    <Fragment>
                        {discounted && (
                            <span className="asset-discount-note">
                                <Message
                                    className="asset-discount-percentage"
                                    code="messages.com.epicgames.plugin.store.asset.discount_off"
                                    args={[100 - discountPercentage]}
                                />
                            </span>
                        )}
                        {priceValue === 0 ? (
                            <Message
                                className="asset-price"
                                code="messages.com.epicgames.plugin.store.asset.free"
                            />
                        ) : (
                            <span className="asset-price asset-discount-price-wrapper">
                                <span className={discounted ? 'asset-discount-price' : ''}>
                                    {price}
                                </span>
                                {discountedAssetPrice}
                            </span>
                        )}
                    </Fragment>
                )
            )}
        </div>
    );
};

AssetTilePrice.propTypes = {
    isLearnAsset: PropTypes.bool,
    data: PropTypes.object
};
export default AssetTilePrice;
