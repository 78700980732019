/**
 * Created by Kaku.Guo on 2019/5/16.
 */
/* eslint-disable react/prop-types*/
import React, {useState, useRef, useEffect, useImperativeHandle, forwardRef} from 'react';
import cn from 'classnames';
import {KEY_BACKSPACE} from '../../../constants';
import CustomToolBar from './customToolBar/CustomToolBar';
import root from 'window-or-global';

const EMPTY_STRING = '<p><br></p>';

function RichTextEditor(props, ref) {
    const {
        placeholder = '',
        value: defaultValue,
        className,
        maxLength = 3000,
        required,
        isDisabled,
        validateError,
        clearValidator
    } = props;
    const [value, setValue] = useState(defaultValue || '');
    const [errorMessage, setErrorMessage] = useState(validateError);
    useEffect(() => {
        setValue(defaultValue || '');
    }, [defaultValue]);
    useEffect(() => {
        setErrorMessage(validateError);
    }, [validateError]);
    const valueLength = value ? value.replace(/<[^>]+>/g, '').length : 0;
    //https://github.com/zenoamaro/react-quill/issues/122
    const ReactQuill = require('react-quill'); //eslint-disable-line
    const CustomTheme = require('./customTheme/CustomTheme'); //eslint-disable-line
    ReactQuill.Quill.register('themes/custom', CustomTheme.default(), true);

    const quillRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => value,
        getEditor: () => quillRef.current.getEditor()
    }));
    useEffect(() => {
        if (root.document.activeElement) {
            setTimeout(() => {
                root.document.activeElement.blur();
            });
        }
    }, []);

    useEffect(() => {
        if (quillRef && quillRef.current) {
            try {
                const {handler} = quillRef.current
                    .getEditor()
                    .keyboard.bindings[KEY_BACKSPACE].find(it => it.collapsed && it.prefix);
                quillRef.current
                    .getEditor()
                    .keyboard.addBinding({key: KEY_BACKSPACE, collapsed: true, handler});
            } catch (e) {
                console.error('RichTextEditor: failed to add keyboard binding: ', e);
            }
        }
    }, [quillRef]);

    const classNames = cn(
        'text-editor',
        className,
        isDisabled && 'disabled',
        errorMessage && 'error'
    );

    const handleChange = content => {
        let val = content;
        if (content.startsWith(EMPTY_STRING)) {
            val = content.replace(EMPTY_STRING, '');
        }
        setValue(val);
        clearValidator();
    };
    const onDragOver = event => {
        event.stopPropagation();
        event.preventDefault();
    };

    const formats = [
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'link',
        'list',
        'bullet'
    ];

    const placeHolder = required && placeholder ? `* ${placeholder}` : placeholder;

    return (
        <div className={classNames}>
            <div className="text-editor__container" onDragOver={onDragOver}>
                <div className="character-amount">
                    {valueLength}/{maxLength}
                </div>
                <CustomToolBar />
                <ReactQuill
                    ref={quillRef}
                    className="rich-text-content"
                    placeholder={placeHolder}
                    value={value}
                    readOnly={isDisabled}
                    formats={formats}
                    theme="custom"
                    preserveWhitespace={true}
                    modules={{
                        toolbar: {
                            container: '#custom-toolbar'
                        },
                        clipboard: {
                            matchVisual: false
                        }
                    }}
                    onChange={handleChange}
                />
            </div>
            <div className="text-editor--error">{errorMessage}</div>
        </div>
    );
}

export default forwardRef(RichTextEditor);
