/**
 * Created by Kaku.Guo on 2019/5/28.
 */
import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'react-redux';
import {ModalDialog, Button, getMessage, SummaryInput, RichTextEditor, ImageList} from '../index';
import {ReviewDialogActions} from '../../actions';
import {runRule, hasValue, formatTextInput, formatWhiteSpaces, parseHTML} from '../../utils';
import root from 'window-or-global';

const mapDispatchToProps = dispatch => {
    return {
        hideReviewDialog: () => dispatch(ReviewDialogActions.hide(true))
    };
};

const REL_FILTER = ['noreferrer', 'noopener'];

const mapStateToProps = state => {
    const {locale, messageProperties} = state.get('localization').toJS();
    const {data = {}} = state
        .get('asset')
        .get('data')
        .toJS();
    const currentUserName = state
        .get('user')
        .get('accountInfo')
        .get('displayName');
    const options = state.get('reviewDialog').toJS();
    const uploadQueue = state
        .get('upload')
        .get('queue')
        .toJS();
    const isLauncher = state.get('config').get('isLauncher');
    const isImageFeatureEnabled = state.get('config').get('isImageFeatureEnabled');
    const {owned} = data;
    return {
        locale,
        messages: messageProperties,
        owned,
        currentUserName,
        options,
        uploadQueue,
        isLauncher,
        isImageFeatureEnabled
    };
};

function ReviewDialog(props) {
    const {
        options = {},
        messages,
        locale,
        owned,
        currentUserName,
        hideReviewDialog,
        uploadQueue,
        isLauncher,
        isImageFeatureEnabled
    } = props;
    const {
        isVisible,
        defaultValue = {},
        validations,
        isLoading,
        error,
        className,
        headerText,
        saveText,
        contentText,
        isSummaryEnable,
        isRatingEnable = false,
        summaryPlaceholder,
        isDescEnable,
        isDescRequired,
        descPlaceholder,
        isImageEnable,
        contentMaxLength,
        onSave,
        onPreload,
        preventSubmit,
        isShowCancel
    } = options;
    const {title, content, images, rating} = defaultValue;
    const summaryRef = useRef(null);
    const descRef = useRef(null);
    const imageRef = useRef(null);
    const [validator, setValidator] = useState({});
    useEffect(() => {
        if (isVisible && onPreload) {
            onPreload();
        }
        setValidator({});
    }, [isVisible]);

    function handlerOnFormatWhiteSpace(ref) {
        const editor = ref.current.getEditor();
        let spaceLine = 0;
        let isActive = false;
        let listType = '';
        const blocks = editor.getLines();
        // EGS-11193 Posts should have newlines/spaces trimmed from beginning and end
        const c = blocks
            .reduce((prev, next) => {
                const _line = next.domNode.innerText.trim();
                if (!_line) {
                    ++spaceLine;
                } else {
                    isActive = true;
                    spaceLine = 0;
                }
                if (spaceLine <= 2 && isActive) {
                    // EGS-28285 reviews won't show bulleted/numbered lists
                    const prevLine = parseHTML(prev[prev.length - 1]).firstElementChild;
                    const prevLineTag = prevLine ? prevLine.tagName : '';
                    const nextLineTag = next.domNode.nodeName;
                    const nextLineParentTag = next.parent.domNode.nodeName;
                    const isListStarting = prevLineTag !== 'LI' && nextLineTag === 'LI';
                    const isListTypeChanging =
                        prevLineTag === 'LI' &&
                        nextLineTag === 'LI' &&
                        listType !== nextLineParentTag;
                    const isListEnding = prevLineTag === 'LI' && nextLineTag !== 'LI';

                    if (isListStarting) {
                        if (nextLineParentTag === 'UL') {
                            prev.push('<ul>');
                            listType = 'UL';
                        } else if (nextLineParentTag === 'OL') {
                            prev.push('<ol>');
                            listType = 'OL';
                        }
                    }
                    if (isListTypeChanging) {
                        if (nextLineParentTag === 'UL') {
                            prev.push('</ol>');
                            prev.push('<ul>');
                            listType = 'UL';
                        } else if (nextLineParentTag === 'OL') {
                            prev.push('</ul>');
                            prev.push('<ol>');
                            listType = 'OL';
                        }
                    }
                    if (isListEnding) {
                        if (listType === 'UL') {
                            prev.push('</ul>');
                            listType = '';
                        } else if (listType === 'OL') {
                            prev.push('</ol>');
                            listType = '';
                        }
                    }

                    prev.push(formatWhiteSpaces(next.domNode.outerHTML));
                }
                return prev;
            }, [])
            .join('');
        const element = root.document.createElement('div');
        element.innerHTML = c;
        element.querySelectorAll('a').forEach(link => {
            REL_FILTER.forEach(filter => {
                if (!link.rel.includes(filter)) {
                    link.rel = link.rel.concat(` ${filter}`).trim();
                }
            });
        });
        return element.innerHTML;
    }

    const onSubmit = () => {
        if (onSave) {
            const value = {};
            if (summaryRef && summaryRef.current) {
                value.title = summaryRef.current.getValue();
                if (isRatingEnable) {
                    value.rating = summaryRef.current.getRating();
                }
            }
            if (descRef && descRef.current) {
                value.content = descRef.current.getValue();
            }
            if (imageRef && imageRef.current) {
                value.images = imageRef.current.getValue();
            }
            const validate = runRule(value, validations);
            setValidator(validate);
            if (!hasValue(validate)) {
                const _content =
                    hasValue(descRef) && descRef.current ? handlerOnFormatWhiteSpace(descRef) : {};
                const _title = formatTextInput(value.title);
                onSave({...value, title: _title, content: _content});
            }
        }
    };
    const isDisabled = !!isLoading;
    let errorMessage = null;
    if (error && error.errorCode) {
        errorMessage = getMessage(messages, error.errorCode);
    }
    const classNames = cn('review-dialog', className, isDisabled && 'disabled');
    return (
        <ModalDialog onClose={hideReviewDialog} isVisible={isVisible} className={classNames}>
            <div className="header">{getMessage(messages, headerText)}</div>
            <div className="content">
                {contentText && (
                    <p dangerouslySetInnerHTML={{__html: getMessage(messages, contentText)}} />
                )}
                {isSummaryEnable && (
                    <SummaryInput
                        ref={summaryRef}
                        value={title}
                        rating={rating}
                        isRatingEnable={isRatingEnable}
                        messages={messages}
                        placeholder={getMessage(messages, summaryPlaceholder)}
                        username={currentUserName}
                        locale={locale}
                        isDisabled={isDisabled}
                        clearValidator={() => setValidator({})}
                        validateError={validator.title}
                        ratingError={validator.rating}
                        isLauncher={isLauncher}
                        required
                    />
                )}
                {isDescEnable && (
                    <RichTextEditor
                        ref={descRef}
                        value={content}
                        placeholder={getMessage(messages, descPlaceholder)}
                        isDisabled={isDisabled}
                        maxLength={contentMaxLength}
                        clearValidator={() => setValidator({})}
                        validateError={validator.content}
                        required={isDescRequired}
                    />
                )}
                {isImageFeatureEnabled && isImageEnable && (
                    <ImageList
                        ref={imageRef}
                        value={images}
                        messages={messages}
                        isDisabled={isDisabled}
                        uploadDisabled={!owned}
                        uploadStatus={uploadQueue}
                        validateError={validator.images}
                    />
                )}
            </div>
            <div className="footer">
                <div className="error">{errorMessage}</div>
                <div>
                    {isLoading && <div className="loading" />}
                    <Button disabled={isDisabled || preventSubmit} onClick={onSubmit}>
                        {getMessage(messages, saveText)}
                    </Button>
                    {isShowCancel && (
                        <Button variant="gray" onClick={hideReviewDialog}>
                            {getMessage(
                                messages,
                                'messages.com.epicgames.plugin.store.asset.review.dialog.cancel'
                            )}
                        </Button>
                    )}
                </div>
            </div>
        </ModalDialog>
    );
}

ReviewDialog.propTypes = {
    options: PropTypes.object,
    messages: PropTypes.object,
    locale: PropTypes.string,
    owned: PropTypes.bool,
    currentUserName: PropTypes.string,
    hideReviewDialog: PropTypes.func,
    uploadQueue: PropTypes.object,
    isLauncher: PropTypes.bool,
    isImageFeatureEnabled: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDialog);
