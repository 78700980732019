import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Review, getMessage, ReviewItem} from '../../index';
import {ReviewActions} from '../../../actions';
import {hasValue} from '../../../utils/Utils';
import {REVIEW_TYPES} from '../../../constants';

const mapDispatchToProps = dispatch => ({
    getReviews: model => dispatch(ReviewActions.getReviews(model))
});

const mapStateToProps = state => {
    const messages = state.get('localization').get('messageProperties');
    const reviewData = state.get('review').toJS();
    const {data} = state
        .get('asset')
        .get('data')
        .toJS();
    const {isLoggedIn, isPostingAllowed, accountInfo = {}} = state.get('user').toJS();
    return {
        messages,
        reviewData,
        assetData: data,
        isLoggedIn,
        isPostingAllowed,
        accountId: accountInfo.id
    };
};

const SORT_OPTIONS = [
    {
        label: 'messages.com.epicgames.plugin.store.filter.newest_first',
        value: 'CREATEDAT',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.filter.oldest_first',
        value: 'CREATEDAT',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_desc',
        value: 'HELPFULNUMBER',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.questions.sort.helpful_asc',
        value: 'HELPFULNUMBER',
        dir: 'ASC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.reviews.sort.rating_desc',
        value: 'RATING',
        dir: 'DESC'
    },
    {
        label: 'messages.com.epicgames.plugin.store.asset.reviews.sort.rating_asc',
        value: 'RATING',
        dir: 'ASC'
    }
];

const AssetReviews = props => {
    const type = REVIEW_TYPES.REVIEW;
    const {
        messages,
        assetData = {},
        reviewData = {},
        getReviews,
        preventScroll,
        showLegacyComments
    } = props;
    const {id} = assetData;
    const {data = {}} = reviewData;
    const selectOptions = SORT_OPTIONS.map(it => ({
        text: getMessage(messages, it.label),
        value: `${it.value}_${it.dir}`
    }));

    const onSearch = params => {
        if (id) {
            getReviews({...params, type, offerId: id});
        }
    };

    const renderList = () => {
        const {elements = []} = data;
        return elements.map((it, idx) => (
            <ReviewItem
                type={type}
                key={`review-item-${idx}`}
                data={{isReplyEnabled: true, ...it}}
            />
        ));
    };

    const preventScrollVal = !hasValue(assetData) || preventScroll;
    return (
        <Review
            onSearch={onSearch}
            preventScroll={preventScrollVal}
            isLegacyCommentsEnabled={false}
            showLegacyComments={showLegacyComments}
            type={type}
            messages={messages}
            reviewData={reviewData}
            selectOptions={selectOptions}>
            {renderList()}
        </Review>
    );
};

AssetReviews.propTypes = {
    messages: PropTypes.object,
    assetData: PropTypes.object,
    reviewData: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    accountId: PropTypes.string,
    getReviews: PropTypes.func,
    addReview: PropTypes.func,
    uploadImages: PropTypes.func,
    isPostingAllowed: PropTypes.bool,
    setDialogOptions: PropTypes.func,
    hideDialog: PropTypes.func,
    preventScroll: PropTypes.bool,
    showLegacyComments: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetReviews);
