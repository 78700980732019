/**
 * Created by Kaku.Guo on 2018/10/24.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {NavLink as Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';

const NavLink = props => {
    if (props.isHidden) {
        return null;
    }
    const {to, children, keywords, className, onClick, useDefaultTo} = props;
    let finalTo;
    if (typeof to === 'string') {
        finalTo = generateRoutePath(to);
    } else if (typeof to === 'object' && to.pathname !== null) {
        finalTo = Object.assign(to, {pathname: generateRoutePath(to.pathname)});
    }
    const isActive = (match, location) => {
        if (keywords && keywords.length) {
            return !!keywords.find(key => {
                const keyTo = generateRoutePath(key);
                return location.pathname.includes(keyTo);
            });
        }
        return location.pathname.includes(finalTo);
    };

    const handleClick = e => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <Link
            to={onClick && !useDefaultTo ? '/#' : finalTo}
            activeClassName="active"
            exact={true}
            isActive={isActive}
            className={className}
            onClick={handleClick}>
            {children}
        </Link>
    );
};
NavLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    children: PropTypes.any,
    keywords: PropTypes.array,
    isHidden: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    useDefaultTo: PropTypes.bool
};
export default NavLink;
