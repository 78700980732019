/**
 * Created by Kaku.Guo on 2018/10/26.
 */
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Link, Message} from '../index';

const AssetTileInfo = props => {
    const {
        isLearnAsset,
        isCompact,
        isNewTagEnabled,
        isVaultPage,
        noHeading,
        data: {urlSlug, title, description, catalogItemId, isFeatured, isNew, seller, owned},
        children
    } = props;
    const sellerName = (seller && seller.name) || '';
    const getTitleLink = () => {
        if (urlSlug) {
            return `product/${urlSlug}`;
        }
        if (catalogItemId) {
            return `item/${catalogItemId}`;
        }
        return '#';
    };

    const tagPrefix = 'asset-status';
    const getTag = () => {
        if (!isNewTagEnabled) {
            return null;
        }
        if (owned && !isVaultPage) {
            return (
                <Message
                    className={cn(tagPrefix, `${tagPrefix}--owned`)}
                    code="messages.com.epicgames.plugin.store.asset.owned"
                />
            );
        }
        if (isFeatured) {
            return (
                <Message
                    className={cn(tagPrefix, `${tagPrefix}--featured`)}
                    code="messages.com.epicgames.plugin.store.asset.featured"
                />
            );
        }
        if (isNew) {
            return (
                <Message
                    className={cn(tagPrefix, `${tagPrefix}--new`)}
                    code="messages.com.epicgames.plugin.store.asset.new"
                />
            );
        }
        return null;
    };

    const titleElement =
        urlSlug || catalogItemId ? (
            <Link
                to={getTitleLink()}
                className="mock-ellipsis-item mock-ellipsis-item-helper ellipsis-text">
                {title}
            </Link>
        ) : (
            <a
                className="mock-ellipsis-item mock-ellipsis-item-helper ellipsis-text"
                onClick={e => e.preventDefault()}>
                {title}
            </a>
        );

    return (
        <div className="info">
            {!isCompact && (
                <Fragment>
                    {noHeading ? (
                        <span className="h3 asset-title">{titleElement}</span>
                    ) : (
                        <h3>{titleElement}</h3>
                    )}
                    <div className="creator ellipsis">
                        <Link to={`profile/${sellerName.replace(/ /g, '+')}`}>{sellerName}</Link>
                        &nbsp;
                    </div>
                    {isLearnAsset && <p>{description}</p>}
                </Fragment>
            )}
            {getTag()}
            {children}
            <div className="clearfix" />
        </div>
    );
};

AssetTileInfo.propTypes = {
    isLearnAsset: PropTypes.bool,
    isCompact: PropTypes.bool,
    isNewTagEnabled: PropTypes.bool,
    isVaultPage: PropTypes.bool,
    data: PropTypes.object,
    children: PropTypes.any,
    noHeading: PropTypes.bool
};
export default AssetTileInfo;
