import {fromJS} from 'immutable';
import typeToReducer from 'type-to-reducer';
import {UserActions} from '../actions';
import {getError} from '../utils';

const {GET_SSO} = UserActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isLoggedIn: false,
    isLoading: false,
    accountInfo: null,
    error: {}
});

export default typeToReducer(
    {
        [GET_SSO]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoading: true,
                    accountInfo: {},
                    error: {}
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoading: false,
                    accountInfo: action.payload
                }),
            FAILED: (state, action) =>
                state.merge({
                    error: getError(action.payload),
                    isLoading: false
                })
        }
    },
    DEFAULT_STATE
);
