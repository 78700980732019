import {ModStatus} from './common';
import {ModImage} from './image';
import {ModPublicListQuery, ModPublicSearchParams, Paging} from './search';

export enum NamespaceType {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE'
}

export interface Tag {
    id: string;
    name: string;
}

export interface ChangelogItem {
    title: string;
    description: string;
}

export enum ModSocialNetwork {
    Twitter = 'twitter',
    Facebook = 'facebook',
    Twitch = 'twitch',
    Discord = 'discord',
    Instagram = 'instagram',
    VKontakte = 'vKontakte',
    YouTube = 'youTube',
    Naver = 'naver',
    Youku = 'youku',
    Weibo = 'weibo',
    Reddit = 'reddit'
}

export interface ModSocialLink {
    networkType: ModSocialNetwork;
    link: string;
}

export interface ModDescription {
    title: string;
    description: string;
}

export interface LongDescriptionText {
    id: string;
    type: 'text';
    value: ModDescription;
}

export interface LongDescriptionImage {
    id: string;
    type: 'image';
    value: ModImage;
}

export type LongDescriptionItem = LongDescriptionImage | LongDescriptionText;

export interface ModPublicIndexData {
    subscriptionCount: number;
    likeCount: number;
    installCount: number;
}

export interface ModPrivateIndexData extends ModPublicIndexData {
    flagCount: number;
    publishingDate?: string;
}

export interface ModPublicMetaData {
    modSlug: string;
    itemId: string;
    offerId: string;
    artifactId: string;
    productId: string;
    productSandboxId: string;
    namespace: string;
    status: (keyof typeof ModStatus)[];
    indexData: ModPublicIndexData;
    isRecommended: boolean;
}

export interface ModComment {
    id: string;
    comment: string;
    acknowledged: boolean;
    creationDate: string;
}

export interface ModComments {
    elements: ModComment[];
    paging: Paging;
}

export interface ModEditableRequiredData {
    title: string;
    shortDescription: string;
    createdBy: string;
}

export interface ModEditableOptionalData {
    longDescription: LongDescriptionItem[];
    changelog: ChangelogItem[];
    modVersion: string;
    tags: Tag[];
    socialNetworks: ModSocialLink[];
    images: ModImage[];
}

export type ModEditableData = Partial<ModEditableOptionalData> & Required<ModEditableRequiredData>;

export interface ModCreationData extends ModEditableData {
    organizationId: string;
}

export interface ModTagsParams {
    namespace: string;
    sortDir?: string;
    count?: number;
    locale?: string;
    excludeZeroReferenceCount?: boolean;
    start?: number;
    sortBy?: string;
}

export interface ModPublicMetaList {
    elements: ModPublicMetaData[];
    paging: Paging;
}

export interface ModPrivateMetaData extends ModPublicMetaData {
    privateNamespace: string;
    indexData: ModPrivateIndexData;
    productSandboxId: string;
    isLockedBy: string;
    isAutoPublishingEnabled: boolean;
    lastModifiedDate: string;
    hasUnreadComments?: boolean;
}

export interface ModPrivateMetaList {
    elements: ModPrivateMetaData[];
    paging: Paging;
}

export interface ModCreateParams {
    productId: string;
    // TODO: make this field required when old api is deprecated
    productSandboxId?: string;
}

export type ModCreateResponse = Omit<ModPrivateMetaData, 'indexData' | 'isRecommended' | 'status'> &
    ModEditableOptionalData &
    Required<ModEditableRequiredData>;

export type ModUpdateResponse = ModCreateResponse;

export interface ModCreationData extends ModEditableData {
    organizationId: string;
}

export interface ModEditParams extends ModPublicSearchParams {
    orgId?: string;
}

export interface ModPrivateListQuery extends ModPublicListQuery {
    isFlagged?: boolean;
    status?: ModStatus;
    checkUnreadComments?: boolean;
    hideInProgress?: boolean;
}

export interface ModActionHistory {
    elements: ModActionHistoryData[];
    paging: Paging;
}

export interface ModActionHistoryData {
    id: string;
    action: string;
    actionStatus: string;
    creationDate: string;
    initialModStatus: ModStatus;
    resultModStatus?: ModStatus;
    commentId?: string;
    authorAccountId?: string;
}

export enum SettingsKey {
    BINARY_VALIDATION_MAX_ALLOWED_SIZE_BYTES = 'BINARY_VALIDATION_MAX_ALLOWED_SIZE_BYTES',
    BINARY_VALIDATION_FILE_TYPE_BLACKLIST = 'BINARY_VALIDATION_FILE_TYPE_BLACKLIST',
    ENABLE_PLATFORMS_SELECTION_FOR_GAME = 'ENABLE_PLATFORMS_SELECTION_FOR_GAME',
    SUSPEND_MODDING = 'SUSPEND_MODDING',
    ENABLE_MMP = 'ENABLE_MMP'
}

export interface ModProductSetting {
    value: string;
    space: string;
    key: string;
}

export interface ModPrivateListAllModsQuery extends Omit<ModPrivateListQuery, 'tags'> {
    isLocked?: boolean;
    excludeSuspendedProducts?: boolean;
}

export interface ModProductSandboxSettingParams {
    productId: string;
    productSandboxId: string;
}

export interface ModProductSandboxSettingData {
    key: string;
    value: string;
    isProtected: boolean;
}

export interface ModProductSandboxSetting extends ModProductSandboxSettingData {
    productId: string;
    productSandboxId: string;
    defaultValue: string;
}

export type ModProductSandboxPublicSetting = Omit<ModProductSandboxSetting, 'isProtected'>;

export interface PromoteSettingsParams {
    productId: string;
    sourceSandboxId: string;
}

export interface PromoteSettingsData {
    destinationSandboxId: string;
    keys: SettingsKey[];
}
