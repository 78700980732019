import moment from 'moment';
import sanitize from 'sanitize-html';
import {URL_PROTOCOL} from './Constants';

export function formatDate(date = null) {
    let result = date;
    if (!result) {
        result = moment();
    }
    return `${result.format('YYYY-MM-DD,HH:mm:ss.SSS').replace(',', 'T')}Z`;
}

export function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function bulkRandomNumber(count, min, max) {
    const result = new Set();
    while (result.size < count) {
        result.add(randomNumber(min, max));
    }
    return Array.from(result);
}

/**
 * Fisher-Yates shuffle function. This shuffles and returns a cloned array, and is non-destructive.
 * @param array
 */
export function shuffle(array) {
    const clonedArray = [...array];
    let unShuffled = clonedArray.length;
    let element = null;
    let currentElement;

    // While there remain elements to shuffle…
    while (unShuffled) {
        // Pick a remaining element…
        currentElement = Math.floor(Math.random() * unShuffled--);

        // And swap it with the current element.
        element = clonedArray[unShuffled];
        clonedArray[unShuffled] = clonedArray[currentElement];
        clonedArray[currentElement] = element;
    }

    return clonedArray;
}

export function compareVersion(version1, version2) {
    const versionArray1 = version1.split('.');
    const versionArray2 = version2.split('.');
    let idx = 0;
    const minLength = Math.min(versionArray1.length, versionArray2.length);
    let diff = 0;
    while (
        idx < minLength &&
        (diff = versionArray1[idx].length - versionArray2[idx].length) === 0 &&
        (diff = versionArray1[idx].localeCompare(versionArray2[idx])) === 0
    ) {
        ++idx;
    }
    diff = diff !== 0 ? diff : versionArray1.length - versionArray2.length;
    return diff;
}

export function buildDisplayTags(tags = []) {
    return tags.map(({id, name}) => ({
        id,
        name
    }));
}

export function getAssetKeyImages(assetInfo) {
    const keyImages = [];
    const collectedImageTypes = new Set();
    //Default to the offer level images, if there are any, add those
    if (assetInfo.keyImages) {
        assetInfo.keyImages.forEach(it => {
            keyImages.push(it);
            collectedImageTypes.add(it.type);
        });
    }
    //Then check the item level to see if there are any image types there that don't exist at the
    //offer level and add those.  This will pick screenshots that may not be included at the offer level as well
    //as images for item bundles (we will show images from all items in the bundle.
    if (assetInfo.items) {
        assetInfo.items.forEach(item => {
            if (item && item.keyImages) {
                item.keyImages.forEach(im => {
                    if (!collectedImageTypes.has(im.type)) {
                        keyImages.push(im);
                    }
                });
            }
        });
    }
    return keyImages;
}

export function sanitizeHtml(html = '') {
    const attrRel = 'noreferrer noopener';
    if (!html) {
        return '';
    }
    return sanitize(html, {
        allowedTags: sanitize.defaults.allowedTags.concat(['u', 's']),
        enforceHtmlBoundary: true,
        parser: {
            lowerCaseTags: true
        },
        nonTextTags: ['script', 'image', 'object', 'style', 'textarea', 'noscript', 'iframe'],
        allowedAttributes: {
            a: ['href', 'name', 'target', 'rel']
        },
        allowProtocolRelative: false,
        transformTags: {
            a(tagName, attr) {
                attr.rel = attrRel;
                return {
                    tagName: 'a',
                    attribs: {
                        ...attr
                    }
                };
            }
        },
        exclusiveFilter: ({tag, attribs, text}) => {
            return (
                tag === 'a' &&
                (!text.trim() ||
                    !URL_PROTOCOL.some(it => {
                        return attribs.href.startsWith(it);
                    }))
            );
        }
    });
}

export function handleImages(images, enabled) {
    if (!enabled) {
        return [];
    }
    return images || [];
}
