import root from 'window-or-global';

export const useClientState = () => {
    const state = root.__epic_client_state || {};
    return state;
};

export const useClientStateConfig = () => {
    const state = useClientState();
    const config = state.config || {};
    return config;
};

export const useCountryCode = () => {
    const config = useClientStateConfig();
    const countryCode = config.country || 'US';
    return countryCode;
};
