/**
 * Created by Kaku.Guo on 2019/7/19.
 */
/* eslint-disable react/prop-types*/
import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import cn from 'classnames';
import { runRule } from '../../../utils';

function ValidateTextArea(props, ref) {
    const prefixCls = 'validate-text-area';
    const { className, isDisabled, placeholder, defaultValue = '', validateRule, required } = props;

    const [value, setValue] = useState(defaultValue);
    const [validateError, setValidateError] = useState(null);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            const validate = runRule({ value }, { value: validateRule });
            if (validate.value) {
                setValidateError(validate.value);
                return false;
            }
            return { value };
        }
    }));

    const handleChange = e => {
        setValue(e.target.value);
        setValidateError(null);
    };

    const classNames = cn(prefixCls, className);
    const placeHolder = required && placeholder ? `* ${placeholder}` : placeholder;
    return (
        <div className={classNames}>
            <textarea
                className={validateError && `${prefixCls}--error`}
                placeholder={placeHolder}
                value={value}
                disabled={isDisabled}
                onChange={handleChange}
            />
            {validateError && <span className="error">{validateError}</span>}
        </div>
    );
}

export default forwardRef(ValidateTextArea);