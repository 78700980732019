import React from 'react';
import SellerAssetsWrap from './SellerAssetsWrap.connected';
import {AssetActions} from '../../actions';

export default class SellerAssetsView extends React.Component {
    static async getInitialProps(match, {dataPreload, parsedUrl, store, config}, token) {
        const params = match.params || {};

        store.dispatch(AssetActions.sellerAssets(params));
    }

    render() {
        return <SellerAssetsWrap {...this.props} />;
    }
}
