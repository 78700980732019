import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Icon(props) {
    const {
        className,
        component: Component,
        name,
        size,
        ...otherProps
    } = props;

    return (
        <Component
            className={classNames(
                'fa',
                `fa-${name}`,
                size && `font__size--${size}`,
                className
            )}
            {...otherProps}
        />
    );
}

Icon.propTypes = {
    className: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string,
    size: PropTypes.oneOf([16, 20, 24, 32])
};

Icon.defaultProps = {
    component: 'span',
    size: 16
};
