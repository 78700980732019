import React from 'react';
import PropTypes from 'prop-types';
import markdownUtils from 'epic-comment-rating/app/scripts/commentrating/utils/markdown';
import DateUtil from 'epic-comment-rating/app/scripts/commentrating/utils/dateUtils';
import { connect } from 'react-redux';
import { Link, Message, getMessage } from '../index';

const mapStateToProps = (state) => {
    return {
        messages: state.get('localization').get('messageProperties').toJS()
    };
};
@connect(mapStateToProps)
export default class ReplyItem extends React.PureComponent {
    static propTypes = {
        seller: PropTypes.object,
        messages: PropTypes.object,
        reply: PropTypes.object,
        enableMarkdown: PropTypes.bool,
        enableAvatar: PropTypes.bool
    };

    render() {
        const { messages, seller } = this.props;
        const reply = this.props.reply.asMutable({ deep: true }) || {};
        reply.displayTime = DateUtil.jsDateFormat(reply.displayTime, '', messages);

        const hiddenStyle = {
            display: 'none'
        };
        /**
         * set displayName to [Deleted User] if account is deleted
         */
        let displayName = reply.accountDisplayName;
        if (reply.deletedAccount) {
            displayName = getMessage(messages, 'messages.com.epicgames.plugin.commentrating.comments.deleted_user');
        }
        let displayNameContent = (
            <span className="username accent-color">{displayName}</span>
        );
        if (seller && seller.owner === reply.accountId) {
            displayNameContent = (
                <h4>
                    <Message code="messages.com.epicgames.plugin.commentrating.comments.publisher.reply.label" />
                    <Link to={`profile/${seller.name}`} className="username accent-color">{seller.name}</Link>
                </h4>
            );
        }


        /**
         * enable makedown content
         */
        let replyContent;
        if (this.props.enableMarkdown) {
            replyContent = (
                <div className="textile-container-view">
                    <div className="textile-preview" dangerouslySetInnerHTML={markdownUtils.rawMarkup(reply.markdownContent)} />
                </div>
            );
        } else {
            replyContent = (
                <span className="textile-preview">{reply.markdownContent}</span>
            );
        }

        /**
         * Enable avatar
         *
         */
        let avatar;
        if (this.props.enableAvatar && reply.avatarImgUrl) {
            avatar = (
                <div className="user-avatar">
                    <img alt="avatar" src={reply.avatarImgUrl} />
                </div>
            );
        }

        return (
            <div className="reply-container">
                {avatar}

                <div className="comment-info-box">
                    <div className="comment-body">
                        <div className="comment-content-container" style={hiddenStyle}>{reply.markdownContent}</div>
                        <span className="comment-content">
                            {displayNameContent}
                        </span>

                        {replyContent}

                        <p className="comment-links">
                            <span className="comment-date">{reply.displayTime}</span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
