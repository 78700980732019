import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, {END} from 'redux-saga';
import sagas from './sagas';
import { createReducer, bootstrap } from './reducers';
import { ErrorHandlerMiddleware } from './utils';

const sagaMiddleware = createSagaMiddleware();

export function configureStore(initialState) {
    const middlewares = [
        sagaMiddleware,
        ErrorHandlerMiddleware
    ];

    const store = createStore(
        createReducer(),
        initialState,
        applyMiddleware(...middlewares)
    );

    // API Required for react SSR
    store.sagas = sagaMiddleware.run(sagas);
    store.close = () => {
        store.dispatch(END);
    };

    return store;
}

/**
 * A function to take a data preload (from the server) and transform it into a redux store with reducers
 * @param {*} bootstrapData
 */
export function bootstrapStore(bootstrapData) {
    const initialState = bootstrap(bootstrapData);
    return configureStore(initialState);
}
