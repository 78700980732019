import axios from 'axios';
import Url from '../constants/Url';

class TraderApi {
    getTraderInfo({sellerId, solveToken, flowId}) {
        return axios
            .post(Url().SELLER.GET_TRADER_INFO, {
                seller_id: sellerId,
                solve_token: solveToken,
                flow_id: flowId
            })
            .then(response => response.data.data)
            .catch(e => {
                console.error('TraderApi.getTraderInfo() failed: ', e.message);
                throw e;
            });
    }
}
export default new TraderApi();
