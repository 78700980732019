/**
 * Created by Kaku.Guo on 2019/1/9.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {AssetTile} from '../index';

const RelatedAssetList = props => {
    const {header, data = [], ...otherProps} = props;
    return (
        <div className="featured-items-container offset-top related-assets">
            <div className="sub-header">
                <span className="h2">{header}</span>
            </div>
            <hr />
            <div className="asset-list-group">
                {!!data.length &&
                    data.map((it, idx) => (
                        <AssetTile
                            index={idx}
                            key={`related-asset-${idx}`}
                            data={it}
                            {...otherProps}
                            isNewTagEnabled
                            noHeading
                        />
                    ))}
            </div>
        </div>
    );
};
RelatedAssetList.propTypes = {
    header: PropTypes.string,
    data: PropTypes.array
};
export default RelatedAssetList;
