import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {generateRoutePath} from '@epic-core/common';
import {useLocalization} from '@epic-core/localization';
import {useCoupons} from '../../shared/coupons/coupon.hooks';

const CouponWrap = styled.div`
    display: inline-block;
    margin: 0 0.25em 0 1em;
    padding: 0;
    position: relative;
    left: -5px;
`;

const NumberWrap = styled.div`
    position: absolute;
    bottom: -5px;
    right: -16px;
    font-size: 10px;
    background: rgb(226, 114, 4);
    border-radius: 8px;
    color: white;
    height: 16px;
    min-width: 16px;
    padding: 1px 2px 0;
    text-align: center;
    font-weight: bolder;
    font-size: 11px;

    &.two-digits {
        padding: 1px 2px 0 0px;
    }
`;

export const CouponInfo = ({identityId}: {identityId?: string}) => {
    const getMessage = useLocalization();
    const {couponsCount} = useCoupons(identityId);
    if (!couponsCount) return null;
    return (
        <CouponWrap>
            <Link
                to={generateRoutePath('/coupons')}
                title={getMessage('epic.marketplace.wishlist.view_coupon_list')}>
                <div className="coupon-icon" />
            </Link>
            <NumberWrap>{couponsCount}</NumberWrap>
        </CouponWrap>
    );
};
