import {generateAsyncActions} from '../utils';

const TYPES = {
    ...generateAsyncActions('GET_SSO')
};

export default {
    TYPES,
    getSso: payload => ({
        type: TYPES.GET_SSO_PENDING,
        payload
    }),
    getSsoSuccess: payload => ({
        type: TYPES.GET_SSO_SUCCESS,
        payload
    }),
    getSsoFailed: payload => ({
        type: TYPES.GET_SSO_FAILED,
        payload
    })
};
