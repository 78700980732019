import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const VARIANTS = {
    PRIMARY: 'primary',
    ALT: 'alt',
    GRAY: 'gray'
};

export default function Button(props) {
    const {
        children,
        className,
        variant,
        ...otherProps
    } = props;

    return (
        <button
            className={classNames(
                'mktBtn',
                variant && `mktBtn--${variant}`,
                className
            )}
            {...otherProps}
        >
            <span className="mktBtn__label">{children}</span>
        </button>
    );
}
Button.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    variant: PropTypes.oneOf(Object.values(VARIANTS))
};

Button.defaultProps = {
    variant: VARIANTS.PRIMARY
};
