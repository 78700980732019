/**
 * Created by Kaku.Guo on 2019/5/16.
 */
/* eslint-disable react/prop-types*/
import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import cn from 'classnames';
import ReactFiles from 'react-files';

function FileUpload(props, ref) {
    const { className, multiple = true, accepts, maxFiles, maxFileSize = 10000000, minFileSize = 0, children, onChange, isDisabled } = props;
    const classNames = cn('file-upload', className);
    const fileRef = useRef(null);
    useImperativeHandle(ref, () => ({
        onRemove: file => fileRef.current.removeFile(file)
    }));
    const onFilesError = (error) => {
        console.log(error);
    };

    return (
        <ReactFiles
            ref={fileRef}
            className={classNames}
            onChange={onChange}
            onError={onFilesError}
            accepts={accepts}
            multiple={multiple}
            maxFiles={maxFiles}
            maxFileSize={maxFileSize}
            minFileSize={minFileSize}
            clickable={!isDisabled}>
            {children}
        </ReactFiles>
    );
}

export default forwardRef(FileUpload);