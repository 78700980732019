/**
 * Created by Kaku.Guo on 2019/5/22.
 */
import axios from 'axios';
import Url from '../constants/Url';

class ReviewApi {
    addReview(offerId, type, params) {
        return axios
            .post(Url().REVIEW.ADD(offerId, type), params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    setBestReply(topicId, id, params) {
        return axios
            .post(Url().REVIEW.SET_BEST_REPLY(topicId, id), params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getReviews(offerId, type, params) {
        return axios
            .get(Url().REVIEW.LIST(offerId, type), {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getReviewDetail(offerId, type, topicId, params) {
        return axios
            .get(Url().REVIEW.DETAIL(offerId, type, topicId), {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getReviewDetailByAccount(offerId) {
        return axios
            .get(Url().REVIEW.DETAIL_BY_ACCOUNT(offerId), {})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    editReview(offerId, type, reviewBody) {
        return axios
            .put(Url().REVIEW.EDIT(offerId, type), reviewBody)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    deleteReview(offerId, topicId, params) {
        return axios
            .delete(Url().REVIEW.DELETE(offerId, topicId), {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    replyReview(topicId, type, replyBody) {
        return axios
            .post(Url().REVIEW.REPLY(topicId, type), replyBody)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getReviewReplies(topicId, type, params) {
        return axios
            .get(Url().REVIEW.GET_REPLY(topicId, type), {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    voteReview(id, type, params) {
        return axios
            .put(Url().REVIEW.VOTE(id, type), params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    editReply(replyId, type, params) {
        return axios
            .put(Url().REVIEW.EDIT_REPLY(replyId, type), params)
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    deleteReply(replyId, type, params) {
        return axios
            .delete(Url().REVIEW.DELETE_REPLY(replyId, type), {params})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getRating(offerId) {
        const url = Url().REVIEW.RATING(offerId);
        return axios
            .get(url)
            .then(response => {
                return response.data.data;
            })
            .catch(ex => {
                throw ex;
            });
    }
}
export default new ReviewApi();
