import React from 'react';
import PropTypes from 'prop-types';
import Message from '../../common/message/Message';
import Modal from 'react-responsive-modal';

export default class ErrorModal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func,
        showDialog: PropTypes.bool,
        title: PropTypes.string,
        message: PropTypes.string
    };

    render() {
        const { showDialog, closeModal, title, message } = this.props;
        return (
            <Modal
                open={showDialog}
                onClose={closeModal}
                styles={{closeIcon: {color: '#ababab'}}}
                center
                classNames={{modal: 'enterprise-modal_subscribe-error'}}>
                <div className="error-icon">
                    <i className="enterprise-icon-caution" />
                </div>
                <p className="error-title">
                    <Message code={title} />
                </p>
                <div className="gray-box">
                    <p className="">
                        <Message code={message} />
                    </p>

                    <button className="btn btn-primary" onClick={closeModal}>
                        <Message code="epic.enterprise.dashboard.close" />
                    </button>
                </div>
            </Modal>
        );
    }
}
