import {connect} from 'react-redux';
import root from 'window-or-global';
import FilterPanelView from './FilterPanelView';
import {PRICE_RANGE_REGX} from '../../constants';
import {ConfigActions} from '../../actions';
import {hasValue, generatePriceRange, debounce} from '../../utils';

const mapStateToProps = state => {
    const {
        platforms,
        engineVersions,
        priceRanges,
        defaultTags,
        selectedTags,
        tagData,
        isLoadingTags,
        isLauncher,
        launcherVersions
    } = state.get('config').toJS();
    const {
        tag,
        compatibleWith,
        platform,
        priceRange,
        customPriceRange,
        isAllEngineVisible,
        isCustomPrice,
        isPriceInvalid,
        tagKeyWord,
        discountPercentageRange
    } = state.get('page').toJS();
    return {
        pageData: {
            platforms,
            engineVersions,
            priceRanges,
            defaultTags,
            selectedTags,
            tagData,
            isLoadingTags,
            isLauncher,
            launcherVersions
        },
        filterOptions: {
            tag,
            compatibleWith,
            platform,
            priceRange,
            customPriceRange,
            isAllEngineVisible,
            isCustomPrice,
            isPriceInvalid,
            tagKeyWord,
            discountPercentageRange
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSuggestTags: model => {
            dispatch(ConfigActions.getSuggestTags(model));
        }
    };
};

const selectedTagCache = [];

@connect(mapStateToProps, mapDispatchToProps)
class FilterPanel extends FilterPanelView {
    constructor(props) {
        super(props);
        this.debounceApplyCustomPrice = debounce(this.applyCustomPrice, 800);
        this.state = {top: -1};
    }

    resetFilter = () => {
        this.props.handleChange({
            reset: true
        });
    };

    generateSelectedTags = tag => {
        if (!hasValue(tag)) {
            return [];
        }
        const {
            pageData: {selectedTags}
        } = this.props;
        if (selectedTags && selectedTags.length) {
            selectedTags.forEach(item => {
                if (!selectedTagCache.find(it => it.id === item.id)) {
                    selectedTagCache.push(item);
                }
            });
        }
        return tag.reduce((prev, next) => {
            const tagObj = selectedTagCache.find(t => t.id === next);
            if (tagObj) {
                prev.push(tagObj);
            }
            return prev;
        }, []);
    };

    handleTagSuggest = value => {
        const {getSuggestTags, handleChange} = this.props;
        if (value) {
            getSuggestTags({query: `*${value}*`});
        }
        handleChange({
            tagKeyWord: value
        });
    };

    handleTagSelect = item => {
        if (!selectedTagCache.some(st => st.id === item.id)) {
            selectedTagCache.push(item);
        }
        this.props.handleChange({
            tag: item.id,
            tagKeyWord: '',
            start: 0
        });
    };

    handleDiscountSelect = opt => e => {
        if (e) {
            let {
                filterOptions: {discountPercentageRange}
            } = this.props;
            if (discountPercentageRange === opt.value) {
                discountPercentageRange = '';
            } else {
                discountPercentageRange = opt.value;
            }
            this.props.handleChange({
                discountPercentageRange,
                start: 0
            });
        }
    };

    handleRemoveTag = item => e => {
        if (e) {
            this.props.handleChange({
                tag: item.id,
                start: 0
            });
        }
    };

    handleCustomPrice = type => e => {
        if (e) {
            const {
                filterOptions: {customPriceRange},
                handleChange
            } = this.props;
            const newData = {};
            newData[type] = e.target.value;
            handleChange({
                customPriceRange: Object.assign({}, customPriceRange, newData),
                isCustomPrice: true,
                isPriceInvalid: false
            });
            this.debounceApplyCustomPrice();
        }
    };

    applyCustomPrice = () => {
        const {
            filterOptions: {customPriceRange},
            handleChange
        } = this.props;
        const {low, high} = customPriceRange;
        if (!low && !high) {
            handleChange({
                isCustomPrice: false,
                priceRange: '',
                start: 0
            });
            return;
        }
        const priceRange = this.calculateCustomPrice(customPriceRange);
        if (
            !PRICE_RANGE_REGX.test(priceRange) ||
            (low && high && parseFloat(low) > parseFloat(high))
        ) {
            handleChange({
                isPriceInvalid: true
            });
        } else {
            handleChange({
                priceRange,
                start: 0
            });
        }
    };

    calculateCustomPrice = customPrice => {
        const {
            pageData: {
                priceRanges: {decimals}
            }
        } = this.props;
        return generatePriceRange(customPrice, decimals);
    };

    handlePriceKeyPress = e => {
        if (e && e.charCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            this.applyCustomPrice();
        }
    };

    handleFieldChange = (field, value) => () => {
        const newState = {start: 0};
        newState[field] = value;
        if (field === 'priceRange') {
            newState.isCustomPrice = false;
            newState.isPriceInvalid = false;
            newState.customPriceRange = {low: '', high: ''};
            const {
                filterOptions: {priceRange, isCustomPrice}
            } = this.props;
            if (!isCustomPrice && value === priceRange) {
                newState.priceRange = '';
            }
        }
        this.props.handleChange(newState);
    };

    showMoreEngine = () => {
        this.props.handleChange({
            isAllEngineVisible: true
        });
    };

    handleClose = () => {
        const {onClose} = this.props;
        if (onClose) {
            onClose();
        }
    };

    stopPropagation = e => {
        if (e) {
            e.stopPropagation();
        }
    };

    getExtraStyle = () => {
        const {top} = this.state;
        if (top < 0) {
            return null;
        }
        return {top, height: root.screen.height - top};
    };
}
export default FilterPanel;
