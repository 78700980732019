import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { Star } from '../index';
import cn from 'classnames';

//TODO try remove Star component to this doc and only expose Rate ? since star only work for rating
const Rate = ({ className, isLauncher = false, size, ...otherProps }) => {
    return (
        <Rating
            className={cn('rate', className)}
            emptySymbol={<Star isLauncher={isLauncher} offset={0} size={size} />}
            fullSymbol={<Star isLauncher={isLauncher} offset={100} size={size} />}
            {...otherProps}
        />
    );
};

Rate.propTypes = {
    isLauncher: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.number
};

export default Rate;
