import React from 'react';
import PropTypes from 'prop-types';

export default function TabList(props) {
    const {
        children,
        ...otherProps
    } = props;

    return (
        <ul
            className="tabList"
            role="tablist"
            {...otherProps}
        >
            {children}
        </ul>
    );
}

TabList.propTypes = {
    children: PropTypes.node
};
