/**
 * Created by Kaku.Guo on 2018/11/19.
 */
import AutoSuggest from './AutoSuggest';

class TagAutoSuggest extends AutoSuggest {
    handleSelect = (value) => {
        const { onChange } = this.props;
        const { listData } = this.state;
        this.setState({
            value: '',
            currentIndex: -1,
            isListVisible: false
        });

        //search
        const item = listData.find(li => li === value);
        onChange(item);
    };

    handleInputChange = (e) => {
        if (e) {
            const { handleSuggest } = this.state;
            const value = e.target.value;
            if (value) {
                this.setState({
                    value,
                    isListVisible: false,
                    currentIndex: -1
                });
            } else {
                const listData = this.getDefaultData();
                this.setState({
                    value,
                    listData,
                    isListVisible: true,
                    currentIndex: 0
                });
            }
            handleSuggest(value);
        }
    };

    handleInputFocus = () => {
        const { value } = this.state;
        if (!value) {
            const listData = this.getDefaultData();
            this.setState({
                listData,
                isListVisible: true,
                currentIndex: 0
            });
        }
    };

    getDefaultData = () => {
        const { defaultTags, excludeData = [], valueField } = this.props;
        return defaultTags.filter(li => !excludeData.find(ex => ex[valueField] === li[valueField])).slice(0, 10);
    }
}

export default TagAutoSuggest;