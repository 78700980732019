/**
 * Created by Kaku.Guo on 2018/10/25.
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import qs from 'query-string';
import {BackArrow, AutoSuggest, getMessage, ShoppingCart} from '../index';
import {AssetActions} from '../../actions';
import HeaderNav from './HeaderNav';
import {debounce, ResizeObserver} from '../../utils';
import root from 'window-or-global';
import {WishlistIcon} from '../wishlist/WishlistIcon';
import {CouponInfo} from '../coupons/CouponInfo';
import {PageTitle} from './PageTitle';

const mapStateToProps = state => {
    const {
        categories,
        contentHeader,
        customizedHistory,
        eventSaleLabel,
        isEventSaleVisible,
        isHomeSuppressed,
        isLauncher,
        isShoppingCartEnabled,
        keywords,
        sellerPortalUrl,
        supportSiteUrl,
        tagFreeForMonth,
        tagPermanentlyFree,
        tagMarketplaceCollection,
        tagIndustries,
        tagArchitecture,
        tagAutomotive,
        tagSimulation,
        tagFashion,
        tagFilm
    } = state.get('config').toJS();
    const {isLoadingSuggest, suggestData} = state.get('asset').toJS();
    const {isLoggedIn, accountInfo = {}} = state.get('user').toJS();
    const {messageProperties: messages, locale} = state.get('localization').toJS();
    return {
        categories,
        contentHeader,
        customizedHistory,
        eventSaleLabel,
        isEventSaleVisible,
        isHomeSuppressed,
        isLauncher,
        isLoadingSuggest,
        isLoggedIn,
        isShoppingCartEnabled,
        accountInfo,
        keywords,
        locale,
        messages,
        sellerPortalUrl,
        suggestData,
        supportSiteUrl,
        tagFreeForMonth,
        tagPermanentlyFree,
        tagMarketplaceCollection,
        tagIndustries,
        tagArchitecture,
        tagAutomotive,
        tagSimulation,
        tagFashion,
        tagFilm
    };
};
const mapDispatchToProps = dispatch => {
    return {
        suggestAssets: model => {
            dispatch(AssetActions.suggestAssets(model));
        }
    };
};
@connect(mapStateToProps, mapDispatchToProps)
class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            keywords: props.keywords || ''
        };
        this.adjustPagePadding = debounce(this.adjustPagePadding, 100);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {keywords} = nextProps;
        if (keywords !== this.props.keywords) {
            this.setState({
                keywords
            });
        }
    }

    componentDidMount() {
        const {isLauncher} = this.props;
        if (isLauncher && ResizeObserver()) {
            root.document.querySelector('#sub-nav-container').handleResize = this.adjustPagePadding;
            ResizeObserver().observe(root.document.querySelector('#sub-nav-container'));
        }
    }

    componentWillUnmount() {
        const {isLauncher} = this.props;
        if (isLauncher && ResizeObserver()) {
            ResizeObserver().unobserve(root.document.querySelector('#sub-nav-container'));
        }
    }

    adjustPagePadding = ({contentRect: {height}}) => {
        //adjust page padding for fixed header
        const container = root.document.querySelector('.launcher-scrollbar');
        if (container) {
            container.style.paddingTop = `${height}px`;
        }
    };

    handleSearch = ({query}) => {
        const assetsPath = generateRoutePath('assets');
        const params = {
            keywords: query
        };
        this.props.history.push({pathname: assetsPath, search: `?${qs.stringify(params)}`});
    };

    handleSuggest = keywords => {
        if (!keywords) {
            return;
        }
        this.props.suggestAssets({
            keywords,
            count: 10
        });
        this.setState({
            keywords
        });
    };

    render() {
        const {
            accountInfo,
            categories,
            contentHeader,
            customizedHistory,
            eventSaleLabel,
            history,
            isEventSaleVisible,
            isHomeSuppressed,
            isLauncher,
            isLearnPath,
            isLoadingSuggest,
            isLoggedIn,
            isShoppingCartEnabled,
            locale,
            messages,
            sellerPortalUrl,
            suggestData,
            supportSiteUrl,
            tagFreeForMonth,
            tagPermanentlyFree,
            tagMarketplaceCollection,
            tagIndustries,
            tagArchitecture,
            tagAutomotive,
            tagSimulation,
            tagFashion,
            tagFilm
        } = this.props;

        const {keywords} = this.state;
        const launcherAndLearn = isLearnPath && isLauncher;

        return (
            <header className="sub-nav-container" id="sub-nav-container">
                <div className="page-header-container">
                    <div className="page-title">
                        {!isHomeSuppressed && isLauncher && (
                            <BackArrow
                                history={history}
                                customizedHistory={customizedHistory}
                                backToLearn={launcherAndLearn}
                            />
                        )}
                        <PageTitle header={contentHeader} />
                    </div>
                    {!launcherAndLearn ? (
                        <>
                            <div className="header-block">
                                <AutoSuggest
                                    classNames="asset-search"
                                    history={history}
                                    locale={locale}
                                    placeholder={getMessage(
                                        messages,
                                        'messages.com.epicgames.plugin.store.search.placeholder'
                                    )}
                                    onSearch={this.handleSearch}
                                    onSuggest={this.handleSuggest}
                                    value={keywords || ''}
                                    isLoadingSuggest={isLoadingSuggest}
                                    listData={suggestData}
                                    valueField="name"
                                />
                                {isLoggedIn && <CouponInfo identityId={accountInfo.id} />}
                                {isLoggedIn && <WishlistIcon />}
                                {isLoggedIn && isShoppingCartEnabled && (
                                    <ShoppingCart messages={messages} identityId={accountInfo.id} />
                                )}
                            </div>

                            <HeaderNav
                                categories={categories}
                                eventSaleLabel={eventSaleLabel}
                                isEventSaleVisible={isEventSaleVisible}
                                isHomeSuppressed={isHomeSuppressed}
                                isLauncher={isLauncher}
                                locale={locale}
                                sellerPortalUrl={sellerPortalUrl}
                                supportSiteUrl={supportSiteUrl}
                                tagFreeForMonth={tagFreeForMonth}
                                tagPermanentlyFree={tagPermanentlyFree}
                                tagMarketplaceCollection={tagMarketplaceCollection}
                                tagIndustries={tagIndustries}
                                tagArchitecture={tagArchitecture}
                                tagAutomotive={tagAutomotive}
                                tagSimulation={tagSimulation}
                                tagFashion={tagFashion}
                                tagFilm={tagFilm}
                            />
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </header>
        );
    }
}
PageHeader.propTypes = {
    categories: PropTypes.array,
    contentHeader: PropTypes.object,
    customizedHistory: PropTypes.object,
    eventSaleLabel: PropTypes.string,
    history: PropTypes.object,
    isEventSaleVisible: PropTypes.bool,
    isHomeSuppressed: PropTypes.bool,
    isLauncher: PropTypes.bool,
    isLearnPath: PropTypes.bool,
    isLoadingSuggest: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    accountInfo: PropTypes.object,
    isShoppingCartEnabled: PropTypes.bool,
    keywords: PropTypes.string,
    locale: PropTypes.string,
    messages: PropTypes.object,
    sellerPortalUrl: PropTypes.string,
    suggestAssets: PropTypes.func,
    suggestData: PropTypes.array,
    supportSiteUrl: PropTypes.string,
    tagFreeForMonth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagPermanentlyFree: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagMarketplaceCollection: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagIndustries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagArchitecture: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagAutomotive: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagSimulation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagFashion: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    tagFilm: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default withRouter(PageHeader);
