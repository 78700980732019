import {SharedValueEffect} from '@epic-core/hooks';

import {EulaService, AcceptEulaSuccessEvent} from "./eula.service";
import {acceptEulaCallbackKey} from "./eula.hooks";

export const EulaEffects: SharedValueEffect = values => {
    const service = values.service(EulaService);

    service.addAcceptEulaListener((callback) => {
        values.dispatch(AcceptEulaSuccessEvent, callback as any);
    });

    values.on(AcceptEulaSuccessEvent, (callback) => {
        const _cb = values.get(acceptEulaCallbackKey);
        const acceptCallback = typeof _cb()  === 'function' ? _cb() : callback();
        acceptCallback();
    });
}
