/**
 * Created by Kaku.Guo on 2019/1/4.
 */
import {ErrorActions} from '../actions';

export default store => next => action => {
    if (action.payload && action.payload.response) {
        const {
            response: {data, status}
        } = action.payload;
        if (status === 404) {
            const errorData = data || {};
            errorData.status = {};
            errorData.status = status;
            next(ErrorActions.errorOccurred(errorData));
        } else {
            next(action);
        }
    } else {
        next(action);
    }
};
