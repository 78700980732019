import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon, Spacer } from '../../index';
import Button from './Button';

export const VARIANTS = {
    PRIMARY: 'primary',
    INVERSE: 'inverse'
};

export default function IconButton(props) {
    const {
        className,
        iconProps,
        name,
        size,
        variant,
        ...rootProps
    } = props;

    const classes = classNames(
        'iconBtn',
        variant && `iconBtn--${variant}`,
        size && `font__size--${size}`,
        className
    );

    const padding = variant === VARIANTS.INVERSE
        ? { paddingLeft: 0, paddingRight: 0 }
        : { paddingLeft: 4, paddingRight: 4 };

    return (
        <Spacer
            className={classes}
            component={Button}
            variant={null}
            {...padding}
            {...rootProps}
        >
            <Icon
                name={name}
                size={null}
                {...iconProps}
            />
        </Spacer>
    );
}
IconButton.propTypes = {
    className: PropTypes.string,
    iconProps: PropTypes.object,
    name: PropTypes.string,
    size: PropTypes.oneOf([16, 20, 24, 32]),
    variant: PropTypes.oneOf(Object.values(VARIANTS))
};

IconButton.defaultProps = {
    iconProps: {},
    size: 16,
    variant: VARIANTS.PRIMARY
};
