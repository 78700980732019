import {useState, useEffect, useCallback} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {wishlistApi} from './wishlist.api';
import {useCountryCode} from './misc.hooks';
import {emitEvent} from 'epic-ue-shared';
import {useShoppingCart, useReduxStore} from './redux.hooks';
import $ from 'jquery';

export const WISHLIST_ITEM_LIMIT = 200;

export const wishlistItemsKey = createSharedValueKey('WishlistItems', []);
const wishlistItemsLoadingKey = createSharedValueKey('WishlistItemsLoading', {
    loading: false,
    loaded: false,
    error: ''
});
export const wishlistRefreshKey = createSharedValueKey('WishlistNeedsRefresh', false);

let loadActive = false;
export const useWishlistItems = () => {
    const [loadingState, setLoadingState] = useSharedValue(wishlistItemsLoadingKey);
    const [needRefresh, setNeedRefresh] = useSharedValue(wishlistRefreshKey);
    const [wishlistItemsStore, setWishlistItemsStore] = useSharedValue(wishlistItemsKey);
    const countryCode = useCountryCode();

    useEffect(() => {
        if (
            (!needRefresh && loadingState.loaded) ||
            loadActive ||
            loadingState.loading ||
            loadingState.error
        ) {
            return;
        }
        async function fetchData() {
            try {
                loadActive = true;
                setLoadingState({loading: true, loaded: false, error: ''});
                const data = await wishlistApi.getWishlistItems(countryCode);
                setNeedRefresh(false);
                setWishlistItemsStore(data);
                setLoadingState({loading: false, loaded: true, error: ''});
                loadActive = false;
            } catch (ex) {
                loadActive = false;
                console.error('Failed to fetch wishlist data', ex);
                setLoadingState({loading: false, loaded: true, error: ex.message});
            }
        }
        fetchData();
    }, [wishlistItemsStore, needRefresh]);

    const refreshWishlistItems = useCallback(() => {
        setNeedRefresh(true);
    }, [needRefresh, setNeedRefresh]);

    return {
        wishlistItemsStore,
        setWishlistItemsStore,
        refreshWishlistItems,
        ...loadingState
    };
};

export const useWishlistItemCount = () => {
    const {wishlistItemsStore} = useWishlistItems();
    return wishlistItemsStore.length;
};

export const useIsInWishlist = (offerId: string) => {
    const {wishlistItemsStore} = useWishlistItems();
    const matches = wishlistItemsStore.filter(
        item => item && item.offer && item.offer.id === offerId
    );
    return Boolean(matches && matches.length);
};

export const useAddToWishlist = () => {
    const [wishlistItemsStore, setWishlistItemsStore] = useSharedValue(wishlistItemsKey);
    const {refreshWishlistItems} = useWishlistItems();
    const countryCode = useCountryCode();
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        success: boolean;
    }>({loading: false, loaded: false, success: false});

    const addToWishlist = useCallback(
        async (offerId: string) => {
            const matches = wishlistItemsStore.filter(
                item => item && item.offer && item.offer.id === offerId
            );
            const isInWishlist = Boolean(matches && matches.length);
            if (isInWishlist) {
                console.warn('Offer is already in wishlist', offerId);
                return;
            }
            if (matches.length > WISHLIST_ITEM_LIMIT) {
                console.warn(
                    'Wishlist is at max limit',
                    WISHLIST_ITEM_LIMIT,
                    'the offer cannot be added',
                    offerId
                );
                return;
            }
            try {
                setLoadingState({loading: true, loaded: false, success: false});
                const data = await wishlistApi.addToWishlist(offerId, countryCode);
                if (data && data.success && data.wishlistItem) {
                    wishlistItemsStore.push(data.wishlistItem);
                    emitEvent({
                        eventAction: 'wishlist.add',
                        interactionType: 'interaction',
                        eventLabel: wishlistItemsStore.length,
                        eventValue: offerId
                    });
                    setWishlistItemsStore(wishlistItemsStore);
                    refreshWishlistItems();
                }
                setLoadingState({loading: false, loaded: true, success: data && data.success});
                return data;
            } catch (ex) {
                console.error('Failed to add to wishlist', ex);
                setLoadingState({loading: false, loaded: true, success: false});
            }
        },
        [wishlistItemsStore, setWishlistItemsStore, loadingState]
    );

    return {
        addToWishlist,
        ...loadingState
    };
};

export const useRemoveFromWishlist = () => {
    const [wishlistItemsStore, setWishlistItemsStore] = useSharedValue(wishlistItemsKey);
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        success: boolean;
    }>({loading: false, loaded: false, success: false});

    const removeFromWishlist = useCallback(
        async (offerId: string) => {
            try {
                setLoadingState({loading: true, loaded: false, success: false});
                const data = await wishlistApi.removeFromWishlist(offerId);
                if (data && data.success) {
                    const newItems = wishlistItemsStore.filter(
                        item => item && item.offer && item.offer.id !== offerId
                    );
                    setWishlistItemsStore(newItems);
                    emitEvent({
                        eventAction: 'wishlist.remove',
                        interactionType: 'interaction',
                        eventLabel: wishlistItemsStore.length,
                        eventValue: offerId
                    });
                }
                setLoadingState({loading: false, loaded: true, success: data && data.success});
                return data;
            } catch (ex) {
                console.error('Failed to remove from wishlist', ex);
                setLoadingState({loading: false, loaded: true, success: false});
            }
        },
        [wishlistItemsStore, setWishlistItemsStore, loadingState]
    );

    return {
        removeFromWishlist,
        ...loadingState
    };
};

export const useAddToCart = (offerId: string) => {
    const state = useReduxStore();
    const {handleAddToCart} = state;
    const {
        isAddingOffer,
        currentOffer,
        isPurchasingOffer,
        offerIds: offersInCart
    } = useShoppingCart();
    const isAddingToCart = isAddingOffer && currentOffer === offerId;
    const isAddedToCart = offersInCart.includes(offerId);

    const addToCart = useCallback(
        e => {
            if (isAddedToCart || isAddingToCart || isPurchasingOffer) {
                return;
            }
            const ele = $(e.target)
                .closest('.wishlist-item')
                .find('.wishlist-clickable')
                .first();
            handleAddToCart({offerId, ele});
        },
        [isAddingToCart, isPurchasingOffer, offersInCart, offerId, handleAddToCart]
    );

    return {addToCart};
};
