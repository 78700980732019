import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './AssetDownloadProgress.sass';

class AssetDownloadProgress extends PureComponent {
    humanReadableUnits = (value) => {
        let humanReadableValue = value;
        let unit = 'B';
        if (humanReadableValue >= 100) {
            humanReadableValue /= 1024;
            unit = 'KB';
        }
        if (humanReadableValue >= 100) {
            humanReadableValue /= 1024;
            unit = 'MB';
        }
        if (humanReadableValue >= 100) {
            humanReadableValue /= 1024;
            unit = 'GB';
        }
        if (humanReadableValue >= 100) {
            humanReadableValue /= 1024;
            unit = 'TB';
        }
        return (Math.round(humanReadableValue * 10) / 10) + unit;
    };

    runDownloadCommand = (command) => () => {
        const {executeDownloadCommand} = this.props;
        executeDownloadCommand(command);
    };

    render() {
        const {downloadProgress} = this.props;
        const {progress, downloadspeed, downloadcurrent, downloadtotal, statustext, bcanresume, bcanpause, bcancancel} = downloadProgress;
        let inProgress = progress >= 0;
        const progressBarStyle = { minWidth: '2em' };
        let progressPercent;
        if (inProgress) {
            progressPercent = `${Math.floor(progress * 100)}%`;
        } else {
            progressPercent = '100%';
        }
        progressBarStyle.width = progressPercent;
        if (downloadspeed < 0) {
            inProgress = false;
        }
        const currentSpeed = `${this.humanReadableUnits(downloadspeed)}/s`;
        const currentDownload = this.humanReadableUnits(downloadcurrent);
        const downloadTotal = this.humanReadableUnits(downloadtotal);
        const downloadProgressText = (downloadtotal > 0) ? `${currentDownload}/${downloadTotal}` : '';

        return (
            <div id="asset-acquire-progress-container">
                <div id="asset-download-progress" className="asset-progress-container row">
                    <div className="col-sm-9">
                        <div className="progress">
                            <div
                                id="asset-download-progress-bar"
                                className={`progress-bar ${inProgress ? '' : 'progress-bar-striped active'}`}
                                style={progressBarStyle} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="btn-group btn-group-xs" role="group">
                            {bcanresume && (
                                <button type="button" className="btn btn-default" onClick={this.runDownloadCommand('resume')}>
                                    <i className="fa fa-play" />
                                </button>)}
                            {bcanpause && (
                                <button type="button" className="btn btn-default" onClick={this.runDownloadCommand('pause')}>
                                    <i className="fa fa-pause" />
                                </button>)}
                            {bcancancel && (
                                <button type="button" className="btn btn-default" onClick={this.runDownloadCommand('cancel')}>
                                    <i className="fa fa-times" />
                                </button>)}
                        </div>
                    </div>

                    <div className="col-xs-12 text-center">
                        <div className="progress-text-container">
                            <span>{statustext}</span>
                            <span>{inProgress ? progressPercent : ''}</span>
                            <span>{(!bcanresume && inProgress) ? `${currentSpeed} - ${downloadProgressText}` : downloadProgressText}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AssetDownloadProgress.propTypes = {
    executeDownloadCommand: PropTypes.func,
    downloadProgress: PropTypes.object
};
export default AssetDownloadProgress;
