import { fromJS } from 'immutable';
import typeToReducer from 'type-to-reducer';
import { ConfigActions } from '../actions';
import { getError } from '../utils';

const {
    SET_CONFIG,
    GET_SUGGEST_TAGS,
    GET_LAUNCHER_OPTIONS
} = ConfigActions.TYPES;

export const DEFAULT_STATE = fromJS({
    useEpicHeader: true,
    isLoadingTags: false,
    tagData: [],
    tagError: {}
});

export default typeToReducer({
    [SET_CONFIG]: (state, action) => {
        return state.merge(action.payload);
    },
    [GET_SUGGEST_TAGS]: {
        PENDING: (state, action) => (state.merge({
            isLoadingTags: true,
            tagData: [],
            tagError: ''
        })),
        SUCCESS: (state, action) => (state.merge({
            isLoadingTags: false,
            tagData: action.payload
        })),
        FAILED: (state, action) => (state.merge({
            tagError: getError(action.payload),
            isLoadingTags: false
        }))
    },
    [GET_LAUNCHER_OPTIONS]: {
        SUCCESS: (state, action) => (state.merge(action.payload))
    }
}, DEFAULT_STATE);