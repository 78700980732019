/**
 * Created by Kaku.Guo on 2018/10/29.
 */
import axios from 'axios';
import Url from '../constants/Url';
import {getLocale} from '@epic-core/common';

const bearerTokenHeaders = token => {
    return {
        headers: {
            'x-epic-bearer-token': token || '',
            authorization: token ? `Bearer ${token}` : ''
        }
    };
};

class AssetApi {
    listAssets(params, token) {
        const url = Url().ASSET.LIST;
        return axios
            .get(
                url,
                Object.assign({params: {lang: getLocale(), ...params}}, bearerTokenHeaders(token))
            )
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    suggestAssets(params) {
        return axios
            .get(Url().ASSET.SUGGEST, {params: {lang: getLocale(), ...params}})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    vaultAssets(params) {
        return axios
            .get(Url().ASSET.VAULT, {params: {lang: getLocale(), ...params}})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getHomeData(token) {
        const url = Url().HOME_DATA.GET;
        return axios
            .get(url, bearerTokenHeaders(token))
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    getAsset(slug, token) {
        const url = Url().ASSET.GET(slug);
        return axios
            .get(url, Object.assign({params: {lang: getLocale()}}, bearerTokenHeaders(token)))
            .then(response => {
                return response.data.data;
            })
            .catch(ex => {
                throw ex;
            });
    }

    getItem(item, token) {
        const url = Url().ASSET.ITEM(item);
        return axios
            .get(url, Object.assign({params: {lang: getLocale()}}, bearerTokenHeaders(token)))
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }

    sellerAssets({sellerName, ...params}) {
        return axios
            .get(Url().ASSET.SELLER(sellerName), {params: {lang: getLocale(), ...params}})
            .then(response => response.data.data)
            .catch(ex => {
                throw ex;
            });
    }
}
export default new AssetApi();
