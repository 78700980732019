/**
 * Created by Kaku.Guo on 2018/12/12.
 */
import {fromJS} from 'immutable';
import typeToReducer from 'type-to-reducer';
import {ShoppingCartActions} from '../actions';
import {getError} from '../utils';

const {
    GET_OFFERS,
    ADD_OFFER,
    REMOVE_OFFER,
    CLEAR_OFFERS,
    PURCHASE,
    RESET_MAX_AMOUNT_ALERT
} = ShoppingCartActions.TYPES;

export const DEFAULT_STATE = fromJS({
    isAddingOffer: false,
    isLoadingOffer: false,
    isPurchasingOffer: false,
    isReachMaxLimit: false,
    currentOffer: '',
    offers: [],
    offerIds: [],
    totalPrice: 0,
    errorMessage: '',
    addErrorMessage: '',
    appliedCoupon: false,
    totalCouponDiscount: 0,
    totalDiscountPrice: 0
});

export default typeToReducer(
    {
        [GET_OFFERS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingOffer: true,
                    errorMessage: ''
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoadingOffer: false,
                    offers: action.payload.offers || [],
                    offerIds: action.payload.offerIds || [],
                    totalPrice: action.payload.totalPrice || 0,
                    totalAmount: action.payload.totalAmount || 0,
                    totalDiscountPrice: action.payload.totalDiscountPrice || 0,
                    totalCouponDiscount: action.payload.totalCouponDiscount || 0,
                    appliedCoupon: action.payload.appliedCoupon || false
                }),
            FAILED: (state, action) =>
                state.merge({
                    errorMessage: getError(action.payload),
                    isLoadingOffer: false
                })
        },
        [ADD_OFFER]: {
            PENDING: (state, action) =>
                state.merge({
                    isAddingOffer: true,
                    currentOffer: action.payload,
                    addErrorMessage: ''
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isAddingOffer: false,
                    currentOffer: '',
                    offerIds: action.payload
                }),
            FAILED: (state, {payload}) => {
                const newState = {
                    isAddingOffer: false,
                    currentOffer: ''
                };
                if (payload.isReachMaxLimit) {
                    newState.isReachMaxLimit = true;
                } else {
                    newState.addErrorMessage = getError(payload);
                }
                return state.merge(newState);
            }
        },
        [REMOVE_OFFER]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingOffer: true,
                    offers: [],
                    errorMessage: ''
                }),
            FAILED: (state, action) =>
                state.merge({
                    errorMessage: getError(action.payload),
                    isLoadingOffer: false
                })
        },
        [CLEAR_OFFERS]: {
            PENDING: (state, action) =>
                state.merge({
                    isLoadingOffer: true,
                    errorMessage: ''
                }),
            SUCCESS: (state, action) =>
                state.merge({
                    isLoadingOffer: false,
                    offers: [],
                    offerIds: []
                }),
            FAILED: (state, action) =>
                state.merge({
                    errorMessage: getError(action.payload),
                    isLoadingOffer: false
                })
        },
        [PURCHASE]: {
            PENDING: (state, action) => {
                action.payload.isPurchasingOffer = state.get('isPurchasingOffer');
                return state.merge({
                    isPurchasingOffer: true,
                    errorMessage: ''
                });
            },
            SUCCESS: (state, action) => {
                let {offerIds, offers} = state.toJS();
                if (action.payload && action.payload.offers) {
                    const purchasedIds = action.payload.offers.map(it => it.offerid);
                    offerIds = offerIds.filter(it => !purchasedIds.includes(it));
                    offers = offers.filter(it => !purchasedIds.includes(it.id));
                }
                return state.merge({
                    isPurchasingOffer: false,
                    offers,
                    offerIds
                });
            },
            FAILED: (state, action) =>
                state.merge({
                    errorMessage: getError(action.payload),
                    isPurchasingOffer: false
                })
        },
        [RESET_MAX_AMOUNT_ALERT]: state =>
            state.merge({
                isReachMaxLimit: false
            })
    },
    DEFAULT_STATE
);
