/**
 * Created by Kaku.Guo on 2018/11/22.
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {UnrealLoading} from 'epic-ue-loading';
import {AssetActions, ShoppingCartActions, ConfigActions} from '../../actions';
import {AssetDetail, getMessage} from '../../components';
import {combinePageTitle} from '../../utils';
import {getHelmetMetaTags, getHelmetStructuredData} from '../../utils/metaTagUtils';

const mapDispatchToProps = dispatch => {
    return {
        getAsset: model => {
            dispatch(AssetActions.getAsset(model));
        },
        handleAddToCart: model => {
            dispatch(ShoppingCartActions.addOffer(model));
        },
        purchase: model => {
            dispatch(ShoppingCartActions.purchase(model));
        },
        setConfig: model => {
            dispatch(ConfigActions.setConfig(model));
        }
    };
};

const mapStateToProps = state => {
    const {isLoading, data, helmet} = state.get('asset').toJS();
    const {isLoggedIn, accountInfo = {}} = state.get('user').toJS();
    const {isShoppingCartEnabled, globalBase, isLauncher, tagNoAI} = state.get('config').toJS();
    const {isAddingOffer, currentOffer, offerIds} = state.get('shoppingCart').toJS();
    const {messageProperties, locale} = state.get('localization').toJS();
    const {rating} = state.get('review').toJS();
    return {
        messages: messageProperties,
        locale,
        isLoading,
        data,
        helmet,
        isLoggedIn,
        isShoppingCartEnabled,
        globalBase,
        isLauncher,
        tagNoAI,
        isAddingOffer,
        currentOffer: currentOffer ? currentOffer.offerId : '',
        offersInCart: offerIds,
        currentAccountId: accountInfo.id,
        rating
    };
};

@connect(mapStateToProps, mapDispatchToProps)
class AssetDetailWrap extends Component {
    constructor(props) {
        super(props);
        props.setConfig({
            contentHeader: {
                code: 'messages.com.epicgames.plugin.store.header.content_detail'
            }
        });
    }

    UNSAFE_componentWillReceiveProps({
        match: {
            params: {slug, id}
        }
    }) {
        const {
            match: {
                params: {slug: oldSlug, id: oldId}
            },
            getAsset
        } = this.props;
        const newValue = slug || id;
        const oldValue = oldSlug || oldId;
        if (newValue && newValue !== oldValue) {
            getAsset({slug: newValue, isItem: !slug});
        }
    }

    getHelmetElement = () => {
        const {data, messages, helmet, rating, locale, tagNoAI} = this.props;
        let helmetData = null;
        if (helmet && helmet.data) {
            helmetData = helmet.data;
        }
        if (data && data.data) {
            helmetData = data.data;
        }
        if (helmetData) {
            const {title, categories = [], description, featured, tags} = helmetData;
            const cateName = categories
                .reduce((prev, next) => {
                    if (next && next.name) {
                        prev.push(next.name);
                    }
                    return prev;
                }, [])
                .join(',');
            const pageTitle = `${combinePageTitle(
                messages,
                getMessage,
                'messages.com.epicgames.plugin.store.title.content_detail',
                [title, cateName]
            )}${getMessage('messages.com.epicgames.plugin.store.title.suffix')}`;

            const {meta, link, title: titleTag} = getHelmetMetaTags({
                title: pageTitle,
                description,
                image: featured,
                locale
            });

            if (tags && tags.length) {
                meta.push({
                    name: 'keywords',
                    content: tags.map(it => it.name).join(',')
                });
                tags.forEach(tag => {
                    if (tag && tag.id) {
                        if (tag.id === `${tagNoAI}`) {
                            meta.push({
                                name: 'robots',
                                content: 'noai, noimageai'
                            });
                        }
                    }
                });
            }
            const script = getHelmetStructuredData({
                name: title,
                description,
                category: cateName,
                price: helmetData.price,
                releaseDate: helmetData.effectiveDate,
                images: [featured],
                currencyCode: helmetData.currencyCode,
                rating,
                ...helmetData
            });
            return <Helmet title={titleTag} meta={meta} link={link} script={script} />;
        }
        return null;
    };

    componentDidMount() {
        const {match, getAsset, data} = this.props;
        const assetData = data.data || {};
        const storedSlug = assetData.urlSlug;
        const params = match.params || {};
        let slug = params.slug;
        const isSameAsset = storedSlug === slug || assetData.id === params.id;
        if (!data || !data.data || !isSameAsset) {
            let isItem = false;
            if (!slug) {
                slug = params.id;
                isItem = true;
            }
            getAsset({slug, isItem});
        }
    }

    handlePurchase = ({id, namespace}) => e => {
        if (e) {
            e.preventDefault();
        }
        const {purchase} = this.props;
        purchase({
            namespace,
            offers: [id],
            isShoppingCart: false,
            quantity: 1
        });
    };

    render() {
        const {
            isLoading,
            data,
            messages = {},
            isLoggedIn,
            isShoppingCartEnabled,
            offersInCart,
            currentOffer,
            globalBase,
            isAddingOffer,
            handleAddToCart,
            isLauncher,
            locale,
            currentAccountId,
            isLearnAsset = false
        } = this.props;
        if (!data || isLoading) {
            return <UnrealLoading fillViewHeight={false} />;
        }
        const {data: assetData = {}} = data;

        const commonProps = {
            locale,
            messages,
            isAddingOffer,
            currentOffer,
            offersInCart,
            handleAddToCart,
            isLauncher,
            currentAccountId
        };

        return (
            <Fragment>
                {this.getHelmetElement()}
                <AssetDetail
                    data={assetData}
                    isLinkSeller={true}
                    isLoggedIn={isLoggedIn}
                    isShoppingCartEnabled={isShoppingCartEnabled}
                    globalBase={globalBase}
                    handlePurchase={this.handlePurchase}
                    isLearnAsset={isLearnAsset}
                    {...commonProps}
                />
            </Fragment>
        );
    }
}
AssetDetailWrap.propTypes = {
    match: PropTypes.object,
    messages: PropTypes.object,
    isLoading: PropTypes.bool,
    data: PropTypes.object,
    helmet: PropTypes.object,
    getAsset: PropTypes.func,
    setConfig: PropTypes.func,
    isShoppingCartEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    offersInCart: PropTypes.array,
    globalBase: PropTypes.string,
    isAddingOffer: PropTypes.bool,
    handleAddToCart: PropTypes.func,
    purchase: PropTypes.func,
    history: PropTypes.object,
    currentOffer: PropTypes.string,
    isLauncher: PropTypes.bool,
    locale: PropTypes.string,
    currentAccountId: PropTypes.string,
    isLearnAsset: PropTypes.bool,
    rating: PropTypes.object,
    tagNoAI: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
export default withRouter(AssetDetailWrap);
