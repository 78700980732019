import {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {HttpContext} from '../../server/utils/httpContext';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';

export interface RedirectProps {
    to?: string; //a pathname without the locale e.g. /channels/unreal-engine
    status?: number;
}
export const FabRedirect = ({to, status = 301}: RedirectProps): null => {
    if (!to) {
        throw new Error('FabRedirect component requires "to" to be specified');
    }
    const clientEnvConfig = root.clientEnvConfig || {};
    const fabBaseUrl = clientEnvConfig.EPIC_FAB_BASE;

    const params = useParams();
    const context = useContext(HttpContext);
    const locale = params.locale || getLocale();
    const fabLocale = locale === 'en-US' ? '' : locale;

    const redirectUrl = `${fabBaseUrl}${fabLocale === '' ? '' : `/${locale}`}${to}`;

    useEffect(() => {
        root.location.href = redirectUrl;
    }, [redirectUrl]);

    if (context) {
        context.status = status;
        context.url = redirectUrl;
    }

    return null;
};
