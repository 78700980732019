/**
 * Created by Kaku.Guo on 2018/11/12.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {getMessage, Link, AssetTile} from '../index';
import FeaturedContent from './FeaturedContent';

const HomeSection = props => {
    const {isFeatured, className, messages, header, linkText, linkPath, data} = props;
    if (!data || !data.length) {
        return null;
    }
    return (
        <section className={`assets-block ${className}`}>
            <header className="sub-header">
                <h1>{getMessage(messages, header)}</h1>
                <Link to={linkPath}>{getMessage(messages, linkText)}</Link>
            </header>
            {isFeatured ? (
                <FeaturedContent {...props} />
            ) : (
                <div className="asset-list-group">
                    {data.map((asset, idx) => (
                        <AssetTile
                            {...props}
                            index={idx}
                            key={`asset-${idx}`}
                            data={asset}
                            isNewTagEnabled={true}
                        />
                    ))}
                </div>
            )}
        </section>
    );
};
HomeSection.propTypes = {
    isFeatured: PropTypes.bool,
    className: PropTypes.string,
    messages: PropTypes.object,
    header: PropTypes.string,
    linkText: PropTypes.string,
    linkPath: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    data: PropTypes.array
};
export default HomeSection;
