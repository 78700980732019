/**
 * Created by Kaku.Guo on 2018/10/24.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Link as ReactLink} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';

const Link = props => {
    const {to, children, replace, className, onClick} = props;
    let finalTo;
    if (typeof to === 'string') {
        finalTo = generateRoutePath(to);
    } else if (typeof to === 'object' && to.pathname !== null) {
        finalTo = Object.assign(to, {pathname: generateRoutePath(to.pathname)});
    }
    const handleClick = e => {
        if (onClick) {
            onClick(e);
        }
    };
    return (
        <ReactLink to={finalTo} replace={replace} className={className} onClick={handleClick}>
            {children}
        </ReactLink>
    );
};
Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    children: PropTypes.any,
    replace: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func
};
export default Link;
