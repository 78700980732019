/* eslint-disable @typescript-eslint/no-use-before-define */

import root from 'window-or-global';
import React, {useEffect, useState} from 'react';

import {loadTalon} from '../../utils';

interface CaptchaProps {
    onSolved: (solveToken, flowId) => {};
    onClosed: () => {};
    onError: (error) => {};
}

const Captcha: React.FC<CaptchaProps> = (props: CaptchaProps): JSX.Element => {
    const {onSolved: solvedCb, onClosed: closedCb, onError: errorCb} = props;
    const [solveToken, setSolveToken] = useState<string>('');
    const flowId = root.clientEnvConfig.EPIC_TALON_FLOW_ID || '';

    // 1. Initialize talon if not already done so, otherwise initialize our flow
    useEffect(() => {
        if (!root.talon) {
            initializeTalon();
        } else {
            initializeFlow();
        }
    }, []);

    // 2. Append the talon script to document.head, then initialize our flow
    const initializeTalon = async (): Promise<any> => {
        await loadTalon()
            .then(initializeFlow)
            .catch(e => errorCb(e));
    };

    // 3. Configure talon to use our flowId with the desired callbacks
    const initializeFlow = () => {
        root.talon.load({
            flow: flowId,
            onReady,
            onComplete,
            onClosed,
            onError
        });
    };

    // 4. Once initialized, execute our flowId
    const onReady = () => executeFlow();

    // 5. Get talon to present an interactive challenge to high-risk users, otherwise passively complete flow
    const executeFlow = () => root.talon.execute(flowId);

    // 6a. Once the flow is completed (actively or passively), set the solveToken and invoke the onComplete callback
    const onComplete = response => setSolveToken(response);

    // 6b. If the user aborts the flow, invoke the onClosed callback
    const onClosed = () => closedCb && closedCb();

    // 6c. If the user aborts the flow, invoke the onError callback
    const onError = e => errorCb && errorCb(e);

    // 7. Once solveToken is set, use it to verify some corresponding request
    useEffect(() => {
        if (solveToken) solvedCb && solvedCb(solveToken, flowId);
    }, [solveToken]);

    return <div />;
};

export default Captcha;
