/**
 * Created by Kaku.Guo on 2019/5/28.
 */

const TYPES = {
    SET_OPTIONS: 'SET_OPTIONS',
    MERGE_OPTIONS: 'MERGE_OPTIONS',
    HIDE_REVIEW_DIALOG: 'HIDE_REVIEW_DIALOG'
};
export default {
    TYPES,
    setOptions: payload => ({
        type: TYPES.SET_OPTIONS,
        payload
    }),
    mergeOptions: payload => ({
        type: TYPES.MERGE_OPTIONS,
        payload
    }),
    hide: payload => ({
        type: TYPES.HIDE_REVIEW_DIALOG,
        payload
    })
};
