export interface ServiceError {
    code?: string;
    message: string;
    numericErrorCode: number;
}

export interface ServiceResponse<D = undefined> {
    success: boolean;
    error?: ServiceError;
    data?: D;
}

export interface GenerateFriendCodeRequest {
    inviteeName: string;
    needCommit: false;
    endDate: string;
    description: string;
    codeRuleId: string;
    startDate: string;
    labels: string[];
}

export interface CriteriaChecks {
    data: string;
    type: string;
}

export interface Criteria {
    action: string;
    checks: CriteriaChecks[];
    elseAction: string;
    operator: string;
    rejectErrorType: string;
}

export interface ConsumptionMetadata {
    criteria: Criteria;
    namespace: string;
    offerId: string;
}

export interface CodeInfo {
    code: string;
    namespace: string;
    creator: string;
    dateCreated: string; // datetime string
    startDate: string; // datetime string
    allowedUsers: string[];
    allowedCountries: string[];
    allowedClients: string[];
    distributionMetadata: any;
    allowedDistributionClients: string[];
    codeType: string;
    maxNumberOfUses: number;
    allowRepeatedUsesBySameUser: boolean;
    useCount: number;
    consumptionMetadata: ConsumptionMetadata;
    codeStatus: string;
    batchId: string;
    batchNumber: number;
    labels: string[];
    codeRuleId: string;
}

export interface LockedCodeInfo extends CodeInfo {
    anonymousUse: boolean;
    batchId: string;
    batchNumber: number;
    codeId: string;
    codeType: string;
    codeUseId: string;
    consumingUser: string;
    consumptionMetadata: ConsumptionMetadata;
    country: string;
    distributionMetadata: any;
    namespace: string;
    redeemAction: string;
    redeemEnded: string; // datetime string
    redeemStarted: string; // datetime string
    redeemingClient: string;
    useData: any;
    useNumber: number;
}

export interface CodeRedemption {
    elements: LockedCodeInfo[];
    paging: CodeRedemptionPaging;
}

export interface CodeRedemptionPaging {
    count: number;
    start: number;
    total: number;
}

export interface EntitlementDetails {
    entitlementId: string;
    itemId: string;
    namespace: string;
    country: string;
}

export interface RedeemedCodeResponse {
    offerId: string;
    accountId: string;
    identityId: string;
    details: EntitlementDetails[];
    accountRoleNames: string[];
}

export interface Currency {
    currency: string;
    minSalePrice: number;
    reward: number;
}

export enum EventType {
    DISCOUNT = 'DISCOUNT',
    VOUCHER = 'VOUCHER'
}

export interface EventValue {
    expireDate: string;
    grantInitialVoucher: boolean;
    label: string;
    link: string;
    priceCurrencies: Currency[];
}

export interface SalesEvent {
    endDate: string;
    eventName: string;
    id: string;
    eventType: EventType;
    eventValue: EventValue;
    merchantGroups: string[];
    startDate: string;
}

export interface FiscalStimulusNormalParams {
    country: string;
    activityName: string;
    checkLabelUnique: string;
    safeForRetry: boolean;
    referId: string;
}

export interface FiscalStimulusSimplifiedParams {
    country: string;
}

export interface FiscalStimulusOptions {
    params: FiscalStimulusNormalParams | FiscalStimulusSimplifiedParams;
    simplified?: boolean;
}

export interface CommonCurrencyDisplay {
    amount: string;
    currency: string;
    symbol: string;
    placement: string;
}

export interface FiscalStimulusConsumptionMetadata {
    amount: number;
    valueName: string;
    activityName: string;
    minSalesPrice: number;
    currency: string;
    // only simplified version includes this
    minSalesPriceDisplay?: CommonCurrencyDisplay;
    // only simplified version includes this
    amountDisplay?: CommonCurrencyDisplay;
}

export interface FiscalStimulusGrantData {
    code: string;
    namespace: string;
    creator: string;
    dateCreated: string; // datetime string
    endDate: string; // datetime string
    allowedUsers: string[];
    allowedCountries: string[];
    allowedClients: string[];
    distributionMetadata: any;
    allowedDistributionClients: string[];
    codeType: string;
    maxNumberOfUses: number;
    allowRepeatedUsesBySameUser: boolean;
    useCount: number;
    completedCount: number;
    consumptionMetadata: FiscalStimulusConsumptionMetadata;
    codeStatus: string;
    batchNumber: number;
    labels: string[];
}

export type FiscalStimulusGrantResponse = ServiceResponse<FiscalStimulusGrantData> | ServiceResponse<null>;
