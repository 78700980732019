import root from 'window-or-global';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import styled from 'styled-components';
import useLocalStorageState from 'use-local-storage-state';
import {getOptImage} from 'epic-ue-shared';
import DialogCloseButton from '../common/button/DialogCloseButton';

const Background = styled.div`
    background-image: url(${p => getOptImage({url: p.desktopImage, width: 1920})});
    @media (max-width: 770px) {
        background-image: url(${p => getOptImage({url: p.smallImage, width: 770})});
    }
`;

const mapStateToProps = state => {
    const {banner, dismissibleBanner} = state.get('config').toJS();

    return {
        banner,
        dismissibleBanner: dismissibleBanner || {}
    };
};

const DismissibleBanner = props => {
    const {banner, dismissibleBanner} = props;
    const {title, buttonLabel, textColor, url, desktopImage, smallImage} = dismissibleBanner;

    const [isBannerDismissed, setIsBannerDismissed] = useLocalStorageState(
        'UEMP_DISMISSIBLE_BANNER',
        {
            defaultValue: 'show'
        }
    );
    const disableRender = isBannerDismissed === 'dismissed';
    // don't render banner if localStorage isBannerDismissed or if its SSR
    // don't render banner if image and url is available. otherwise, a large blank area will appear over the header
    if (root.__server_side_render || disableRender || !desktopImage || !smallImage || !url) {
        return null;
    }

    const handleClick = e => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }
        setIsBannerDismissed('dismissed');
    };

    // adjust spacing based on whether the other banner is present
    const dismissibleBannerStyle = {
        marginBottom: banner ? '36px' : '20px'
    };

    return (
        <a className="dismissibleBanner" href={url} style={dismissibleBannerStyle}>
            <Background
                className="dismissibleBanner__img"
                desktopImage={desktopImage}
                smallImage={smallImage}>
                <h3 className="dismissibleBanner__title" style={{color: textColor}}>
                    {title}
                </h3>
                <span className="dismissibleBanner__cta">
                    {buttonLabel ? buttonLabel : 'Learn More'}
                </span>
                <button className="dismissibleBanner__closeBtn" onClick={handleClick}>
                    <svg
                        className="dismissibleBanner__closeIcon"
                        width="28"
                        height="28"
                        viewBox="0 0 36 36">
                        <DialogCloseButton fillColor={textColor} />
                    </svg>
                </button>
            </Background>
        </a>
    );
};

DismissibleBanner.displayName = 'DismissibleBanner';

DismissibleBanner.propTypes = {
    banner: PropTypes.object,
    dismissibleBanner: PropTypes.object
};

export default connect(mapStateToProps)(DismissibleBanner);
