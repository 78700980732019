import typeToReducer from 'type-to-reducer';
import {fromJS} from 'immutable';
import {LauncherIntegrationActions} from '../actions';

const {
    INIT_LAUNCHER_DOWNLOAD,
    UPDATE_DOWNLOAD_PROGRESS,
    UPDATE_COMMAND,
    RESET_LAUNCHER_DOWNLOAD
} = LauncherIntegrationActions.TYPES;

export const DEFAULT_STATE = fromJS({
    downloadProgress: null,
    commandLists: null
});

export default typeToReducer({
    [INIT_LAUNCHER_DOWNLOAD]: (state, action) => (state.merge({
        isLoading: true,
        downloadProgress: null,
        commandLists: null
    })),
    [UPDATE_COMMAND]: (state, action) => (state.merge({
        isLoading: false,
        commandLists: action.payload
    })),
    [UPDATE_DOWNLOAD_PROGRESS]: (state, action) => (state.merge({
        isLoading: false,
        downloadProgress: action.payload
    })),
    [RESET_LAUNCHER_DOWNLOAD]: (state, action) => (state.merge({
        isLoading: false,
        downloadProgress: null,
        commandLists: null
    }))
}, DEFAULT_STATE);