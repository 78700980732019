/**
 * Created by Kaku.Guo on 2018/11/30.
 */
import typeToReducer from 'type-to-reducer';
import { fromJS } from 'immutable';
import { ErrorActions } from '../actions';

const {
    ERROR_OCCURRED,
    RESET_ERROR
} = ErrorActions.TYPES;

export const DEFAULT_STATE = fromJS({
    status: null,
    errorCode: null,
    args: null
});
export default typeToReducer({
    [ERROR_OCCURRED]: (state, action) => {
        return DEFAULT_STATE.merge(action.payload);
    },
    [RESET_ERROR]: (state, action) => (DEFAULT_STATE)
}, DEFAULT_STATE);