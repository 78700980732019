import {delay} from 'redux-saga';
import {call, put, takeLatest} from 'redux-saga/effects';
import {TraderDialogActions} from '../actions';
import {TraderApi} from '../apis';

function* hideTraderDialog() {
    yield put(TraderDialogActions.mergeOptions({isVisible: false}));
    yield call(delay, 300);
    yield put(TraderDialogActions.setOptions());
}

function* getTraderInfo(action) {
    try {
        const data = yield call(TraderApi.getTraderInfo, action.payload);
        yield put(TraderDialogActions.getTraderInfoSuccess(data));
    } catch (e) {
        console.error('Call TraderApi.getTraderInfo error: ', e.message);
        yield put(TraderDialogActions.getTraderInfoFailed(e));
    }
}

function* TraderSaga() {
    const {HIDE_TRADER_DIALOG} = TraderDialogActions.TYPES;

    yield takeLatest(HIDE_TRADER_DIALOG, hideTraderDialog);
    yield takeLatest(TraderDialogActions.TYPES.GET_TRADER_INFO_PENDING, getTraderInfo);
}

export default TraderSaga;
