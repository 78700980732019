/**
 * Created by Kaku.Guo on 2019/5/16.
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import { connect } from 'react-redux';
import cn from 'classnames';
import { getMessage } from '../../index';
import Pagination from 'rc-pagination';
import Select from 'rc-select';

const mapStateToProps = (state) => {
    const messages = state.get('localization').get('messageProperties');
    return { messages };
};
@connect(mapStateToProps)
export default class Paginator extends PureComponent {

    render() {
        const { className, messages, current, total, pageSizeOptions, defaultPageSize, pageSize, onShowSizeChange, onChange, handleChange } = this.props;
        const containerClassName = cn('pagination', className);
        const localeInfo = {
            items_per_page: getMessage(messages, 'messages.com.epicgames.plugin.store.paginate.items.per.page'),
            jump_to: getMessage(messages, 'messages.com.epicgames.plugin.store.paginate.jump.to')
        };

        const handlePageChange = (startPage, itemsPerPage) => {
            const newStart = (startPage * itemsPerPage) - itemsPerPage;
            handleChange({ start: newStart, count: itemsPerPage });
            root.scrollTo(0, 0);
        };

        return (
            <Pagination
                showTotal={(totalResults, range) => getMessage(messages, 'messages.com.epicgames.plugin.store.paginate.items.results.range', [range[0], range[1], totalResults])}
                hideOnSinglePage
                showTitle={false}
                showQuickJumper
                selectComponentClass={Select}
                showSizeChanger
                defaultPageSize={defaultPageSize}
                total={total}
                current={current}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                onShowSizeChange={onShowSizeChange || handlePageChange}
                onChange={onChange || handlePageChange}
                className={containerClassName}
                locale={localeInfo}
            />
        );
    }
}

Paginator.defaultProps = {
    pageCount: 1
};

Paginator.propTypes = {
    messages: PropTypes.object,
    pageSizeOptions: PropTypes.array,
    defaultPageSize: PropTypes.number,
    pageSize: PropTypes.number,
    current: PropTypes.number,
    total: PropTypes.number,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onShowSizeChange: PropTypes.func,
    handleChange: PropTypes.func
};