import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Spacer } from '../../index';

const FONT_WEIGHTS = {
    BOLD: 'bold',
    NORMAL: 'normal'
};

const FONT_STYLES = {
    ITALIC: 'italic',
    NORMAL: 'normal'
};

const TEXT_DECORATIONS = {
    UNDERLINE: 'underline'
};

const TEXT_ALIGNS = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center'
};

export default function Text(props) {
    const {
        align,
        className,
        decoration,
        fontStyle,
        size,
        weight,
        ...otherProps
    } = props;
    const textClasses = classNames(
        size && `font__size--${size}`,
        decoration && `text__dec--${decoration}`,
        weight && `font__weight--${weight}`,
        fontStyle && `font__style--${fontStyle}`,
        align && `text__align--${align}`,
        className
    );
    return (
        <Spacer
            className={textClasses}
            {...otherProps}
        />
    );
}

Text.propTypes = {
    align: PropTypes.oneOf(Object.values(TEXT_ALIGNS)),
    className: PropTypes.string,
    decoration: PropTypes.oneOf(Object.values(TEXT_DECORATIONS)),
    font: PropTypes.string,
    fontStyle: PropTypes.oneOf(Object.values(FONT_STYLES)),
    size: PropTypes.number,
    weight: PropTypes.oneOf(Object.values(FONT_WEIGHTS))
};

Text.defaultProps = {
    size: 16
};
