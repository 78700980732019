/**
 * Created by Kaku.Guo on 2018/10/29.
 */
import root from 'window-or-global';
import {call, put, takeLatest} from 'redux-saga/effects';
import {ConfigActions, UserActions} from '../actions';
import {ConfigApi, UserApi} from '../apis';

function* getSuggestTags(action) {
    try {
        const params = action.payload;
        const data = yield call(ConfigApi.getSuggestTags, params);
        yield put(ConfigActions.getSuggestTagsSuccess(data));
    } catch (e) {
        yield put(ConfigActions.getSuggestTagsFailed(e));
    }
}

function* getLauncherOptions(action) {
    const payload = action.payload;
    const {isShoppingCartEnabled: defaultEnabled, isLoggedIn} = payload;
    let isShoppingCartEnabled = false;
    try {
        const {apiversions} = yield root.ue.client.getversion();
        isShoppingCartEnabled = apiversions && apiversions.purchase && defaultEnabled;
    } catch (e) {
        console.error('Call ue.client.getversion error: ', e.message);
    }
    let launcherVersions = [];
    try {
        launcherVersions = yield root.ue.engines.getengines();
        launcherVersions = launcherVersions.map(it => `UE_${it.version.replace(/\.\d+$/, '')}`);
    } catch (e) {
        console.error('Call ue.engines.getengines error: ', e.message);
    }
    yield put(ConfigActions.getLauncherOptionsSuccess({isShoppingCartEnabled, launcherVersions}));

    if (!isLoggedIn) {
        try {
            const data = yield call(UserApi.getSso, {});
            yield put(UserActions.getSsoSuccess(data));
        } catch (e) {
            console.error('Call getSso error: ', e.message);
            yield put(UserActions.getSsoFailed(e));
        }
    }
}

function* getSso(action = {}) {
    try {
        const data = yield call(UserApi.getSso, action.payload);
        yield put(UserActions.getSsoSuccess(data));
    } catch (e) {
        console.error('Call UserApi.getSso error: ', e.message);
        yield put(UserActions.getSsoFailed(e));
    }
}

function* ConfigSaga() {
    yield takeLatest(ConfigActions.TYPES.GET_SUGGEST_TAGS_PENDING, getSuggestTags);
    yield takeLatest(ConfigActions.TYPES.GET_LAUNCHER_OPTIONS_PENDING, getLauncherOptions);
    yield takeLatest(UserActions.TYPES.GET_SSO_PENDING, getSso);
}

export default ConfigSaga;
