/**
 * Created by Kaku.Guo on 2019/6/27.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactGallery from 'react-image-gallery';
import {ModalDialog} from '../index';

export function FullScreenReducer(state, action = {}) {
    return action.payload || {};
}

function FullScreenModal({options = {}, dispatch}) {
    const {className, data = [], isVisible = false, currentIdx = 0} = options;

    const prefixCls = 'full-screen-modal';
    const imageData = data.map(it => ({
        original: it.imageUrl,
        thumbnail: it.thumbUrl || it.imageUrl
    }));
    const classNames = cn(prefixCls, className);
    return (
        <ModalDialog
            className={classNames}
            overlayClassName={`${prefixCls}__overlay`}
            isVisible={isVisible}
            onClose={() => dispatch()}>
            <span className={`${prefixCls}__close`} onClick={() => dispatch()} />
            <div className={`${prefixCls}__content`}>
                <ReactGallery
                    items={imageData}
                    startIndex={currentIdx}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails
                    infinite
                    lazyLoad
                    slideDuration={0}
                />
            </div>
        </ModalDialog>
    );
}

FullScreenModal.propTypes = {
    options: PropTypes.object,
    dispatch: PropTypes.func
};

export default FullScreenModal;
