/**
 * Created by Kaku.Guo on 2018/11/30.
 */
const TYPES = {
    REDIRECT_CHANGE: 'REDIRECT_CHANGE',
    FILTER_CHANGE: 'FILTER_CHANGE',
    SORT_CHANGE: 'SORT_CHANGE',
    PAGINATION_CHANGE: 'PAGINATION_CHANGE'
};
export default {
    TYPES,
    redirectChange: payload => ({
        type: TYPES.REDIRECT_CHANGE,
        payload
    }),
    filterChange: payload => ({
        type: TYPES.FILTER_CHANGE,
        payload
    }),
    sortChange: payload => ({
        type: TYPES.SORT_CHANGE,
        payload
    }),
    paginationChange: payload => ({
        type: TYPES.PAGINATION_CHANGE,
        payload
    })
};