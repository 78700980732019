import * as React from 'react';
import Modal from 'react-responsive-modal';
import cn from "classnames";
import styled from 'styled-components';

import {useEula, useEulaDialog} from "../../shared/eula/eula.hooks";

import {EulaContent} from "./EulaContent";
import {EulaAcceptForm} from "./EulaAcceptForm";

const EulaModal = styled(Modal)`
    background-color: #121212
`
const DialogSpace = styled.div`
    height: 10px;
`

const EulaDialog: React.FC = () => {
    const {visible, closeEulaDialog} = useEulaDialog();
    const { eulaContent, loading } = useEula();
    const {title, body, version} = eulaContent;

    const classNames = cn('enterprise-modal_dialog eula-modal_dialog');
    if (loading) {
        return null;
    }

    return (
        <EulaModal classNames={{
            modal: classNames,
            closeButton: 'dialog__close'
        }}
               open={visible}
               onClose={closeEulaDialog}
               closeOnOverlayClick={false}
               showCloseIcon={false}
               blockScroll={false}
               center={false}>
            <EulaContent title={title} content={body} version={version} />
            <DialogSpace />
            <EulaAcceptForm loaded={!!version} />
        </EulaModal>
    )
}

export default EulaDialog;
