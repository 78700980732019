/**
 * Created by Kaku.Guo on 2018/11/8.
 */
import { generateAsyncActions } from '../utils';

const TYPES = {
    RESET_MAX_AMOUNT_ALERT: 'RESET_MAX_AMOUNT_ALERT',
    ...generateAsyncActions('GET_OFFERS', 'ADD_OFFER', 'REMOVE_OFFER', 'CLEAR_OFFERS', 'PURCHASE', 'TRACK_PURCHASE_TYPE')
};
export default {
    TYPES,
    getOffers: payload => ({
        type: TYPES.GET_OFFERS_PENDING,
        payload
    }),
    getOffersSuccess: payload => ({
        type: TYPES.GET_OFFERS_SUCCESS,
        payload
    }),
    getOffersFailed: payload => ({
        type: TYPES.GET_OFFERS_FAILED,
        payload
    }),
    addOffer: payload => ({
        type: TYPES.ADD_OFFER_PENDING,
        payload
    }),
    addOfferSuccess: payload => ({
        type: TYPES.ADD_OFFER_SUCCESS,
        payload
    }),
    addOfferFailed: payload => ({
        type: TYPES.ADD_OFFER_FAILED,
        payload
    }),
    removeOffer: payload => ({
        type: TYPES.REMOVE_OFFER_PENDING,
        payload
    }),
    removeOfferSuccess: payload => ({
        type: TYPES.REMOVE_OFFER_SUCCESS,
        payload
    }),
    removeOfferFailed: payload => ({
        type: TYPES.REMOVE_OFFER_FAILED,
        payload
    }),
    clearOffers: payload => ({
        type: TYPES.CLEAR_OFFERS_PENDING,
        payload
    }),
    clearOffersSuccess: payload => ({
        type: TYPES.CLEAR_OFFERS_SUCCESS,
        payload
    }),
    clearOffersFailed: payload => ({
        type: TYPES.CLEAR_OFFERS_FAILED,
        payload
    }),
    purchase: payload => ({
        type: TYPES.PURCHASE_PENDING,
        payload
    }),
    purchaseSuccess: payload => ({
        type: TYPES.PURCHASE_SUCCESS,
        payload
    }),
    purchaseFailed: payload => ({
        type: TYPES.PURCHASE_FAILED,
        payload
    }),
    trackPurchaseType: payload => ({
        type: TYPES.TRACK_PURCHASE_TYPE_PENDING,
        payload
    }),
    trackPurchaseTypeSuccess: payload => ({
        type: TYPES.TRACK_PURCHASE_TYPE_SUCCESS,
        payload
    }),
    trackPurchaseTypeFailed: payload => ({
        type: TYPES.TRACK_PURCHASE_TYPE_FAILED,
        payload
    }),
    resetMaxAmountAlert: (payload) => ({
        type: TYPES.RESET_MAX_AMOUNT_ALERT,
        payload
    })
};